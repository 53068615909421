import React, { useState, useEffect } from "react";
import Switch from "react-switch";

const ActiveGateway = ({ data, onSelectGateway }) => {
  const [gateWay, setGateWay] = useState(data.isActive);

  const handleChangeGateWay = (checked) => {
    setGateWay(checked);
    onSelectGateway(checked, data.fa);
  };
  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <p className="blocked_title">{data.fa}</p>
      <Switch
        width={40}
        height={22}
        onChange={handleChangeGateWay}
        checked={gateWay}
        className="react-switch"
      />
    </div>
  );
};

export default ActiveGateway;
