import React, { useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Components
import Notes from "../components/chatBox/Notes";
import AllChat from "../components/chatBox/AllChat";
import Chat from "../components/chatBox/Chat";
import { Link } from "react-router-dom";

const ChatBox = ({ onClick, toggle }) => {
  const [toggleTab, settoggleTab] = useState(
    window.location.hash.slice(1) ? window.location.hash.slice(1) : "chat"
  );

  const dataToggle = [
    { href: "#chat", name: "Chat", label: "خوانده نشده" },
    { to: "/ticket-list", name: "Alerts", label: "همه" },
  ];

  return (
    <div className={`chatbox ${toggle === "chatbox" ? "active" : ""}`}>
      <div className="chatbox-close" onClick={() => onClick()}></div>
      <div className="custom-tab-1">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${toggleTab === "chat" ? "active" : ""}`}
              to="#"
              data-toggle="tab"
              href="#chat"
              onClick={() => settoggleTab("chat")}
            >
              چت های خوانده نشده
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${toggleTab === "alerts" ? "active" : ""}`}
              to="/ticket-list"
              data-toggle="tab"
              onClick={() => onClick()}
            >
              همه چت ها
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          <Chat
            PerfectScrollbar={PerfectScrollbar}
            toggle={toggle}
            toggleTab={toggleTab}
          />
          <Notes
            PerfectScrollbar={PerfectScrollbar}
            toggle={toggle}
            toggleTab={toggleTab}
          />
          <AllChat
            PerfectScrollbar={PerfectScrollbar}
            toggle={toggle}
            toggleTab={toggleTab}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
