import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import pMinDelay from "p-min-delay";
import { usersAction, ordersAction } from "../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { isValidEmail } from "../Validation/checkEmail";
import { isValidNationalCode } from "../Validation//checkNationalCode";
import { DefaultButton } from "../Defaults/DefaultButton";
import loadable from "@loadable/component";
import Pagination from "react-js-pagination";

const UserOpenOrders = ({ dispatch }) => {
  const route = useParams();
  const { id } = route;
  const [userOpenOrdersData, setUserOpenOrdersData] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [hasOrder, setHasOrder] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "order") setHasOrder(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const getAllOrders = () => {
    const userId = id;
    const status = "in_list";
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(usersAction.getAllOpenOrders({ page, limit, userId, status }))
        .then((r) => {
          setUserOpenOrdersData(r?.data?.data);
          setUsersMeta(r.data?.meta);
          console.log(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [hasOrder]);

  const removePairCoins = (orderId) => {
    dispatch(ordersAction.deleteAnOrder({ orderId }))
      .then((r) => {
        SUCCESS("سفارش با موفقیت حذف شد");
        getAllOrders();
      })
      .catch((ex) => {
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  };

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    const userId = id;
    const status = "in_list";
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        usersAction.getAllOpenOrders({
          page,
          limit,
          userId,
          status,
        })
      )
        .then((r) => {
          setUserOpenOrdersData(r?.data?.data);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }

  return (
    <div className="card-body px-0">
      <div className="table-responsive">
        {userOpenOrdersData?.length !== 0 ? (
          <div>
            <table className="table shadow-hover user-detail-bank card-table border-no tbl-btn short-one">
              <thead>
                <tr>
                  <th className={`text-center fs-10`} scope="col">
                    بازار
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    مقدار
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    قیمت واحد(تومان)
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    قیمت کل(تومان)
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    نوع تراکنش
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    تاریخ
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    ساعت
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {userOpenOrdersData?.map((item, idx) => {
                  return (
                    <tr className="line_hight10" key={idx}>
                      <td className="text-right fs-11" scope="row">
                        <img
                          className="cc_size ml-2"
                          // onError={(e) => {
                          //   e.target.onerror = null;
                          //   e.target.src = "./images/coins/noImg.png";
                          // }}
                          src={
                            process.env.REACT_APP_SOCKET_URL +
                            `assets/icon/${item?.coin?.toLowerCase()}.png`
                          }
                        />
                        {item?.coin.toUpperCase()}
                      </td>
                      <td
                        className={`text-center fs-11 ${
                          item.type === "buy"
                            ? "text-success"
                            : item.type === "sell"
                            ? "text-danger"
                            : null
                        }`}
                      >
                        {item.amount}
                      </td>
                      <td
                        className={`text-center fs-11 ${
                          item.type === "buy"
                            ? "text-success"
                            : item.type === "sell"
                            ? "text-danger"
                            : null
                        }`}
                      >
                        {Math.round(item?.priceUnit)
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td
                        className={`text-center fs-11 ${
                          item.type === "buy"
                            ? "text-success"
                            : item.type === "sell"
                            ? "text-danger"
                            : null
                        }`}
                      >
                        {Math.round(item?.price)
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td className={`text-center fs-11`}>
                        {item?.type === "buy" ? (
                          <span
                            className={`text-center fs-11 ${
                              item?.type === "buy"
                                ? "badge_success"
                                : item?.type === "sell"
                                ? "badge_danger"
                                : null
                            }`}
                          >
                            خرید
                          </span>
                        ) : item?.type === "sell" ? (
                          <span
                            className={`text-center fs-11 ${
                              item?.type === "buy"
                                ? "badge_success"
                                : item?.type === "sell"
                                ? "badge_danger"
                                : null
                            }`}
                          >
                            فروش
                          </span>
                        ) : null}
                      </td>
                      <td className="text-center fs-11">
                        {new Date(item.createdAt)?.toLocaleDateString("fa-IR")}
                      </td>
                      <td className="text-center fs-11">
                        {new Date(item.createdAt)?.toLocaleTimeString("fa-IR")}
                      </td>
                      <td className="text-center fs-11">
                        <a
                          className="cursor-pointer"
                          onClick={() => removePairCoins(item._id)}
                        >
                          <i
                            className="fa fa-trash fs-16"
                            style={{ color: "#ff5d5d" }}
                          ></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="card-content bg-primary p-3 rounded text-center">
            <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
          </div>
        )}
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">
            نمایش {usersMeta?.page * usersMeta?.limit - usersMeta?.limit + 1} تا{" "}
            {usersMeta?.page * usersMeta?.limit} از {usersMeta?.total} سفارش
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example5_paginate"
          >
            <div className="d-inline-block pb-3 ltr_direction">
              <Pagination
                activePage={page}
                itemsCountPerPage={usersMeta?.limit}
                totalItemsCount={usersMeta?.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UserOpenOrders);
