import React, { useState, useRef, useEffect } from "react";
import { Modal, Dropdown, Row, Button, Col, Spinner } from "react-bootstrap";
import { usersAction } from "./../../../_actions";
import { ERROR, SUCCESS } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";

import { Link } from "react-router-dom";
// import data from "./tableData.js";

const UsersTicketsTable = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [test, settest] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchFamily, setSearchFamily] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [description, setDescription] = useState("");
  const [usersListData, setUsersList] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [usersConfirmedMeta, setUsersConfirmedMeta] = useState([]);
  const [usersNotConfirmedMeta, setUsersNotConfirmedMeta] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const [identitiesAccept, setIdentitiesAccept] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [identitiesNotAccept, setIdentitiesNotAccept] = useState([]);
  const [active, setActive] = useState("notAccept");
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeToggle, setActiveToggle] = useState("all");

  const handleNameChange = (evt) => {
    setSearchName(evt.target.value);
  };

  const handleLastChange = (evt) => {
    setSearchFamily(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setSearchEmail(evt.target.value);
  };

  const handleMobileChange = (evt) => {
    setSearchMobile(evt.target.value);
  };

  const onChangeDesc = (evt) => {
    setDescription(evt.target.value);
  };

  useEffect(() => {
    dispatch(
      usersAction.getUsersList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setUsersList(r.data?.data);
        console.log(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(
      usersAction.getUsersList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setUsersList(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }

  useEffect(() => {
    setIsLoading(true);

    if (activeToggle === "confirmed") {
      const verify = true;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersConfirmedMeta(r.data?.meta);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (activeToggle === "waiting") {
      const verify = false;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesNotAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersNotConfirmedMeta(r.data?.meta);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [activeToggle]);

  const handleAuthPageChange = (page) => {
    setIsLoading(true);

    setPage(page);

    if (activeToggle === "confirmed") {
      const verify = true;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else if (activeToggle === "waiting") {
      const verify = false;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesNotAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersNotConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const searchByActive = () => {
    setIsLoading(true);

    setPage(page);

    if (activeToggle === "confirmed") {
      const verify = true;
      dispatch(
        usersAction.usersIdentityList({
          page,
          limit,
          verify,
          searchName,
          searchEmail,
          searchMobile,
        })
      )
        .then((r) => {
          setIdentitiesAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else if (activeToggle === "waiting") {
      const verify = false;
      dispatch(
        usersAction.usersIdentityList({
          page,
          limit,
          verify,
          searchName,
          searchEmail,
          searchMobile,
        })
      )
        .then((r) => {
          setIdentitiesNotAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersNotConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  async function searchBy() {
    setLoader(true);
    dispatch(
      usersAction.getUsersList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setUsersList(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(true);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  async function verifyUser(item, type) {
    const id = item._id;
    const firstName = item.firstName;
    const lastName = item.lastName;
    if (type === "accepted") {
      setSendLoader(true);
      dispatch(usersAction.acceptIdentity(id, firstName, lastName, true))
        .then((r) => {
          if (r.status === 200) {
            setIdentitiesAccept(
              identitiesAccept.filter((item) => item._id !== id)
            );
            SUCCESS(r?.data?.message || "حساب کاربر با موفقیت تایید شد");
            // fetchIdentitiesList();
            setSendLoader(false);
          }
        })
        .catch((ex) => {
          setSendLoader(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        })
        .finally(() => {
          setAcceptLoading(false);
          setBasicModal(false);
        });
    }
    if (type === "rejected") {
      setSendLoader(true);
      dispatch(usersAction.acceptIdentity(id, firstName, lastName, false))
        .then((r) => {
          if (r.status === 200) {
            setIdentitiesNotAccept(
              identitiesNotAccept.filter((item) => item._id !== id)
            );
            SUCCESS(r?.data?.message || "حساب کاربر رد شد");
          }
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setSendLoader(false);
          setAcceptLoading(false);
          setBasicModal(false);
        });
    }
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    chackboxFun();
  }, [test]);

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">لیست کاربران</h4>
        </div>

        <div className="tab-content">
          <div
            id="all"
            className={`tab-pane fade ${
              activeToggle === "all" ? "active show" : ""
            }`}
          >
            {" "}
            <Row className="p-3">
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام"}
                  id={"search"}
                  name="search"
                  value={searchName}
                  onChange={handleNameChange}
                />
              </Col>
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام خانوادگی"}
                  id={"searchF"}
                  name="searchF"
                  value={searchFamily}
                  onChange={handleLastChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ایمیل"}
                  id={"searchE"}
                  name="searchE"
                  value={searchEmail}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"موبایل"}
                  id={"searchM"}
                  name="searchM"
                  value={searchMobile}
                  onChange={handleMobileChange}
                />
              </Col>
              <Col lg={2}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchBy()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {usersListData && usersListData?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                            // style={{ width: 73 }}
                          >
                            نام و نام خانوادگی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            کد ملی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            موبایل
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                            // style={{ width: 62 }}
                          >
                            ایمیل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            نقش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {usersListData && usersListData?.length !== 0 ? (
                        usersListData?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                {item.firstName} {item.lastName}
                              </td>
                              <td>{item.meliCode ? item.meliCode : "-----"}</td>
                              <td>{item.mobile ? item.mobile : "-----"}</td>
                              <td>{item.email ? item.email : "-----"}</td>
                              <td>
                                <span
                                  className={`badge light fs-10 ${
                                    item.role === "admin"
                                      ? "badge-info"
                                      : item.role === "user"
                                      ? "badge-primary"
                                      : item.role === "panel"
                                      ? "badge-warning"
                                      : item.role === "setting"
                                      ? "badge-success"
                                      : item.role === "config"
                                      ? "badge-secondary"
                                      : item.role === "role"
                                      ? "badge-danger"
                                      : item.role === "banking"
                                      ? "badge-dark"
                                      : item.role === "coining"
                                      ? "badge-light"
                                      : item.role === "order"
                                      ? "badge-primary"
                                      : item.role === "otc"
                                      ? "badge-info"
                                      : item.role === "trade"
                                      ? "badge-success"
                                      : item.role === "notification"
                                      ? "badge-warning"
                                      : item.role === "ticket"
                                      ? "badge-info"
                                      : item.role === "trace"
                                      ? "badge-danger"
                                      : item.role === "affiliate"
                                      ? "badge-secondary"
                                      : item.role === "thirdParty"
                                      ? "badge-primary"
                                      : item.role === "balance"
                                      ? "badge-success"
                                      : ""
                                  }`}
                                >
                                  <i
                                    className={`fa fa-circle ${
                                      item.role === "admin"
                                        ? "text-info"
                                        : item.role === "user"
                                        ? "text-primary"
                                        : item.role === "panel"
                                        ? "text-warning"
                                        : item.role === "setting"
                                        ? "text-success"
                                        : item.role === "config"
                                        ? "text-secondary"
                                        : item.role === "role"
                                        ? "text-danger"
                                        : item.role === "banking"
                                        ? "text-dark"
                                        : item.role === "coining"
                                        ? "text-light"
                                        : item.role === "order"
                                        ? "text-primary"
                                        : item.role === "otc"
                                        ? "text-info"
                                        : item.role === "trade"
                                        ? "text-success"
                                        : item.role === "notification"
                                        ? "text-warning"
                                        : item.role === "ticket"
                                        ? "text-info"
                                        : item.role === "trace"
                                        ? "text-danger"
                                        : item.role === "affiliate"
                                        ? "text-secondary"
                                        : item.role === "thirdParty"
                                        ? "text-primary"
                                        : item.role === "balance"
                                        ? "text-success"
                                        : ""
                                    } ml-1`}
                                  />

                                  {item.role === "admin"
                                    ? "ادمین"
                                    : item.role === "user"
                                    ? "مدیریت کاربران"
                                    : item.role === "panel"
                                    ? "مدیریت پنل"
                                    : item.role === "setting"
                                    ? "مدیریت تنظیمات"
                                    : item.role === "config"
                                    ? "مدیریت کانفیگ ها"
                                    : item.role === "role"
                                    ? "مدیریت نقش ها"
                                    : item.role === "banking"
                                    ? "مدیریت حساب های بانکی"
                                    : item.role === "coining"
                                    ? "مدیریت کیف پول ها"
                                    : item.role === "order"
                                    ? "مدیریت سفارش ها"
                                    : item.role === "otc"
                                    ? "مدیریت سفارشات سریع"
                                    : item.role === "trade"
                                    ? "مدیریت معاملات"
                                    : item.role === "notification"
                                    ? "مدیریت پیام ها"
                                    : item.role === "ticket"
                                    ? "مدیریت تیکت ها"
                                    : item.role === "trace"
                                    ? "مدیریت Trace ها"
                                    : item.role === "affiliate"
                                    ? "مدیریت کدهای دعوت"
                                    : item.role === "thirdParty"
                                    ? "مدیریت تنظیمات OTC"
                                    : item.role === "balance"
                                    ? "مدیریت موجودی ها"
                                    : ""}
                                </span>
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                <Link
                                  to={`chats/${item._id}`}
                                  className="btn-link text-info confirm_bank_account float-right"
                                >
                                  نمایش چت ها
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {usersMeta?.page * usersMeta?.limit -
                        usersMeta?.limit +
                        1}{" "}
                      تا {usersMeta?.page * usersMeta?.limit} از{" "}
                      {usersMeta?.total} کاربر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={usersMeta?.limit}
                          totalItemsCount={usersMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UsersTicketsTable);
