import { httpRequest } from "./../_helper";

export const panelActions = {
  userPanel,
  userTrades,
  userOrders,
};

function userPanel() {
  return () => {
    return httpRequest("get", `panel/users`);
  };
}

function userTrades() {
  return () => {
    return httpRequest("get", `panel/trades`);
  };
}

function userOrders() {
  return () => {
    return httpRequest("get", "panel/orders");
  };
}
