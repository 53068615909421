import axios from "axios";

export const httpRequest = (
  method,
  url,
  { params, data, body, headers, ...rest } = {}
) => {
  if (!method) throw new Error("method not set");

  const baseURL = process.env.REACT_APP_BASE_URL;
  // const baseURL = 'https://p2p.datest.ir/v1/';

  return axios({
    baseURL,
    timeout: 120 * 1000,
    url,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
      ...headers,
    },
    params,
    data,
    body,
    method,
    ...rest,
  });
};
