import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import CoiningTable from "./CoiningTable";
const CoiningList = () => {
  return (
    <Fragment>
      <PageTitle
        activeMenu="کیف پول"
        motherMenu="داشبورد"
        pageContent="کیف پول"
      />

      <div className="row">
        <CoiningTable />
      </div>
    </Fragment>
  );
};

export default CoiningList;
