import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import OrderTable from "./OrderTable";

const UserList = () => {
  return (
    <Fragment>
      <PageTitle
        activeMenu="سفارشات"
        motherMenu="داشبورد"
        pageContent="سفارشات"
      />
      <div className="row">
        <OrderTable />
      </div>
    </Fragment>
  );
};

export default UserList;
