import React, { useState, useEffect } from "react";
import { httpRequest } from "../../../_helper/httpServices";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { NumberInput } from "../Defaults/NumberInput";
import { Button } from "react-bootstrap";
import { DefaultButton } from "../Defaults/DefaultButton";
import Switch from "react-switch";
import { Label, FormGroup } from "reactstrap";
import Select from "react-select";

const RobotUpdate = ({ tabs, robotsData, userId }) => {
  //state
  const [coinList, setCoinList] = useState([]);
  const [loader, setLoader] = useState(false);

  //state disposite
  const [minAmountBuy, setMinAmountBuy] = useState(0);
  const [minAmountTradeBuy, setMinAmountTradeBuy] = useState(0);
  const [minAmountTradeSell, setMinAmountTradeSell] = useState(0);
  const [maxAmountBuy, setMaxAmountBuy] = useState(0);
  const [maxAmountSell, setMaxAmountSell] = useState(0);
  const [maxAmountTradeBuy, setMaxAmountTradeBuy] = useState(0);
  const [maxAmountTradeSell, setMaxAmountTradeSell] = useState(0);
  const [maxPriceFactorTradeBuy, setMaxPriceFactorTradeBuy] = useState(0);
  const [skipOnCountBuy, setSkipOnCountBuy] = useState(0);
  const [skipOnCountTradeBuy, setSkipOnCountTradeBuy] = useState(0);
  const [skipOnCountTradeSell, setSkipOnCountTradeSell] = useState(0);
  const [skipOnMinuteTradeBuy, setSkipOnMinuteTradeBuy] = useState(0);
  const [skipOnMinuteTradeSell, setSkipOnMinuteTradeSell] = useState(0);
  const [minPriceFactorBuy, setMinPriceFactorBuy] = useState(0);
  const [maxPriceFactorBuy, setMaxPriceFactorBuy] = useState(0);
  const [minPriceFactorTradeSell, setMinPriceFactorTradeSell] = useState(0);
  const [minAmountSell, setMinAmountSell] = useState(0);
  const [skipOnCountSell, setSkipOnCountSell] = useState(0);
  const [minPriceFactorSell, setMinPriceFactorSell] = useState(0);
  const [maxPriceFactorSell, setMaxPriceFactorSell] = useState(0);
  //state withdraw
  const [checked, setChecked] = useState(false);
  const [pair, setPair] = useState("");
  const [pairCoin, setPairCoin] = useState([]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  const statusHandleChange = () => {
    setChecked(!checked);
  };

  const handleChangePairs = (selected) => {
    setPair(selected);
  };

  const getPairCoins = () => {
    httpRequest("get", "settings/pairs")
      .then((res) => {
        let options = res.data.data?.map((item) => {
          return {
            value: item.symbol,
            label: `${item.symbol.toUpperCase()}`,
          };
        });
        let irt = [{ value: "irt", label: "IRT" }];
        let result = [options, [...irt]];

        const concatArr = [].concat(...result);
        setPairCoin(concatArr);
      })
      .catch((err) => ERROR(err));
  };
  useEffect(() => {
    getPairCoins();
  }, []);

  useEffect(() => {
    robotsData?.filter((x) => {
      if (x.coin === tabs) {
        setChecked(x?.isActive);
        setPair(
          pairCoin?.filter((y) => {
            return y.value === x?.pair;
          })
        );
        setMinAmountBuy(x?.order?.buy?.minAmount);
        setMaxAmountBuy(x?.order?.buy?.maxAmount);
        setMinPriceFactorBuy(x?.order?.buy?.minPriceFactor);
        setMaxPriceFactorBuy(x?.order?.buy?.maxPriceFactor);
        setSkipOnCountBuy(x?.order?.buy?.skipOnCount);

        setMinAmountSell(x?.order?.sell?.minAmount);
        setMaxAmountSell(x?.order?.sell?.maxAmount);
        setMinPriceFactorSell(x?.order?.sell?.minPriceFactor);
        setMaxPriceFactorSell(x?.order?.sell?.maxPriceFactor);
        setSkipOnCountSell(x?.order?.sell?.skipOnCount);

        setMinAmountTradeBuy(x?.trade?.buy?.minAmount);
        setMaxAmountTradeBuy(x?.trade?.buy?.maxAmount);
        setMaxPriceFactorTradeBuy(x?.trade?.buy?.maxPriceFactor);
        setSkipOnCountTradeBuy(x?.trade?.buy?.skipOnCount);
        setSkipOnMinuteTradeBuy(x?.trade?.buy?.skipOnMinutes);

        setMinAmountTradeSell(x?.trade?.sell?.minAmount);
        setMaxAmountTradeSell(x?.trade?.sell?.maxAmount);
        setMinPriceFactorTradeSell(x?.trade?.sell?.minPriceFactor);
        setSkipOnCountTradeSell(x?.trade?.sell?.skipOnCount);
        setSkipOnMinuteTradeSell(x?.trade?.sell?.skipOnMinutes);
      }
    });
  }, [robotsData, tabs, pairCoin]);

  async function editCoining() {
    const data = {
      userId: userId,
      userIp: "127.0.0.1",
      data: robotsData.map((coin) => {
        if (coin.coin === tabs) {
          return (coin = {
            coin: tabs,
            pair: pair?.value ? pair?.value : pair[0]?.value,
            order: {
              buy: {
                minAmount:
                  typeof minAmountBuy === "string"
                    ? parseInt(minAmountBuy?.replaceAll(",", ""))
                    : minAmountBuy,
                maxAmount:
                  typeof maxAmountBuy === "string"
                    ? parseInt(maxAmountBuy?.replaceAll(",", ""))
                    : maxAmountBuy,
                minPriceFactor:
                  typeof minPriceFactorBuy === "string"
                    ? parseInt(minPriceFactorBuy?.replaceAll(",", ""))
                    : minPriceFactorBuy,
                maxPriceFactor:
                  typeof maxPriceFactorBuy === "string"
                    ? parseInt(maxPriceFactorBuy?.replaceAll(",", ""))
                    : maxPriceFactorBuy,
                skipOnCount:
                  typeof skipOnCountBuy === "string"
                    ? parseInt(skipOnCountBuy?.replaceAll(",", ""))
                    : skipOnCountBuy,
              },
              sell: {
                minAmount:
                  typeof minAmountSell === "string"
                    ? parseInt(minAmountSell?.replaceAll(",", ""))
                    : minAmountSell,
                maxAmount:
                  typeof maxAmountSell === "string"
                    ? parseInt(maxAmountSell?.replaceAll(",", ""))
                    : maxAmountSell,
                minPriceFactor:
                  typeof minPriceFactorSell === "string"
                    ? parseInt(minPriceFactorSell?.replaceAll(",", ""))
                    : minPriceFactorSell,
                maxPriceFactor:
                  typeof maxPriceFactorSell === "string"
                    ? parseInt(maxPriceFactorSell?.replaceAll(",", ""))
                    : maxPriceFactorSell,
                skipOnCount:
                  typeof skipOnCountSell === "string"
                    ? parseInt(skipOnCountSell?.replaceAll(",", ""))
                    : skipOnCountSell,
              },
            },
            trade: {
              buy: {
                minAmount:
                  typeof minAmountTradeBuy === "string"
                    ? parseInt(minAmountTradeBuy?.replaceAll(",", ""))
                    : minAmountTradeBuy,
                maxAmount:
                  typeof maxAmountTradeBuy === "string"
                    ? parseInt(maxAmountTradeBuy?.replaceAll(",", ""))
                    : maxAmountTradeBuy,
                maxPriceFactor:
                  typeof maxPriceFactorTradeBuy === "string"
                    ? parseInt(maxPriceFactorTradeBuy?.replaceAll(",", ""))
                    : maxPriceFactorTradeBuy,
                skipOnCount:
                  typeof skipOnCountTradeBuy === "string"
                    ? parseInt(skipOnCountTradeBuy?.replaceAll(",", ""))
                    : skipOnCountTradeBuy,
                skipOnMinutes:
                  typeof skipOnMinuteTradeBuy === "string"
                    ? parseInt(skipOnMinuteTradeBuy?.replaceAll(",", ""))
                    : skipOnMinuteTradeBuy,
              },
              sell: {
                minAmount:
                  typeof minAmountTradeSell === "string"
                    ? parseInt(minAmountTradeSell?.replaceAll(",", ""))
                    : minAmountTradeSell,
                maxAmount:
                  typeof maxAmountTradeSell === "string"
                    ? parseInt(maxAmountTradeSell?.replaceAll(",", ""))
                    : maxAmountTradeSell,
                minPriceFactor:
                  typeof minPriceFactorTradeSell === "string"
                    ? parseInt(minPriceFactorTradeSell?.replaceAll(",", ""))
                    : minPriceFactorTradeSell,
                skipOnCount:
                  typeof skipOnCountTradeSell === "string"
                    ? parseInt(skipOnCountTradeSell?.replaceAll(",", ""))
                    : skipOnCountTradeSell,
                skipOnMinutes:
                  typeof skipOnMinuteTradeSell === "string"
                    ? parseInt(skipOnMinuteTradeSell?.replaceAll(",", ""))
                    : skipOnMinuteTradeSell,
              },
            },
            isActive: checked,
          });
        } else return coin;
      }),
    };

    setLoader(true);
    await httpRequest("put", `settings/robots`, { data })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r.data.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        ERROR(err.response?.data?.message || err.message, {
          error: err,
        });
      });
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-align-items-center">
        <h5 className="mb-4 mt-3">
          <div className="form-group col-md-12">
            <Label className="ml-3 mb-0 fs-12" for="status">
              وضعیت
            </Label>
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              height={15}
              width={28}
              onColor="#1fa2ff"
              onChange={statusHandleChange}
              checked={checked}
              classNmae="mt-2"
            />
          </div>
        </h5>
      </div>
      <div className="col-12 mb-5">
        <FormGroup className="form-group multi-select-style">
          <Label for="status">جفت ارز</Label>
          <Select
            styles={customStyles}
            className={`react-select w-100 text-left`}
            classNamePrefix="react-select"
            onChange={handleChangePairs}
            options={pairCoin}
            value={pair}
            placeholder="جفت ارز"
            instanceId="pair"
            name="pair"
          />
        </FormGroup>
      </div>
      <h5 className="mb-3 mt-3 title__Modla">
        سفارش <span className="text-success">(خرید)</span>
      </h5>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="minAmountBuy"
            name="minAmountBuy"
            label={"کمترین مقدار"}
            className="form-control"
            value={minAmountBuy}
            onChange={(e) => setMinAmountBuy(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="maxAmountBuy"
            label={"بیشترین مقدار"}
            name="maxAmountBuy"
            className="form-control"
            value={maxAmountBuy}
            onChange={(e) => setMaxAmountBuy(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="minPriceFactorBuy"
            placeholder="به درصد"
            label={"حداقل درصد قیمت (%)"}
            name="minPriceFactorBuy"
            value={minPriceFactorBuy}
            className="form-control"
            onChange={(e) => setMinPriceFactorBuy(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            id="maxPriceFactorBuy"
            label={"حداکثر درصد قیمت (%)"}
            name="maxPriceFactorBuy"
            className="form-control"
            value={maxPriceFactorBuy}
            onChange={(e) => setMaxPriceFactorBuy(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="skipOnCountBuy"
            label={"Skip on count"}
            name="skipOnCountBuy"
            className="form-control"
            value={skipOnCountBuy}
            onChange={(e) => setSkipOnCountBuy(e.target.value)}
          />
        </div>
      </div>

      <h5 className="mb-3 mt-3 title__Modla">
        سفارش <span className="text-danger">(فروش)</span>
      </h5>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="minAmountSell"
            name="minAmountSell"
            label={"کمترین مقدار"}
            className="form-control"
            value={minAmountSell}
            onChange={(e) => setMinAmountSell(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="maxAmountSell"
            label={"بیشترین مقدار"}
            name="maxAmountSell"
            className="form-control"
            value={maxAmountSell}
            onChange={(e) => setMaxAmountSell(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="minPriceFactorSell"
            placeholder="به درصد"
            label={"حداقل درصد قیمت (%)"}
            name="minPriceFactorSell"
            value={minPriceFactorSell}
            className="form-control"
            onChange={(e) => setMinPriceFactorSell(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            id="maxPriceFactorSell"
            label={"حداکثر درصد قیمت (%)"}
            name="maxPriceFactorSell"
            className="form-control"
            value={maxPriceFactorSell}
            onChange={(e) => setMaxPriceFactorSell(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="skipOnCountSell"
            label={"Skip on count"}
            name="skipOnCountSell"
            className="form-control"
            value={skipOnCountSell}
            onChange={(e) => setSkipOnCountSell(e.target.value)}
          />
        </div>
      </div>

      <h5 className="mb-3 mt-3 title__Modla">
        معامله <span className="text-success">(خرید)</span>
      </h5>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="minAmountTradeBuy"
            name="minAmountTradeBuy"
            label={"کمترین مقدار"}
            className="form-control"
            value={minAmountTradeBuy}
            onChange={(e) => setMinAmountTradeBuy(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            id="maxAmountTradeBuy"
            label={"بیشترین مقدار"}
            name="maxAmountTradeBuy"
            className="form-control"
            value={maxAmountTradeBuy}
            onChange={(e) => setMaxAmountTradeBuy(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="maxPriceFactorTradeBuy"
            placeholder="به درصد"
            label={"حداکثر درصد قیمت (%)"}
            name="maxPriceFactorTradeBuy"
            value={maxPriceFactorTradeBuy}
            className="form-control"
            onChange={(e) => setMaxPriceFactorTradeBuy(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="skipOnCountTradeBuy"
            label={"Skip on count"}
            name="skipOnCountTradeBuy"
            className="form-control"
            value={skipOnCountTradeBuy}
            onChange={(e) => setSkipOnCountTradeBuy(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="skipOnMinuteTradeBuy"
            label={"Skip on minutes"}
            name="skipOnMinuteTradeBuy"
            className="form-control"
            value={skipOnMinuteTradeBuy}
            onChange={(e) => setSkipOnMinuteTradeBuy(e.target.value)}
          />
        </div>
      </div>

      <h5 className="mb-3 mt-3 title__Modla">
        معامله <span className="text-danger">(فروش)</span>
      </h5>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="minAmountTradeSell"
            name="minAmountTradeSell"
            label={"کمترین مقدار"}
            className="form-control"
            value={minAmountTradeSell}
            onChange={(e) => setMinAmountTradeSell(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            id="maxAmountTradeSell"
            label={"بیشترین مقدار"}
            name="maxAmountTradeSell"
            className="form-control"
            value={maxAmountTradeSell}
            onChange={(e) => setMaxAmountTradeSell(e.target.value)}
          />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="minPriceFactorTradeSell"
            placeholder="به درصد"
            label={"حداقل درصد قیمت (%)"}
            name="minPriceFactorTradeSell"
            value={minPriceFactorTradeSell}
            className="form-control"
            onChange={(e) => setMinPriceFactorTradeSell(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="skipOnCountTradeSell"
            label={"Skip on count"}
            name="skipOnCountTradeSell"
            className="form-control"
            value={skipOnCountTradeSell}
            onChange={(e) => setSkipOnCountTradeSell(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="skipOnMinuteTradeSell"
            label={"Skip on minutes"}
            name="skipOnMinuteTradeSell"
            className="form-control"
            value={skipOnMinuteTradeSell}
            onChange={(e) => setSkipOnMinuteTradeSell(e.target.value)}
          />
        </div>
      </div>
      <div className="row pull-left mt-3">
        <DefaultButton
          type="submit"
          onClick={() => editCoining()}
          loading={loader}
          variant="primary"
        >
          ذخیره تغییرات
        </DefaultButton>
      </div>
    </div>
  );
};

export default RobotUpdate;
