import { CONFIRM, ERROR, LOADING, SUCCESS } from "./../_helper/alerts";

export const getResult = (res, type, dispatch) => {
  if (res?.data?.success) {
    dispatch({
      type: type,
      data: res.data,
    });
    return true;
  } else if (!res?.data?.success) {
    ERROR(res.data?.errors[0]?.errorMessage);
  } else {
    ERROR("خطا");
    return;
  }
  return false;
};
