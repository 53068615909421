import { httpRequest } from "./../_helper";

export const robotsActions = {
  getConfigRobots,
  setConfigRobots,
};

function getConfigRobots() {
  return () => {
    return httpRequest("get", `settings/robots`);
  };
}

function setConfigRobots(data) {
  return () => {
    return httpRequest("put", `settings/robots`, { data });
  };
}
