import React, { useState, useRef, useEffect } from "react";
import { Modal, Dropdown, Row, Button, Col, Spinner } from "react-bootstrap";
import { usersAction } from "./../../../_actions";
import { ERROR, SUCCESS } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";
import { rolesAction } from "../../../_actions";
import { Link } from "react-router-dom";
// import data from "./tableData.js";

const UserTable = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [test, settest] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchFamily, setSearchFamily] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [description, setDescription] = useState("");
  const [usersListData, setUsersList] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [usersConfirmedMeta, setUsersConfirmedMeta] = useState([]);
  const [usersNotConfirmedMeta, setUsersNotConfirmedMeta] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const [identitiesAccept, setIdentitiesAccept] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [identitiesNotAccept, setIdentitiesNotAccept] = useState([]);
  const [active, setActive] = useState("notAccept");
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeToggle, setActiveToggle] = useState("all");
  const [permissions, setPermissions] = useState([]);
  const [hasRole, setHasRole] = useState(false);
  const handleNameChange = (evt) => {
    setSearchName(evt.target.value);
  };

  const handleLastChange = (evt) => {
    setSearchFamily(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setSearchEmail(evt.target.value);
  };

  const handleMobileChange = (evt) => {
    setSearchMobile(evt.target.value);
  };

  const onChangeDesc = (evt) => {
    setDescription(evt.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "role") setHasRole(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    dispatch(
      usersAction.getUsersList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setUsersList(r.data?.data);
        console.log(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(
      usersAction.getUsersList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setUsersList(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }

  useEffect(() => {
    setIsLoading(true);

    if (activeToggle === "confirmed") {
      const verify = true;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersConfirmedMeta(r.data?.meta);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (activeToggle === "waiting") {
      const verify = false;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesNotAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersNotConfirmedMeta(r.data?.meta);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [activeToggle]);

  const handleAuthPageChange = (page) => {
    setIsLoading(true);

    setPage(page);

    if (activeToggle === "confirmed") {
      const verify = true;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else if (activeToggle === "waiting") {
      const verify = false;
      dispatch(usersAction.usersIdentityList({ page, limit, verify }))
        .then((r) => {
          setIdentitiesNotAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersNotConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const searchByActive = () => {
    setIsLoading(true);

    setPage(page);

    if (activeToggle === "confirmed") {
      const verify = true;
      dispatch(
        usersAction.usersIdentityList({
          page,
          limit,
          verify,
          searchName,
          searchEmail,
          searchMobile,
        })
      )
        .then((r) => {
          setIdentitiesAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else if (activeToggle === "waiting") {
      const verify = false;
      dispatch(
        usersAction.usersIdentityList({
          page,
          limit,
          verify,
          searchName,
          searchEmail,
          searchMobile,
        })
      )
        .then((r) => {
          setIdentitiesNotAccept(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setUsersNotConfirmedMeta(r.data?.meta);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  async function searchBy() {
    setLoader(true);
    dispatch(
      usersAction.getUsersList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setUsersList(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(true);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  async function verifyUser(item, type) {
    const id = item._id;
    const firstName = item.firstName;
    const lastName = item.lastName;
    if (type === "accepted") {
      setSendLoader(true);
      dispatch(usersAction.acceptIdentity(id, firstName, lastName, true))
        .then((r) => {
          if (r.status === 200) {
            setIdentitiesAccept(
              identitiesAccept.filter((item) => item._id !== id)
            );
            SUCCESS(r?.data?.message || "حساب کاربر با موفقیت تایید شد");
            // fetchIdentitiesList();
            setSendLoader(false);
          }
        })
        .catch((ex) => {
          setSendLoader(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        })
        .finally(() => {
          setAcceptLoading(false);
          setBasicModal(false);
        });
    }
    if (type === "rejected") {
      setSendLoader(true);
      dispatch(usersAction.acceptIdentity(id, firstName, lastName, false))
        .then((r) => {
          if (r.status === 200) {
            setIdentitiesNotAccept(
              identitiesNotAccept.filter((item) => item._id !== id)
            );
            SUCCESS(r?.data?.message || "حساب کاربر رد شد");
          }
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setSendLoader(false);
          setAcceptLoading(false);
          setBasicModal(false);
        });
    }
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    chackboxFun();
  }, [test]);

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  ////user roles  useEffect(() => {
  useEffect(() => {
    if (hasRole || localStorage.getItem("role") === "admin") {
      dispatch(rolesAction.getAllRoles({ page, limit }))
        .then((r) => {
          // setRolesList(r.data?.data);
          setPermissions(r.data?.data);
        })
        .catch((e) =>
          ERROR(e.response?.data?.message || e.message, {
            error: e,
          })
        )
        .finally(() => {
          // setIsLoading(false);
        });
    }
  }, [hasRole]);

  return (
    <div className="col-12">
      <div className="card">
        {/* <div className="card-header">
          <h4 className="card-title">لیست کاربران</h4>
        </div> */}

        <div className="profile-tab">
          <div className="custom-tab-1">
            <ul className="nav nav-tabs pr-2 my-3">
              <li className="nav-item">
                <Link
                  to="#all"
                  data-toggle="tab"
                  onClick={() => setActiveToggle("all")}
                  className={`nav-link ${
                    activeToggle === "all" ? "active show" : ""
                  }`}
                >
                  همه کاربران
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveToggle("waiting")}
              >
                <Link
                  to="#waiting"
                  data-toggle="tab"
                  className={`nav-link ${
                    activeToggle === "waiting" ? "active show" : ""
                  }`}
                >
                  کاربران در انتظار تایید
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveToggle("confirmed")}
              >
                <Link
                  to="#confirmed"
                  data-toggle="tab"
                  className={`nav-link ${
                    activeToggle === "confirmed" ? "active show" : ""
                  }`}
                >
                  تایید شده
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div
            id="confirmed"
            className={`tab-pane fade ${
              activeToggle === "confirmed" ? "active show" : ""
            }`}
          >
            <Row className="p-3">
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"نام"}
                  id={"search"}
                  name="search"
                  value={searchName}
                  onChange={handleNameChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ایمیل"}
                  id={"searchE"}
                  name="searchE"
                  value={searchEmail}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"موبایل"}
                  id={"searchM"}
                  name="searchM"
                  value={searchMobile}
                  onChange={handleMobileChange}
                />
              </Col>
              <Col lg={3}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchByActive()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {identitiesAccept && identitiesAccept?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                            // style={{ width: 73 }}
                          >
                            نام و نام خانوادگی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            کد ملی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            موبایل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                            // style={{ width: 120 }}
                          >
                            موجودی (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                            // style={{ width: 62 }}
                          >
                            ایمیل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            نقش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {identitiesAccept && identitiesAccept?.length !== 0 ? (
                        identitiesAccept?.map((item, index) => {
                          console.log(item);
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                {item.firstName ? item.firstName : "-----"}
                                {item.lastName}
                              </td>
                              <td>{item.meliCode ? item.meliCode : "-----"}</td>
                              <td>{item.mobile ? item.mobile : "-----"}</td>
                              <td>
                                {item.balance
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td>{item.email ? item.email : "-----"}</td>
                              <td>
                                <span
                                  className={`badge light fs-10 ${
                                    item.role === "admin"
                                      ? "badge-info"
                                      : item.role === "user"
                                      ? "badge-primary"
                                      : item.role === "panel"
                                      ? "badge-warning"
                                      : item.role === "setting"
                                      ? "badge-success"
                                      : item.role === "config"
                                      ? "badge-secondary"
                                      : item.role === "role"
                                      ? "badge-danger"
                                      : item.role === "banking"
                                      ? "badge-dark"
                                      : item.role === "coining"
                                      ? "badge-light"
                                      : item.role === "order"
                                      ? "badge-primary"
                                      : item.role === "otc"
                                      ? "badge-info"
                                      : item.role === "trade"
                                      ? "badge-success"
                                      : item.role === "notification"
                                      ? "badge-warning"
                                      : item.role === "ticket"
                                      ? "badge-info"
                                      : item.role === "trace"
                                      ? "badge-danger"
                                      : item.role === "affiliate"
                                      ? "badge-secondary"
                                      : item.role === "thirdParty"
                                      ? "badge-primary"
                                      : item.role === "balance"
                                      ? "badge-success"
                                      : ""
                                  }`}
                                >
                                  <i
                                    className={`fa fa-circle ${
                                      item.role === "admin"
                                        ? "text-info"
                                        : item.role === "user"
                                        ? "text-primary"
                                        : item.role === "panel"
                                        ? "text-warning"
                                        : item.role === "setting"
                                        ? "text-success"
                                        : item.role === "config"
                                        ? "text-secondary"
                                        : item.role === "role"
                                        ? "text-danger"
                                        : item.role === "banking"
                                        ? "text-dark"
                                        : item.role === "coining"
                                        ? "text-light"
                                        : item.role === "order"
                                        ? "text-primary"
                                        : item.role === "otc"
                                        ? "text-info"
                                        : item.role === "trade"
                                        ? "text-success"
                                        : item.role === "notification"
                                        ? "text-warning"
                                        : item.role === "ticket"
                                        ? "text-info"
                                        : item.role === "trace"
                                        ? "text-danger"
                                        : item.role === "affiliate"
                                        ? "text-secondary"
                                        : item.role === "thirdParty"
                                        ? "text-primary"
                                        : item.role === "balance"
                                        ? "text-success"
                                        : ""
                                    } ml-1`}
                                  />
                                  {item.role === "admin"
                                    ? "ادمین"
                                    : item.role === "user"
                                    ? "مدیریت کاربران"
                                    : item.role === "panel"
                                    ? "مدیریت پنل"
                                    : item.role === "setting"
                                    ? "مدیریت تنظیمات"
                                    : item.role === "config"
                                    ? "مدیریت کانفیگ ها"
                                    : item.role === "role"
                                    ? "مدیریت نقش ها"
                                    : item.role === "banking"
                                    ? "مدیریت حساب های بانکی"
                                    : item.role === "coining"
                                    ? "مدیریت کیف پول ها"
                                    : item.role === "order"
                                    ? "مدیریت سفارش ها"
                                    : item.role === "otc"
                                    ? "مدیریت سفارشات سریع"
                                    : item.role === "trade"
                                    ? "مدیریت معاملات"
                                    : item.role === "notification"
                                    ? "مدیریت پیام ها"
                                    : item.role === "ticket"
                                    ? "مدیریت تیکت ها"
                                    : item.role === "trace"
                                    ? "مدیریت Trace ها"
                                    : item.role === "affiliate"
                                    ? "مدیریت کدهای دعوت"
                                    : item.role === "thirdParty"
                                    ? "مدیریت تنظیمات OTC"
                                    : item.role === "balance"
                                    ? "مدیریت موجودی ها"
                                    : ""}
                                </span>
                              </td>

                              <td className="py-2 text-right">
                                {" "}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="table-dropdown i-false btn tp-btn-light sharp"
                                  >
                                    <span class="fs--1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <Link
                                        className="w-100 fs-11 text-secondary text-right"
                                        to={`users-detail/${item._id}`}
                                      >
                                        نمایش جزئیات
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <a
                                        className="w-100 fs-11 text-success text-right"
                                        onClick={() =>
                                          verifyUser(item, "accepted")
                                        }
                                      >
                                        تایید اطلاعات
                                      </a>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-danger text-right"
                                    >
                                      <a
                                        className="w-100 text-danger fs-11 text-right"
                                        onClick={() => setBasicModal(true)}
                                      >
                                        رد کردن اطلاعات
                                      </a>
                                      <Modal
                                        className="fade"
                                        show={basicModal}
                                        onHide={setBasicModal}
                                      >
                                        <Modal.Header>
                                          <h4 className="text-black">
                                            رد اطلاعات کاربر
                                          </h4>
                                          <Button
                                            variant=""
                                            className="close"
                                            onClick={() => setBasicModal(false)}
                                          >
                                            <span>&times;</span>
                                          </Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <BaseInput
                                            className="form-control text-right"
                                            id="description"
                                            value={description}
                                            onChange={onChangeDesc}
                                            label={
                                              "علت عدم موافقت خود را وارد نمایید"
                                            }
                                          />
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <DefaultButton
                                            onClick={() =>
                                              verifyUser(item, "rejected")
                                            }
                                            loading={
                                              sendLoader && (
                                                <Spinner
                                                  className="mr-2"
                                                  animation="border"
                                                  variant="light"
                                                />
                                              )
                                            }
                                            variant="primary light"
                                          >
                                            ارسال
                                          </DefaultButton>
                                          <Button
                                            className="mr-2"
                                            onClick={() => setBasicModal(false)}
                                            variant="danger light"
                                          >
                                            بستن
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {usersConfirmedMeta?.page * usersConfirmedMeta?.limit -
                        usersConfirmedMeta?.limit +
                        1}{" "}
                      تا {usersConfirmedMeta?.page * usersConfirmedMeta?.limit}{" "}
                      از {usersConfirmedMeta?.total} کاربر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={usersConfirmedMeta?.limit}
                          totalItemsCount={usersConfirmedMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handleAuthPageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="all"
            className={`tab-pane fade ${
              activeToggle === "all" ? "active show" : ""
            }`}
          >
            {" "}
            <Row className="p-3">
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام"}
                  id={"search"}
                  name="search"
                  value={searchName}
                  onChange={handleNameChange}
                />
              </Col>
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام خانوادگی"}
                  id={"searchF"}
                  name="searchF"
                  value={searchFamily}
                  onChange={handleLastChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ایمیل"}
                  id={"searchE"}
                  name="searchE"
                  value={searchEmail}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"موبایل"}
                  id={"searchM"}
                  name="searchM"
                  value={searchMobile}
                  onChange={handleMobileChange}
                />
              </Col>
              <Col lg={2}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchBy()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {usersListData && usersListData?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                            // style={{ width: 73 }}
                          >
                            نام و نام خانوادگی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            کد ملی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            موبایل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                            // style={{ width: 120 }}
                          >
                            موجودی (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                            // style={{ width: 62 }}
                          >
                            ایمیل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            نقش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {usersListData && usersListData?.length !== 0 ? (
                        usersListData?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                {item.firstName ? item.firstName : "-----"}{" "}
                                {item.lastName}
                              </td>
                              <td>{item.meliCode ? item.meliCode : "-----"}</td>
                              <td>{item.mobile ? item.mobile : "-----"}</td>
                              <td>
                                {item.balance
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td>{item.email ? item.email : "-----"}</td>
                              <td>
                                {permissions?.map((val, ind) => {
                                  if (val._id === item.role) {
                                    return (
                                      <span
                                        className="badge light fs-10 badge-primary"
                                        key={ind}
                                      >
                                        {val.name}
                                      </span>
                                    );
                                  }

                                  //    <span
                                  //    key={ind}
                                  //    className={`badge light fs-10 ${
                                  //      item.role === "admin"
                                  //        ? "badge-info"
                                  //        : item.role === "user"
                                  //        ? "badge-primary"
                                  //        : item.role === "panel"
                                  //        ? "badge-warning"
                                  //        : item.role === "setting"
                                  //        ? "badge-success"
                                  //        : item.role === "config"
                                  //        ? "badge-secondary"
                                  //        : item.role === "role"
                                  //        ? "badge-danger"
                                  //        : item.role === "banking"
                                  //        ? "badge-dark"
                                  //        : item.role === "coining"
                                  //        ? "badge-light"
                                  //        : item.role === "order"
                                  //        ? "badge-primary"
                                  //        : item.role === "otc"
                                  //        ? "badge-info"
                                  //        : item.role === "trade"
                                  //        ? "badge-success"
                                  //        : item.role === "notification"
                                  //        ? "badge-warning"
                                  //        : item.role === "ticket"
                                  //        ? "badge-info"
                                  //        : ""
                                  //    }`}
                                  //  >
                                  //    <i
                                  //      className={`fa fa-circle ${
                                  //        item.role === "admin"
                                  //          ? "text-info"
                                  //          : item.role === "user"
                                  //          ? "text-primary"
                                  //          : item.role === "panel"
                                  //          ? "text-warning"
                                  //          : item.role === "setting"
                                  //          ? "text-success"
                                  //          : item.role === "config"
                                  //          ? "text-secondary"
                                  //          : item.role === "role"
                                  //          ? "text-danger"
                                  //          : item.role === "banking"
                                  //          ? "text-dark"
                                  //          : item.role === "coining"
                                  //          ? "text-light"
                                  //          : item.role === "order"
                                  //          ? "text-primary"
                                  //          : item.role === "otc"
                                  //          ? "text-info"
                                  //          : item.role === "trade"
                                  //          ? "text-success"
                                  //          : item.role === "notification"
                                  //          ? "text-warning"
                                  //          : item.role === "ticket"
                                  //          ? "text-info"
                                  //          : ""
                                  //      } ml-1`}
                                  //    />
                                  //    {item.role === "admin"
                                  //      ? "ادمین"
                                  //      : item.role === "user"
                                  //      ? "مدیریت کاربران"
                                  //      : item.role === "panel"
                                  //      ? "مدیریت پنل"
                                  //      : item.role === "setting"
                                  //      ? "مدیریت تنظیمات"
                                  //      : item.role === "config"
                                  //      ? "مدیریت کانفیگ ها"
                                  //      : item.role === "role"
                                  //      ? "مدیریت نقش ها"
                                  //      : item.role === "banking"
                                  //      ? "مدیریت حساب های بانکی"
                                  //      : item.role === "coining"
                                  //      ? "مدیریت کیف پول ها"
                                  //      : item.role === "order"
                                  //      ? "مدیریت سفارش ها"
                                  //      : item.role === "otc"
                                  //      ? "مدیریت سفارشات سریع"
                                  //      : item.role === "trade"
                                  //      ? "مدیریت معاملات"
                                  //      : item.role === "notification"
                                  //      ? "مدیریت پیام ها"
                                  //      : item.role === "ticket"
                                  //      ? "مدیریت تیکت ها"
                                  //      : ""}
                                  //  </span>
                                })}
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="table-dropdown i-false btn tp-btn-light sharp"
                                  >
                                    <span class="fs--1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <Link
                                        className="w-100 fs-11 text-secondary text-right"
                                        to={`users-detail/${item._id}`}
                                      >
                                        نمایش جزئیات
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <a
                                        className="w-100 fs-11 text-success text-right"
                                        onClick={() =>
                                          verifyUser(item, "accepted")
                                        }
                                      >
                                        تایید اطلاعات
                                      </a>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-danger text-right"
                                    >
                                      <a
                                        className="w-100 text-danger fs-11 text-right"
                                        onClick={() => setBasicModal(true)}
                                      >
                                        رد کردن اطلاعات
                                      </a>
                                      <Modal
                                        className="fade"
                                        show={basicModal}
                                        onHide={setBasicModal}
                                      >
                                        <Modal.Header>
                                          <h4 className="text-black">
                                            رد اطلاعات کاربر
                                          </h4>
                                          <Button
                                            variant=""
                                            className="close"
                                            onClick={() => setBasicModal(false)}
                                          >
                                            <span>&times;</span>
                                          </Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <BaseInput
                                            className="form-control text-right"
                                            id="description"
                                            value={description}
                                            onChange={onChangeDesc}
                                            label={
                                              "علت عدم موافقت خود را وارد نمایید"
                                            }
                                          />
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <DefaultButton
                                            onClick={() =>
                                              verifyUser(item, "rejected")
                                            }
                                            loading={
                                              sendLoader && (
                                                <Spinner
                                                  className="mr-2"
                                                  animation="border"
                                                  variant="light"
                                                />
                                              )
                                            }
                                            variant="primary light"
                                          >
                                            ارسال
                                          </DefaultButton>
                                          <Button
                                            className="mr-2"
                                            onClick={() => setBasicModal(false)}
                                            variant="danger light"
                                          >
                                            بستن
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {usersMeta?.page * usersMeta?.limit -
                        usersMeta?.limit +
                        1}{" "}
                      تا {usersMeta?.page * usersMeta?.limit} از{" "}
                      {usersMeta?.total} کاربر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={usersMeta?.limit}
                          totalItemsCount={usersMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="waiting"
            className={`tab-pane fade ${
              activeToggle === "waiting" ? "active show" : ""
            }`}
          >
            <Row className="p-3">
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"نام"}
                  id={"search"}
                  name="search"
                  value={searchName}
                  onChange={handleNameChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ایمیل"}
                  id={"searchE"}
                  name="searchE"
                  value={searchEmail}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"موبایل"}
                  id={"searchM"}
                  name="searchM"
                  value={searchMobile}
                  onChange={handleMobileChange}
                />
              </Col>
              <Col lg={3}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchByActive()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {identitiesNotAccept && identitiesNotAccept?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                            // style={{ width: 73 }}
                          >
                            نام و نام خانوادگی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            کد ملی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            موبایل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                            // style={{ width: 120 }}
                          >
                            موجودی (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                            // style={{ width: 62 }}
                          >
                            ایمیل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            نقش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {identitiesNotAccept &&
                      identitiesNotAccept?.length !== 0 ? (
                        identitiesNotAccept?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                {item.firstName ? item.firstName : "-----"}
                                {item.lastName}
                              </td>
                              <td>{item.meliCode ? item.meliCode : "-----"}</td>
                              <td>{item.mobile ? item.mobile : "-----"}</td>
                              <td>
                                {item.balance
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td>{item.email ? item.email : "-----"}</td>
                              <td>
                                {permissions?.map((user, i) => {
                                  if (user._id === item.role) {
                                    return (
                                      <span
                                        key={i}
                                        className="badge light fs-10 badge-primary"
                                      >
                                        {user.name ? user.name : "_____"}
                                      </span>
                                    );
                                  }
                                })}
                                {/* <span
                                  className={`badge light fs-10 ${
                                    item.role === "admin"
                                      ? "badge-info"
                                      : item.role === "user"
                                      ? "badge-primary"
                                      : item.role === "panel"
                                      ? "badge-warning"
                                      : item.role === "setting"
                                      ? "badge-success"
                                      : item.role === "config"
                                      ? "badge-secondary"
                                      : item.role === "role"
                                      ? "badge-danger"
                                      : item.role === "banking"
                                      ? "badge-dark"
                                      : item.role === "coining"
                                      ? "badge-light"
                                      : item.role === "order"
                                      ? "badge-primary"
                                      : item.role === "otc"
                                      ? "badge-info"
                                      : item.role === "trade"
                                      ? "badge-success"
                                      : item.role === "notification"
                                      ? "badge-warning"
                                      : item.role === "ticket"
                                      ? "badge-info"
                                      : ""
                                  }`}
                                >
                                  <i
                                    className={`fa fa-circle ${
                                      item.role === "admin"
                                        ? "text-info"
                                        : item.role === "user"
                                        ? "text-primary"
                                        : item.role === "panel"
                                        ? "text-warning"
                                        : item.role === "setting"
                                        ? "text-success"
                                        : item.role === "config"
                                        ? "text-secondary"
                                        : item.role === "role"
                                        ? "text-danger"
                                        : item.role === "banking"
                                        ? "text-dark"
                                        : item.role === "coining"
                                        ? "text-light"
                                        : item.role === "order"
                                        ? "text-primary"
                                        : item.role === "otc"
                                        ? "text-info"
                                        : item.role === "trade"
                                        ? "text-success"
                                        : item.role === "notification"
                                        ? "text-warning"
                                        : item.role === "ticket"
                                        ? "text-info"
                                        : ""
                                    } ml-1`}
                                  />

                                  {item.role === "admin"
                                    ? "ادمین"
                                    : item.role === "user"
                                    ? "مدیریت کاربران"
                                    : item.role === "panel"
                                    ? "مدیریت پنل"
                                    : item.role === "setting"
                                    ? "مدیریت تنظیمات"
                                    : item.role === "config"
                                    ? "مدیریت کانفیگ ها"
                                    : item.role === "role"
                                    ? "مدیریت نقش ها"
                                    : item.role === "banking"
                                    ? "مدیریت حساب های بانکی"
                                    : item.role === "coining"
                                    ? "مدیریت کیف پول ها"
                                    : item.role === "order"
                                    ? "مدیریت سفارش ها"
                                    : item.role === "otc"
                                    ? "مدیریت سفارشات سریع"
                                    : item.role === "trade"
                                    ? "مدیریت معاملات"
                                    : item.role === "notification"
                                    ? "مدیریت پیام ها"
                                    : item.role === "ticket"
                                    ? "مدیریت تیکت ها"
                                    : ""}
                                </span> */}
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="table-dropdown i-false btn tp-btn-light sharp"
                                  >
                                    <span class="fs--1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <Link
                                        className="w-100 fs-11 text-secondary text-right"
                                        to={`users-detail/${item._id}`}
                                      >
                                        نمایش جزئیات
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <a
                                        className="w-100 fs-11 text-success text-right"
                                        onClick={() =>
                                          verifyUser(item, "accepted")
                                        }
                                      >
                                        تایید اطلاعات
                                      </a>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-danger text-right"
                                    >
                                      <a
                                        className="w-100 text-danger fs-11 text-right"
                                        onClick={() => setBasicModal(true)}
                                      >
                                        رد کردن اطلاعات
                                      </a>
                                      <Modal
                                        className="fade"
                                        show={basicModal}
                                        onHide={setBasicModal}
                                      >
                                        <Modal.Header>
                                          <h4 className="text-black">
                                            رد اطلاعات کاربر
                                          </h4>
                                          <Button
                                            variant=""
                                            className="close"
                                            onClick={() => setBasicModal(false)}
                                          >
                                            <span>&times;</span>
                                          </Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <BaseInput
                                            className="form-control text-right"
                                            id="description"
                                            value={description}
                                            onChange={onChangeDesc}
                                            label={
                                              "علت عدم موافقت خود را وارد نمایید"
                                            }
                                          />
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <DefaultButton
                                            onClick={() =>
                                              verifyUser(item, "rejected")
                                            }
                                            loading={
                                              sendLoader && (
                                                <Spinner
                                                  className="mr-2"
                                                  animation="border"
                                                  variant="light"
                                                />
                                              )
                                            }
                                            variant="primary light"
                                          >
                                            ارسال
                                          </DefaultButton>
                                          <Button
                                            className="mr-2"
                                            onClick={() => setBasicModal(false)}
                                            variant="danger light"
                                          >
                                            بستن
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {usersNotConfirmedMeta?.page *
                        usersNotConfirmedMeta?.limit -
                        usersNotConfirmedMeta?.limit +
                        1}{" "}
                      تا{" "}
                      {usersNotConfirmedMeta?.page *
                        usersNotConfirmedMeta?.limit}{" "}
                      از {usersNotConfirmedMeta?.total} کاربر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={usersNotConfirmedMeta?.limit}
                          totalItemsCount={usersNotConfirmedMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handleAuthPageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UserTable);
