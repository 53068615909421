import React, { useState, useRef, useEffect } from "react";
import { Modal, Dropdown, Row, Button, Col, Spinner } from "react-bootstrap";
import { usersAction, coiningActions } from "./../../../_actions";
import { ERROR, SUCCESS } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";
import Select from "react-select";
import "./coining.css";
import { Link } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";
// import data from "./tableData.js";

const CoiningTable = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [test, settest] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [address, setAddress] = useState("");
  const [coin, setCoin] = useState("");
  const [totalItems, setTotalItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [coiningList, setCoiningList] = useState([]);
  const [coiningMeta, setCoiningMeta] = useState([]);
  const [status, setStatus] = useState({ value: "", label: "همه" });
  const [flow, setFlow] = useState({ value: "", label: "همه" });
  const [hasCoining, setHasCoining] = useState(false);
  const [allCoins, setAllCoins] = useState([]);

  const options = [
    { value: "", label: "همه" },
    { value: "pending", label: "نامشخص" },
    { value: "success", label: "موفق" },
    { value: "error", label: "ناموفق" },
  ];
  const flowOptions = [
    { value: "", label: "همه" },
    { value: "deposit", label: "واریز" },
    { value: "withdraw", label: "برداشت" },
  ];

  const handleChangeCoins = (selected) => {
    setCoin(selected);
  };

  const handleAddressChange = (evt) => {
    setAddress(evt.target.value);
  };

  const handleCoinChange = (evt) => {
    setCoin(evt.target.value);
  };

  const handleStatusChange = (selected) => {
    setStatus(selected);
  };
  const handleFlowChange = (selected) => {
    setFlow(selected);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      // textAlign: "right",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        // textAlign: "right",
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  useEffect(() => {
    dispatch(coiningActions.getPreDefinedCoins())
      .then((r) => {
        const emptyList = { value: "", label: "همه" };
        const arr = r?.data?.data?.map((item) => {
          return {
            value: item.id,
            label: item?.fa,
          };
        });
        arr?.unshift(emptyList);
        setAllCoins(arr);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "coining") setHasCoining(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    let statusTitle = status?.value;
    let flowTitle = flow?.value;
    let coinTitle = coin?.value;

    if (hasCoining || localStorage.getItem("role") === "admin") {
      dispatch(
        coiningActions.getCoining({
          page,
          limit,
          flowTitle,
          coinTitle,
          statusTitle,
        })
      )
        .then((r) => {
          setCoiningList(r.data?.data);
          console.log(r.data?.data);
          setCoiningMeta(r.data?.meta);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((e) =>
          ERROR(e.response?.data?.message || e.message, {
            error: e,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [hasCoining, flow]);

  async function handlePageChange(page) {
    setPage(page);
    setIsLoading(true);
    let statusTitle = status?.value;
    let flowTitle = flow?.value;
    let coinTitle = coin?.value;

    if (hasCoining || localStorage.getItem("role") === "admin") {
      dispatch(
        coiningActions.getCoining({
          page,
          limit,
          flowTitle,
          coinTitle,
          statusTitle,
        })
      )
        .then((r) => {
          setCoiningList(r.data?.data);
          setCoiningMeta(r.data?.meta);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const searchBy = () => {
    setLoader(true);
    let statusTitle = status?.value;
    let flowTitle = flow?.value;
    let coinTitle = coin?.value;
    let page = 1;
    if (hasCoining || localStorage.getItem("role") === "admin") {
      dispatch(
        coiningActions.getCoining({
          page,
          limit,
          flowTitle,
          coinTitle,
          statusTitle,
        })
      )
        .then((r) => {
          setCoiningList(r.data?.data);
          setTotalItems(r.data?.meta?.total);
          setLoader(false);
          setPage(1);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setLoader(false);
        });
    }
  };

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    chackboxFun();
  }, [test]);

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  // const getPersianName = (coinName) => {
  //   dispatch(coiningActions.getAllAvailableCoins())
  //     .then((res) => {
  //       return res?.date?.data?.find((item) => {
  //         return item?.id === coinName;
  //       })[0]?.fa;
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title fs-14">لیست کیف پول ها</h4>
        </div>

        <Row className="p-3">
          <Col lg={3} className="ml-0">
            {/*<BaseInput*/}
            {/*    placeholder={"آدرس"}*/}
            {/*    id={"search"}*/}
            {/*    name="search"*/}
            {/*    value={address}*/}
            {/*    onChange={handleAddressChange}*/}
            {/*/>*/}
            <Select
              styles={customStyles}
              options={flowOptions}
              label={"نوع تراکنش"}
              id={"flow"}
              name={"flow"}
              className={`react-select mb-3`}
              classNamePrefix="react-select"
              value={flow}
              onChange={handleFlowChange}
            />
          </Col>
          <Col lg={3} className="ml-0">
            <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
              <Select
                styles={customStyles}
                className={`react-select w-100 text-left`}
                classNamePrefix="react-select"
                onChange={handleChangeCoins}
                options={allCoins}
                value={coin}
                placeholder="ارز"
                instanceId="coins"
                name="coins"
              />
            </FormGroup>
          </Col>
          <Col lg={3} className="ml-0 search_select">
            <Select
              styles={customStyles}
              options={options}
              label={"وضعیت"}
              id={"status"}
              name={"status"}
              className={`react-select mb-3`}
              classNamePrefix="react-select"
              value={status}
              onChange={handleStatusChange}
            />
          </Col>
          <Col lg={3}>
            <DefaultButton
              loading={loader}
              color={"primary"}
              className={"d-block w-100"}
              onClick={() => searchBy()}
            >
              جستجو
            </DefaultButton>
          </Col>
        </Row>
        <div className="card-body pt-0">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                {coiningList && coiningList?.length !== 0 && (
                  <thead>
                    <tr className="text-center" role="row">
                      <th
                        className="sorting_asc"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-sort="ascending"
                        aria-label="activate to sort column descending"
                        style={{ width: 32 }}
                      >
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            onClick={() => chackboxFun("all")}
                            className="custom-control-input"
                            id="checkAll"
                            required
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkAll"
                          />
                        </div>
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        نام و نام خانوادگی
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        نوع ارز{" "}
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Date Check in: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        مقدار{" "}
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient Name: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        نوع تراکنش{" "}
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        آدرس کیف پول{" "}
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Disease: activate to sort column ascending"
                        // style={{ width: 62 }}
                      >
                        تاریخ تراکنش{" "}
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Disease: activate to sort column ascending"
                        // style={{ width: 62 }}
                      >
                        ساعت تراکنش{" "}
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Status: activate to sort column ascending"
                        // style={{ width: 106 }}
                      >
                        وضعیت{" "}
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {coiningList && coiningList?.length !== 0 ? (
                    coiningList?.map((item, index) => {
                      return (
                        <tr role="row" className="odd text-center">
                          <td className="sorting_1">
                            <div className="custom-control custom-checkbox ">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun()}
                                className="custom-control-input"
                                id={`customCheckBox${index}`}
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customCheckBox${index}`}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center ">
                              {item.user.avatar !== null ? (
                                <img
                                  src={item.user.avatar}
                                  alt=""
                                  className="rounded-circle ml-2 width40 height40"
                                />
                              ) : (
                                <div className="img_cont_table">
                                  <img
                                    src={item?.user?.avatar}
                                    className="rounded-circle user_img"
                                    alt=""
                                  />
                                </div>
                              )}
                              {item.user.firstName !== null ? (
                                <span>
                                  {item.user.firstName} {item.user.lastName}
                                </span>
                              ) : (
                                "------"
                              )}
                            </div>
                          </td>
                          <td>
                            {/* {getPersianName(item?.coin)} */}
                            {item.coin?.toUpperCase()}
                            {/* {item.coin === "btc"
                              ? "بیتکوین"
                              : item.coin === "eth"
                              ? "اتریوم"
                              : item.coin === "trx"
                              ? "ترون"
                              : item.coin === "usdt"
                              ? "تتر"
                              : ""} */}
                          </td>
                          <td>{item.amount}</td>
                          <td>
                            {item.flow === "deposit" ? (
                              <span className="text-success">واریز</span>
                            ) : item.flow === "withdraw" ? (
                              <span className="text-danger">برداشت</span>
                            ) : (
                              ""
                            )}
                          </td>

                          <td>{item.address}</td>
                          <td>
                            {new Date(item.createdAt).toLocaleDateString(
                              "fa-IR"
                            )}
                          </td>
                          <td>
                            {new Date(item.createdAt).toLocaleTimeString(
                              "fa-IR"
                            )}
                          </td>
                          <td>
                            <span
                              className={`badge light fs-10                              
                              ${
                                item.status === "success"
                                  ? "badge-success"
                                  : item.status === "pending"
                                  ? "badge-primary"
                                  : item.status === "error"
                                  ? "badge-danger"
                                  : ""
                              }
                               `}
                            >
                              {item.status === "success"
                                ? "موفق"
                                : item.status === "pending"
                                ? "نامشخص"
                                : item.status === "error"
                                ? "ناموفق"
                                : ""}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="card-content bg-primary p-3 rounded text-center">
                      <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
                    </div>
                  )}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  نمایش{" "}
                  {coiningMeta?.page * coiningMeta?.limit -
                    coiningMeta?.limit +
                    1}{" "}
                  تا {coiningMeta?.page * coiningMeta?.limit} از{" "}
                  {coiningMeta?.total} کاربر
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <div className="d-inline-block pb-3 ltr_direction">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={coiningMeta?.limit}
                      totalItemsCount={coiningMeta?.total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CoiningTable);
