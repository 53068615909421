import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import pMinDelay from "p-min-delay";
import { usersAction, notificationActions } from "../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import Pagination from "react-js-pagination";

const UserAccountActivity = ({ dispatch }) => {
  const route = useParams();
  const { id } = route;
  const [allSessions, setAllSessions] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(null);

  useEffect(() => {
    const userId = id;
    dispatch(usersAction.getAllSessions({ page, limit, userId }))
      .then((r) => {
        setAllSessions(r?.data?.data);
        setUsersMeta(r.data?.meta);
        console.log(r?.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    const userId = id;
    dispatch(
      usersAction.getAllSessions({
        page,
        limit,
        userId,
      })
    )
      .then((r) => {
        setAllSessions(r?.data?.data);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }

  return (
    <div className="card-body px-0">
      <div className="table-responsive">
        {allSessions?.length !== 0 ? (
          <div>
            <table className="table shadow-hover user-detail-bank card-table border-no tbl-btn short-one">
              <thead>
                <tr className="border_table_title">
                  <th className="text-center fs-10">تاریخ</th>
                  <th className="text-center fs-10">ساعت</th>
                  <th className="text-center fs-10">شناسه آدرس</th>
                  <th className="text-center fs-10">مرورگر</th>

                  <th className="text-center fs-10">نقش</th>
                  <th className="text-center fs-10">پلتفرم</th>
                </tr>
              </thead>
              <tbody className="landing_tbody">
                {allSessions?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center fs-11">
                        {new Date(item.createdAt).toLocaleDateString("fa-IR")}
                      </td>
                      <td className="text-center fs-11">
                        {new Date(item.createdAt).toLocaleTimeString("fa-IR")}
                      </td>
                      <td className="text-center fs-11">{item.ip}</td>
                      <td className="text-center fs-11">
                        {item.extra.deviceName} {item.extra.deviceVersion}{" "}
                        {item.extra.osVersion}
                      </td>
                      <td className="text-center fs-11">
                        <span
                          className={`badge badge-activity-soft-info ml-1 fs-10`}
                        >
                          {item?.role}
                        </span>
                      </td>

                      <td className="text-success fs-11 text-center">
                        {item.platform}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="card-content bg-primary p-3 rounded text-center">
            <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
          </div>
        )}
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">
            نمایش {usersMeta?.page * usersMeta?.limit - usersMeta?.limit + 1} تا{" "}
            {usersMeta?.page * usersMeta?.limit} از {usersMeta?.total} حساب
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example5_paginate"
          >
            <div className="d-inline-block pb-3 ltr_direction">
              <Pagination
                activePage={page}
                itemsCountPerPage={usersMeta?.limit}
                totalItemsCount={usersMeta?.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(UserAccountActivity);
