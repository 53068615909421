import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import BankingTable from "./BankingTable";

const BankingList = () => {
  return (
    <Fragment>
      <PageTitle
        activeMenu="حساب های بانکی"
        motherMenu="داشبورد"
        pageContent="حساب های بانکی"
      />
      <div className="row">
        <BankingTable />
      </div>
    </Fragment>
  );
};

export default BankingList;
