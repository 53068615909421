import { httpRequest } from "./../_helper";

export const settingsAction = {
  getConfigItems,
  getAvailableCoinNetwork,
  setConfigItems,
};

function getConfigItems() {
  return () => {
    return httpRequest("get", `settings/items`);
  };
}
function getAvailableCoinNetwork() {
  return () => {
    return httpRequest("get", `settings/coins/networks`);
  };
}

function setConfigItems(data) {
  return () => {
    return httpRequest("put", `settings/items`, { data });
  };
}
