import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAvalibleBank } from "../../../_actions/CoiningActions";
import { Badge } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ModalBanks from "./ModalBanks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ERROR, SUCCESS } from "../../../_helper/alerts";

const BankAccount = () => {
  const selectBank = useSelector((state) => state.selectBank);

  const { Bank, loading, error } = selectBank;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAvalibleBank());
  }, [dispatch]);
  return (
    <div className="">
      <div className="card">
        <div className="card-header d-sm-flex d-block pb-0 border-0">
          <div>
            <h3 className="bank-title">ویرایش حساب های بانکی </h3>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <CircularProgress color="primary" />
          ) : error ? (
            ERROR(error)
          ) : (
            <>
              {/* ///orders */}
              <div className=" box-item">
                <h3>سفارش</h3>
                <div>
                  <span>کمترین حد سفارش</span>
                  <Badge variant="primary light">
                    {Bank.data.order.min.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span>بیشترین حد سفارش</span>
                  <Badge variant="primary light">
                    {Bank.data.order.max.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span> کارمزد </span>
                  <Badge variant="primary light">
                    {Bank.data.order.feeFactor}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد تا سقف</span>
                  <Badge variant="primary light">
                    {Bank.data.order.feeMax}
                  </Badge>
                </div>
              </div>
              {/* diposit */}
              <div className="box-item">
                <h3>واریز</h3>
                <div>
                  <span>کمنترین حد واریز</span>
                  <Badge variant="primary light">
                    {Bank.data.deposit.min.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span>بیشترین حد واریز</span>
                  <Badge variant="primary light">
                    {Bank.data.deposit.max.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span> کارمزد </span>
                  <Badge variant="primary light">
                    {Bank.data.deposit.feeFactor}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد تا سقف</span>
                  <Badge variant="primary light">
                    {Bank.data.deposit.feeMax}
                  </Badge>
                </div>
              </div>
              {/* withdraw */}
              <div className=" box-item">
                <h3>برداشت</h3>
                <div>
                  <span>کمنترین حد برداشت</span>
                  <Badge variant="primary light">
                    {Bank.data.withdraw.min.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span>بیشترین حد برداشت</span>
                  <Badge variant="primary light">
                    {Bank.data.withdraw.max.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span> کارمزد </span>
                  <Badge variant="primary light">
                    {Bank.data.withdraw.feeFactor}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد تا سقف</span>
                  <Badge variant="primary light">
                    {Bank.data.withdraw.feeMax}
                  </Badge>
                </div>
              </div>
              {/* OTC */}
              <div className=" box-item">
                <h3>OTC</h3>
                <div>
                  <span>کمترین حد</span>
                  <Badge variant="primary light">
                    {Bank.data.otc.min.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span>بیشترین حد</span>
                  <Badge variant="primary light">
                    {Bank.data.otc.max.toLocaleString()}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد Taker</span>
                  <Badge variant="primary light">
                    {Bank.data.otc.takerFeeFactor}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد Maker</span>
                  <Badge variant="primary light">
                    {Bank.data.otc.makerFeeFactor}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد Taker تا سقف</span>
                  <Badge variant="primary light">
                    {Bank.data.otc.takerFeeMax}
                  </Badge>
                </div>
                <div>
                  <span>کارمزد Maker تا سقف</span>
                  <Badge variant="primary light">
                    {Bank.data.otc.makerFeeMax}
                  </Badge>
                </div>
              </div>
              {/* Bank */}
              <div className=" box-item">
                <h3>درگاه های بانکی</h3>
                {Bank.data.banks?.map((item) => {
                  return (
                    <div>
                      <span>
                        {item.fa} {item.name}
                      </span>
                      <Badge variant="primary light">
                        <span>{item.isActive ? "فعال" : "غیرفعال"}</span>
                      </Badge>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <ModalBanks>
            <span className="">ویرایش فیات</span>
          </ModalBanks>
        </div>
      </div>
    </div>
  );
};

export default BankAccount;
