import { httpRequest } from "./../_helper";


const getAnOtc = (otcId) => {
    return () => {
      return httpRequest("get", `otc/${otcId}`);
    };
}


export const otcActions = {
    getAnOtc
}