import React, { useState, useRef, useEffect } from "react";
import { Modal, Dropdown, Row, Button, Col, Spinner } from "react-bootstrap";
import { rolesAction } from "./../../../_actions";
import { ERROR } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";
import Select from "react-select";

import { Link } from "react-router-dom";
// import data from "./tableData.js";

const RoleGrid = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [test, settest] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [address, setAddress] = useState("");
  const [coin, setCoin] = useState("");
  const [totalItems, setTotalItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [coiningMeta, setCoiningMeta] = useState([]);
  const [status, setStatus] = useState({ value: "", label: "همه" });

  const options = [
    { value: "", label: "همه" },
    { value: "pending", label: "نامشخص" },
    { value: "success", label: "موفق" },
    { value: "error", label: "ناموفق" },
  ];
  const handleAddressChange = (evt) => {
    setAddress(evt.target.value);
  };

  const handleCoinChange = (evt) => {
    setCoin(evt.target.value);
  };

  const handleStatusChange = (selected) => {
    setStatus(selected);
  };

  useEffect(() => {
    dispatch(rolesAction.getAllRoles({ page, limit }))
      .then((r) => {
        setRolesList(r.data?.data);
        console.log(r.data?.data);
        setCoiningMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  async function handlePageChange(page) {
    setPage(page);

    setIsLoading(true);

    dispatch(rolesAction.getAllRoles({ page, limit }))
      .then((r) => {
        setRolesList(r.data?.data);
        setCoiningMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    chackboxFun();
  }, [test]);

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title fs-14">لیست نقش ها</h4>
        </div>
        <div className="card-body pt-0">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                {rolesList && rolesList?.length !== 0 && (
                  <thead>
                    <tr className="text-center" role="row">
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        نام
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Date Check in: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        نقش
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient Name: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        تاریخ
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient Name: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        ساعت
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {rolesList && rolesList?.length !== 0 ? (
                    rolesList?.map((item, index) => {
                      return (
                        <tr role="row" className="odd text-center">
                          <td>{item.name}</td>
                          <td>
                            {item?.permissions?.map((x) => {
                              return (
                                <span
                                  className={`m-1 badge light fs-10 ${
                                    x.activity?.read === false &&
                                    x.activity?.write === false
                                      ? "badge-dark"
                                      : x.scope === "admin"
                                      ? "badge-info"
                                      : x.scope === "user"
                                      ? "badge-primary"
                                      : x.scope === "panel"
                                      ? "badge-warning"
                                      : x.scope === "setting"
                                      ? "badge-success"
                                      : x.scope === "config"
                                      ? "badge-secondary"
                                      : x.scope === "role"
                                      ? "badge-danger"
                                      : x.scope === "banking"
                                      ? "badge-warning"
                                      : x.scope === "coining"
                                      ? "badge-light"
                                      : x.scope === "order"
                                      ? "badge-primary"
                                      : x.scope === "otc"
                                      ? "badge-info"
                                      : x.scope === "trade"
                                      ? "badge-success"
                                      : x.scope === "notification"
                                      ? "badge-warning"
                                      : x.scope === "ticket"
                                      ? "badge-info"
                                      : x.scope === "trace"
                                      ? "badge-danger"
                                      : x.scope === "affiliate"
                                      ? "badge-secondary"
                                      : x.scope === "thirdParty"
                                      ? "badge-primary"
                                      : x.scope === "balance"
                                      ? "badge-success"
                                      : ""
                                  }`}
                                >
                                  {x.scope === "admin"
                                    ? "ادمین"
                                    : x.scope === "user"
                                    ? "مدیریت کاربران"
                                    : x.scope === "panel"
                                    ? "مدیریت پنل"
                                    : x.scope === "setting"
                                    ? "مدیریت تنظیمات"
                                    : x.scope === "config"
                                    ? "مدیریت کانفیگ ها"
                                    : x.scope === "role"
                                    ? "مدیریت نقش ها"
                                    : x.scope === "banking"
                                    ? "مدیریت حساب های بانکی"
                                    : x.scope === "coining"
                                    ? "مدیریت کیف پول ها"
                                    : x.scope === "order"
                                    ? "مدیریت سفارش ها"
                                    : x.scope === "otc"
                                    ? "مدیریت سفارشات سریع"
                                    : x.scope === "trade"
                                    ? "مدیریت معاملات"
                                    : x.scope === "notification"
                                    ? "مدیریت پیام ها"
                                    : x.scope === "ticket"
                                    ? "مدیریت تیکت ها"
                                    : x.scope === "trace"
                                    ? "مدیریت Trace ها"
                                    : x.scope === "affiliate"
                                    ? "مدیریت کدهای دعوت"
                                    : x.scope === "thirdParty"
                                    ? "مدیریت تنظیمات OTC"
                                    : x.scope === "balance"
                                    ? "مدیریت موجودی ها"
                                    : ""}
                                  {x.activity.write && x.activity.read ? (
                                    <span>
                                      <i class="las la-pen mr-2 fs-12"></i>{" "}
                                      <i class="las la-eye mr-2 fs-12"></i>
                                    </span>
                                  ) : x.activity.write === true ? (
                                    <i class="las la-pen mr-2 fs-12"></i>
                                  ) : x.activity.read === true ? (
                                    <i class="las la-eye mr-2 fs-12"></i>
                                  ) : null}
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {new Date(item.createdAt).toLocaleDateString(
                              "fa-IR"
                            )}
                          </td>
                          <td>
                            {new Date(item.createdAt).toLocaleTimeString(
                              "fa-IR"
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="card-content bg-primary p-3 rounded text-center">
                      <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
                    </div>
                  )}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  نمایش{" "}
                  {coiningMeta?.page * coiningMeta?.limit -
                    coiningMeta?.limit +
                    1}{" "}
                  تا {coiningMeta?.page * coiningMeta?.limit} از{" "}
                  {coiningMeta?.total} نقش
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <div className="d-inline-block pb-3 ltr_direction">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={coiningMeta?.limit}
                      totalItemsCount={coiningMeta?.total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(RoleGrid);
