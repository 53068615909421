import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DefaultButton } from "../components/Defaults/DefaultButton";
import { NumberInput } from "../components/Defaults/NumberInput";
import { isValidPhone } from "../components/Validation/checkMobile";
import { authActions } from "./../../_actions";
import { ERROR } from "../../_helper/alerts";
import { connect } from "react-redux";
import {
  osVersion,
  osName,
  browserName,
  browserVersion,
} from "react-device-detect";

// image
import logo from "../../images/logo.png";

const Login = ({ history, dispatch, requestOtp, verifyOtp }) => {
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [emptyMobile, setEmptyMobile] = useState(false);
  const [emptyCode, setEmptyCode] = useState(false);
  const [sentRequestOtp, setSentRequestOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderOtp, setLoaderOtp] = useState(false);
  const [osV, setOsVersion] = useState("");
  const [browserV, setBrowserVersion] = useState("");
  const [browserN, setBrowserName] = useState("");
  const [code, setCode] = useState("");
  const [FACode, setFACode] = useState("");
  const [getRoles, setGetRoles] = useState(false);
  const [preToken, setPreToken] = useState("");
  const [verify2FA, setVerify2FA] = useState(false);
  const [loader2FA, setLoader2FA] = useState(false);

  useEffect(() => {
    if (isValidPhone(mobile) || mobile === "") {
      setMobileError(false);
    } else {
      setMobileError(true);
    }

    if (mobile?.trim().length === 11) {
      setEmptyMobile(false);
    } else {
      setEmptyMobile(true);
    }
  }, [mobile]);

  useEffect(() => {
    if (code?.trim().length === 5) {
      setEmptyCode(false);
    } else {
      setEmptyCode(true);
    }
  }, [code]);

  useEffect(() => {
    if (osVersion && osName) {
      setOsVersion(osName + " " + osVersion);
    }
    if (browserName) {
      setBrowserName(browserName);
    }
    if (browserVersion) {
      setBrowserVersion(browserVersion);
    }
  }, []);

  const extra = {
    deviceName: browserN,
    deviceVersion: browserV,
    osVersion: osV,
    appVersion: "React v16.9.0",
  };

  const requestOtpBtn = () => {
    setLoader(true);
    dispatch(authActions.requestOtp(mobile));
  };

  useEffect(() => {
    if (requestOtp?.success) {
      setLoader(false);
      setSentRequestOtp(true);
    }
  }, [requestOtp]);

  useEffect(() => {
    if (requestOtp === "یک پیامک حاوی کد ورود برای شماره شما ارسال شد") {
      setLoader(false);
      setSentRequestOtp(true);
    } else if (requestOtp === "error") {
      setLoader(false);
    }
  }, [requestOtp]);

  const verifyOtpBtn = () => {
    setLoaderOtp(true);
    dispatch(authActions.verifyOtp(mobile, parseInt(code), extra));
  };

  useEffect(() => {
    if (verifyOtp) {
      setVerify2FA(true);
      setPreToken(verifyOtp?.preToken);
    }
  }, [verifyOtp]);

  const confirm2FACOde = () => {
    if (verifyOtp) {
      setLoader2FA(true);
      dispatch(authActions.verify2FA(preToken, FACode, extra));
    }
  };

  useEffect(() => {
    if (verifyOtp?.message === "با موفقیت وارد شدید") {
      if (verifyOtp?.data?.user?.role !== "user") window.location.href = "/";
      else {
        setLoaderOtp(false);
        ERROR("شما اجازه دسترسی به این بخش را ندارید");
      }
    }
  }, [dispatch, verifyOtp]);

  const onChangeMobile = (evt) => {
    if (evt.value) {
      setMobile(evt.value);
    }
  };
  const onChangeCode = (evt) => {
    if (evt.value) {
      setCode(evt.value);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // history.push("/");
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="200" alt="" />
                      </Link>
                    </div>
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        {verify2FA ? (
                          <NumberInput
                            className="form-control text-center ltr_direction"
                            id="FACode"
                            value={FACode}
                            allowLeadingZeros={true}
                            format="######"
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              setFACode(formattedValue);
                            }}
                            label={
                              "لطفا کد احراز هویت دو مرحله ای را ارسال کنید"
                            }
                          />
                        ) : sentRequestOtp ? (
                          <NumberInput
                            className="form-control text-center ltr_direction"
                            id="code"
                            value={code}
                            allowLeadingZeros={true}
                            format="#####"
                            onValueChange={onChangeCode}
                            label={"کد 5 رقمی را وارد نمایید"}
                          />
                        ) : (
                          <NumberInput
                            className="form-control text-left ltr_direction"
                            id="mobile"
                            value={mobile}
                            allowLeadingZeros={true}
                            format="###########"
                            placeholder="09---------"
                            onValueChange={onChangeMobile}
                            label={"شماره همراه"}
                            error={mobileError}
                            errorText="تلفن همراه باید با 09 شروع شود"
                          />
                        )}
                      </div>
                      <div className="text-center">
                        {verify2FA ? (
                          <DefaultButton
                            disabled={emptyCode}
                            onClick={() => confirm2FACOde()}
                            loading={loader2FA}
                            type="submit"
                            className="w-100 fs-12"
                            variant="primary"
                          >
                            ورود
                          </DefaultButton>
                        ) : sentRequestOtp ? (
                          <DefaultButton
                            disabled={emptyCode}
                            onClick={() => verifyOtpBtn()}
                            loading={loaderOtp}
                            type="submit"
                            className="w-100 fs-12"
                            variant="primary"
                          >
                            ارسال
                          </DefaultButton>
                        ) : (
                          <DefaultButton
                            disabled={emptyMobile}
                            type="submit"
                            onClick={() => requestOtpBtn()}
                            loading={loader}
                            className="w-100 fs-12"
                            variant="primary"
                          >
                            ارسال کد
                          </DefaultButton>
                        )}
                      </div>
                    </form>
                    {/* <div className="new-account mt-3">
                      <p className="">
                        Don't have an account?{" "}
                        <Link className="text-primary" to="./page-register">
                          Sign up
                        </Link>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    requestOtp: state.auth.requestOtp,
    verifyOtp: state.auth.verifyOtp,
  };
}

export default connect(mapStateToProps)(Login);
