import { httpRequest } from "./../_helper";

export const ticketsActions = {
  getAllUnreadTickets,
  getAllTickets,
  createTicket,
  readAllTicketFromUser,
};

function getAllUnreadTickets({ page, limit }) {
  return () => {
    return httpRequest(
      "get",
      `tickets/admin/unread?page=${page}&limit=${limit}`
    );
  };
}

function getAllTickets({ page, limit, userId }) {
  return () => {
    return httpRequest(
      "get",
      `tickets?page=${page}&limit=${limit}&search=${
        userId ? `userId` : ""
      }&query=${userId ? `${userId}` : ""}`
    );
  };
}

function createTicket(data) {
  return () => {
    return httpRequest("post", "tickets/admin", { data });
  };
}

function readAllTicketFromUser(userId) {
  return () => {
    return httpRequest("put", `tickets/admin/read/all/${userId}`);
  };
}
