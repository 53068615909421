import React, { useState, useEffect } from "react";
import { ERROR } from "../../../_helper/alerts";
import { useDispatch } from "react-redux";
import { tokenActions } from "../../../_actions";

const UploadIcon = ({
  onSelect,
  isEdit,
  onSelectEdit,
  hasText,
  isExistImg,
  symbol,
  isSaveToken,
}) => {
  const [refresh, setRefresh] = useState(false);
  const [iconRef, setIconRef] = useState(React.createRef());
  const [iconImg, setIconImg] = useState("");
  const [isText, setIsText] = useState(hasText);

  const dispatch = useDispatch();

  const updateIcon = (e) => {
    if (isExistImg) {
      const files = new FormData();
      if (iconRef.current.files[0].size > 3500000) {
        ERROR("حجم تصویر ارسالی نباید بیشتر از 3.5 مگابایت باشد");
      } else {
        files.append("icon", iconRef.current.files[0]);
        setIconImg(URL.createObjectURL(iconRef.current.files[0]));
        // setModalCentered(false);
        // if (isEdit) handleSelectEdit(true);
        // else handleSelect(true);
        dispatch(tokenActions.updateTokenIcon(symbol, files))
          .then((r) => {
            // if (isEdit) handleSelectEdit(true);
            // else handleSelect(true);
            setRefresh(true);
          })
          .catch((ex) => {
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            });
            // setModalCentered(false);
          });
      }
    } else {
      setIsText(true);
    }
  };

  //   const handleSelect = (item) => {
  //     // setRefresh(true);
  //     onSelect(item);
  //   };

  //   const handleSelectEdit = (item) => {
  //     // setRefresh(true);
  //     onSelectEdit(item);
  //   };

  const checkImage = (e) => {
    // setIsExistImg(false);
    e.target.onerror = null;
    e.target.src = "./images/coins/noImg.png";
  };

  return (
    <div>
      {symbol !== "" && isSaveToken ? (
        <>
          <div className="img-features mt-3">
            <p>
              * لطفا تصاویر مناسب، با کیفیت و با فرمت png در سایز 256*256
              بارگذاری شوند.
            </p>
          </div>
          <div className="form-group col-md-12">
            <label for="icon-img" className=" custom-file-upload">
              <input
                accept="image/png"
                onChange={(e) => updateIcon(e)}
                ref={iconRef}
                type="file"
                id="icon-img"
                name="icon-img"
              />
              آپلود آیکون
            </label>
            <img
              onError={(e) => {
                checkImage(e);
                // e.target.onerror = null;
                // e.target.src = "./images/coins/noImg.png";
              }}
              src={
                isEdit && !refresh
                  ? process.env.REACT_APP_SOCKET_URL +
                    `assets/icon/${symbol?.toLowerCase()}.png`
                  : iconImg
              }
              width={"50"}
              className="img-fluid rounded-circle mx-4"
            />
            {hasText && !isExistImg ? (
              <p className="mt-2 text-primary fs-12">
                آیکن مورد نظر از قبل موجود است
              </p>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UploadIcon;
