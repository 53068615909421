import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RobotModal from "./RobotModal";
import RobotUpdate from "./RobotUpdate";
import { robotsActions, usersAction } from "../../../_actions";
import { ERROR } from "../../../_helper/alerts";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

const RobotsList = ({ dispatch }) => {
  const route = useParams();
  const [tabs, setTabs] = useState("Bitcoin");
  const [robotsData, setRobotsData] = useState([]);
  const [userId, setUserId] = useState("");
  const [userIp, setUserIp] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const { id } = route;

  useEffect(() => {
    dispatch(robotsActions.getConfigRobots())
      .then((r) => {
        setUserId(r?.data?.data?.userId);
        setUserIp(r?.data?.data?.userIp);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(usersAction.userInfo(userId))
        .then((r) => {
          setUserInfo(r?.data?.data);
          console.log(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [userId]);

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="mb-0 fs-20 text-black">ربات ها</h4>
          <RobotModal robotsData={robotsData} userId={id}>
            <a className="btn btn-secondary mb-2">
              <i className="fa fa-plus ml-2" />
              افزودن ربات جدید{" "}
            </a>
          </RobotModal>
        </div>
        <div className="card-body">
          <div className=" my-3">
            <div
              className={`col-12 bg-secondary coin-holding flex-wrap d-flex align-items-center`}
            >
              <div className="col-lg-4 col-md-6 col-sm-12">
                {/* <div className="d-flex align-items-center mr-1 mt-3"> */}
                <div className="d-flex align-items-center ">
                  {userInfo.avatar !== null ? (
                    <img
                      src={userInfo?.avatar}
                      alt=""
                      className="rounded-circle ml-2 width40 height40"
                    />
                  ) : (
                    <div className="img_cont_table">
                      <img
                        src={userInfo?.avatar}
                        className="rounded-circle user_img"
                        alt=""
                      />
                    </div>
                  )}
                  {userInfo?.firstName !== null ? (
                    <span>
                      {userInfo?.firstName} {userInfo?.lastName}
                    </span>
                  ) : (
                    "------"
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="d-flex align-items-center">
                  <div className="coin-bx-one">آدرس Ip: </div>
                  <div className="ml-3">
                    <span className="mb-0 text-white mr-2 wrap_text">
                      {userIp}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="d-flex align-items-center">
                  <div className="coin-bx-one">شناسه کاربر: </div>
                  <div className="ml-3">
                    <span className="mb-0 text-white mr-2 wrap_text">
                      {userInfo?._id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {robotsData && robotsData?.length !== 0 ? (
            <Tabs
              defaultActiveKey={robotsData[0]?.coin}
              transition={false}
              id="cp-coins"
            >
              {robotsData?.map((item, index) => {
                return (
                  <Tab key={index} eventKey={item.coin} title={item.coin}>
                    <RobotUpdate
                      userId={id}
                      tabs={item.coin}
                      title={item.coin}
                      isActive={item.isActive}
                      robotsData={robotsData}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          ) : (
            <div className="card-content bg-primary p-3 rounded text-center">
              <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(RobotsList);
