import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { httpRequest } from "../../../_helper/httpServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NumberInput } from "../Defaults/NumberInput";
import { BaseInput } from "../Defaults/BaseInput";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { useDispatch } from "react-redux";
import { getAvalibleBank } from "../../../_actions/CoiningActions";
import { DefaultButton } from "../Defaults/DefaultButton";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { coiningActions, tokenActions } from "../../../_actions";
import { history } from "../../../_helper/history";

const NewCoinModal = ({ children, isEdit, tokenData }) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allCoins, setAllCoins] = useState([]);
  const [allAvailable, setAllAvailable] = useState([]);
  const [tokensList, setTokensList] = useState([]);
  const [hasEditSetting, setHasEditSetting] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);
  const [value, onChange] = useState([]);
  const [coin, setCoin] = useState("");
  const [coin2, setCoin2] = useState("");
  const [orderMin, setOrderMin] = useState(0);
  const [orderMax, setOrderMax] = useState(0);
  const [takerOrderFeeFactor, setTakerOrderFeeFactor] = useState(0);
  const [makerOrderFeeFactor, setMakerOrderFeeFactor] = useState(0);
  const [takerOrderFeeMax, setTakerOrderFeeMax] = useState(0);
  const [makerOrderFeeMax, setMakerOrderFeeMax] = useState(0);
  const [persianName, setPersianName] = useState("");
  const [englishName, setEnglishName] = useState("");
  //state otc
  const [otcMin, setOtcMin] = useState(0);
  const [otcMax, setOtcMax] = useState(0);
  const [takerOtcFeeFactor, setTakerOtcFeeFactor] = useState(0);
  const [makerOtcFeeFactor, setMakerOtcFeeFactor] = useState(0);
  const [takerOtcFeeMax, setTakerOtcFeeMax] = useState(0);
  const [makerOtcFeeMax, setMakerOtcFeeMax] = useState(0);
  //state diposit
  const [depositMin, setDepositMin] = useState(0);
  const [depositMax, setDepositMax] = useState(0);
  const [depositFeeFactor, setDepositFeeFactor] = useState(0);
  const [depositFeeMax, setDepositFeeMax] = useState(0);
  //state withdraw
  const [withdrawMin, setWithdrawMin] = useState(0);
  const [withdrawMax, setWithdrawMax] = useState(0);
  const [withdrawFeeFactor, setWithdrawFeeFactor] = useState(0);
  const [withdrawFeeMax, setWithdrawFeeMax] = useState(0);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  function getUniqueArray(arr = [], compareProps = []) {
    let modifiedArray = [];
    if (compareProps.length === 0 && arr.length > 0)
      compareProps.push(...Object.keys(arr[0]));
    arr.forEach((item) => {
      if (modifiedArray.length === 0) {
        modifiedArray.push(item);
      } else {
        if (
          !modifiedArray.some((item2) =>
            compareProps.every(
              (eachProps) => item2[eachProps] === item[eachProps]
            )
          )
        ) {
          modifiedArray.push(item);
        }
      }
    });
    return modifiedArray;
  }

  const handleChangeCoins = (selected) => {
    setCoin(selected);
    setChecked(selected.isActive);
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.write === true;
      });

      roles?.filter((item) => {
        if (item.scope === "setting") setHasEditSetting(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(coiningActions.getAllAvailableCoins())
      .then((r) => {
        setAllAvailable(r?.data?.data);
        // console.log(r?.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, [modalCentered]);

  useEffect(() => {
    dispatch(tokenActions.getAllTokens({ page, limit }))
      .then((r) => {
        const seen = new Set();
        const filteredArr = r.data?.data.filter((el) => {
          const duplicate = seen.has(el.symbol);
          seen.add(el.symbol);
          return !duplicate;
        });
        setTokensList(filteredArr);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, [modalCentered]);

  useEffect(() => {
    dispatch(coiningActions.getPreDefinedCoins())
      .then((r) => {
        const mergedArr = [...r?.data?.data, ...tokensList];
        const arr = mergedArr?.map((item) => {
          return {
            value: item.symbol,
            label: `${item.symbol.toUpperCase()}`,
            labelFa: item?.fa,
            name: item?.name,
            isActive: item?.isActive,
          };
        });

        var dataArr = arr.map((item) => {
          return [JSON.stringify(item), item];
        });
        var mapArr = new Map(dataArr);

        var result = [...mapArr.values()];

        setAllCoins(result);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, [modalCentered]);

  async function saveToken() {
    let dataList = allAvailable;
    dataList?.push({
      id: coin?.value,
      symbol: coin?.value,
      name: englishName ? englishName : coin?.label,
      fa: persianName,
      otc: {
        min:
          typeof otcMin === "string"
            ? parseFloat(otcMin?.replaceAll(",", ""))
            : otcMin,
        max:
          typeof otcMax === "string"
            ? parseFloat(otcMax?.replaceAll(",", ""))
            : otcMax,
        takerFeeFactor:
          typeof takerOtcFeeFactor === "string"
            ? parseFloat(takerOtcFeeFactor?.replaceAll(",", ""))
            : takerOtcFeeFactor,
        makerFeeFactor:
          typeof makerOtcFeeFactor === "string"
            ? parseFloat(makerOtcFeeFactor?.replaceAll(",", ""))
            : makerOtcFeeFactor,
        takerFeeMax:
          typeof takerOtcFeeMax === "string"
            ? parseFloat(takerOtcFeeMax?.replaceAll(",", ""))
            : takerOtcFeeMax,
        makerFeeMax:
          typeof makerOtcFeeMax === "string"
            ? parseFloat(makerOtcFeeMax?.replaceAll(",", ""))
            : makerOtcFeeMax,
      },
      order: {
        min:
          typeof orderMin === "string"
            ? parseFloat(orderMin?.replaceAll(",", ""))
            : orderMin,
        max:
          typeof orderMax === "string"
            ? parseFloat(orderMax?.replaceAll(",", ""))
            : orderMax,
        takerFeeFactor:
          typeof takerOrderFeeFactor === "string"
            ? parseFloat(takerOrderFeeFactor?.replaceAll(",", ""))
            : takerOrderFeeFactor,
        makerFeeFactor:
          typeof makerOrderFeeFactor === "string"
            ? parseFloat(makerOrderFeeFactor?.replaceAll(",", ""))
            : makerOrderFeeFactor,
        takerFeeMax:
          typeof takerOrderFeeMax === "string"
            ? parseFloat(takerOrderFeeMax?.replaceAll(",", ""))
            : takerOrderFeeMax,
        makerFeeMax:
          typeof makerOrderFeeMax === "string"
            ? parseFloat(makerOrderFeeMax?.replaceAll(",", ""))
            : makerOrderFeeMax,
      },
      coining: {
        deposit: {
          min:
            typeof depositMin === "string"
              ? parseFloat(depositMin?.replaceAll(",", ""))
              : depositMin,
          max:
            typeof depositMax === "string"
              ? parseFloat(depositMax?.replaceAll(",", ""))
              : depositMax,
          feeFactor:
            typeof depositFeeFactor === "string"
              ? parseFloat(depositFeeFactor?.replaceAll(",", ""))
              : depositFeeFactor,
          feeMax:
            typeof depositFeeMax === "string"
              ? parseFloat(depositFeeMax?.replaceAll(",", ""))
              : depositFeeMax,
        },
        withdraw: {
          min:
            typeof withdrawMin === "string"
              ? parseFloat(withdrawMin?.replaceAll(",", ""))
              : withdrawMin,
          max:
            typeof withdrawMax === "string"
              ? parseFloat(withdrawMax?.replaceAll(",", ""))
              : withdrawMax,
          feeFactor:
            typeof withdrawFeeFactor === "string"
              ? parseFloat(withdrawFeeFactor?.replaceAll(",", ""))
              : withdrawFeeFactor,
          feeMax:
            typeof withdrawFeeMax === "string"
              ? parseFloat(withdrawFeeMax?.replaceAll(",", ""))
              : withdrawFeeMax,
        },
      },
      isActive: checked,
    });

    const uniqueCoins = getUniqueArray(dataList);
    const data = uniqueCoins;

    //   const data = {
    //     data: [
    //          {
    //     "name": "Bitcoin",
    //     "symbol": "btc",
    //     "id": "btc",
    //     "fa": "بیتکوین",
    //     "order": {
    //         "min": 0,
    //         "max": 100,
    //         "takerFeeFactor": 0,
    //         "takerFeeMax": 0,
    //         "makerFeeFactor": 0,
    //         "makerFeeMax": 0
    //     },
    //     "otc": {
    //         "min": 0,
    //         "max": 100,
    //         "takerFeeFactor": 0,
    //         "takerFeeMax": 0,
    //         "makerFeeFactor": 0,
    //         "makerFeeMax": 0
    //     },
    //     "coining": {
    //         "deposit": {
    //             "min": 0,
    //             "max": 50000000,
    //             "feeFactor": 0.0001,
    //             "feeMax": 0
    //         },
    //         "withdraw": {
    //             "min": 0,
    //             "max": 50000000,
    //             "feeFactor": 0.0001,
    //             "feeMax": 0
    //         }
    //     },
    //     "isActive": true
    // }
    //     ],
    //   };

    setLoader(true);
    await httpRequest("put", "settings/coins", {
      data: { data },
    })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r.data.message);
          setModalCentered(false);
          setLoader(false);
          history.push("/coin-details");
        }
      })
      .catch((err) => {
        setLoader(false);
        ERROR(err.response?.data?.message || err.message, {
          error: err,
        });
      });
  }

  const statusHandleChange = () => {
    setChecked(!checked);
  };

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <a className="d-flex m-auto" onClick={() => setModalCentered(true)}>
        {children}
      </a>
      {/* <!-- Modal --> */}
      <Modal
        className="fade "
        show={modalCentered}
        size="lg"
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="number-black">افزودن ارز جدید</h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-row">
                <div className="col-12">
                  <BaseInput
                    className="form-control text-right"
                    id="persianName"
                    value={persianName}
                    onChange={(evt) => {
                      setPersianName(evt.target.value);
                    }}
                    label={"نام فارسی"}
                  />
                </div>
                <div className="col-12">
                  <BaseInput
                    className="form-control text-right"
                    id="englishName"
                    value={englishName}
                    onChange={(evt) => {
                      setEnglishName(evt.target.value);
                    }}
                    label={"نام انگلیسی"}
                  />
                </div>
                <div className="form-group col-md-12">
                  <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                    <Label for="status">ارز</Label>
                    <Select
                      styles={customStyles}
                      className={`react-select w-100 text-left`}
                      classNamePrefix="react-select"
                      onChange={handleChangeCoins}
                      options={allCoins}
                      value={coin}
                      placeholder="ارز"
                      instanceId="coins"
                      name="coins"
                    />

                    {/* <SelectAsyncPaginate
                      value={coin2}
                      onChange={(x) => setCoin2(x)}
                    /> */}
                  </FormGroup>
                </div>
                <div className="form-group col-md-12">
                  <Label className="ml-2" for="status">
                    وضعیت
                  </Label>
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={15}
                    width={28}
                    onColor="#1fa2ff"
                    onChange={statusHandleChange}
                    checked={checked}
                  />
                </div>
              </div>
              <h5 className="mb-3 mt-3 title__Modla">سفارش</h5>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    label={"کمترین حد سفارش"}
                    name="orderMin"
                    allowLeadingZeros={true}
                    className="form-control"
                    onChange={(e) => setOrderMin(e.target.value)}
                    value={orderMin}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="orderMax"
                    label={"بیشترین حد سفارش"}
                    name="orderMax"
                    className="form-control"
                    allowLeadingZeros={true}
                    value={orderMax}
                    onChange={(e) => setOrderMax(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    htmlFor
                    id="takerOrderFeeFactor"
                    label={"کارمزد Taker (%)"}
                    placeholder="به درصد"
                    name="takerOrderFeeFactor"
                    className="form-control"
                    allowLeadingZeros={true}
                    value={takerOrderFeeFactor}
                    onChange={(e) => setTakerOrderFeeFactor(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    htmlFor
                    id="makerOrderFeeFactor"
                    label={"کارمزد Maker (%)"}
                    placeholder="به درصد"
                    name="makerOrderFeeFactor"
                    className="form-control"
                    allowLeadingZeros={true}
                    value={makerOrderFeeFactor}
                    onChange={(e) => setMakerOrderFeeFactor(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="takerOrderFeeMax"
                    name="takerOrderFeeMax"
                    label={"کارمزد Taker تا سقف (مقدار صفر = بدون سقف)"}
                    className="form-control"
                    allowLeadingZeros={true}
                    value={takerOrderFeeMax}
                    onChange={(e) => setTakerOrderFeeMax(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="makerOrderFeeMax"
                    name="makerOrderFeeMax"
                    label={"کارمزد Maker تا سقف (مقدار صفر = بدون سقف)"}
                    className="form-control"
                    allowLeadingZeros={true}
                    value={makerOrderFeeMax}
                    onChange={(e) => setMakerOrderFeeMax(e.target.value)}
                  />
                </div>
              </div>
              <h5 className="mb-3 mt-3 title__Modla">OTC</h5>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="otcMin"
                    name="otcMin"
                    label={" کمترین حد otc"}
                    className="form-control"
                    value={otcMin}
                    onChange={(e) => setOtcMin(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="otcMax"
                    label={" بیشترین حد otc"}
                    name="otcMax"
                    className="form-control"
                    value={otcMax}
                    onChange={(e) => setOtcMax(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    htmlFor
                    id="takerOtcFeeFactor"
                    placeholder="به درصد"
                    label={"کارمزد Taker (%)"}
                    name="takerOtcFeeFactor"
                    value={takerOtcFeeFactor}
                    className="form-control"
                    onChange={(e) => setTakerOtcFeeFactor(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    htmlFor
                    id="makerOtcFeeFactor"
                    placeholder="به درصد"
                    label={"کارمزد Maker (%)"}
                    name="makerOtcFeeFactor"
                    value={makerOtcFeeFactor}
                    className="form-control"
                    onChange={(e) => setMakerOtcFeeFactor(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="takerOtcFeeMax"
                    label={"کارمزد Taker تا سقف (مقدار صفر = بدون سقف)"}
                    name="takerOtcFeeMax"
                    className="form-control"
                    value={takerOtcFeeMax}
                    onChange={(e) => setTakerOtcFeeMax(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="makerOtcFeeMax"
                    label={"کارمزد Maker تا سقف (مقدار صفر = بدون سقف)"}
                    name="makerOtcFeeMax"
                    className="form-control"
                    value={makerOtcFeeMax}
                    onChange={(e) => setMakerOtcFeeMax(e.target.value)}
                  />
                </div>
              </div>
              <h5 className="mb-3 mt-3 title__Modla">واریز </h5>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="depositMin"
                    name="depositMin"
                    label={" کمترین حد واریز"}
                    className="form-control"
                    value={depositMin}
                    onChange={(e) => setDepositMin(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="depositMax"
                    label={" بیشترین حد واریز"}
                    name="depositMax"
                    className="form-control"
                    value={depositMax}
                    onChange={(e) => setDepositMax(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    htmlFor
                    id="depositFeeFactor"
                    placeholder="به درصد"
                    label={"کارمزد (%)"}
                    name="depositFeeFactor"
                    value={depositFeeFactor}
                    className="form-control"
                    onChange={(e) => setDepositFeeFactor(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="depositFeeMax"
                    label={"کارمزد تا سقف (مقدار صفر = بدون سقف)"}
                    name="depositFeeMax"
                    className="form-control"
                    value={depositFeeMax}
                    onChange={(e) => setDepositFeeMax(e.target.value)}
                  />
                </div>
              </div>

              <h5 className="mb-3 mt-3 title__Modla">برداشت</h5>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="withdrawMin"
                    label={"کمترین حد برداشت"}
                    name="withdrawMin"
                    className="form-control"
                    value={withdrawMin}
                    onChange={(e) => setWithdrawMin(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="withdrawMax"
                    name="withdrawMax"
                    label={" بیشترین حد برداشت "}
                    className="form-control"
                    value={withdrawMax}
                    onChange={(e) => setWithdrawMax(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="withdrawFeeFactor"
                    label={"کارمزد (%) "}
                    name="withdrawFeeFactor"
                    placeholder="به درصد"
                    className="form-control"
                    value={withdrawFeeFactor}
                    onChange={(e) => setWithdrawFeeFactor(e.target.value)}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <NumberInput
                    id="withdrawFeeMax"
                    label={"کارمزد تا سقف (مقدار صفر = بدون سقف)"}
                    name="withdrawFeeMax"
                    className="form-control"
                    value={withdrawFeeMax}
                    onChange={(e) => setWithdrawFeeMax(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setModalCentered(false)}
              variant="danger light"
              className="ml-2"
            >
              بستن
            </Button>
            <DefaultButton
              onClick={() => saveToken()}
              loading={loader}
              type="submit"
              variant="primary"
            >
              ذخیره تغییرات
            </DefaultButton>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default NewCoinModal;
