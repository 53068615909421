import React, { useEffect, useState } from "react";
import { httpRequest } from "../../../_helper/httpServices";
import ModalPairCoins from "./ModalPairCoins";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import Table from "react-bootstrap/Table";
const PairCoins = () => {
  const [pairCoin, setPairCoin] = useState([]);
  const [update, setUpdate] = useState(false);

  const getPairConis = () => {
    httpRequest("get", "settings/pairs")
      .then((res) => setPairCoin(res.data.data))
      .catch((err) => ERROR(err));
  };
  useEffect(() => {
    getPairConis();
  }, [update]);

  async function removePairCoins(item) {
    let items = pairCoin?.filter((x) => x !== item);
    console.log(items);
    const data = {
      data: items,
    };
    await httpRequest("put", "settings/pairs", { data })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r?.data?.message || "اطلاعات با موفقیت حذف شد");
          getPairConis();
        }
      })
      .catch((ex) => {
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  return (
    <div className="">
      <div className="card">
        <div className="card-header d-sm-flex d-block pb-0 border-0">
          <div>
            <h3 className="bank-title"> جفت ارز</h3>
          </div>
        </div>
        <div className="card-body">
          <Table hover bordered striped responsive>
            <thead>
              <tr>
                <th className="text-center text-dark">نماد</th>
                <th className="text-center text-dark">نام ارز به فارسی</th>
                <th className="text-center text-dark">نام ارز به انگلیسی</th>
                <th className="text-center text-dark">عملیات</th>
              </tr>
            </thead>
            <tbody>
              {pairCoin?.map((pair) => (
                <tr>
                  <td className="text-center">{pair.symbol}</td>
                  <td className="text-center">
                    <img
                      className="cc_size fs-16"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "./images/coins/noImg.png";
                      }}
                      src={
                        process.env.REACT_APP_SOCKET_URL +
                        `assets/icon/${pair?.symbol?.toLowerCase()}.png`
                      }
                    />

                    <span class="mr-2">{pair.fa}</span>
                  </td>
                  <td className="text-center">{pair.name}</td>
                  <td className="text-center">
                    <div>
                      <a
                        className="cursor-pointer"
                        onClick={() => removePairCoins(pair)}
                      >
                        <i
                          className="fa fa-trash fs-20"
                          style={{ color: "#ff5d5d" }}
                        ></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <ModalPairCoins setUpdate={update} pairCoinData={pairCoin}>
            <span className="">ویرایش جفت ارز</span>
          </ModalPairCoins>
        </div>
      </div>
    </div>
  );
};

export default PairCoins;
