import { httpRequest } from "./../_helper";

export const notificationActions = {
  getAllNotifications,
  getAllUnreadNotifications,
  createNotification,
  readAllNotification,
};

function getAllNotifications({ page, limit }) {
  return () => {
    return httpRequest("get", `notifications?page=${page}&limit=${limit}`);
  };
}

function getAllUnreadNotifications({ page, limit }) {
  return () => {
    return httpRequest(
      "get",
      `notifications/unread?page=${page}&limit=${limit}`
    );
  };
}

function createNotification(data) {
  return () => {
    return httpRequest("post", "notifications", { data });
  };
}

function readAllNotification(data) {
  return () => {
    return httpRequest("put", "notifications/read/all");
  };
}
