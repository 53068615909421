import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { httpRequest } from "../../../_helper/httpServices";
import { NumberInput } from "../Defaults/NumberInput";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { getAvalibleWages } from "../../../_actions/CoiningActions";
import { useDispatch, useSelector } from "react-redux";
import { DefaultButton } from "../Defaults/DefaultButton";
const ModalWages = ({ children }) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [loader, setLoader] = useState(false);
  const [Buyfact, setBuyfact] = useState(0);
  const [sellfact, setSellfact] = useState(0);
  const [affiliatep2p, setAffiliatep2p] = useState(0);
  const [affiliateOtc, setAffiliateOtc] = useState(0);
  const [userLevelOneMax, setUserLevelOneMax] = useState(0);
  const [userLevelOneMin, setUserLevelOneMin] = useState(0);
  const [userLevelOneOff, setUserLevelOneOff] = useState(0);
  const [userLevelTowOneMax, setUserLevelTowOneMax] = useState(0);
  const [userLevelTowOneMin, setUserLevelTowOneMin] = useState(0);
  const [userLevelTowOneOff, setUserLevelTowOneOff] = useState(0);
  const [userLevelTreeMax, setUserLevelTreeMax] = useState(0);
  const [userLevelTreeMin, setUserLevelTreeMin] = useState(0);
  const [userLevelTreeOff, setUserLevelTreeOff] = useState(0);
  const selectWages = useSelector((state) => state.selectWages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvalibleWages);
    if (selectWages?.Wages?.data) {
      setAffiliatep2p(selectWages?.Wages?.data?.affiliate.p2pPercent);
      setAffiliateOtc(selectWages?.Wages?.data?.affiliate.otcPercent);
      setBuyfact(selectWages?.Wages?.data?.dollar.buyFactor);
      setSellfact(selectWages?.Wages?.data?.dollar.sellFactor);
      setUserLevelOneMax(selectWages?.Wages.data.stars[0].max);
      setUserLevelOneMin(selectWages?.Wages.data.stars[0].min);
      setUserLevelOneOff(selectWages?.Wages.data.stars[0].off);
      setUserLevelTowOneMax(selectWages?.Wages.data.stars[1].max);
      setUserLevelTowOneMin(selectWages?.Wages.data.stars[1].min);
      setUserLevelTowOneOff(selectWages?.Wages.data.stars[1].off);
      setUserLevelTreeMax(selectWages?.Wages.data.stars[2].max);
      setUserLevelTreeMin(selectWages?.Wages.data.stars[2].min);
      setUserLevelTreeOff(selectWages?.Wages.data.stars[2].off);
    }
  }, [dispatch, selectWages]);

  async function editWages() {
    const data = {
      data: {
        affiliate: {
          p2pPercent:
            typeof affiliatep2p === "string"
              ? parseInt(affiliatep2p?.replaceAll(",", ""))
              : affiliatep2p,
          otcPercent:
            typeof affiliateOtc === "string"
              ? parseInt(affiliateOtc?.replaceAll(",", ""))
              : affiliateOtc,
        },
        dollar: {
          sellFactor:
            typeof sellfact === "string"
              ? parseFloat(sellfact?.replaceAll(",", ""))
              : sellfact,
          buyFactor:
            typeof Buyfact === "string"
              ? parseFloat(Buyfact?.replaceAll(",", ""))
              : Buyfact,
        },
        stars: [
          {
            star: 1,
            min:
              typeof userLevelOneMin === "string"
                ? parseInt(userLevelOneMin?.replaceAll(",", ""))
                : userLevelOneMin,
            max:
              typeof userLevelOneMax === "string"
                ? parseInt(userLevelOneMax?.replaceAll(",", ""))
                : userLevelOneMax,
            off:
              typeof userLevelOneOff === "string"
                ? parseInt(userLevelOneOff?.replaceAll(",", ""))
                : userLevelOneOff,
          },
          {
            star: 2,

            min:
              typeof userLevelTowOneMin === "string"
                ? parseInt(userLevelTowOneMin?.replaceAll(",", ""))
                : userLevelTowOneMin,
            max:
              typeof userLevelTowOneMax === "string"
                ? parseInt(userLevelTowOneMax?.replaceAll(",", ""))
                : userLevelTowOneMax,
            off:
              typeof userLevelTowOneOff === "string"
                ? parseInt(userLevelTowOneOff?.replaceAll(",", ""))
                : userLevelTowOneOff,
          },
          {
            star: 3,
            min:
              typeof userLevelTreeMin === "string"
                ? parseInt(userLevelTreeMin?.replaceAll(",", ""))
                : userLevelTreeMin,
            max:
              typeof userLevelTreeMax === "string"
                ? parseInt(userLevelTreeMax?.replaceAll(",", ""))
                : userLevelTreeMax,
            off:
              typeof userLevelTreeOff === "string"
                ? parseInt(userLevelTreeOff?.replaceAll(",", ""))
                : userLevelTreeOff,
          },
        ],
      },
    };
    setLoader(true);
    await httpRequest("put", "settings/wages", { data })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r.data.message);
          setModalCentered(false);
          dispatch(getAvalibleWages());
          setLoader(false);
        }
      })
      .catch((err) => {
        ERROR(err.response?.data?.message || err.message, {
          error: err,
        });
        setLoader(false);
      });
  }
  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <Button
        variant="primary"
        type="button"
        className="d-flex m-auto"
        onClick={() => setModalCentered(true)}
      >
        {children}
      </Button>
      {/* <!-- Modal --> */}
      <Modal
        className="fade customeModal"
        show={modalCentered}
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="text-black"> تنظیمات سطوح کاربری </h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body>
            {/*Factor  ********* */}
            <h5 className="mb-3">فاکتور </h5>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  id="Buyfact"
                  label={"ضریب احتساب خرید"}
                  name="Buyfact"
                  className="form-control"
                  onChange={(e) => {
                    setBuyfact(e.target.value);
                  }}
                  value={Buyfact}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  id="sellfact"
                  label={"ضریب احتساب فروش"}
                  name="sellfact"
                  className="form-control"
                  onChange={(e) => {
                    setSellfact(e.target.value);
                  }}
                  value={sellfact}
                />
              </div>
            </div>{" "}
            {/*Factor  ********* */}
            <h5 className="mb-3">درصد معرفی</h5>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  id="affiliatep2p"
                  label={"درصد معرفی p2p"}
                  name="affiliatep2p"
                  className="form-control"
                  onChange={(e) => {
                    setAffiliatep2p(e.target.value);
                  }}
                  value={affiliatep2p}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  id="affiliateOtc"
                  label={"درصد معرفی OTC"}
                  name="affiliateOtc"
                  className="form-control"
                  onChange={(e) => {
                    setAffiliateOtc(e.target.value);
                  }}
                  value={affiliateOtc}
                />
              </div>
            </div>
            {/* user level 1 ********* */}
            <h5 className="mb-3 fs-13">کاربر سطح 1 </h5>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="userLevelOneMin"
                  label={"کمترین مقدار تراکنش "}
                  name="userLevelOneMin"
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelOneMin(e.target.value);
                  }}
                  value={userLevelOneMin}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"بیشترین مقدار تراکنش  "}
                  id="userLevelOneMax"
                  placeholder="قیمت را به تومان وارد کنید..."
                  name="userLevelOneMax"
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelOneMax(e.target.value);
                  }}
                  value={userLevelOneMax}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"کارمزد (%)  "}
                  id="userLevelOneOff"
                  name="userLevelOneOff"
                  placeholder="به درصد "
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelOneOff(e.target.value);
                  }}
                  value={userLevelOneOff}
                />
              </div>
            </div>
            {/* user level 2 ********* */}
            <h5 className="mb-3 mt-3 fs-13">کاربر سطح 2 </h5>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"کمترین مقدار تراکنش "}
                  id="userLevelTowOneMin"
                  name="userLevelTowOneMin"
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelTowOneMin(e.target.value);
                  }}
                  value={userLevelTowOneMin}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"بیشترین مقدار تراکنش  "}
                  id="userLevelTowOneMax"
                  name="userLevelTowOneMax"
                  className="form-control"
                  placeholder="قیمت را به تومان وارد کنید..."
                  onChange={(e) => {
                    setUserLevelTowOneMax(e.target.value);
                  }}
                  value={userLevelTowOneMax}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"تخفیف بر کارمزد (%)  "}
                  id="userLevelTowOneOff"
                  name="userLevelTowOneOff"
                  placeholder="به درصد"
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelTowOneOff(e.target.value);
                  }}
                  value={userLevelTowOneOff}
                />
              </div>
            </div>
            {/* user level 1 ********* */}
            <h5 className="mb-3 mt-3 fs-13">کاربر سطح 3 </h5>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"کمترین مقدار تراکنش  "}
                  id="userLevelTreeMin"
                  placeholder="قیمت را به تومان وارد کنید..."
                  name="userLevelTreeMin"
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelTreeMin(e.target.value);
                  }}
                  value={userLevelTreeMin}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"بیشترین مقدار تراکنش   "}
                  id="userLevelTreeMax"
                  name="userLevelTreeMax"
                  className="form-control"
                  placeholder="قیمت را به تومان وارد کنید..."
                  onChange={(e) => {
                    setUserLevelTreeMax(e.target.value);
                  }}
                  value={userLevelTreeMax}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  label={"تخفیف بر کارمزد (%)   "}
                  id="userLevelTreeOff"
                  placeholder="به درصد"
                  name="userLevelTreeOff"
                  className="form-control"
                  onChange={(e) => {
                    setUserLevelTreeOff(e.target.value);
                  }}
                  value={userLevelTreeOff}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setModalCentered(false)}
              variant="danger light"
            >
              خروج
            </Button>
            <DefaultButton
              onClick={() => editWages()}
              type="submit"
              loading={loader}
              variant="primary"
            >
              ذخیره تغییرات
            </DefaultButton>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ModalWages;
