import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import IndexTab from "../zenix/Home/IndexTab";
import { panelActions, rolesAction } from "./../../../_actions";
import { connect } from "react-redux";
import { CONFIRM, ERROR, LOADING, SUCCESS } from "./../../../_helper/alerts";

//Images
import TradeDetailCard from "./TradeDetailCard";
import OrderDetailCard from "./OrderDetailCard";
import { getCoinDetail, getOrderDetail } from "./helper";

const OverviewUserChart = loadable(() =>
  pMinDelay(import("../zenix/Home/OverviewUserChart"), 1000)
);
const CurrentChart = loadable(() =>
  pMinDelay(import("../zenix/Home/CurrentChart"), 1000)
);

const orderdataBlog = [
  { price: "82.1", amount: "58.9", total: "134.10" },
  { price: "85.2", amount: "55.8", total: "136,12" },
  { price: "87.3", amount: "53.7", total: "138,12" },
  { price: "89.4", amount: "51.6", total: "139,12" },
  { price: "91.9", amount: "47.1", total: "140,12" },
  { price: "93.8", amount: "46.2", total: "142,12" },
  { price: "94.7", amount: "45.3", total: "145,12" },
  { price: "97.6", amount: "44.4", total: "147,12" },
];

const Home = ({ dispatch }) => {
  const [userPanelData, setUserPanelData] = useState({});

  const [userTradesData, setUserTradesData] = useState(null);
  const [tradeDetailSample, setTradeDetailSample] = useState([])

  const [userOrdersData, setUserOrdersData] = useState(null);
  const [orderDetailSample, setOrderDetailSample] = useState([])

  const [hasPanel, setHasPanel] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "panel") setHasPanel(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const getUserData = () => {
    if (hasPanel || localStorage.getItem("role") === "admin") {
      dispatch(panelActions.userPanel())
        .then((r) => {
          setUserPanelData(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  useEffect(() => {
    if (userTradesData) {
      const coins = Object.keys(userTradesData.tradeOfDay)
      let temp = []
      coins.map(item => {
        const details = getCoinDetail(userTradesData, item)
        temp.push({ name: item, detail: details })
      })

      setTradeDetailSample(temp);
    }
  }, [userTradesData])

  useEffect(() => {
    if (userOrdersData) {
      const coins = Object.keys(userOrdersData.orderOfDay)
      let temp = []
      coins.map(item => {
        const details = getOrderDetail(userOrdersData, item)
        temp.push({ name: item, detail: details })
      })

      setOrderDetailSample(temp);
    }
  }, [userOrdersData])

  const getTradesData = () => {
    if (hasPanel || localStorage.getItem("role") === "admin") {
      dispatch(panelActions.userTrades())
        .then((r) => {
          setUserTradesData(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  const getOrdersData = () => {
    if (hasPanel || localStorage.getItem("role") === "admin") {
      dispatch(panelActions.userOrders())
        .then((r) => {
          setUserOrdersData(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  console.log(orderDetailSample)

  useEffect(() => {
    getUserData();
    getTradesData();
    getOrdersData();
  }, [hasPanel]);

  return (
    <Fragment>
      <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
        <h2 className="font-w600 mb-2 ml-auto ">داشبورد</h2>
      </div>
      {hasPanel || localStorage.getItem("role") === "admin" ? (
        <>
          {" "}
          <h4>حجم کل معاملات</h4>
          <div className="row">
            {/* {userTradeCount?.map((item) => {
              return (
                <div className="col-xl-3 col-sm-6 m-t35">
                  <div className="card card-coin">
                    <div className="card-body text-center">
                      <svg
                        className="mb-3 currency-icon"
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="40" cy="40" r="40" fill="white" />
                        <path
                          d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                          fill="#00ADA3"
                        />
                        <path
                          d="M40.5283 10.8305C24.4443 10.5471 11.1271 23.3976 10.8438 39.4816C10.5438 55.549 23.3943 68.8662 39.4783 69.1662C55.5623 69.4495 68.8795 56.599 69.1628 40.5317C69.4462 24.4477 56.6123 11.1305 40.5283 10.8305ZM40.0033 19.1441L49.272 35.6798L40.8133 30.973C40.3083 30.693 39.6966 30.693 39.1916 30.973L30.7329 35.6798L40.0033 19.1441ZM40.0033 60.8509L30.7329 44.3152L39.1916 49.022C39.4433 49.162 39.7233 49.232 40.0016 49.232C40.28 49.232 40.56 49.162 40.8117 49.022L49.2703 44.3152L40.0033 60.8509ZM40.0033 45.6569L29.8296 39.9967L40.0033 34.3364L50.1754 39.9967L40.0033 45.6569Z"
                          fill="#00ADA3"
                        />
                      </svg>
                      <h2 className="text-black font-w600">
                        {userTradesData?.tradeOfTotal?.eth}
                      </h2>
                      <p className="text-right mb-0 mb-2 fs-14">
                        <span
                          className="ml-1 number_trades"
                          style={{ background: "#95dad6" }}
                        >
                          {userTradesData?.tradeOfMonth?.eth}
                        </span>
                        معامله در ماه
                      </p>
                      <p className="text-right mb-0 mb-2 fs-14">
                        <span
                          className="ml-1 number_trades"
                          style={{ background: "#95dad6" }}
                        >
                          {userTradesData?.tradeOfWeek?.eth}
                        </span>
                        معامله در هفته
                      </p>
                      <p className="text-right mb-0 fs-14">
                        <span
                          className="ml-1 number_trades"
                          style={{ background: "#95dad6" }}
                        >
                          {item[0]}
                        </span>
                        معامله در روز
                      </p>
                    </div>
                  </div>
                </div>
              );
            })} */}
          </div>

          {/* detail cards */}
          <div className="trade-detail-container">
            {tradeDetailSample && tradeDetailSample.map(detail => {
              return (
                <TradeDetailCard coin={detail} />
              )
            })}
          </div>

          <div className="row">
            <div className="col-xl-8 col-xxl-7">
              <div className="card">
                <div className="card-header border-0 flex-wrap pb-0 select-btn">
                  <div className="mb-3">
                    <h4 className="fs-20 text-black">بررسی کاربران</h4>
                  </div>
                </div>
                <div className="card-body pb-0">
                  <OverviewUserChart userPanelData={userPanelData} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-xxl-5">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="fs-20 text-black">کاربران</h4>
                </div>
                <div className="card-body pb-0">
                  <div id="currentChart" className="current-chart">
                    <CurrentChart userPanelData={userPanelData} />
                  </div>
                  <div className="chart-content">
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="ml-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#EB8153"
                          />
                        </svg>
                        <span className="fs-14">تعداد کل کاربران</span>
                      </div>
                      <div>
                        <h5 className="mb-0">{userPanelData?.userTotal}</h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="ml-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#71B945"
                          />
                        </svg>

                        <span className="fs-14">
                          کاربران فعال (
                          {Math.round(
                            (userPanelData?.userActive /
                              userPanelData?.userTotal) *
                            100
                          )}
                          %)
                        </span>
                      </div>
                      <div>
                        <h5 className="mb-0">{userPanelData?.userActive}</h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="ml-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#4A8CDA"
                          />
                        </svg>
                        <span className="fs-14">
                          کاربران تایید شده (
                          {Math.round(
                            (userPanelData?.userVerified /
                              userPanelData?.userTotal) *
                            100
                          )}
                          %)
                        </span>
                      </div>
                      <div>
                        <h5 className="mb-0">{userPanelData?.userVerified}</h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="ml-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#6647BF"
                          />
                        </svg>
                        <span className="fs-14">
                          کاربران ادمین (
                          {Math.round(
                            (userPanelData?.userAdmin /
                              userPanelData?.userTotal) *
                            100
                          )}
                          %)
                        </span>
                      </div>
                      <div>
                        <h5 className="mb-0">{userPanelData?.userAdmin}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* user orders */}
          <div className="row">
            <div className="col-xl-6 col-xxl-12">
              <div className="row">
                {orderDetailSample.map((order, index) => (
                  <OrderDetailCard order={order} index={index} />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Fragment>
  );
};
export { orderdataBlog };

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(Home);
