import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import UsersTicketsTable from "./UsersTicketsTable";

const TicketsList = () => {
  return (
    <Fragment>
      <PageTitle
        activeMenu="چت ها"
        motherMenu="داشبورد"
        pageContent="لیست چت های کاربران"
      />
      <div className="row">
        <UsersTicketsTable />
      </div>
    </Fragment>
  );
};

export default TicketsList;
