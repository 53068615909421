import { getCardToShow } from "./helper";


const OrderDetailCard = ({ order, index }) => {

    const cardToShow = getCardToShow(index + 1)

    return (
        <div className="col-sm-6">
            <div className="card-bx stacked card">
                <img src={cardToShow} alt="" />
                <div className="card-info">

                    <p className="mb-1 text-white fs-14">
                        سفارشات {order.name.toUpperCase()}
                    </p>

                    <div className="d-flex justify-content-between">
                        <h2 className="num-text text-white mb-5 font-w600">
                            {order.detail.orderOfTotal}
                        </h2>

                        <div style={{ width: "32px", height: "32px" }}>
                            <img style={{ width: "32px", height: "32px" }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "./images/coins/noImg.png";
                                }}
                                src={
                                    process.env.REACT_APP_SOCKET_URL +
                                    `assets/icon/${order.name.toLowerCase()}.png`
                                } alt=" " />
                        </div>

                    </div>
                    <div className="d-flex">
                        <div className="ml-4 text-white">
                            <p className="fs-12 mb-1 op6">سفارشات ماهانه</p>
                            <span>{order.detail.orderOfMonth}</span>
                        </div>
                        <div className="text-white ml-4">
                            <p className="fs-12 mb-1 op6">سفارشات هفتگی</p>
                            <span>{order.detail.orderOfWeek}</span>
                        </div>
                        <div className="text-white">
                            <p className="fs-12 mb-1 op6">سفارشات روزانه</p>
                            <span>{order.detail.orderOfDay}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OrderDetailCard;