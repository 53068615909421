import { httpRequest } from "./../_helper";

export const rolesAction = {
  getAllRoles,
  createRole,
  getRolesConfig,
  getRole,
};

function getAllRoles({ page, limit }) {
  return () => {
    return httpRequest("get", `roles?page=${page}&limit=${limit}`);
  };
}

function createRole(data) {
  return () => {
    return httpRequest("post", "roles", { data });
  };
}

function getRolesConfig() {
  return () => {
    return httpRequest("get", "configs/role");
  };
}

function getRole(id) {
  return () => {
    return httpRequest("get", `roles/${id}`);
  };
}
