import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvalibleWages } from "../../../_actions/CoiningActions";
import { Row, Col, Card, Badge } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "react-bootstrap/Alert";
import ModalWages from "./ModalWage";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
const Wages = () => {
  const selectWages = useSelector((state) => state.selectWages);
  const { Wages, loading, error } = selectWages;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAvalibleWages());
  }, [dispatch]);
  return (
    <div className="">
      <div className="card">
        <div className="card-header d-sm-flex d-block pb-0 border-0">
          <div>
            <h4 className="fs-20 text-black"> سطوح کاربری</h4>
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <CircularProgress />
          ) : error ? (
            ERROR(error)
          ) : (
            <>
              <div className="d-flex">
                <div className="">
                  ضریب احتساب قیمت به دلار خرید(
                  <Badge variant="primary badge-xs light">
                    {Wages.data.dollar.sellFactor}
                  </Badge>
                  ) فروش (
                  <Badge variant="primary badge-xs light">
                    {Wages.data.dollar.buyFactor}
                  </Badge>
                  )
                </div>
              </div>
              <br />
              <Row>
                {Wages.data.stars.map((item, index) => (
                  <Col key={index} xl="4" sm="6">
                    <Card
                      className={
                        item.star === 1
                          ? ` text-white bg-info`
                          : item.star === 2
                          ? ` text-white bg-primary`
                          : item.star === 3
                          ? ` text-white bg-success`
                          : "text-white bg-danger"
                      }
                    >
                      <Card.Header>
                        <Card.Title className="text-white">
                          <h3 className="card__title ">
                            سطح کاربری {item.star}
                          </h3>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className=" mb-0">
                        <div className="d-flex justify-content-between m-2">
                          <span>کمترین مقدار تراکنش</span>
                          <span>{item.min}</span>
                        </div>
                        <div className="d-flex justify-content-between m-2">
                          <span>بیشترین مقدار تراکنش</span>
                          <span>{item.max}</span>
                        </div>
                        <div className="d-flex justify-content-between m-2">
                          <span>تخفیف بر کارمزد به در صد</span>
                          <span>{item.off}</span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
          <ModalWages>
            <span className="d-flex m-auto">ویرایش سطوح کاربری </span>
          </ModalWages>
        </div>
      </div>
    </div>
  );
};

export default Wages;
