import React, { Fragment, useState, useEffect } from "react";
import { Button, Dropdown, Modal, Spinner, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BaseInput } from "../../Defaults/BaseInput";
import { NumberInput } from "../../Defaults/NumberInput";
import Switch from "react-switch";

//** Import Image */
import Tron2 from "../../../../images/svg/tron2.svg";
import Ethereum from "../../../../images/svg/etherium.svg";
import Tether2 from "../../../../images/svg/tether2.svg";

import PageTitle from "../../../layouts/PageTitle";
import { profileAction } from "./../../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "./../../../../_helper/alerts";
import { isValidEmail } from "../../Validation/checkEmail";
import { isValidNationalCode } from "../../Validation//checkNationalCode";
import { DefaultButton } from "../../Defaults/DefaultButton";
import loadable from "@loadable/component";
import { FormGroup, Label } from "reactstrap";

const AppProfile = ({ dispatch }) => {
  const [activeToggle, setActiveToggle] = useState("setting");
  const [userProfileData, setUserProfileData] = useState([]);
  const [userWalletData, setUserWalletData] = useState([]);
  const [userBanksData, setUserBanksData] = useState([]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tel, setTel] = useState("");
  const [mobile, setMobile] = useState("");
  const [nationalCode, setNationalCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nationalCodeError, setNationalCodeError] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [countyErr, setCountyErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [filled, setFilled] = useState(false);
  const [replayModal, setReplayModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [isRemoved, setIsRemoved] = useState(false);
  const [avatarImg, setAvatarImg] = useState("");
  const [checkedUser, setCheckedUser] = useState(false);
  const [checkedAdmin, setCheckedAdmin] = useState(false);
  const [isAvatarChange, setIsAvatarChange] = useState(false);
  const avatarRef = React.createRef();

  const CompleteLink = () => {
    return (
      <Link to={"#"} className="btn-link text-success float-right">
        Completed
      </Link>
    );
  };
  const PendingLink = () => {
    return (
      <Link to={"#"} className="btn-link text-warning float-right">
        Pending
      </Link>
    );
  };

  const getProfile = () => {
    dispatch(profileAction.getProfile())
      .then((r) => {
        if (r.status === 200) {
          setUserProfileData(r.data.data);
          setUserWalletData(r.data.data.wallets);
          setUserBanksData(r.data.data.banks);
        }
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  };

  useEffect(() => {
    getProfile();
  }, []);

  const userHandleChange = () => {
    setCheckedUser(!checkedUser);
    if (checkedUser === false) {
      setCheckedAdmin(false);
    }
  };
  const adminHandleChange = () => {
    setCheckedAdmin(!checkedAdmin);
    if (checkedAdmin === false) {
      setCheckedUser(false);
    }
  };

  useEffect(() => {
    if (isValidEmail(email) || email === "" || email === null) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (
      isValidNationalCode(nationalCode) === false &&
      nationalCode !== "" &&
      nationalCode !== null
    ) {
      setNationalCodeError(true);
    } else {
      setNationalCodeError(false);
    }
  }, [email, nationalCode]);

  useEffect(() => {
    setFirstName(userProfileData?.firstName);
    setLastName(userProfileData?.lastName);
    setAvatar(userProfileData?.avatar);
    setEmail(userProfileData?.email);
    setMobile(userProfileData?.mobile);
    setTel(userProfileData?.phone);
    setNationalCode(userProfileData?.meliCode);
    setAddress(userProfileData?.address?.line);
    setCounty(userProfileData?.address?.county);
    setCity(userProfileData?.address?.city);
    setZip(userProfileData?.address?.zip);
    if (userProfileData?.role === "admin") {
      setCheckedAdmin(true);
      setCheckedUser(false);
    } else if (userProfileData?.role === "user") {
      setCheckedUser(true);
      setCheckedAdmin(false);
    }
  }, [userProfileData]);

  useEffect(() => {
    if (
      firstName === "" ||
      firstName === null ||
      lastName === "" ||
      lastName === null ||
      nationalCode === null ||
      email === null ||
      tel === null ||
      county === null ||
      city === null ||
      zip === null ||
      address === null ||
      mobile === null ||
      nationalCode === "" ||
      email === "" ||
      tel === "" ||
      county === "" ||
      city === "" ||
      zip === "" ||
      address === "" ||
      mobile === ""
    ) {
      setFilled(false);
    } else if (
      firstName !== "" ||
      firstName !== null ||
      lastName !== "" ||
      lastName !== null ||
      nationalCode !== null ||
      email !== null ||
      tel !== null ||
      county !== null ||
      city !== null ||
      zip !== null ||
      address !== null ||
      mobile !== null ||
      nationalCode !== "" ||
      email !== "" ||
      tel !== "" ||
      county !== "" ||
      city !== "" ||
      zip !== "" ||
      address !== "" ||
      mobile !== ""
    ) {
      setFilled(true);
    }
  }, [
    firstName,
    lastName,
    email,
    nationalCode,
    email,
    tel,
    county,
    city,
    zip,
    address,
    mobile,
  ]);

  useEffect(() => {
    if (firstName?.length < 30 && firstName !== "" && firstName !== null) {
      setFirstNameErr(false);
    }
    if (lastName?.length <= 30 && lastName !== "" && lastName !== null) {
      setLastNameErr(false);
    }
    if (
      tel?.replace("-", "").replace(" ", "")?.length === 11 ||
      tel !== "" ||
      tel !== null
    ) {
      setPhoneErr(false);
    }
    if (city?.length <= 30 && city !== "" && city !== null) {
      setCityErr(false);
    }
    if (county?.length <= 30 && county !== "" && county !== null) {
      setCountyErr(false);
    }
    if (address?.length <= 255 && address !== "" && address !== null) {
      setAddressErr(false);
    }
  }, [
    firstName,
    lastName,
    email,
    nationalCode,
    email,
    tel,
    county,
    city,
    zip,
    address,
    mobile,
  ]);

  async function editProfileUser() {
    let userRole;
    if (checkedUser === true) {
      userRole = "user";
    } else if (checkedAdmin === true) {
      userRole = "admin";
    }
    if (firstName?.length > 30 || firstName === "" || firstName === null) {
      setFirstNameErr(true);
    } else if (
      firstName?.length < 30 &&
      firstName !== "" &&
      firstName !== null
    ) {
      setFirstNameErr(false);
    }
    if (lastName?.length > 30 || lastName === "" || lastName === null) {
      setLastNameErr(true);
    } else if (lastName?.length <= 30 && lastName !== "" && lastName !== null) {
      setLastNameErr(false);
    }
    if (
      tel?.replace("-", "").replace(" ", "")?.length < 11 ||
      tel === "" ||
      tel === null
    ) {
      setPhoneErr(true);
    } else if (
      tel?.replace("-", "").replace(" ", "")?.length === 11 ||
      tel !== "" ||
      tel !== null
    ) {
      setPhoneErr(false);
    }
    if (city?.length > 30 || city === "" || city === null) {
      setCityErr(true);
    } else if (city?.length <= 30 && city !== "" && city !== null) {
      setCityErr(false);
    }
    if (county?.length > 30 || county === "" || county === null) {
      setCountyErr(true);
    } else if (county?.length <= 30 && county !== "" && county !== null) {
      setCountyErr(false);
    }
    if (address?.length > 255 || address === "" || address === null) {
      setAddressErr(true);
    } else if (address?.length <= 255 && address !== "" && address !== null) {
      setAddressErr(false);
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      meliCode: nationalCode,
      mobile: "09" + mobile,
      phone: tel?.replace("-", ""),
      address: {
        county: county,
        city: city,
        line: address,
        zip: zip,
      },
      email: email,
      role: userRole,
    };
    if (
      firstName?.length <= 30 &&
      lastName?.length <= 30 &&
      city?.length <= 30 &&
      county?.length <= 30 &&
      address?.length <= 255 &&
      tel?.replace("-", "").replace(" ", "")?.length === 11
    ) {
      setLoader(true);
      dispatch(profileAction.updateProfile(data))
        .then((r) => {
          SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
          setLoader(false);
          // dispatch(setUser(r.data));
        })
        .catch((ex) => {
          setLoader(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        });
    }
  }

  async function removeAvatar() {
    dispatch(profileAction.deleteAvatar(avatar))
      .then((r) => {
        setIsRemoved(true);
        getProfile();
      })
      .catch((ex) => {
        setIsRemoved(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  const updateAvatar = () => {
    const files = new FormData();
    if (avatarRef.current.files[0].size > 3500000) {
      ERROR("حجم تصویر ارسالی نباید بیشتر از 3.5 مگابایت باشد");
    } else {
      files.append("avatar", avatarRef.current.files[0]);
      setAvatarImg(URL.createObjectURL(avatarRef.current.files[0]));

      dispatch(profileAction.updateAvatar(files))
        .then((r) => {
          setIsAvatarChange(true);
          setIsRemoved(false);
          getProfile();
        })
        .catch((ex) => {
          setIsAvatarChange(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        });
    }
  };

  const onChangeEmail = (evt) => {
    setEmail(evt.target.value);
  };

  const onChangeFirstName = (evt) => {
    setFirstName(evt.target.value);
  };
  const onChangeLastName = (evt) => {
    setLastName(evt.target.value);
  };
  const onChangeAddress = (evt) => {
    setAddress(evt.target.value);
  };
  const onChangeCounty = (evt) => {
    setCounty(evt.target.value);
  };
  const onChangeCity = (evt) => {
    setCity(evt.target.value);
  };
  const onChangeNationalCode = (evt) => {
    if (evt.value) {
      setNationalCode(evt.value);
    }
  };
  const onChangeTel = (evt) => {
    if (evt.value) {
      setTel(evt.value);
    }
  };
  const onChangeZip = (evt) => {
    if (evt.value) {
      setZip(evt.value);
    }
  };
  const onChangeMobile = (evt) => {
    if (evt.value) {
      setMobile(evt.value);
    }
  };
  const FormatTime = (time) => {
    let date = new Date(time).toLocaleString("fa-IR");
    return date;
  };
  return (
    <Fragment>
      <PageTitle activeMenu="پروفایل" motherMenu="داشبورد" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                <div className="cover-photo" />
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  {avatar && !isRemoved ? (
                    isAvatarChange ? (
                      <img
                        src={avatarImg}
                        className="img-fluid rounded-circle"
                        alt="profile"
                      />
                    ) : (
                      <img
                        src={avatar}
                        className="img-fluid rounded-circle"
                        alt="profile"
                      />
                    )
                  ) : isAvatarChange && !isRemoved ? (
                    <img
                      src={avatarImg}
                      className="img-fluid rounded-circle"
                      alt="profile"
                    />
                  ) : (
                    <div className="no_img text-center">No image</div>
                  )}

                  <label for="avatar" className="px-5 avatar_edit_title">
                    <i className="fa fa-camera fs-18" />
                    <input
                      accept="image/jpeg, image/jpg"
                      onChange={updateAvatar}
                      ref={avatarRef}
                      type="file"
                      id="avatar"
                      name="avatar"
                    />
                  </label>

                  {avatar ? (
                    <span className="avatar_remove_title d-flex">
                      <a
                        onClick={removeAvatar}
                        className="cursor-pointer fs-11 mt-2"
                      >
                        حذف تصویر
                      </a>
                    </span>
                  ) : null}
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {firstName} {lastName}
                    </h4>
                    <p>{localStorage.getItem("role")}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">
                      {localStorage.getItem("email")}
                    </h4>
                    <p>Email</p>
                  </div>
                  {/* <Dropdown className="dropdown ml-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item text-right">
                        <i className="fa fa-ban text-primary ml-2" />
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link
                        to="#profile-settings"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("setting")}
                        className={`nav-link ${
                          activeToggle === "setting" ? "active show" : ""
                        }`}
                      >
                        اطلاعات پروفایل
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("banks")}
                    >
                      <Link
                        to="#my-banks"
                        data-toggle="tab"
                        className={`nav-link ${
                          activeToggle === "banks" ? "active show" : ""
                        }`}
                      >
                        حساب بانکی
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("wallets")}
                    >
                      <Link
                        to="#wallet"
                        data-toggle="tab"
                        className={`nav-link ${
                          activeToggle === "wallets" ? "active show" : ""
                        }`}
                      >
                        کیف پول
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade ${
                        activeToggle === "setting" ? "active show" : ""
                      }`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          {userProfileData ? (
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-row">
                                <div className="form-group col-md-4">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="firstName"
                                    value={firstName}
                                    onChange={onChangeFirstName}
                                    label={"نام"}
                                    error={firstNameErr}
                                    errorText="طول پارامتر نام نمی تواند بیشتر از 30 کاراکتر باشد"
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="lastName"
                                    value={lastName}
                                    onChange={onChangeLastName}
                                    label={"نام خانوادگی"}
                                    error={lastNameErr}
                                    errorText="طول پارامتر نام خانوادگی نمی تواند بیشتر از 50 کاراکتر باشد"
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <BaseInput
                                    className="form-control text-left ltr_direction"
                                    id="email"
                                    value={email}
                                    onChange={onChangeEmail}
                                    label={"ایمیل"}
                                    error={emailError}
                                    errorText="فرمت ایمیل صحیح نیست"
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-4">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="nationalCode"
                                    value={nationalCode}
                                    allowLeadingZeros={true}
                                    format="##########"
                                    onValueChange={onChangeNationalCode}
                                    label={"کد ملی"}
                                    error={nationalCodeError}
                                    errorText="فرمت کد ملی صحیح نیست"
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="tel"
                                    value={tel}
                                    allowLeadingZeros={true}
                                    format="###-########"
                                    onValueChange={onChangeTel}
                                    label={"تلفن"}
                                    error={phoneErr}
                                    errorText="طول شماره تلفن نباید کمتر از 11 رقم باشد.(همراه کد شهر)"
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="mobile"
                                    value={mobile}
                                    allowLeadingZeros={true}
                                    onValueChange={onChangeMobile}
                                    label={"تلفن همراه"}
                                    format="09#########"
                                    placeholder="09---------"
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-4">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="zip"
                                    value={zip}
                                    allowLeadingZeros={true}
                                    format="##########"
                                    onValueChange={onChangeZip}
                                    label={"کد پستی"}
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="county"
                                    value={county}
                                    onChange={onChangeCounty}
                                    label={"استان"}
                                    error={countyErr}
                                    errorText="طول پارامتر استان نمی تواند بیشتر از 30 کاراکتر باشد"
                                  />
                                </div>
                                <div className="form-group col-md-4">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="city"
                                    value={city}
                                    onChange={onChangeCity}
                                    label={"شهر"}
                                    error={cityErr}
                                    errorText="طول پارامتر شهر نمی تواند بیشتر از 30 کاراکتر باشد"
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="address"
                                    value={address}
                                    onChange={onChangeAddress}
                                    label={"آدرس"}
                                    error={addressErr}
                                    errorText="طول پارامتر آدرس نمی تواند بیشتر از 255 کاراکتر باشد"
                                  />
                                </div>
                              </div>
                              {/* <div className="form-row">
                                <div className="form-group col-md-3">
                                  <Label className="ml-2" for="status">
                                    کاربر عادی
                                  </Label>
                                  <Switch
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={15}
                                    width={28}
                                    onColor="#1fa2ff"
                                    onChange={userHandleChange}
                                    checked={checkedUser}
                                  />
                                </div>
                                <div className="form-group col-md-3">
                                  <Label className="ml-2" for="status">
                                    ادمین
                                  </Label>
                                  <Switch
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={15}
                                    width={28}
                                    onColor="#1fa2ff"
                                    onChange={adminHandleChange}
                                    checked={checkedAdmin}
                                  />
                                </div>
                              </div> */}
                              <DefaultButton
                                disabled={!filled}
                                onClick={() => editProfileUser()}
                                loading={loader}
                                variant="primary"
                              >
                                ویرایش اطلاعات
                              </DefaultButton>
                            </form>
                          ) : (
                            <div className="text-center">
                              <Spinner
                                size="lg"
                                className="mr-2"
                                animation="border"
                                variant="light"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      id="my-banks"
                      className={`tab-pane fade ${
                        activeToggle === "banks" ? "active show" : ""
                      }`}
                    >
                      <div className="card-header border-0 pb-0">
                        <h4 className="mb-0 fs-14 text-black">
                          لیست حساب های بانکی
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table shadow-hover card-table border-no tbl-btn short-one">
                            <tbody>
                              <tr>
                                <td>
                                  {" "}
                                  <span className="text-black">شماره کارت</span>
                                </td>
                                <td>
                                  <span className="text-black">شماره شبا</span>
                                </td>
                                <td>
                                  <span className="text-black">برچسب</span>
                                </td>

                                <td>
                                  <span className="text-black">وضعیت</span>
                                </td>
                                <td>
                                  <span className="text-black">تایید شده</span>
                                </td>
                              </tr>

                              {userBanksData.map((data, index) => (
                                <tr key={index}>
                                  <td>{data.cardNo}</td>
                                  <td>
                                    <span className="font-w600 text-black">
                                      {data.shebaNo}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-black">
                                      {data.label}
                                    </span>
                                  </td>

                                  <td>
                                    {data.issuer ? (
                                      <CompleteLink />
                                    ) : (
                                      <PendingLink />
                                    )}
                                  </td>
                                  <td>{FormatTime(data.verifyAt)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      id="wallet"
                      className={`tab-pane fade ${
                        activeToggle === "wallets" ? "active show" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-header border-0 pb-0">
                              <h4 className="mb-0 fs-14 text-black">
                                لیست کیف پول ها
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                {userWalletData?.map((item) => {
                                  return (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-3">
                                      <div
                                        className={`${
                                          item.coin === "btc"
                                            ? "bg-warning"
                                            : item.coin === "eth"
                                            ? "bg-secondary"
                                            : item.coin === "trx"
                                            ? "bg-primary"
                                            : item.coin === "usdt"
                                            ? "bg-success"
                                            : ""
                                        }  coin-holding flex-wrap`}
                                      >
                                        <div className="mb-2 coin-bx">
                                          <div className="d-flex align-items-center">
                                            <div>
                                              {item.coin === "btc" ? (
                                                <svg
                                                  width="50"
                                                  height="50"
                                                  viewBox="0 0 38 35"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M19.2744 18.8013H16.0334V23.616H19.2744C19.9286 23.616 20.5354 23.3506 20.9613 22.9053C21.4066 22.4784 21.672 21.8726 21.672 21.1989C21.673 19.8813 20.592 18.8013 19.2744 18.8013Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M18 0C8.07429 0 0 8.07429 0 18C0 27.9257 8.07429 36 18 36C27.9257 36 36 27.9247 36 18C36 8.07531 27.9247 0 18 0ZM21.6627 26.3355H19.5398V29.6722H17.3129V26.3355H16.0899V29.6722H13.8528V26.3355H9.91954V24.2414H12.0898V11.6928H9.91954V9.59863H13.8528V6.3288H16.0899V9.59863H17.3129V6.3288H19.5398V9.59863H21.4735C22.5535 9.59863 23.5491 10.044 24.2599 10.7547C24.9706 11.4655 25.416 12.4611 25.416 13.5411C25.416 15.6549 23.7477 17.3798 21.6627 17.4744C24.1077 17.4744 26.0794 19.4647 26.0794 21.9096C26.0794 24.3453 24.1087 26.3355 21.6627 26.3355Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M20.7062 15.8441C21.095 15.4553 21.3316 14.9338 21.3316 14.3465C21.3316 13.1812 20.3842 12.2328 19.2178 12.2328H16.0334V16.4695H19.2178C19.7959 16.4695 20.3266 16.2226 20.7062 15.8441Z"
                                                    fill="white"
                                                  />
                                                </svg>
                                              ) : item.coin === "eth" ? (
                                                <img
                                                  width={50}
                                                  src={Ethereum}
                                                />
                                              ) : item.coin === "trx" ? (
                                                <img width={50} src={Tron2} />
                                              ) : item.coin === "usdt" ? (
                                                <img width={50} src={Tether2} />
                                              ) : null}
                                            </div>
                                            <div className="mr-3">
                                              <h4 className="coin-font font-w600 mb-0 text-white">
                                                {item.coin === "btc"
                                                  ? "بیت کوین"
                                                  : item.coin === "eth"
                                                  ? "اتریوم"
                                                  : item.coin === "trx"
                                                  ? "ترون"
                                                  : item.coin === "usdt"
                                                  ? "تتر"
                                                  : null}
                                              </h4>
                                              <p className="mb-0 text-white op-6">
                                                {item.coin === "btc"
                                                  ? "BTC"
                                                  : item.coin === "eth"
                                                  ? "ETH"
                                                  : item.coin === "trx"
                                                  ? "TRX"
                                                  : item.coin === "usdt"
                                                  ? "USDT"
                                                  : null}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-2 w-100">
                                          <div className="d-flex align-items-center mr-1 mt-3">
                                            <div className="coin-bx-one">
                                              آدرس کیف پول:{" "}
                                            </div>
                                            <div className="ml-3">
                                              <span className="mb-0 text-white mr-2 wrap_text">
                                                {item.address}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-2">
                                          <div className="d-flex align-items-center">
                                            <div className="coin-bx-one">
                                              برچسب:{" "}
                                            </div>
                                            <p className="mb-0 mr-2 font-w400 text-white wrap_anywhere">
                                              {item.label}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="mb-2">
                                          <div className="d-flex align-items-center">
                                            <div className="coin-bx-one">
                                              وضعیت:{" "}
                                            </div>
                                            <Badge
                                              as="a"
                                              href=""
                                              className="mr-2"
                                              variant={`${
                                                item.verifyAt
                                                  ? "outline-success"
                                                  : "outline-primary"
                                              }`}
                                            >
                                              {item.verifyAt
                                                ? "تایید شده"
                                                : "در حال بررسی"}
                                            </Badge>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AppProfile);
