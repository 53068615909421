import { httpRequest } from "./../_helper";

export const usersAction = {
  getUsersList,
  getKYCUsersList,
  getKYCBanksList,
  userInfo,
  userEditInfo,
  usersIdentityList,
  acceptIdentity,
  verifyAccount,
  getAllUserOrders,
  getAllOpenOrders,
  createUsers,
  getAllSessions,
  removeUser2FA,
  increaseUserBalance,
  decreaseUserBalance,
};

function getUsersList({
  page,
  limit,
  verifyAt,
  searchName,
  searchFamily,
  searchEmail,
  searchMobile,
}) {
  return () => {
    return httpRequest(
      "get",
      `users?page=${page}&limit=${limit}&sort=createdAt&order=-1&search=${
        searchName ? `firstName` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `lastName` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `email` : ""}${
        (searchFamily && searchMobile) ||
        (searchMobile && searchEmail) ||
        (searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `mobile` : ""}&query=${
        searchName ? `${searchName}` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `${searchFamily}` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `${searchEmail}` : ""}${
        (searchFamily && searchMobile) ||
        (searchName && searchMobile) ||
        (searchEmail && searchMobile) ||
        (searchEmail && searchName && searchMobile) ||
        (searchEmail && searchName && searchFamily) ||
        (searchFamily && searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `${searchMobile}` : ""}`
    );
  };
}

function getKYCUsersList({
  page,
  limit,
  searchName,
  searchFamily,
  searchEmail,
  searchMobile,
}) {
  return () => {
    return httpRequest(
      "get",
      `users/kyc/account?page=${page}&limit=${limit}&sort=createdAt&order=-1&search=${
        searchName ? `firstName` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `lastName` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `email` : ""}${
        (searchFamily && searchMobile) ||
        (searchMobile && searchEmail) ||
        (searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `mobile` : ""}&query=${
        searchName ? `${searchName}` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `${searchFamily}` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `${searchEmail}` : ""}${
        (searchFamily && searchMobile) ||
        (searchName && searchMobile) ||
        (searchEmail && searchMobile) ||
        (searchEmail && searchName && searchMobile) ||
        (searchEmail && searchName && searchFamily) ||
        (searchFamily && searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `${searchMobile}` : ""}`
    );
  };
}

function getKYCBanksList({
  page,
  limit,
  searchName,
  searchFamily,
  searchEmail,
  searchMobile,
}) {
  return () => {
    return httpRequest(
      "get",
      `users/kyc/bank?page=${page}&limit=${limit}&sort=createdAt&order=-1&search=${
        searchName ? `firstName` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `lastName` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `email` : ""}${
        (searchFamily && searchMobile) ||
        (searchMobile && searchEmail) ||
        (searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `mobile` : ""}&query=${
        searchName ? `${searchName}` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `${searchFamily}` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `${searchEmail}` : ""}${
        (searchFamily && searchMobile) ||
        (searchName && searchMobile) ||
        (searchEmail && searchMobile) ||
        (searchEmail && searchName && searchMobile) ||
        (searchEmail && searchName && searchFamily) ||
        (searchFamily && searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `${searchMobile}` : ""}`
    );
  };
}

function userInfo(id) {
  return () => {
    return httpRequest("get", `users/${id}`);
  };
}

function removeUser2FA(id) {
  return () => {
    return httpRequest("put", `users/${id}`);
  };
}
function increaseUserBalance(id, data) {
  return () => {
    return httpRequest("put", `users/${id}/balance`, { data });
  };
}
function decreaseUserBalance(id, data) {
  return () => {
    return httpRequest("put", `users/${id}/balance`, { data });
  };
}

function userEditInfo(id, data) {
  return () => {
    return httpRequest("put", `users/${id}`, { data });
  };
}

function createUsers(data) {
  return () => {
    return httpRequest("post", `users`, { data });
  };
}

function usersIdentityList({
  page,
  limit,
  verify,
  searchName,
  searchEmail,
  searchMobile,
}) {
  return () => {
    return httpRequest(
      "get",
      `users?page=${page}&limit=${limit}&sort=createdAt&order=-1&search=${
        searchName ? `firstName` : ""
      }${searchName && verify.toLocaleString() ? `,` : ""}${
        verify.toLocaleString() ? `verifyAt` : ""
      }${
        (verify.toLocaleString() && searchEmail) || (searchName && searchEmail)
          ? `,`
          : ""
      }${searchEmail ? `email` : ""}${
        (verify.toLocaleString() && searchMobile) ||
        (searchMobile && searchEmail) ||
        (searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `mobile` : ""}&query=${
        searchName ? `${searchName}` : ""
      }${searchName && verify.toLocaleString() ? `,` : ""}${
        verify.toLocaleString() ? `${verify.toLocaleString()}` : ""
      }${
        (verify.toLocaleString() && searchEmail) || (searchName && searchEmail)
          ? `,`
          : ""
      }${searchEmail ? `${searchEmail}` : ""}${
        (verify.toLocaleString() && searchMobile) ||
        (searchName && searchMobile) ||
        (searchEmail && searchMobile) ||
        (searchEmail && searchName && searchMobile) ||
        (searchEmail && searchName && verify.toLocaleString()) ||
        (verify.toLocaleString() && searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `${searchMobile}` : ""}`
        .replaceAll("false", false)
        .replaceAll("true", true)
        .replaceAll("false", false)
        .replaceAll("true", true)
        .replace(`,&query`, "&query")
        .replace(/,$/, "")
    );
  };
}

function acceptIdentity(id, firstName, lastName, verifyAt) {
  return () => {
    return httpRequest("put", `users/${id}`, {
      data: { firstName, lastName, verifyAt },
    });
  };
}

function verifyAccount(id, accountId, isVerify) {
  return () => {
    return httpRequest("put", `users/${id}/account/${accountId}`, {
      data: { isVerify },
    });
  };
}

function getAllUserOrders({ page, limit, userId }) {
  return () => {
    return httpRequest(
      "get",
      `trades/admin?page=${page}&limit=${limit}&search=${
        userId ? `order.userId` : ""
      }&query=${userId ? `${userId}` : ""}`
    );
  };
}

function getAllOpenOrders({ page, limit, userId, status }) {
  return () => {
    return httpRequest(
      "get",
      `orders/admin?page=${page}&limit=${limit}&search=${
        userId ? `userId` : ""
      }${userId && status ? "," : ""}${status ? `status` : ""}&query=${
        userId ? `${userId}` : ""
      }${userId && status ? "," : ""}${status ? `${status}` : ""}`
    );
  };
}

function getAllSessions({ page, limit, userId }) {
  return () => {
    return httpRequest(
      "get",
      `users/sessions/${userId}?page=${page}&limit=${limit}`
    );
  };
}
