import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { httpRequest } from "../../../_helper/httpServices";
import { NumberInput } from "../Defaults/NumberInput";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { useDispatch } from "react-redux";
import { getAvalibleBank } from "../../../_actions/CoiningActions";
import { DefaultButton } from "../Defaults/DefaultButton";
import ActiveGateway from "./ActiveGateway";
import Switch from "react-switch";

const ModalBanks = ({ children }) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getbanks, setGetBanks] = useState([]);
  const [active, setActive] = useState(true);
  const Toggle = () => setActive(!active);
  //state order
  const [orderMin, setOrderMin] = useState(0);
  const [orderMax, setOrderMax] = useState(0);
  const [takerOrderFeeFactor, setTakerOrderFeeFactor] = useState(0);
  const [makerOrderFeeFactor, setMakerOrderFeeFactor] = useState(0);
  const [takerOrderFeeMax, setTakerOrderFeeMax] = useState(0);
  const [makerOrderFeeMax, setMakerOrderFeeMax] = useState(0);
  //state otc
  const [otcMin, setOtcMin] = useState(0);
  const [otcMax, setOtcMax] = useState(0);
  const [takerOtcFeeFactor, setTakerOtcFeeFactor] = useState(0);
  const [makerOtcFeeFactor, setMakerOtcFeeFactor] = useState(0);
  const [takerOtcFeeMax, setTakerOtcFeeMax] = useState(0);
  const [makerOtcFeeMax, setMakerOtcFeeMax] = useState(0);

  //state diposit
  const [depositMin, setDepositMin] = useState(0);
  const [depositMax, setDepositMax] = useState(0);
  const [depositFeeFactor, setDepositFeeFactor] = useState(0);
  const [depositFeeMax, setDepositFeeMax] = useState(0);
  //state withdraw
  const [withdrawMin, setWithdrawMin] = useState(0);
  const [withdrawMax, setWithdrawMax] = useState(0);
  const [withdrawFeeFactor, setWithdrawFeeFactor] = useState(0);
  const [withdrawFeeMax, setWithdrawFeeMax] = useState(0);
  const [activeGateway, setActiveGateway] = useState(false);
  const [gateWayBanks, setGateWayBanks] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    const getBank = () => {
      httpRequest("get", "settings/banks")
        .then((res) => setGetBanks(res.data.data))
        .catch((err) => ERROR(err));
    };
    getBank();
  }, []);

  useEffect(() => {
    if (getbanks?.order) {
      setOtcMin(getbanks?.otc?.min);
      setOtcMax(getbanks?.otc?.max);
      setTakerOtcFeeFactor(getbanks?.otc?.takerFeeFactor);
      setMakerOtcFeeFactor(getbanks?.otc?.makerFeeFactor);
      setTakerOtcFeeMax(getbanks?.otc?.takerFeeMax);
      setMakerOtcFeeMax(getbanks?.otc?.makerFeeMax);
      setOrderMin(getbanks?.order?.min);
      setOrderMax(getbanks?.order?.max);
      setTakerOrderFeeFactor(getbanks?.order?.takerFeeFactor);
      setMakerOrderFeeFactor(getbanks?.order?.makerFeeFactor);
      setTakerOrderFeeMax(getbanks?.order?.takerFeeMax);
      setMakerOrderFeeMax(getbanks?.order?.makerFeeMax);
      setDepositMin(getbanks?.deposit?.min);
      setDepositMax(getbanks?.deposit?.max);
      setDepositFeeFactor(getbanks?.deposit?.feeFactor);
      setDepositFeeMax(getbanks?.deposit?.feeMax);
      setWithdrawMin(getbanks?.withdraw?.min);
      setWithdrawMax(getbanks?.withdraw?.max);
      setWithdrawFeeFactor(getbanks?.withdraw?.feeFactor);
      setWithdrawFeeMax(getbanks?.withdraw?.feeMax);
      setGateWayBanks(getbanks?.banks);
    }
  }, [getbanks]);

  const handleGateway = (checked, name) => {
    setActiveGateway(checked);
    for (var i in gateWayBanks) {
      if (gateWayBanks[i].fa == name) {
        gateWayBanks[i].isActive = checked;
        break;
      }
    }
  };

  async function editBanks() {
    const data = {
      data: {
        otc: {
          min:
            typeof otcMin === "string"
              ? parseFloat(otcMin?.replaceAll(",", ""))
              : otcMin,
          max:
            typeof otcMax === "string"
              ? parseFloat(otcMax?.replaceAll(",", ""))
              : otcMax,
          takerFeeFactor:
            typeof takerOtcFeeFactor === "string"
              ? parseFloat(takerOtcFeeFactor?.replaceAll(",", ""))
              : takerOtcFeeFactor,
          makerFeeFactor:
            typeof makerOtcFeeFactor === "string"
              ? parseFloat(makerOtcFeeFactor?.replaceAll(",", ""))
              : makerOtcFeeFactor,
          takerFeeMax:
            typeof takerOtcFeeMax === "string"
              ? parseFloat(takerOtcFeeMax?.replaceAll(",", ""))
              : takerOtcFeeMax,
          makerFeeMax:
            typeof makerOtcFeeMax === "string"
              ? parseFloat(makerOtcFeeMax?.replaceAll(",", ""))
              : makerOtcFeeMax,
        },
        order: {
          min:
            typeof orderMin === "string"
              ? parseFloat(orderMin?.replaceAll(",", ""))
              : orderMin,
          max:
            typeof orderMax === "string"
              ? parseFloat(orderMax?.replaceAll(",", ""))
              : orderMax,
          takerFeeFactor:
            typeof takerOrderFeeFactor === "string"
              ? parseFloat(takerOrderFeeFactor?.replaceAll(",", ""))
              : takerOrderFeeFactor,
          makerFeeFactor:
            typeof makerOrderFeeFactor === "string"
              ? parseFloat(makerOrderFeeFactor?.replaceAll(",", ""))
              : makerOrderFeeFactor,
          takerFeeMax:
            typeof takerOrderFeeMax === "string"
              ? parseFloat(takerOrderFeeMax?.replaceAll(",", ""))
              : takerOrderFeeMax,
          makerFeeMax:
            typeof makerOrderFeeMax === "string"
              ? parseFloat(makerOrderFeeMax?.replaceAll(",", ""))
              : makerOrderFeeMax,
        },
        deposit: {
          min:
            typeof depositMin === "string"
              ? parseFloat(depositMin?.replaceAll(",", ""))
              : depositMin,
          max:
            typeof depositMax === "string"
              ? parseFloat(depositMax?.replaceAll(",", ""))
              : depositMax,
          feeFactor:
            typeof depositFeeFactor === "string"
              ? parseFloat(depositFeeFactor?.replaceAll(",", ""))
              : depositFeeFactor,
          feeMax:
            typeof depositFeeMax === "string"
              ? parseFloat(depositFeeMax?.replaceAll(",", ""))
              : depositFeeMax,
        },
        withdraw: {
          min:
            typeof withdrawMin === "string"
              ? parseFloat(withdrawMin?.replaceAll(",", ""))
              : withdrawMin,
          max:
            typeof withdrawMax === "string"
              ? parseFloat(withdrawMax?.replaceAll(",", ""))
              : withdrawMax,
          feeFactor:
            typeof withdrawFeeFactor === "string"
              ? parseFloat(withdrawFeeFactor?.replaceAll(",", ""))
              : withdrawFeeFactor,
          feeMax:
            typeof withdrawFeeMax === "string"
              ? parseFloat(withdrawFeeMax?.replaceAll(",", ""))
              : withdrawFeeMax,
        },
        banks: gateWayBanks,
      },
    };
    setLoader(true);
    await httpRequest("put", "settings/banks", { data })
      .then((r) => {
        if (r.status === 200) {
          setLoader(true);
          SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
          setModalCentered(false);
          dispatch(getAvalibleBank());
          setLoader(false);
        }
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }
  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <Button
        variant="primary"
        type="button"
        className="d-flex mx-auto mt-3"
        onClick={() => setModalCentered(true)}
      >
        {children}
      </Button>
      {/* <!-- Modal --> */}
      <Modal
        className="fade customeModal"
        show={modalCentered}
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="number-black">تنظیمات فیات</h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body>
            {/* OTC *************** */}
            <h5 className="mb-3 ">OTC </h5>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  label={"کمترین حد otc (تومان)"}
                  name="otcMin"
                  allowLeadingZeros={true}
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  onChange={(e) => setOtcMin(e.target.value)}
                  value={otcMin}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  id="otcMax"
                  label={"بیشترین حد otc (تومان)"}
                  name="otcMax"
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  allowLeadingZeros={true}
                  value={otcMax}
                  onChange={(e) => setOtcMax(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="takerOtcFeeFactor"
                  label={"کارمزد Taker (%)"}
                  placeholder="به درصد"
                  name="takerOtcFeeFactor"
                  className="form-control"
                  allowLeadingZeros={true}
                  value={takerOtcFeeFactor}
                  onChange={(e) => setTakerOtcFeeFactor(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="makerOtcFeeFactor"
                  label={"کارمزد Maker (%)"}
                  placeholder="به درصد"
                  name="makerOtcFeeFactor"
                  className="form-control"
                  allowLeadingZeros={true}
                  value={makerOtcFeeFactor}
                  onChange={(e) => setMakerOtcFeeFactor(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="takerOtcFeeMax"
                  name="takerOtcFeeMax"
                  label={"کارمزد Taker تا سقف (مقدار صفر = بدون سقف)"}
                  className="form-control"
                  allowLeadingZeros={true}
                  value={takerOtcFeeMax}
                  onChange={(e) => setTakerOtcFeeMax(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="makerOtcFeeMax"
                  name="makerOtcFeeMax"
                  label={"کارمزد Maker تا سقف (مقدار صفر = بدون سقف)"}
                  className="form-control"
                  allowLeadingZeros={true}
                  value={makerOtcFeeMax}
                  onChange={(e) => setMakerOtcFeeMax(e.target.value)}
                />
              </div>
            </div>
            {/* ORDER *************** */}
            <h5 className="mb-3 ">سفارش </h5>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  label={"کمترین حد سفارش (تومان)"}
                  name="orderMin"
                  allowLeadingZeros={true}
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  onChange={(e) => setOrderMin(e.target.value)}
                  value={orderMin}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <NumberInput
                  id="orderMax"
                  label={"بیشترین حد سفارش (تومان)"}
                  name="orderMax"
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  allowLeadingZeros={true}
                  value={orderMax}
                  onChange={(e) => setOrderMax(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="takerOrderFeeFactor"
                  label={"کارمزد Taker (%)"}
                  placeholder="به درصد"
                  name="takerOrderFeeFactor"
                  className="form-control"
                  allowLeadingZeros={true}
                  value={takerOrderFeeFactor}
                  onChange={(e) => setTakerOrderFeeFactor(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="makerOrderFeeFactor"
                  label={"کارمزد Maker (%)"}
                  placeholder="به درصد"
                  name="makerOrderFeeFactor"
                  className="form-control"
                  allowLeadingZeros={true}
                  value={makerOrderFeeFactor}
                  onChange={(e) => setMakerOrderFeeFactor(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="takerOrderFeeMax"
                  name="takerOrderFeeMax"
                  label={"کارمزد Taker تا سقف (مقدار صفر = بدون سقف)"}
                  className="form-control"
                  allowLeadingZeros={true}
                  value={takerOrderFeeMax}
                  onChange={(e) => setTakerOrderFeeMax(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="makerOrderFeeMax"
                  name="makerOrderFeeMax"
                  label={"کارمزد Maker تا سقف (مقدار صفر = بدون سقف)"}
                  className="form-control"
                  allowLeadingZeros={true}
                  value={makerOrderFeeMax}
                  onChange={(e) => setMakerOrderFeeMax(e.target.value)}
                />
              </div>
            </div>
            {/* DiPOSIT *************** */}
            <h5 className="mb-3 mt-3">واریز</h5>
            <div className="row">
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="depositMin"
                  name="depositMin"
                  placeholder="قیمت را به تومان وارد کنید ...."
                  label={"کمترین حد واریز"}
                  className="form-control"
                  value={depositMin}
                  onChange={(e) => console.log(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="depositMax"
                  label={"بیشترین حد واریز"}
                  placeholder="قیمت را به تومان وارد کنید..."
                  name="depositMax"
                  className="form-control"
                  value={depositMax}
                  onChange={(e) => setDepositMax(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="depositFeeFactor"
                  placeholder="به درصد"
                  label={"کارمزد (%)"}
                  name="depositFeeFactor"
                  value={depositFeeFactor}
                  className="form-control"
                  onChange={(e) => setDepositFeeFactor(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="depositFeeMax"
                  label={"کارمزد تا سقف (مقدار صفر = بدون سقف)"}
                  name="depositFeeMax"
                  className="form-control"
                  value={depositFeeMax}
                  onChange={(e) => setDepositFeeMax(e.target.value)}
                />
              </div>
            </div>
            {/* WITHDRAW *************** */}
            <h5 className="mb-3 mt-3">برداشت</h5>
            <div className="row">
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="withdrawMin"
                  label={"کمترین حد برداشت"}
                  placeholder="قیمت را به تومان وارد کنید..."
                  name="withdrawMin"
                  className="form-control"
                  value={withdrawMin}
                  onChange={(e) => setWithdrawMin(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="withdrawMax"
                  name="withdrawMax"
                  label={"بیشترین حد برداشت "}
                  placeholder="قیمت را به تومان وارد کنید..."
                  className="form-control"
                  value={withdrawMax}
                  onChange={(e) => setWithdrawMax(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="withdrawFeeFactor"
                  label={"کارمزد (%) "}
                  name="withdrawFeeFactor"
                  placeholder="به درصد"
                  className="form-control"
                  value={withdrawFeeFactor}
                  onChange={(e) => setWithdrawFeeFactor(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <NumberInput
                  id="withdrawFeeMax"
                  label={"کارمزد تا سقف (مقدار صفر = بدون سقف)"}
                  name="withdrawFeeMax"
                  className="form-control"
                  value={withdrawFeeMax}
                  onChange={(e) => setWithdrawFeeMax(e.target.value)}
                />
              </div>
            </div>
            {/* BANK *************** */}
            <h5 className="mb-3 mt-3">درگاه های بانکی</h5>
            <div className="row">
              {gateWayBanks?.map((item) => {
                return (
                  <ActiveGateway data={item} onSelectGateway={handleGateway} />
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setModalCentered(false)}
              variant="danger light"
            >
              بستن
            </Button>
            <DefaultButton
              onClick={() => editBanks()}
              type="submit"
              loading={loader}
              variant="primary"
            >
              ذخیره تغییرات
            </DefaultButton>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ModalBanks;
