import { httpRequest } from "./../_helper";

export const tokenActions = {
  getAllTokens,
  createToken,
  deleteToken,
  updateToken,
  updateTokenIcon,
  getAnToken,
};

function getAllTokens({ page, limit }) {
  return () => {
    return httpRequest("get", `crypto/tokens?page=${page}&limit=${limit}`);
  };
}

function getAnToken(id) {
  return () => {
    return httpRequest("get", `crypto/tokens/${id}`);
  };
}

function createToken(data) {
  return () => {
    return httpRequest("post", "crypto/tokens", { data });
  };
}

function deleteToken(id) {
  return () => {
    return httpRequest("delete", `crypto/tokens/${id}`);
  };
}

function updateToken(id, data) {
  return () => {
    return httpRequest("put", `crypto/tokens/${id}`, { data });
  };
}

function updateTokenIcon(symbol, data) {
  return () => {
    return httpRequest("put", `crypto/tokens/icon/${symbol}`, { data });
  };
}
