import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import pMinDelay from "p-min-delay";
import { bankingActions, notificationActions } from "../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { isValidEmail } from "../Validation/checkEmail";
import { isValidNationalCode } from "../Validation//checkNationalCode";
import { DefaultButton } from "../Defaults/DefaultButton";
import loadable from "@loadable/component";
import Pagination from "react-js-pagination";

const UserBankingTransactions = ({ dispatch }) => {
  const route = useParams();
  const { id } = route;
  const [bankingLIst, setBankingLIst] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [hasBanking, setHasBanking] = useState(false);

  const changeNameStatus = (status) => {
    if (status === "pending") return "نامشخص";
    else if (status === "success") return "موفق";
    else if (status === "pending") return "ناموفق";
    else return null;
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "banking") setHasBanking(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    const userId = id;
    if (hasBanking || localStorage.getItem("role") === "admin") {
      dispatch(bankingActions.getBankingHistory({ page, limit, userId }))
        .then((r) => {
          setBankingLIst(r?.data?.data);
          setUsersMeta(r.data?.meta);
          console.log(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [hasBanking]);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    const userId = id;
    if (hasBanking || localStorage.getItem("role") === "admin") {
      dispatch(
        bankingActions.getBankingHistory({
          page,
          limit,
          userId,
        })
      )
        .then((r) => {
          setBankingLIst(r?.data?.data);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }

  return (
    <div className="card-body px-0">
      <div className="table-responsive">
        {bankingLIst?.length !== 0 ? (
          <div>
            <table className="table shadow-hover user-detail-bank card-table border-no tbl-btn short-one">
              <thead>
                <tr className="border_table_title">
                  <th className="text-center fs-10" scope="col">
                    شناسه تراکنش
                  </th>
                  <th className="text-center fs-10" scope="col">
                    تراکنش
                  </th>
                  <th className="text-center fs-10" scope="col">
                    تراکنش
                  </th>
                  <th className="text-center fs-10" scope="col">
                    تاریخ
                  </th>
                  <th className="text-center fs-10" scope="col">
                    ساعت
                  </th>
                  <th className="text-center fs-10" scope="col">
                    مقدار
                  </th>
                  <th className="text-center fs-10 px-2" scope="col">
                    وضعیت
                  </th>
                  <th className="text-center fs-10 px-2" scope="col">
                    درگاه پرداخت
                  </th>
                </tr>
              </thead>
              <tbody className="landing_tbody">
                {bankingLIst?.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="fs-11 text-center">{item.bankId}</td>
                      {item.flow === "deposit" ? (
                        <td className="fs-11 text-center">
                          <span class="bg-success ic-icon">
                            <svg
                              width="29"
                              height="29"
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.99954 10.4687L21.1821 23.6513C21.7435 24.2127 22.6537 24.2127 23.2151 23.6513C23.7765 23.0899 23.7765 22.1798 23.2151 21.6184L10.0325 8.43582L13.4612 8.4441L13.4612 8.44409C14.2551 8.44598 14.9002 7.80394 14.9021 7.01004C14.904 6.21593 14.2617 5.57098 13.468 5.56905L13.4668 6.06905L13.468 5.56905L6.55703 5.55234L6.54969 5.55232L6.54737 5.55239C5.75771 5.55578 5.11953 6.19662 5.11616 6.98358L5.1161 6.98585L5.11612 6.99333L5.13282 13.9043L5.13282 13.9043C5.13475 14.6982 5.77979 15.3403 6.57378 15.3384C7.36769 15.3365 8.00975 14.6914 8.00787 13.8975L8.00787 13.8975L7.99954 10.4687Z"
                                fill="white"
                                stroke="white"
                              ></path>
                            </svg>
                          </span>
                        </td>
                      ) : item.flow === "withdraw" ? (
                        <td className="fs-11 text-center">
                          <span class="bg-danger ic-icon">
                            <svg
                              width="29"
                              height="29"
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.7529 19.1563L7.5703 5.97367C7.00891 5.41228 6.09876 5.41228 5.53737 5.97367C4.97598 6.53505 4.97598 7.44521 5.53737 8.0066L18.72 21.1892L15.2913 21.1809L15.2912 21.1809C14.4973 21.179 13.8522 21.8211 13.8503 22.615C13.8484 23.4091 14.4907 24.054 15.2844 24.056L15.2856 23.556L15.2844 24.056L22.1954 24.0727L22.2028 24.0727L22.2051 24.0726C22.9947 24.0692 23.6329 23.4284 23.6363 22.6414L23.6363 22.6391L23.6363 22.6317L23.6196 15.7207L23.6196 15.7207C23.6177 14.9268 22.9727 14.2847 22.1787 14.2866C21.3847 14.2885 20.7427 14.9336 20.7446 15.7275L20.7446 15.7275L20.7529 19.1563Z"
                                fill="white"
                                stroke="white"
                              ></path>
                            </svg>
                          </span>
                        </td>
                      ) : (
                        <td className="fs-11 text-center"></td>
                      )}
                      {item.flow === "deposit" ? (
                        <td className="fs-11 text-center">واریز</td>
                      ) : item.flow === "withdraw" ? (
                        <td className="fs-11 text-center">برداشت</td>
                      ) : (
                        <td className="fs-11 text-center"></td>
                      )}
                      <td className="fs-11 text-center">
                        {new Date(item.createdAt).toLocaleDateString("fa-IR")}
                      </td>
                      <td className="fs-11 text-center">
                        {new Date(item.createdAt).toLocaleTimeString("fa-IR")}
                      </td>
                      <td className="fs-11 text-center">
                        {item.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        ریال
                      </td>
                      <td
                        className={`fs-11 text-center px-2
                          ${
                            item.status === "pending"
                              ? "text-warning"
                              : item.status === "error"
                              ? "text-danger"
                              : item.status === "success"
                              ? "text-success"
                              : null
                          }
                        `}
                      >
                        {changeNameStatus(item.status)}
                      </td>
                      <td className="fs-11 text-center">{item.gateway}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>{" "}
          </div>
        ) : (
          <div className="card-content bg-primary p-3 rounded text-center">
            <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
          </div>
        )}
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">
            نمایش {usersMeta?.page * usersMeta?.limit - usersMeta?.limit + 1} تا{" "}
            {usersMeta?.page * usersMeta?.limit} از {usersMeta?.total} تراکنش
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example5_paginate"
          >
            <div className="d-inline-block pb-3 ltr_direction">
              <Pagination
                activePage={page}
                itemsCountPerPage={usersMeta?.limit}
                totalItemsCount={usersMeta?.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UserBankingTransactions);
