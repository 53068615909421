import React, {Fragment, useState, useEffect} from "react";
import {Button, Modal, Badge, Spinner, Card} from "react-bootstrap";
import {useParams} from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import {settingsAction} from "../../../_actions";
import {connect} from "react-redux";
import {ERROR, SUCCESS} from "../../../_helper/alerts";
import {isValidEmail} from "../Validation/checkEmail";
import {isValidNationalCode} from "../Validation//checkNationalCode";
import {DefaultButton} from "../Defaults/DefaultButton";
import Switch from "react-switch";
import "react-image-lightbox/style.css";

const BlockItems = ({dispatch}) => {
    const route = useParams();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [tel, setTel] = useState("");
    const [mobile, setMobile] = useState("");
    const [nationalCode, setNationalCode] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [zip, setZip] = useState("");
    const [blockLoader, setBlockLoader] = useState(false);
    const [deposit, setDeposit] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const [order, setOrder] = useState(false);
    const [otc, setOtc] = useState(false);
    const [defaultVal, setDefaultVal] = useState(false);
    const [onVal, setOnVal] = useState(false);
    const [base, setBase] = useState(false);
    const [coins, setCoins] = useState([]);

    const handleChangeDeposit = (checked) => {
        setDeposit(checked);
    };
    const handleChangeWithdraw = (checked) => {
        setWithdraw(checked);
    };
    const handleChangeOrder = (checked) => {
        setOrder(checked);
    };
    const handleChangeOtc = (checked) => {
        setOtc(checked);
    };

    useEffect(() => {
        dispatch(settingsAction.getConfigItems())
            .then((r) => {
                console.log(r)
                setDeposit(r?.data?.data?.block?.deposit);
                setWithdraw(r?.data?.data?.block?.withdraw);
                setOrder(r?.data?.data?.block?.order);
                setOtc(r?.data?.data?.block?.otc);
            })
            .catch((e) =>
                ERROR(e.response?.data?.message || e.message, {
                    error: e,
                })
            );
    }, []);

    useEffect(() => {
        dispatch(settingsAction.getConfigItems())
            .then((r) => {
                setDefaultVal(r?.data?.data?.third?.default)
                setOnVal(r?.data?.data?.third?.on)
                setBase(r?.data?.data?.third?.base)
                setCoins(r?.data?.data?.third?.coins);

            })
            .catch((e) =>
                ERROR(e.response?.data?.message || e.message, {
                    error: e,
                })
            );
    }, []);

    const setBlockedItems = () => {
        setBlockLoader(true);
        const data = {
            data: {
                block: {
                    deposit: deposit,
                    withdraw: withdraw,
                    order: order,
                    otc: otc,
                },
                third: {
                    default: defaultVal,
                    on: onVal,
                    base: base,
                    coins: coins
                }
            }
        };
        dispatch(settingsAction.setConfigItems(data))
            .then((r) => {
                SUCCESS(r?.data?.message || "اطلاعات با موفقیت ثبت شد");
                setBlockLoader(false);
            })
            .catch((ex) => {
                setBlockLoader(false);
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                });
            });
    };

    return (
        <Fragment>
            <PageTitle activeMenu="بلاک کردن دسترسی ها" motherMenu="تنظیمات"/>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">بلاک کردن دسترسی های سیستم</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <p className="blocked_title">واریز</p>
                                    <Switch
                                        width={40}
                                        height={22}
                                        onChange={handleChangeDeposit}
                                        checked={deposit}
                                        className="react-switch"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <p className="blocked_title">برداشت</p>
                                    <Switch
                                        width={40}
                                        height={22}
                                        onChange={handleChangeWithdraw}
                                        checked={withdraw}
                                        className="react-switch"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <p className="blocked_title">ثبت سفارش</p>
                                    <Switch
                                        width={40}
                                        height={22}
                                        onChange={handleChangeOrder}
                                        checked={order}
                                        className="react-switch"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <p className="blocked_title"> سفارش سریع (OTC)</p>
                                    <Switch
                                        width={40}
                                        height={22}
                                        onChange={handleChangeOtc}
                                        checked={otc}
                                        className="react-switch"
                                    />
                                </div>
                            </div>
                            <DefaultButton
                                onClick={() => setBlockedItems()}
                                loading={blockLoader}
                                variant="primary"
                                className="mt-4"
                            >
                                ثبت اطلاعات
                            </DefaultButton>
                        </div>
                    </div>
                </div>
                {" "}
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(BlockItems);
