import React, { Fragment, useEffect, useState } from "react";
import { Badge, Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { FormGroup, Label, TabContent, TabPane } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import {
  coiningActions,
  notificationActions,
  rolesAction,
  usersAction,
  ordersAction,
  robotsActions,
} from "../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { BaseInput } from "../Defaults/BaseInput";
import { NumberInput } from "../Defaults/NumberInput";
import { isValidEmail } from "../Validation/checkEmail";
import { isValidNationalCode } from "../Validation//checkNationalCode";
import { DefaultButton } from "../Defaults/DefaultButton";
import Tron2 from "../../../images/svg/tron2.svg";
import Ethereum from "../../../images/svg/etherium.svg";
import Tether2 from "../../../images/svg/tether2.svg";
import Tether from "./../../../images/svg/tether.svg";
import Tron from "./../../../images/svg/tron.svg";
import UserTrades from "./UserTrades";
import RobotModal from "./RobotModal";
import UserOpenOrders from "./UserOpenOrders";
import UserBankingTransactions from "./UserBankingTransactions";
import UserCoiningTransactions from "./UserCoiningTransactions";
import UserAccountActivity from "./UserAccountActivity";
import Lightbox from "react-image-lightbox";
import Switch from "react-switch";
import "react-image-lightbox/style.css";
import { traceActions } from "../../../_actions/trace.actions";
import Pagination from "react-js-pagination";
import classnames from "classnames";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { otcActions } from "../../../_actions/otc.actions";

const UserDetail = ({ dispatch, history }) => {
  const route = useParams();
  const { id } = route;
  const [activeToggle, setActiveToggle] = useState("setting");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tel, setTel] = useState("");
  const [mobile, setMobile] = useState("");
  const [nationalCode, setNationalCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nationalCodeError, setNationalCodeError] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [countyErr, setCountyErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [filled, setFilled] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [blockLoader, setBlockLoader] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [banksAccountsList, setBankAccountsList] = useState([]);
  const [banksList, setBanksList] = useState([]);
  const [walletAccountsList, setWalletAccountsList] = useState([]);
  const [identitiesAccept, setIdentitiesAccept] = useState([]);
  const [identitiesNotAccept, setIdentitiesNotAccept] = useState([]);
  const [roleVal, setRoleVal] = useState({});
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [order, setOrder] = useState(false);
  const [otc, setOtc] = useState(false);
  const [increaseModal, setIncreaseModal] = useState(false);
  const [decreaseModal, setDecreaseModal] = useState(false);
  const [robotModal, setRobotModal] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState({ value: "DEFAULT", label: "پیش فرض" });
  const [priority, setPriority] = useState({ value: "LOW", label: "کم" });
  const [description, setDescription] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [allTraces, setAllTraces] = useState([]);
  const [tracesMeta, setTracesMeta] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selfieIsOpen, setSelfieIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [selfiePhotoIndex, setSelfiePhotoIndex] = useState(0);
  const [volume, setVolume] = useState(0);
  const [volumeCoin, setVolumeCoin] = useState(0);
  const [checkedUser, setCheckedUser] = useState(false);
  const [checkedAdmin, setCheckedAdmin] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [cause, setCause] = useState({});

  const [traceDetail, setTraceDetail] = useState(null)

  const [allCoins, setAllCoins] = useState([]);
  const [coins, setCoins] = useState("");
  const [hasRole, setHasRole] = useState(false);
  const [hasTrace, setHasTrace] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [robotsData, setRobotsData] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [traceType, setTraceType] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setCoins("");
    setVolumeCoin(0);
    setVolume(0);
  };

  const getPersianName = (coinName) => {
    dispatch(coiningActions.getAllAvailableCoins())
      .then((res) => {
        return res?.date?.data?.filter((item) => {
          return item?.id === coinName;
        })[0]?.fa;
      })
      .catch((err) => console.log(err));
  };

  const userHandleChange = () => {
    setRoleVal({});
    setCheckedUser(!checkedUser);
    if (checkedUser === false) {
      setCheckedAdmin(false);
    }
  };

  const adminHandleChange = () => {
    setRoleVal({});
    setCheckedAdmin(!checkedAdmin);
    if (checkedAdmin === false) {
      setCheckedUser(false);
    }
  };

  const handleChangeDeposit = (checked) => {
    setDeposit(checked);
  };

  const handleChangeWithdraw = (checked) => {
    setWithdraw(checked);
  };

  const handleChangeOrder = (checked) => {
    setOrder(checked);
  };

  const handleChangeOtc = (checked) => {
    setOtc(checked);
  };

  const PriorityOptions = [
    { value: "LOW", label: "کم" },
    { value: "MEDIUM", label: "عادی" },
    { value: "HIGH", label: "زیاد" },
  ];
  const TypeOptions = [
    { value: "DEFAULT", label: "پیش فرض" },
    { value: "ORDER", label: "سفارش" },
    { value: "TRADE", label: "معامله" },
    { value: "USER", label: "کاربر" },
    { value: "BALANCE_DEPOSIT", label: "واریز موجودی" },
    { value: "BALANCE_WITHDRAW", label: "برداشت موجودی" },
    { value: "COIN_DEPOSIT", label: "واریز ارز" },
    { value: "COIN_WITHDRAW", label: "برداشت ارز" },
    { value: "TICKET", label: "تیکت" },
  ];

  const captions = ["تصویر کارت ملی"];
  const images = [userDetails?.meliCard];

  const selfieCaptions = ["تصویر سلفی"];
  const selfieImages = [userDetails?.selfi];

  const imgOpen = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const selfieOpen = (index) => {
    setSelfieIsOpen(true);
    setSelfiePhotoIndex(index);
  };

  const handleChangeCoins = (selected) => {
    setCoins(selected);
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "role") setHasRole(true);
        if (item.scope === "trace") setHasTrace(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    if (increaseModal || decreaseModal) {
      dispatch(coiningActions.getAllAvailableCoins())
        .then((r) => {
          setAllCoins(
            r?.data?.data?.map((item) => {
              return {
                value: item.id,
                label: item?.id?.toUpperCase(),
              };
            })
          );
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [increaseModal, decreaseModal]);

  useEffect(() => {
    if (robotModal) {
      dispatch(robotsActions.getConfigRobots())
        .then((r) => {
          console.log(r?.data?.data);
          setRobotsData(r?.data?.data?.data);
          // setAllCoins(
          //   r?.data?.data?.map((item) => {
          //     return {
          //       value: item.id,
          //       label: item?.id?.toUpperCase(),
          //     };
          //   })
          // );
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [robotModal]);

  useEffect(() => {
    if (hasRole || localStorage.getItem("role") === "admin") {
      dispatch(rolesAction.getAllRoles({ page, limit }))
        .then((r) => {
          setPermissions(r.data?.data);
        })
        .catch((e) =>
          ERROR(e.response?.data?.message || e.message, {
            error: e,
          })
        );
    }
  }, [hasRole]);

  useEffect(() => {
    const userId = id;
    if (hasTrace || localStorage.getItem("role") === "admin") {
      dispatch(traceActions.getAllTraces({ page, limit, userId }))
        .then((r) => {
          setAllTraces(r.data?.data);
          setTracesMeta(r.data?.meta);
          console.log(r?.data);
        })
        .catch((e) =>
          ERROR(e.response?.data?.message || e.message, {
            error: e,
          })
        );
    }
  }, [hasTrace]);

  const handlePageChange = (pageNumber) => {
    const userId = id;
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(traceActions.getAllTraces({ page, limit, userId }))
      .then((r) => {
        setAllTraces(r.data?.data);
        setTotalItems(r.data?.meta?.total);
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  };

  useEffect(() => {
    dispatch(usersAction.userInfo(id))
      .then((r) => {
        setDeposit(
          r?.data?.data?.block?.deposit ? r?.data?.data?.block?.deposit : false
        );
        setWithdraw(
          r?.data?.data?.block?.withdraw
            ? r?.data?.data?.block?.withdraw
            : false
        );
        setOrder(
          r?.data?.data?.block?.order ? r?.data?.data?.block?.order : false
        );
        setOtc(r?.data?.data?.block?.otc ? r?.data?.data?.block?.otc : false);
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  }, []);

  const setBlockedItems = () => {
    setBlockLoader(true);
    const data = {
      block: {
        deposit: deposit,
        withdraw: withdraw,
        order: order,
        otc: otc,
      },
    };

    dispatch(usersAction.userEditInfo(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
        setBlockLoader(false);
        // dispatch(setUser(r.data));
      })
      .catch((ex) => {
        setBlockLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  };

  const rolesOptions = permissions?.map((item) => {
    return {
      value: item._id,
      label: item.name,
    };
  });

  const handleChangeRole = (selected) => {
    setRoleVal(selected);
    setCheckedAdmin(false);
    setCheckedUser(false);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  const CompleteLink = () => {
    return (
      <Link to={"#"} className="btn-link text-success ">
        Completed
      </Link>
    );
  };
  const PendingLink = () => {
    return (
      <Link to={"#"} className="btn-link text-warning ">
        Pending
      </Link>
    );
  };

  const onChangeDesc = (evt) => {
    setDescription(evt.target.value);
  };

  const onChangeTitle = (evt) => {
    setTitle(evt.target.value);
  };

  const onChangeVolumeCoin = (evt) => {
    setVolumeCoin(evt.target.value);
  };
  const onChangeVolume = (evt) => {
    setVolume(evt.target.value);
  };

  const handleTypeChange = (selected) => {
    setType(selected);
  };

  const handlePriorityChange = (selected) => {
    setPriority(selected);
  };

  useEffect(() => {
    getUserInfoDetail(0);
  }, []);

  const getUserInfoDetail = () => {
    dispatch(usersAction.userInfo(id))
      .then((r) => {
        setUserDetails(r?.data?.data);
        console.log(r?.data?.data);
        setWalletAccountsList(r.data.data.coins);
        setBanksList(r.data.data.banks);
        setBankAccountsList(r.data.data.wallets);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  async function sendNotification() {
    const data = {
      title: title,
      body: description,
      receptorId: id,
      type: type?.value,
      priority: priority?.value,
    };
    setLoader(true);
    dispatch(notificationActions.createNotification(data))
      .then((r) => {
        SUCCESS(r?.data?.message || "نوتیفیکیشن با موفقیت ارسال شد.");
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {
        setLoader(false);
        setNotifyModal(false);
      });
  }

  async function increaseBalance() {
    const data = {
      action: "INCREASE",
      currency: "irt",
      volume: parseInt(volume?.replaceAll(",", "")),
    };
    setLoader(true);
    dispatch(usersAction.increaseUserBalance(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "موجودی کاربر مورد نظر افزایش یافت");
        setLoader(false);
        dispatch(usersAction.userInfo(id))
          .then((r) => {
            setUserDetails(r?.data?.data);
            setWalletAccountsList(r.data.data.coins);
            setBanksList(r.data.data.banks);
            setBankAccountsList(r.data.data.wallets);
          })
          .catch((ex) =>
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            })
          );
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {
        setLoader(false);
        setIncreaseModal(false);
      });
  }
  async function decreaseBalance() {
    const data = {
      action: "DECREASE",
      currency: "irt",
      volume: parseInt(volume?.replaceAll(",", "")),
    };
    setLoader(true);
    dispatch(usersAction.decreaseUserBalance(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "موجودی کاربر مورد نظر کاهش یافت");
        setLoader(false);
        dispatch(usersAction.userInfo(id))
          .then((r) => {
            setUserDetails(r?.data?.data);
            setWalletAccountsList(r.data.data.coins);
            setBanksList(r.data.data.banks);
            setBankAccountsList(r.data.data.wallets);
          })
          .catch((ex) =>
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            })
          );
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {
        setLoader(false);
        setDecreaseModal(false);
      });
  }


  async function increaseBalanceCoin() {
    const data = {
      action: "INCREASE",
      currency: coins?.value,
      volume: volumeCoin?.includes(",")
        ? parseFloat(volumeCoin?.replaceAll(",", ""))
        : parseFloat(volumeCoin),
    };
    setLoader(true);
    dispatch(usersAction.increaseUserBalance(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "موجودی کاربر مورد نظر افزایش یافت");
        setLoader(false);
        dispatch(usersAction.userInfo(id))
          .then((r) => {
            setUserDetails(r?.data?.data);
            setWalletAccountsList(r.data.data.coins);
            setBanksList(r.data.data.banks);
            setBankAccountsList(r.data.data.wallets);
          })
          .catch((ex) =>
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            })
          );
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {
        setLoader(false);
        setIncreaseModal(false);
      });
  }
  async function decreaseBalanceCoin() {
    const data = {
      action: "DECREASE",
      currency: coins?.value,
      volume: volumeCoin?.includes(",")
        ? parseFloat(volumeCoin?.replaceAll(",", ""))
        : parseFloat(volumeCoin),
    };
    setLoader(true);
    dispatch(usersAction.decreaseUserBalance(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "موجودی کاربر مورد نظر کاهش یافت");
        setLoader(false);
        dispatch(usersAction.userInfo(id))
          .then((r) => {
            setUserDetails(r?.data?.data);
            setWalletAccountsList(r.data.data.coins);
            setBanksList(r.data.data.banks);
            setBankAccountsList(r.data.data.wallets);
          })
          .catch((ex) =>
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            })
          );
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {
        setLoader(false);
        setDecreaseModal(false);
      });
  }

  useEffect(() => {
    if (isValidEmail(email) || email === "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [email]);

  useEffect(() => {
    if (isValidNationalCode(nationalCode) === false && nationalCode !== "") {
      setNationalCodeError(true);
    } else {
      setNationalCodeError(false);
    }
  }, [nationalCode]);

  useEffect(() => {
    setFirstName(userDetails?.firstName);
    setLastName(userDetails?.lastName);
    setEmail(userDetails?.email);
    setMobile(userDetails?.mobile);
    setTel(userDetails?.phone);
    setNationalCode(userDetails?.meliCode);
    setAddress(userDetails?.address?.line);
    setCounty(userDetails?.address?.county);
    setCity(userDetails?.address?.city);
    setZip(userDetails?.address?.zip);
    setCheckedAdmin(userDetails?.role === "admin" ? true : false);
    setCheckedUser(userDetails?.role === "user" ? true : false);
    setRoleVal(
      // userDetails?.role === "admin"
      //   ? { value: "admin", label: "ادمین" }
      //   : userDetails?.role === "user"
      //   ? { value: "user", label: "کاربر" }
      //   :
      userDetails?.role === "panel"
        ? { value: "panel", label: "مدیریت پنل" }
        : userDetails?.role === "setting"
          ? { value: "setting", label: "مدیریت تنظیمات" }
          : userDetails?.role === "role"
            ? { value: "role", label: "مدیریت نقش ها" }
            : userDetails?.role === "config"
              ? { value: "config", label: "مدیریت کانفیگ ها" }
              : userDetails?.role === "banking"
                ? { value: "banking", label: "مدیریت حساب های بانکی" }
                : userDetails?.role === "coining"
                  ? { value: "coining", label: "مدیریت کیف پول ها" }
                  : userDetails?.role === "order"
                    ? { value: "order", label: "مدیریت سفارش ها" }
                    : userDetails?.role === "otc"
                      ? { value: "otc", label: "مدیریت سفارشات سریع" }
                      : userDetails?.role === "trade"
                        ? { value: "trade", label: "مدیریت معاملات" }
                        : userDetails?.role === "notification"
                          ? { value: "notification", label: "مدیریت پیام ها" }
                          : userDetails?.role === "ticket"
                            ? { value: "ticket", label: "مدیریت تیکت ها" }
                            : userDetails?.role === "trace"
                              ? { value: "trace", label: "مدیریت Trace ها" }
                              : userDetails?.role === "affiliate"
                                ? { value: "affiliate", label: "مدیریت کدهای دعوت" }
                                : userDetails?.role === "balance"
                                  ? { value: "balance", label: "مدیریت موجودی ها" }
                                  : userDetails?.role === "thirdParty"
                                    ? {
                                      value: "thirdParty",
                                      label: "مدیریت تنظیمات OTC",
                                    }
                                    : null
    );
  }, [userDetails]);

  useEffect(() => {
    if (
      firstName === "" ||
      firstName === null ||
      lastName === "" ||
      lastName === null ||
      nationalCode === null ||
      email === null ||
      tel === null ||
      county === null ||
      city === null ||
      zip === null ||
      address === null ||
      mobile === null ||
      nationalCode === "" ||
      email === "" ||
      tel === "" ||
      county === "" ||
      city === "" ||
      zip === "" ||
      address === "" ||
      mobile === ""
    ) {
      setFilled(false);
    } else if (
      firstName !== "" ||
      firstName !== null ||
      lastName !== "" ||
      lastName !== null ||
      nationalCode !== null ||
      email !== null ||
      tel !== null ||
      county !== null ||
      city !== null ||
      zip !== null ||
      address !== null ||
      mobile !== null ||
      nationalCode !== "" ||
      email !== "" ||
      tel !== "" ||
      county !== "" ||
      city !== "" ||
      zip !== "" ||
      address !== "" ||
      mobile !== ""
    ) {
      setFilled(true);
    }
  }, [
    firstName,
    lastName,
    email,
    nationalCode,
    email,
    tel,
    county,
    city,
    zip,
    address,
    mobile,
  ]);

  useEffect(() => {
    if (firstName?.length < 30 && firstName !== "" && firstName !== null) {
      setFirstNameErr(false);
    }
    if (lastName?.length <= 30 && lastName !== "" && lastName !== null) {
      setLastNameErr(false);
    }
    if (
      tel?.replace("-", "").replace(" ", "")?.length === 11 ||
      tel !== "" ||
      tel !== null
    ) {
      setPhoneErr(false);
    }
    if (city?.length <= 30 && city !== "" && city !== null) {
      setCityErr(false);
    }
    if (county?.length <= 30 && county !== "" && county !== null) {
      setCountyErr(false);
    }
    if (address?.length <= 255 && address !== "" && address !== null) {
      setAddressErr(false);
    }
  }, [
    firstName,
    lastName,
    email,
    nationalCode,
    email,
    tel,
    county,
    city,
    zip,
    address,
    mobile,
  ]);

  async function editProfileUser() {
    if (firstName?.length > 30 || firstName === "" || firstName === null) {
      setFirstNameErr(true);
    } else if (
      firstName?.length < 30 &&
      firstName !== "" &&
      firstName !== null
    ) {
      setFirstNameErr(false);
    }
    if (lastName?.length > 30 || lastName === "" || lastName === null) {
      setLastNameErr(true);
    } else if (lastName?.length <= 30 && lastName !== "" && lastName !== null) {
      setLastNameErr(false);
    }
    if (
      tel?.replace("-", "").replace(" ", "")?.length < 11 ||
      tel === "" ||
      tel === null
    ) {
      setPhoneErr(true);
    } else if (
      tel?.replace("-", "").replace(" ", "")?.length === 11 ||
      tel !== "" ||
      tel !== null
    ) {
      setPhoneErr(false);
    }
    if (city?.length > 30 || city === "" || city === null) {
      setCityErr(true);
    } else if (city?.length <= 30 && city !== "" && city !== null) {
      setCityErr(false);
    }
    if (county?.length > 30 || county === "" || county === null) {
      setCountyErr(true);
    } else if (county?.length <= 30 && county !== "" && county !== null) {
      setCountyErr(false);
    }
    if (address?.length > 255 || address === "" || address === null) {
      setAddressErr(true);
    } else if (address?.length <= 255 && address !== "" && address !== null) {
      setAddressErr(false);
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      meliCode: nationalCode,
      mobile: mobile,
      phone: tel?.replace("-", ""),
      address: {
        county: county,
        city: city,
        line: address,
        zip: zip,
      },
      email: email,
      role: roleVal?.value
        ? roleVal?.value
        : checkedUser === true && checkedAdmin === false
          ? "user"
          : checkedAdmin === true && checkedUser === false
            ? "admin"
            : "user",
    };
    if (
      firstName?.length <= 30 &&
      lastName?.length <= 30 &&
      city?.length <= 30 &&
      county?.length <= 30 &&
      address?.length <= 255 &&
      tel?.replace("-", "").replace(" ", "")?.length === 11
    ) {
      setLoader(true);
      dispatch(usersAction.userEditInfo(id, data))
        .then((r) => {
          SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
          setLoader(false);
          // dispatch(setUser(r.data));
        })
        .catch((ex) => {
          setLoader(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        });
    }
  }

  const verifyAccount = (accountId) => {
    const isVerify = true;
    dispatch(usersAction.verifyAccount(id, accountId, isVerify))
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r?.data?.message || "حساب بانکی با موفقیت تایید شد");
          getUserInfoDetail();
        }
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  const onChangeEmail = (evt) => {
    setEmail(evt.target.value);
  };

  const onChangeFirstName = (evt) => {
    setFirstName(evt.target.value);
  };
  const onChangeLastName = (evt) => {
    setLastName(evt.target.value);
  };
  const onChangeAddress = (evt) => {
    setAddress(evt.target.value);
  };
  const onChangeCounty = (evt) => {
    setCounty(evt.target.value);
  };
  const onChangeCity = (evt) => {
    setCity(evt.target.value);
  };
  const onChangeNationalCode = (evt) => {
    if (evt.value) {
      setNationalCode(evt.value);
    }
  };
  const onChangeTel = (evt) => {
    if (evt.value) {
      setTel(evt.value);
    }
  };
  const onChangeZip = (evt) => {
    if (evt.value) {
      setZip(evt.value);
    }
  };
  const onChangeMobile = (evt) => {
    if (evt.value) {
      setMobile(evt.value);
    }
  };

  async function verifyUser(id, firstName, lastName, type) {
    if (type === "accepted") {
      setSendLoader(true);
      dispatch(usersAction.acceptIdentity(id, firstName, lastName, true))
        .then((r) => {
          if (r.status === 200) {
            setIdentitiesAccept(
              identitiesAccept.filter((item) => item._id !== id)
            );
            SUCCESS(r?.data?.message || "حساب کاربر با موفقیت تایید شد");
            // fetchIdentitiesList();
            setSendLoader(false);
          }
        })
        .catch((ex) => {
          setSendLoader(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        })
        .finally(() => {
          setAcceptLoading(false);
          setBasicModal(false);
        });
    }
    if (type === "rejected") {
      setSendLoader(true);
      dispatch(usersAction.acceptIdentity(id, firstName, lastName, false))
        .then((r) => {
          if (r.status === 200) {
            setIdentitiesNotAccept(
              identitiesNotAccept.filter((item) => item._id !== id)
            );
            SUCCESS(r?.data?.message || "حساب کاربر رد شد");
          }
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        )
        .finally(() => {
          setSendLoader(false);
          setAcceptLoading(false);
          setBasicModal(false);
        });
    }
  }

  async function removeMeliCard(id) {
    const data = {
      meliCard: null,
    };
    setLoader(true);
    dispatch(usersAction.userEditInfo(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "کارت ملی با موفقیت حذف شد");
        setLoader(false);

        dispatch(usersAction.userInfo(id))
          .then((r) => {
            setUserDetails(r?.data?.data);
            setWalletAccountsList(r.data.data.coins);
            setBanksList(r.data.data.banks);
            setBankAccountsList(r.data.data.wallets);
          })
          .catch((ex) =>
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            })
          );
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  async function removeSelfie(id) {
    const data = {
      selfi: null,
    };
    setLoader(true);
    dispatch(usersAction.userEditInfo(id, data))
      .then((r) => {
        SUCCESS(r?.data?.message || "کارت ملی با موفقیت حذف شد");
        setLoader(false);

        dispatch(usersAction.userInfo(id))
          .then((r) => {
            setUserDetails(r?.data?.data);
            setWalletAccountsList(r.data.data.coins);
            setBanksList(r.data.data.banks);
            setBankAccountsList(r.data.data.wallets);
          })
          .catch((ex) =>
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            })
          );
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  // const goToRobot = () => {
  //   history.push(`robotsList/${id}`);
  // };

  const openDetailModal = (item) => {
    setDetailModal(true);
    setCause(item);
    console.log("item", item)
    if (item?.admin) {
      setTraceType("admin");
      dispatch(usersAction.userInfo(item?.admin))
        .then((r) => {
          if (r.status === 200) {
            setTraceDetail(r.data.data);
          }
        })
    } else if (item?.label === "order") {
      setTraceType("order");
      const orderId = item?.order;
      dispatch(ordersAction.getAnOrder({ orderId }))
        .then((r) => {
          console.log("order resp", r)
          if (r.status === 200) {
            setTraceDetail(r.data.data);
          }
        })
    } else if (item?.label === "otc") {
      setTraceType("otc");
      //TODO: find otc by email api
      dispatch(otcActions.getAnOtc(item?.user))
        .then(r => {
          console.log("otc detail", r)
          if (r.status === 200) {
            setTraceDetail(r.data.data)
          }
        })
    }
  };

  useEffect(() => {
    if (detailModal === false) {
      setTraceDetail(null)
    }
  }, [detailModal]);

  const remove2FA = () => {
    dispatch(usersAction.removeUser2FA({ id }))
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r?.data?.message || "فرآیند مورد نظر با موفقیت انجام شد");
        }
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  };

  return (
    <Fragment>
      <PageTitle activeMenu="جزئیات اطلاعات کاربر" motherMenu="کاربران" />
      <div className="row mb-3">
        {" "}
        <div className="col-4 mb-3">
          {userDetails?.verifyAt ? (
            <DefaultButton
              disabled
              loading={sendLoader}
              className="w-100"
              onClick={() =>
                verifyUser(
                  id,
                  userDetails?.firstName,
                  userDetails?.lastName,
                  "accepted"
                )
              }
              variant="dark light"
            >
              تایید اطلاعات کاربر
              {/*( {userDetails?.verifyAt ? "تایید شده" : "تایید نشده"}) */}
            </DefaultButton>
          ) : (
            <DefaultButton
              loading={sendLoader}
              className="w-100"
              onClick={() =>
                verifyUser(
                  id,
                  userDetails?.firstName,
                  userDetails?.lastName,
                  "accepted"
                )
              }
              variant="success light"
            >
              تایید اطلاعات کاربر
              {/* ({userDetails?.verifyAt ? "کاربر تایید شده" : "کاربر تایید نشده"}) */}
            </DefaultButton>
          )}
        </div>
        <div className="col-4 mb-3">
          <DefaultButton
            className="w-100"
            onClick={() => setBasicModal(true)}
            variant="danger light"
          >
            رد اطلاعات کاربر
          </DefaultButton>
          <Modal className="fade" show={basicModal} onHide={setBasicModal}>
            <Modal.Header>
              <h4 className="text-black">رد اطلاعات کاربر</h4>
              <Button
                variant=""
                className="close"
                onClick={() => setBasicModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <BaseInput
                className="form-control text-right"
                id="description"
                value={description}
                onChange={onChangeDesc}
                label={"علت عدم موافقت خود را وارد نمایید"}
              />
            </Modal.Body>
            <Modal.Footer>
              <DefaultButton
                onClick={() =>
                  verifyUser(
                    id,
                    userDetails?.firstName,
                    userDetails?.lastName,
                    "rejected"
                  )
                }
                loading={sendLoader}
                variant="primary light"
              >
                ارسال
              </DefaultButton>
              <Button
                className="mr-2"
                onClick={() => setBasicModal(false)}
                variant="danger light"
              >
                بستن
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="col-4 mb-3">
          <DefaultButton
            className="w-100"
            onClick={remove2FA}
            variant="success light"
          >
            حذف تنظیمات ورود دو مرحله ای
          </DefaultButton>
        </div>
        <div className="col-4 mb-3">
          <DefaultButton
            className="w-100"
            onClick={() => setNotifyModal(true)}
            variant="info light"
          >
            ارسال نوتیفیکیشن
          </DefaultButton>
          <Modal className="fade" show={notifyModal} onHide={setNotifyModal}>
            <Modal.Header>
              <h4 className="text-black">نوتیفیکیشن</h4>
              <Button
                variant=""
                className="close"
                onClick={() => setNotifyModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <BaseInput
                className="form-control text-right"
                id="title"
                value={title}
                onChange={onChangeTitle}
                label={"عنوان پیام"}
              />
              <BaseInput
                className="form-control text-right"
                id="description"
                value={description}
                onChange={onChangeDesc}
                label={"متن پیام"}
              />
              <FormGroup className="form-group mb-0">
                <Label for="status">نوع نوتیفیکیشن</Label>
                <Select
                  styles={customStyles}
                  options={TypeOptions}
                  id={"type"}
                  name={"type"}
                  className={`react-select mb-3`}
                  classNamePrefix="react-select"
                  value={type}
                  onChange={handleTypeChange}
                />
              </FormGroup>
              <FormGroup className="form-group mb-0">
                <Label for="status">اولویت</Label>
                <Select
                  styles={customStyles}
                  options={PriorityOptions}
                  id={"priority"}
                  name={"priority"}
                  className={`react-select mb-3`}
                  classNamePrefix="react-select"
                  value={priority}
                  onChange={handlePriorityChange}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <DefaultButton
                onClick={() => sendNotification()}
                loading={sendLoader}
                variant="primary light"
              >
                ارسال
              </DefaultButton>
              <Button
                className="mr-2"
                onClick={() => setNotifyModal(false)}
                variant="danger light"
              >
                بستن
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="col-4 mb-3">
          <DefaultButton
            className="w-100"
            onClick={() => setIncreaseModal(true)}
            variant="warning light"
          >
            افزایش موجودی کاربر
          </DefaultButton>
          <Modal
            className="fade"
            show={increaseModal}
            onHide={setIncreaseModal}
          >
            <Modal.Header>
              <h4 className="text-black">افزایش موجودی</h4>
              <Button
                variant=""
                className="close"
                onClick={() => setIncreaseModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="card-action card-action-tabs card-tabs mb-3 style-1">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <Link
                      to={"#"}
                      className={
                        classnames({ active: activeTab === "1" }) + " nav-link"
                      }
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      تومان
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"#"}
                      className={
                        classnames({ active: activeTab === "2" }) + " nav-link"
                      }
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      ارزی
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="card-body tab-content p-0">
                <div className="tab-pane active show fade" id="monthly">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <NumberInput
                        label={"مقدار"}
                        name="volume"
                        allowLeadingZeros={true}
                        placeholder="قیمت را به تومان وارد کنید..."
                        className="form-control ltr_direction"
                        onChange={onChangeVolume}
                        value={volume}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <NumberInput
                        label={"مقدار"}
                        name="volumeCoin"
                        allowLeadingZeros={true}
                        placeholder="قیمت را به تومان وارد کنید..."
                        className="form-control ltr_direction"
                        onChange={onChangeVolumeCoin}
                        value={volumeCoin}
                      />
                      <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                        <Label for="status">نوع ارز</Label>
                        <Select
                          styles={customStyles}
                          className={`react-select w-100 text-left`}
                          classNamePrefix="react-select"
                          onChange={handleChangeCoins}
                          options={allCoins}
                          value={coins}
                          placeholder="انتخاب کنید"
                          instanceId="coins"
                          name="coins"
                        />
                      </FormGroup>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {activeTab === "1" ? (
                <DefaultButton
                  onClick={() => increaseBalance()}
                  loading={sendLoader}
                  variant="primary light"
                >
                  ارسال
                </DefaultButton>
              ) : activeTab === "2" ? (
                <DefaultButton
                  onClick={() => increaseBalanceCoin()}
                  loading={sendLoader}
                  variant="primary light"
                >
                  ارسال
                </DefaultButton>
              ) : null}

              <Button
                className="mr-2"
                onClick={() => setIncreaseModal(false)}
                variant="danger light"
              >
                بستن
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="col-4 mb-3">
          <DefaultButton
            className="w-100"
            onClick={() => setDecreaseModal(true)}
            variant="secondary light"
          >
            کاهش موجودی کاربر
          </DefaultButton>
          <Modal
            className="fade"
            show={decreaseModal}
            onHide={setDecreaseModal}
          >
            <Modal.Header>
              <h4 className="text-black">کاهش موجودی</h4>
              <Button
                variant=""
                className="close"
                onClick={() => setDecreaseModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="card-action card-action-tabs card-tabs mb-3 style-1">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <Link
                      to={"#"}
                      className={
                        classnames({ active: activeTab === "1" }) + " nav-link"
                      }
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      تومان
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"#"}
                      className={
                        classnames({ active: activeTab === "2" }) + " nav-link"
                      }
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      ارزی
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="card-body tab-content p-0">
                <div className="tab-pane active show fade" id="monthly">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <NumberInput
                        label={"مقدار"}
                        name="volume"
                        allowLeadingZeros={true}
                        placeholder="قیمت را به تومان وارد کنید..."
                        className="form-control ltr_direction"
                        onChange={onChangeVolume}
                        value={volume}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <NumberInput
                        label={"مقدار"}
                        name="volumeCoin"
                        allowLeadingZeros={true}
                        placeholder="قیمت را به تومان وارد کنید..."
                        className="form-control ltr_direction"
                        onChange={onChangeVolumeCoin}
                        value={volumeCoin}
                      />
                      <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                        <Label for="status">نوع ارز</Label>
                        <Select
                          styles={customStyles}
                          className={`react-select w-100 text-left`}
                          classNamePrefix="react-select"
                          onChange={handleChangeCoins}
                          options={allCoins}
                          value={coins}
                          placeholder="انتخاب کنید"
                          instanceId="coins"
                          name="coins"
                        />
                      </FormGroup>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {activeTab === "1" ? (
                <DefaultButton
                  onClick={() => decreaseBalance()}
                  loading={sendLoader}
                  variant="primary light"
                >
                  ارسال
                </DefaultButton>
              ) : activeTab === "2" ? (
                <DefaultButton
                  onClick={() => decreaseBalanceCoin()}
                  loading={sendLoader}
                  variant="primary light"
                >
                  ارسال
                </DefaultButton>
              ) : null}

              <Button
                className="mr-2"
                onClick={() => setDecreaseModal(false)}
                variant="danger light"
              >
                بستن
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* <div className="col-4">
          <div className="verify-user-btn">
            <DefaultButton
              onClick={goToRobot}
              className="w-100 mb-2 fs-12"
              variant="success light"
            >
              راه اندازی ربات
            </DefaultButton>

            <Modal
              size={"lg"}
              className="fade robot_modal_wrapper"
              show={robotModal}
              onHide={setRobotModal}
            >
              <Modal.Body>
                <Tabs
                  defaultActiveKey={robotsData[0]?.coin}
                  transition={false}
                  id="cp-coins"
                >
                  {robotsData?.map((item, index) => {
                    return (
                      <Tab key={index} eventKey={item.coin} title={item.coin}>
                        <RobotModal
                          userId={id}
                          tabs={item.coin}
                          title={item.coin}
                          isActive={item.isActive}
                          robotsData={robotsData}
                          setRobotModal={setRobotModal}
                        />
                      </Tab>
                    );
                  })}
                </Tabs>
              </Modal.Body>
            </Modal>
          </div>
        </div> */}
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link
                        to="#profile-settings"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("setting")}
                        className={`nav-link fs-11 ml-0 ${activeToggle === "setting" ? "active show" : ""
                          }`}
                      >
                        اطلاعات کاربر
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("banks")}
                    >
                      <Link
                        to="#my-banks"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "banks" ? "active show" : ""
                          }`}
                      >
                        حساب بانکی
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("wallets")}
                    >
                      <Link
                        to="#wallet"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "wallets" ? "active show" : ""
                          }`}
                      >
                        کیف پول
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("trades")}
                    >
                      <Link
                        to="#trades"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "trades" ? "active show" : ""
                          }`}
                      >
                        معاملات
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("openOrders")}
                    >
                      <Link
                        to="#openOrders"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "openOrders" ? "active show" : ""
                          }`}
                      >
                        سفارشات
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("bankTransaction")}
                    >
                      <Link
                        to="#bankTransaction"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "bankTransaction"
                          ? "active show"
                          : ""
                          }`}
                      >
                        تراکنش های بانکی
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("coinTransaction")}
                    >
                      <Link
                        to="#coinTransaction"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "coinTransaction"
                          ? "active show"
                          : ""
                          }`}
                      >
                        تراکنش های ارزی
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("accountActivity")}
                    >
                      <Link
                        to="#accountActivity"
                        data-toggle="tab"
                        className={`nav-link fs-11 ml-0 ${activeToggle === "accountActivity"
                          ? "active show"
                          : ""
                          }`}
                      >
                        فعالیت حساب
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""
                        }`}
                    >
                      <div className="pt-5">
                        <h3 className="fs-13 mb-4">
                          {userDetails.firstName}
                          {"  "}
                          {userDetails.lastName}
                        </h3>

                        <div className="settings-form">
                          {userDetails?.length !== 0 ? (
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <FormGroup className="form-group mb-0 row mx-1">
                                    <Label for="status">نقش</Label>
                                    <Select
                                      styles={customStyles}
                                      className={`react-select w-100 text-left`}
                                      classNamePrefix="react-select"
                                      placeholder={
                                        rolesOptions?.find(
                                          (option) =>
                                            option.value === userDetails?.role
                                        )?.label
                                      }
                                      onChange={handleChangeRole}
                                      options={rolesOptions}
                                      value={roleVal}
                                      instanceId="roleVal"
                                      name="roleVal"
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="firstName"
                                    value={firstName}
                                    onChange={onChangeFirstName}
                                    label={"نام"}
                                    error={firstNameErr}
                                    errorText="طول پارامتر نام نمی تواند بیشتر از 30 کاراکتر باشد"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="lastName"
                                    value={lastName}
                                    onChange={onChangeLastName}
                                    label={"نام خانوادگی"}
                                    error={lastNameErr}
                                    errorText="طول پارامتر نام خانوادگی نمی تواند بیشتر از 50 کاراکتر باشد"
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <BaseInput
                                    className="form-control text-left ltr_direction"
                                    id="email"
                                    value={email}
                                    onChange={onChangeEmail}
                                    label={"ایمیل"}
                                    error={emailError}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="nationalCode"
                                    value={nationalCode}
                                    allowLeadingZeros={true}
                                    format="##########"
                                    onValueChange={onChangeNationalCode}
                                    label={"کد ملی"}
                                    error={nationalCodeError}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="tel"
                                    value={tel}
                                    allowLeadingZeros={true}
                                    format="###-########"
                                    onValueChange={onChangeTel}
                                    label={"تلفن"}
                                    error={phoneErr}
                                    errorText="طول شماره تلفن نباید کمتر از 11 رقم باشد.(همراه کد شهر)"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="mobile"
                                    value={mobile}
                                    allowLeadingZeros={true}
                                    onValueChange={onChangeMobile}
                                    label={"تلفن همراه"}
                                    format="###########"
                                    placeholder="09---------"
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <NumberInput
                                    className="form-control text-left ltr_direction"
                                    id="zip"
                                    value={zip}
                                    allowLeadingZeros={true}
                                    onValueChange={onChangeZip}
                                    format="##########"
                                    label={"کد پستی"}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="county"
                                    value={county}
                                    onChange={onChangeCounty}
                                    label={"استان"}
                                    error={countyErr}
                                    errorText="طول پارامتر استان نمی تواند بیشتر از 30 کاراکتر باشد"
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="city"
                                    value={city}
                                    onChange={onChangeCity}
                                    label={"شهر"}
                                    error={cityErr}
                                    errorText="طول پارامتر شهر نمی تواند بیشتر از 30 کاراکتر باشد"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <BaseInput
                                    className="form-control text-right"
                                    id="address"
                                    value={address}
                                    onChange={onChangeAddress}
                                    label={"آدرس"}
                                    error={addressErr}
                                    errorText="طول پارامتر آدرس نمی تواند بیشتر از 255 کاراکتر باشد"
                                  />
                                </div>
                                <div className="form-group col-md-3">
                                  <Label className="ml-2" for="status">
                                    کاربر عادی
                                  </Label>
                                  <Switch
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={15}
                                    width={28}
                                    onColor="#1fa2ff"
                                    onChange={userHandleChange}
                                    checked={checkedUser}
                                  />
                                </div>
                                <div className="form-group col-md-3">
                                  <Label className="ml-2" for="status">
                                    ادمین
                                  </Label>
                                  <Switch
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={15}
                                    width={28}
                                    onColor="#1fa2ff"
                                    onChange={adminHandleChange}
                                    checked={checkedAdmin}
                                  />
                                </div>
                              </div>
                              <DefaultButton
                                disabled={!filled}
                                onClick={() => editProfileUser()}
                                loading={loader}
                                variant="primary"
                              >
                                ویرایش اطلاعات
                              </DefaultButton>
                            </form>
                          ) : (
                            <div className="text-center">
                              <Spinner
                                size="lg"
                                className="mr-2"
                                animation="border"
                                variant="light"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      id="my-banks"
                      className={`tab-pane fade ${activeToggle === "banks" ? "active show" : ""
                        }`}
                    >
                      <div className="card-header border-0 pb-0">
                        {/* <h4 className="mb-0 fs-14 text-black">
                          لیست حساب های بانکی
                        </h4> */}
                      </div>
                      <div className="card-body px-0">
                        <div className="table-responsive">
                          {banksList?.length !== 0 ? (
                            <table className="table shadow-hover user-detail-bank card-table border-no tbl-btn short-one">
                              <tbody>
                                {userDetails.verifyAt === null ? (
                                  " "
                                ) : (
                                  <tr>
                                    <td className="text-center">
                                      <span className="text-black">
                                        شماره کارت
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <span className="text-black">
                                        شماره شبا
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <span className="text-black">برچسب</span>
                                    </td>
                                    <td className="text-center">
                                      <span className="text-black">وضعیت</span>
                                    </td>
                                    <td className="text-center">
                                      <span className="text-black">
                                        وضعیت تایید
                                      </span>
                                    </td>
                                  </tr>
                                )}

                                {banksList.map((data, index) => (
                                  <tr key={index}>
                                    <td className="text-center fs-11">
                                      {data.cardNo
                                        .toString()
                                        .replace(/\B(?=(\d{4})+(?!\d))/g, "-")}
                                    </td>
                                    <td className="text-center fs-11">
                                      <span className="font-w600 text-black">
                                        {data.shebaNo}
                                      </span>
                                    </td>
                                    <td className="text-center fs-11">
                                      <span className="text-black">
                                        {data.label}
                                      </span>
                                    </td>

                                    <td className="text-center text-center fs-11">
                                      {data.verifyAt ? (
                                        <CompleteLink />
                                      ) : (
                                        <PendingLink className="text-center d-block" />
                                      )}
                                    </td>
                                    <td className="p-0 text-center">
                                      {data.verifyAt ? (
                                        "---"
                                      ) : (
                                        <>
                                          <confirmBankAccount id={data?._id} />
                                          <Link
                                            to={"#"}
                                            onClick={() =>
                                              verifyAccount(data?.id)
                                            }
                                            className="btn-link text-info confirm_bank_account "
                                          >
                                            تایید حساب بانکی
                                          </Link>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="card-content bg-primary p-3 rounded text-center">
                              <span className="">
                                اطلاعاتی جهت نمایش وجود ندارد
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      id="wallet"
                      className={`tab-pane fade ${activeToggle === "wallets" ? "active show" : ""
                        }`}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-header border-0 pb-0">
                              {/* <h4 className="mb-0 fs-14 text-black">
                                لیست کیف پول ها
                              </h4> */}
                            </div>
                            <div className="card-body px-0">
                              {banksAccountsList?.length !== 0 ? (
                                <div className="row">
                                  {banksAccountsList?.map((item) => {
                                    return (
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-3">
                                        <div
                                          className={`${item.coin === "btc"
                                            ? "bg-warning"
                                            : item.coin === "eth"
                                              ? "bg-secondary"
                                              : item.coin === "trx"
                                                ? "bg-primary"
                                                : item.coin === "usdt"
                                                  ? "bg-success"
                                                  : ""
                                            }  coin-holding flex-wrap`}
                                        >
                                          <div className="mb-2 coin-bx">
                                            <div className="d-flex align-items-center">
                                              <div>
                                                {item.coin === "btc" ? (
                                                  <svg
                                                    width="50"
                                                    height="50"
                                                    viewBox="0 0 38 35"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M19.2744 18.8013H16.0334V23.616H19.2744C19.9286 23.616 20.5354 23.3506 20.9613 22.9053C21.4066 22.4784 21.672 21.8726 21.672 21.1989C21.673 19.8813 20.592 18.8013 19.2744 18.8013Z"
                                                      fill="white"
                                                    />
                                                    <path
                                                      d="M18 0C8.07429 0 0 8.07429 0 18C0 27.9257 8.07429 36 18 36C27.9257 36 36 27.9247 36 18C36 8.07531 27.9247 0 18 0ZM21.6627 26.3355H19.5398V29.6722H17.3129V26.3355H16.0899V29.6722H13.8528V26.3355H9.91954V24.2414H12.0898V11.6928H9.91954V9.59863H13.8528V6.3288H16.0899V9.59863H17.3129V6.3288H19.5398V9.59863H21.4735C22.5535 9.59863 23.5491 10.044 24.2599 10.7547C24.9706 11.4655 25.416 12.4611 25.416 13.5411C25.416 15.6549 23.7477 17.3798 21.6627 17.4744C24.1077 17.4744 26.0794 19.4647 26.0794 21.9096C26.0794 24.3453 24.1087 26.3355 21.6627 26.3355Z"
                                                      fill="white"
                                                    />
                                                    <path
                                                      d="M20.7062 15.8441C21.095 15.4553 21.3316 14.9338 21.3316 14.3465C21.3316 13.1812 20.3842 12.2328 19.2178 12.2328H16.0334V16.4695H19.2178C19.7959 16.4695 20.3266 16.2226 20.7062 15.8441Z"
                                                      fill="white"
                                                    />
                                                  </svg>
                                                ) : item.coin === "eth" ? (
                                                  <img
                                                    width={50}
                                                    src={Ethereum}
                                                  />
                                                ) : item.coin === "trx" ? (
                                                  <img width={50} src={Tron2} />
                                                ) : item.coin === "usdt" ? (
                                                  <img
                                                    width={50}
                                                    src={Tether2}
                                                  />
                                                ) : null}
                                              </div>
                                              <div className="mr-3">
                                                <h4 className="coin-font font-w600 mb-0 text-white">
                                                  {item.coin === "btc"
                                                    ? "بیت کوین"
                                                    : item.coin === "eth"
                                                      ? "اتریوم"
                                                      : item.coin === "trx"
                                                        ? "ترون"
                                                        : item.coin === "usdt"
                                                          ? "تتر"
                                                          : null}
                                                </h4>
                                                <p className="mb-0 text-white op-6">
                                                  {item.coin === "btc"
                                                    ? "BTC"
                                                    : item.coin === "eth"
                                                      ? "ETH"
                                                      : item.coin === "trx"
                                                        ? "TRX"
                                                        : item.coin === "usdt"
                                                          ? "USDT"
                                                          : null}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mb-2 d-block w-100">
                                            <div className="d-flex align-items-center mr-1 mt-3">
                                              <div className="coin-bx-one">
                                                آدرس کیف پول:{" "}
                                              </div>
                                              <div className="ml-3">
                                                <span className="mb-0 text-white mr-2 wrap_text">
                                                  {item.address}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mb-2 d-block w-100">
                                            <div className="d-flex align-items-center">
                                              <div className="coin-bx-one">
                                                برچسب:{" "}
                                              </div>
                                              <p className="mb-0 mr-2 font-w400 text-white wrap_anywhere">
                                                {item.label}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-2 d-block w-100">
                                            <div className="d-flex align-items-center">
                                              <div className="coin-bx-one">
                                                وضعیت:{" "}
                                              </div>
                                              <Badge
                                                as="a"
                                                href=""
                                                className="mr-2"
                                                variant={`${item.verifyAt
                                                  ? "success"
                                                  : "primary"
                                                  }`}
                                              >
                                                {item.verifyAt
                                                  ? "تایید شده"
                                                  : "در حال بررسی"}
                                              </Badge>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div className="card-content bg-primary p-3 rounded text-center">
                                  <span className="">
                                    اطلاعاتی جهت نمایش وجود ندارد
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="trades"
                      className={`tab-pane fade ${activeToggle === "trades" ? "active show" : ""
                        }`}
                    >
                      <UserTrades />
                    </div>
                    <div
                      id="openOrders"
                      className={`tab-pane fade ${activeToggle === "openOrders" ? "active show" : ""
                        }`}
                    >
                      <UserOpenOrders />
                    </div>
                    <div
                      id="bankTransaction"
                      className={`tab-pane fade ${activeToggle === "bankTransaction" ? "active show" : ""
                        }`}
                    >
                      <UserBankingTransactions />
                    </div>
                    <div
                      id="coinTransaction"
                      className={`tab-pane fade ${activeToggle === "coinTransaction" ? "active show" : ""
                        }`}
                    >
                      <UserCoiningTransactions />
                    </div>
                    <div
                      id="accountActivity"
                      className={`tab-pane fade ${activeToggle === "accountActivity" ? "active show" : ""
                        }`}
                    >
                      <UserAccountActivity />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-header">بلاک کردن دسترسی های کاربر</div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <p className="blocked_title">واریز</p>
                  <Switch
                    width={40}
                    height={22}
                    onChange={handleChangeDeposit}
                    checked={deposit}
                    className="react-switch"
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <p className="blocked_title">برداشت</p>
                  <Switch
                    width={40}
                    height={22}
                    onChange={handleChangeWithdraw}
                    checked={withdraw}
                    className="react-switch"
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <p className="blocked_title">ثبت سفارش</p>
                  <Switch
                    width={40}
                    height={22}
                    onChange={handleChangeOrder}
                    checked={order}
                    className="react-switch"
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <p className="blocked_title"> سفارش سریع (OTC)</p>
                  <Switch
                    width={40}
                    height={22}
                    onChange={handleChangeOtc}
                    checked={otc}
                    className="react-switch"
                  />
                </div>
              </div>
              <DefaultButton
                onClick={() => setBlockedItems()}
                loading={blockLoader}
                variant="primary"
                className="mt-4"
              >
                ثبت اطلاعات
              </DefaultButton>
            </div>
          </div>
        </div>
        {allTraces?.length !== 0 ? (
          <div className="col-12">
            <div className="card">
              <div
                style={{ background: "#5b5288" }}
                className="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
              ></div>
              <div className="card-body tab-content p-0">
                <div className="table-responsive">
                  <table className="table shadow-hover card-table border-no tbl-btn short-one">
                    {allTraces?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          // style={{ width: 73 }}
                          >
                            نوع ارز
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          // style={{ width: 73 }}
                          >
                            تاریخ
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          // style={{ width: 73 }}
                          >
                            ساعت
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                          // style={{ width: 100 }}
                          >
                            موجودی قبلی
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                          // style={{ width: 100 }}
                          >
                            موجودی فعلی
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          // style={{ width: 120 }}
                          >
                            میزان تغییر
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          // style={{ width: 120 }}
                          >
                            کارمزد سایت
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          // style={{ width: 120 }}
                          >
                            دلیل تغییر
                          </th>
                          <th
                            className="sorting fs-12 text-center font-weight-light"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          // style={{ width: 120 }}
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {allTraces?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-center fs-16" scope="row">
                              {item?.currency !== "irt" ? (
                                <img
                                  className="cc_size ml-2"
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src = "./images/coins/noImg.png";
                                  // }}
                                  src={
                                    process.env.REACT_APP_SOCKET_URL +
                                    `assets/icon/${item?.currency?.toLowerCase()}.png`
                                  }
                                />
                              ) : null}

                              <span>
                                {item?.currency?.toUpperCase()}{" "}
                                <span className="fs-11">
                                  {item.currency === "irt" ? " (تومان) " : ""}
                                </span>
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-black">
                                {" "}
                                {new Date(item.createdAt).toLocaleDateString(
                                  "fa-IR"
                                )}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-black">
                                {" "}
                                {new Date(item.createdAt).toLocaleTimeString(
                                  "fa-IR"
                                )}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="font-w100 text-black">
                                {item.currency === "irt"
                                  ? item.balance
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : item.balance?.toFixed(6)}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="font-w100 text-black">
                                {item.currency === "irt"
                                  ? item.balanceNew
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : item.balanceNew?.toFixed(6)}
                              </span>
                            </td>
                            <td className="text-center">
                              {item.balanceNew > item.balance ? (
                                <Link
                                  to={"#"}
                                  className="btn btn-outline-success ltr_direction"
                                >
                                  +{" "}
                                  {item.currency === "irt"
                                    ? item.volume
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : item.volume?.toFixed(6)}
                                </Link>
                              ) : item.balanceNew < item.balance ? (
                                <Link
                                  to={"#"}
                                  className="btn btn-outline-danger ltr_direction"
                                >
                                  -{" "}
                                  {item.currency === "irt"
                                    ? item.volume
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : item.volume?.toFixed(6)}
                                  {item.currency === "irt" ? null : (
                                    <img
                                      className="cc_size ml-2"
                                      // onError={(e) => {
                                      //   e.target.onerror = null;
                                      //   e.target.src =
                                      //     "./images/coins/noImg.png";
                                      // }}
                                      src={
                                        process.env.REACT_APP_SOCKET_URL +
                                        `assets/icon/${item?.currency?.toLowerCase()}.png`
                                      }
                                    />
                                  )}
                                </Link>
                              ) : (
                                <Link
                                  to={"#"}
                                  className="btn btn-outline-light ltr_direction"
                                >
                                  {item.currency === "irt"
                                    ? item.volume
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : item.volume?.toFixed(6)}
                                  {item.currency === "irt" ? null : (
                                    <img
                                      className="cc_size ml-2"
                                      // onError={(e) => {
                                      //   e.target.onerror = null;
                                      //   e.target.src =
                                      //     "./images/coins/noImg.png";
                                      // }}
                                      src={
                                        process.env.REACT_APP_SOCKET_URL +
                                        `assets/icon/${item?.currency?.toLowerCase()}.png`
                                      }
                                    />
                                  )}
                                </Link>
                              )}
                            </td>
                            <td className="text-center">
                              <span className={`font-w100 text-black`}>
                                {item.wage} %
                              </span>
                            </td>
                            <td className="text-center">
                              <span className={`font-w100 text-black`}>
                                {item.cause.label}
                              </span>
                            </td>
                            <td className="py-2 text-center">
                              {" "}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="primary"
                                  className="table-dropdown i-false btn tp-btn-light sharp"
                                >
                                  <span className="fs--1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18px"
                                      height="18px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <circle
                                          fill="#000000"
                                          cx="5"
                                          cy="12"
                                          r="2"
                                        ></circle>
                                        <circle
                                          fill="#000000"
                                          cx="12"
                                          cy="12"
                                          r="2"
                                        ></circle>
                                        <circle
                                          fill="#000000"
                                          cx="19"
                                          cy="12"
                                          r="2"
                                        ></circle>
                                      </g>
                                    </svg>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    className="text-danger text-right"
                                  >
                                    <Link
                                      to={"#"}
                                      className="w-100 text-secondary fs-11 text-right"
                                      onClick={() =>
                                        openDetailModal(item.cause)
                                      }
                                    >
                                      جزئیات بیشتر
                                    </Link>
                                    <Modal
                                      className="fade"
                                      show={detailModal}
                                      onHide={setDetailModal}
                                      size={"lg"}
                                    >
                                      <Modal.Header>
                                        <h4 className="text-black">
                                          دلیل تغییر موجودی کاربر
                                        </h4>
                                        <Button
                                          variant=""
                                          className="close"
                                          onClick={() => setDetailModal(false)}
                                        >
                                          <span>&times;</span>
                                        </Button>
                                      </Modal.Header>
                                      {/* TODO: user details modal */}
                                      <Modal.Body>
                                        {traceType === "admin" && (
                                          traceDetail?.firstName && (
                                            <table className="table shadow-hover card-table border-no tbl-btn short-one">
                                              <thead>
                                                <tr
                                                  className="text-center"
                                                  role="row"
                                                >
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient ID: activate to sort column ascending"
                                                  // style={{ width: 73 }}
                                                  ></th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient ID: activate to sort column ascending"
                                                  // style={{ width: 73 }}
                                                  >
                                                    نام و نام خانوادگی
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient ID: activate to sort column ascending"
                                                  // style={{ width: 73 }}
                                                  >
                                                    شماره موبایل
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Date Check in: activate to sort column ascending"
                                                  // style={{ width: 100 }}
                                                  >
                                                    نقش
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient Name: activate to sort column ascending"
                                                  // style={{ width: 100 }}
                                                  >
                                                    ایمیل
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className="text-center font-w100">
                                                    <div className="img_cont">
                                                      <img
                                                        width={50}
                                                        src={
                                                          traceDetail?.avatar
                                                        }
                                                        className="rounded-circle user_img"
                                                        alt=""
                                                      />
                                                      <span className="online_icon"></span>
                                                    </div>
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {traceDetail?.firstName}{" "}
                                                    {traceDetail?.lastName}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {traceDetail.mobile}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {traceDetail.role}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {traceDetail.email}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          )
                                        )}
                                        {traceType === "order" && (
                                          traceDetail?.userId && (
                                            <table className="table shadow-hover card-table border-no tbl-btn short-one">
                                              <thead>
                                                <tr
                                                  className="text-center"
                                                  role="row"
                                                >
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient ID: activate to sort column ascending"
                                                  // style={{ width: 73 }}
                                                  >
                                                    بازار
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient ID: activate to sort column ascending"
                                                  // style={{ width: 73 }}
                                                  >
                                                    مقدار
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Date Check in: activate to sort column ascending"
                                                  // style={{ width: 100 }}
                                                  >
                                                    قیمت واحد
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient Name: activate to sort column ascending"
                                                  >
                                                    قیمت کل
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient Name: activate to sort column ascending"
                                                  >
                                                    نوع تراکنش
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient Name: activate to sort column ascending"
                                                  >
                                                    تاریخ
                                                  </th>
                                                  <th
                                                    className="sorting fs-12 text-center font-weight-light"
                                                    tabIndex={0}
                                                    aria-controls="example5"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Patient Name: activate to sort column ascending"
                                                  >
                                                    ساعت
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td className="text-center font-w100">
                                                    <img
                                                      className="cc_size ml-2"
                                                      src={
                                                        process.env
                                                          .REACT_APP_SOCKET_URL +
                                                        `assets/icon/${traceDetail?.coin?.toLowerCase()}.png`
                                                      }
                                                    />
                                                    {traceDetail?.coin?.toUpperCase()}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {traceDetail.amount}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {Math.round(
                                                      traceDetail?.priceUnit
                                                    )
                                                      ?.toString()
                                                      ?.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {Math.round(
                                                      traceDetail?.price
                                                    )
                                                      ?.toString()
                                                      ?.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </td>
                                                  <td className="text-center font-w100">
                                                    {traceDetail?.type ===
                                                      "buy" ? (
                                                      <span
                                                        className={`text-center fs-11 ${traceDetail?.type ===
                                                          "buy"
                                                          ? "badge_success"
                                                          : traceDetail?.type ===
                                                            "sell"
                                                            ? "badge_danger"
                                                            : null
                                                          }`}
                                                      >
                                                        خرید
                                                      </span>
                                                    ) : traceDetail?.type ===
                                                      "sell" ? (
                                                      <span
                                                        className={`text-center fs-11 ${traceDetail?.type ===
                                                          "buy"
                                                          ? "badge_success"
                                                          : traceDetail?.type ===
                                                            "sell"
                                                            ? "badge_danger"
                                                            : null
                                                          }`}
                                                      >
                                                        فروش
                                                      </span>
                                                    ) : null}
                                                  </td>{" "}
                                                  <td className="text-center font-w100">
                                                    {new Date(
                                                      traceDetail.createdAt
                                                    )?.toLocaleDateString(
                                                      "fa-IR"
                                                    )}
                                                  </td>{" "}
                                                  <td className="text-center font-w100">
                                                    {new Date(
                                                      traceDetail.createdAt
                                                    )?.toLocaleTimeString(
                                                      "fa-IR"
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          )
                                        )}
                                        {traceType === "otc" && (
                                          <></>
                                        )}
                                        {
                                          !traceDetail &&
                                          <div style={{ textAlign: "center" }}>
                                            اطلاعاتی جهت نمابش وجود ندارد
                                          </div>
                                        }
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          className="mr-2"
                                          onClick={() => setDetailModal(false)}
                                          variant="danger light"
                                        >
                                          بستن
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                  </table>
                  <div className="d-sm-flex mx-3 text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {tracesMeta?.page * tracesMeta?.limit -
                        tracesMeta?.limit +
                        1}{" "}
                      تا {tracesMeta?.page * tracesMeta?.limit} از{" "}
                      {tracesMeta?.total} تراکنش
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={tracesMeta?.limit}
                          totalItemsCount={tracesMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer border-0 p-0 caret mt-1">
                <Link className="btn-link">
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-4">
          <div className="card h-auto">
            {/* <img className="w-100" src={userDetails?.meliCard} /> */}
            {userDetails?.meliCard ? (
              <div className="card-body p-2">
                <div
                  className="user_img_style"
                  style={{ cursor: "pointer" }}
                  onClick={() => imgOpen(0)}
                >
                  <img className="w-100" src={userDetails?.meliCard} />
                  {isOpen && (
                    <Lightbox
                      imageCaption={captions[photoIndex]}
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      clickOutsideToClose={true}
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}
                  <DefaultButton
                    className="w-100 mt-2 fs-12"
                    onClick={() => removeMeliCard(userDetails?._id)}
                    loading={sendLoader}
                    variant="primary light"
                  >
                    حذف کارت ملی
                  </DefaultButton>
                </div>
              </div>
            ) : (
              <div className="card-content bg-primary p-3 rounded text-center">
                <span className="">بدون تصویر کارت ملی</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-4">
          <div className="card h-auto">
            {/* <img className="w-100" src={userDetails?.meliCard} /> */}
            {userDetails?.selfi ? (
              <div className="card-body p-2">
                <div
                  style={{ cursor: "pointer" }}
                  className="user_img_style"
                  onClick={() => selfieOpen(0)}
                >
                  <img className="w-100" src={userDetails?.selfi} />
                  <DefaultButton
                    className="w-100 mt-2 fs-12"
                    onClick={() => removeSelfie(userDetails?._id)}
                    loading={sendLoader}
                    variant="primary light"
                  >
                    حذف تصویر سلفی
                  </DefaultButton>
                  {selfieIsOpen && (
                    <Lightbox
                      imageCaption={selfieCaptions[selfiePhotoIndex]}
                      mainSrc={selfieImages[selfiePhotoIndex]}
                      nextSrc={
                        selfieImages[
                        (selfiePhotoIndex + 1) % selfieImages.length
                        ]
                      }
                      prevSrc={
                        selfieImages[
                        (selfiePhotoIndex + selfieImages.length - 1) %
                        selfieImages.length
                        ]
                      }
                      onCloseRequest={() => setSelfieIsOpen(false)}
                      onMovePrevRequest={() =>
                        setSelfiePhotoIndex(
                          (selfiePhotoIndex + selfieImages.length - 1) %
                          selfieImages.length
                        )
                      }
                      clickOutsideToClose={true}
                      onMoveNextRequest={() =>
                        setSelfiePhotoIndex(
                          (selfiePhotoIndex + 1) % selfieImages.length
                        )
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="card-content bg-primary p-3 rounded text-center">
                <span className="">بدون تصویر سلفی</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-4">
          <div className="card h-auto">
            {userDetails?.balance ? (
              <div className="card-body">
                {/* <img className="w-100" src={userDetails?.meliCard} /> */}
                <div className="mt-3">
                  <span className="">موجودی: </span>
                  <span className="pull-left">
                    {userDetails?.balance
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {"تومان"}
                  </span>
                </div>{" "}
                <div className="mt-2">
                  <span>موجودی بلاک شده: </span>
                  <span className="pull-left">
                    {userDetails.balanceBlocked
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {"تومان"}
                  </span>
                </div>
              </div>
            ) : (
              <div className="card-content bg-primary p-3 rounded text-center">
                <span className="">بدون موجودی</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        {userDetails?.coins ? (
          userDetails?.coins?.map((item, idx) => {
            return (
              <div className="col-xl-3 ltr_direction" key={idx}>
                <div className="card h-auto">
                  <div className="card-body pb-0 mx-0">
                    <div className="card card-coin mb-0">
                      <div className="card-body text-center px-4">
                        <img
                          className="coin_svg_dashboard"
                          // onError={(e) => {
                          //   e.target.onerror = null;
                          //   e.target.src = "./images/coins/noImg.png";
                          // }}
                          src={
                            process.env.REACT_APP_SOCKET_URL +
                            `assets/icon/${item?.coin?.toLowerCase()}.png`
                          }
                        />

                        <h2 className="text-black font-w600 mb-3">
                          {/* {() => getPersianName(item?.coin)} */}
                          {item?.coin?.toUpperCase()}
                        </h2>
                        <p className="text-center mb-4 fs-14">
                          <span className="d-block font-w600 mb-1 fs-14">
                            مقدار
                          </span>
                          <span className="mr-1 fs-12">
                            {item?.amount?.toFixed(8)}
                          </span>
                        </p>
                        <p className="text-center fs-14">
                          <span className="d-block font-w600 mb-1 fs-12">
                            مقدار بلاک شده
                          </span>
                          <span className="ml-1 fs-14">
                            {item?.amountBlocked?.toFixed(8)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center">
            <Spinner
              size="lg"
              className="mr-2"
              animation="border"
              variant="light"
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UserDetail);
