export const panelConstants = {
  USER_PANEL: "USER_PANEL",
  USER_TRADES: "USER_TRADES",
  USER_ORDERS: "USER_ORDERS",
};

export const Orders = {
  ORDER_DETAILS_REQUEST: "ORDER_DETAILS_REQUEST",
  ORDER_DETAILS_SUCCESS: "ORDER_DETAILS_SUCCESS",
  ORDER_DETAILS_FAIL: "ORDER_DETAILS_FAIL",
};

export const Coining = {
  //GET AVALIBLE BANKS
  GET_AVALIBLE_BANKS_REQUSET: "GET_AVALIBLE_BANKS_REQUSET",
  GET_AVALIBLE_BANKS_SUCCESS: "GET_AVALIBLE_BANKS_SUCCESS",
  GET_AVALIBLE_BANKS_FAIL: "GET_AVALIBLE_BANKS_FAIL",
  //GET AVALIBLE COINS
  GET_AVALIBLE_COINS_REQUSET: "GET_AVALIBLE_COINS_REQUSET",
  GET_AVALIBLE_COINS_SUCCESS: "GET_AVALIBLE_COINS_SUCCESS",
  GET_AVALIBLE_COINS_FAIL: "GET_AVALIBLE_COINS_FAIL",

  //GET AVALIBLE WAGES
  GET_AVALIBLE_WAGES_REQUSET: "GET_AVALIBLE_WAGES_REQUSET",
  GET_AVALIBLE_WAGES_SUCCESS: "GET_AVALIBLE_WAGES_SUCCESS",
  GET_AVALIBLE_WAGES_FAIL: "GET_AVALIBLE_WAGES_FAIL",
};
