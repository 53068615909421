import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import pMinDelay from "p-min-delay";
import { usersAction } from "../../../_actions";
import { connect } from "react-redux";
import { ERROR } from "../../../_helper/alerts";
import Pagination from "react-js-pagination";

const UserTrades = ({ dispatch }) => {
  const route = useParams();
  const { id } = route;
  const [userTrades, setUserTrades] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [hasTrade, setHasTrade] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "trade") setHasTrade(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const getAllOrders = () => {
    const userId = id;
    if (hasTrade || localStorage.getItem("role") === "admin") {
      dispatch(usersAction.getAllUserOrders({ page, limit, userId }))
        .then((r) => {
          setUserTrades(r?.data?.data);
          setUsersMeta(r.data?.meta);
          console.log(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  useEffect(() => {
    getAllOrders();
  }, [hasTrade]);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    const userId = id;

    if (hasTrade || localStorage.getItem("role") === "admin") {
      dispatch(
        usersAction.getAllUserOrders({
          page,
          limit,
          userId,
        })
      )
        .then((r) => {
          setUserTrades(r?.data?.data);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }

  return (
    <div className="card-body px-0">
      <div className="table-responsive">
        {userTrades?.length !== 0 ? (
          <div>
            <table className="table shadow-hover user-detail-bank card-table border-no tbl-btn short-one">
              <thead>
                <tr>
                  <th className={`text-center fs-10`} scope="col">
                    بازار
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    مقدار
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    قیمت واحد(تومان)
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    قیمت کل(تومان)
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    نوع تراکنش
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    تاریخ
                  </th>
                  <th className={`text-center fs-10`} scope="col">
                    ساعت
                  </th>
                </tr>
              </thead>
              <tbody>
                {userTrades?.map((item, idx) => {
                  return (
                    <tr className="line_hight10" key={idx}>
                      <td className="text-center fs-11" scope="row">
                        <img
                          className="cc_size ml-2"
                          src={
                            process.env.REACT_APP_SOCKET_URL +
                            `assets/icon/${item?.order?.coin?.toLowerCase()}.png`
                          }
                        />
                        {item?.order?.coin?.toUpperCase()}
                      </td>
                      <td
                        className={`fs-11 text-center ${
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.type === "buy"
                              ? "text-success"
                              : item?.order?.type === "sell"
                              ? "text-danger"
                              : null
                            : item?.order?.type === "buy"
                            ? "text-danger"
                            : item?.order?.type === "sell"
                            ? "text-success"
                            : null
                        }`}
                      >
                        {item?.order?.userId === localStorage.getItem("userId")
                          ? item?.order?.amount
                          : item?.amount}
                      </td>
                      <td
                        className={`fs-11 text-center ${
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.type === "buy"
                              ? "text-success"
                              : item?.order?.type === "sell"
                              ? "text-danger"
                              : null
                            : item?.order?.type === "buy"
                            ? "text-danger"
                            : item?.order?.type === "sell"
                            ? "text-success"
                            : null
                        }`}
                      >
                        {Math.round(
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.priceUnit
                            : item?.priceUnit
                        )
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>

                      <td
                        className={`text-center fs-11 ${
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.type === "buy"
                              ? "text-success"
                              : item?.order?.type === "sell"
                              ? "text-danger"
                              : null
                            : item?.order?.type === "buy"
                            ? "text-danger"
                            : item?.order?.type === "sell"
                            ? "text-success"
                            : null
                        }`}
                      >
                        {Math.round(
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.price
                            : item?.price
                        )
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td className={`fs-11 text-center`}>
                        {item?.order?.userId ===
                        localStorage.getItem("userId") ? (
                          item?.order?.type === "buy" ? (
                            <span
                              className={`text-center ${
                                item?.order?.type === "buy"
                                  ? "badge_success"
                                  : item?.order?.type === "sell"
                                  ? "badge_danger"
                                  : null
                              }`}
                            >
                              خرید
                            </span>
                          ) : item?.order?.type === "sell" ? (
                            <span
                              className={`fs-11 text-center ${
                                item?.order?.type === "buy"
                                  ? "badge_success"
                                  : item?.order?.type === "sell"
                                  ? "badge_danger"
                                  : null
                              }`}
                            >
                              فروش
                            </span>
                          ) : null
                        ) : item?.order?.type === "buy" ? (
                          <span
                            className={`fs-11 text-center ${
                              item?.order?.type === "buy"
                                ? "badge_danger"
                                : item?.order?.type === "sell"
                                ? "badge_success"
                                : null
                            }`}
                          >
                            فروش
                          </span>
                        ) : item?.order?.type === "sell" ? (
                          <span
                            className={`fs-11 text-center ${
                              item?.order?.type === "buy"
                                ? "badge_danger"
                                : item?.order?.type === "sell"
                                ? "badge_success"
                                : null
                            }`}
                          >
                            خرید
                          </span>
                        ) : null}
                      </td>
                      <td className="fs-11 text-center">
                        {new Date(
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.createdAt
                            : item?.createdAt
                        )?.toLocaleDateString("fa-IR")}
                      </td>
                      <td className="fs-11 text-center">
                        {new Date(
                          item?.order?.userId === localStorage.getItem("userId")
                            ? item?.order?.createdAt
                            : item?.createdAt
                        )?.toLocaleTimeString("fa-IR")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="card-content bg-primary p-3 rounded text-center">
            <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
          </div>
        )}
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">
            نمایش {usersMeta?.page * usersMeta?.limit - usersMeta?.limit + 1} تا{" "}
            {usersMeta?.page * usersMeta?.limit} از {usersMeta?.total} معامله
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example5_paginate"
          >
            <div className="d-inline-block pb-3 ltr_direction">
              <Pagination
                activePage={page}
                itemsCountPerPage={usersMeta?.limit}
                totalItemsCount={usersMeta?.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UserTrades);
