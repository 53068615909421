import { httpRequest } from "./../_helper";

function getBankingTransactionList({
  page,
  limit,
  verifyAt,
  searchName,
  searchFamily,
  searchEmail,
  searchMobile,
}) {
  return () => {
    return httpRequest(
      "get",
      `banking?page=${page}&limit=${limit}&sort=createdAt&order=-1&search=${
        searchName ? `firstName` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `lastName` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `email` : ""}${
        (searchFamily && searchMobile) ||
        (searchMobile && searchEmail) ||
        (searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `mobile` : ""}&query=${
        searchName ? `${searchName}` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `${searchFamily}` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `${searchEmail}` : ""}${
        (searchFamily && searchMobile) ||
        (searchName && searchMobile) ||
        (searchEmail && searchMobile) ||
        (searchEmail && searchName && searchMobile) ||
        (searchEmail && searchName && searchFamily) ||
        (searchFamily && searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `${searchMobile}` : ""}`
    );
  };
}


function getCoiningTransactionList({
  page,
  limit,
  verifyAt,
  searchName,
  searchFamily,
  searchEmail,
  searchMobile,
}) {
  return () => {
    return httpRequest(
      "get",
      `coining?page=${page}&limit=${limit}&sort=createdAt&order=-1&search=${
        searchName ? `firstName` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `lastName` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `email` : ""}${
        (searchFamily && searchMobile) ||
        (searchMobile && searchEmail) ||
        (searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `mobile` : ""}&query=${
        searchName ? `${searchName}` : ""
      }${searchName && searchFamily ? `,` : ""}${
        searchFamily ? `${searchFamily}` : ""
      }${
        (searchFamily && searchEmail) || (searchName && searchEmail) ? `,` : ""
      }${searchEmail ? `${searchEmail}` : ""}${
        (searchFamily && searchMobile) ||
        (searchName && searchMobile) ||
        (searchEmail && searchMobile) ||
        (searchEmail && searchName && searchMobile) ||
        (searchEmail && searchName && searchFamily) ||
        (searchFamily && searchName && searchMobile)
          ? `,`
          : ""
      }${searchMobile ? `${searchMobile}` : ""}`
    );
  };
}

export const transactionAction = {
  getBankingTransactionList,
  getCoiningTransactionList
};

