import React, { Fragment, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Label, FormGroup, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import { usersAction, rolesAction } from "../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { BaseInput } from "../Defaults/BaseInput";
import { NumberInput } from "../Defaults/NumberInput";
import { isValidEmail } from "../Validation/checkEmail";
import { isValidNationalCode } from "../Validation//checkNationalCode";
import { DefaultButton } from "../Defaults/DefaultButton";
import FileUploader from "./FileUploader";
import file from "../../../images/svg/undraw_add_file2_gvbb.svg";
const CreateUser = ({ dispatch }) => {
  const route = useParams();
  const { id } = route;
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tel, setTel] = useState("");
  const [mobile, setMobile] = useState("");
  const [nationalCode, setNationalCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [nationalCodeError, setNationalCodeError] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [countyErr, setCountyErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [filled, setFilled] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [roleVal, setRoleVal] = useState({});
  const [permissions, setPermissions] = useState([]);
  //File Uploader
  const [files, setFiles] = useState();
  const [img, setImg] = useState();
  //file upload functions and  //upload img
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [addFiles, setAddFiles] = useState();
  //useEffect

  useEffect(() => {
    dispatch(rolesAction.getAllRoles({ page, limit }))
      .then((r) => {
        // setRolesList(r.data?.data);
        setPermissions(r.data?.data);
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      )
      .finally(() => {
        // setIsLoading(false);
      });
  }, []);

  const rolesOptions = permissions?.map((item) => {
    return {
      value: item._id,
      label: item.name,
    };
    // return {
    //   value: item?.permissions?.filter((x) => {
    //     if (x.activity.write) return item._id;
    //   }),
    //   label: item?.permissions?.filter((x) => {
    //     if (x.activity.write) return item.name;
    //   }),
    // };
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  const handleChangeRole = (selected) => {
    setRoleVal(selected);
  };

  useEffect(() => {
    if (isValidEmail(email) || email === "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [email]);

  useEffect(() => {
    if (email === "" || email === undefined) setEmailError(false);
    else setEmailError(true);

    if (nationalCode === "" || nationalCode === undefined)
      setNationalCodeError(false);
    else setNationalCodeError(true);
  });

  useEffect(() => {
    if (isValidNationalCode(nationalCode) === false && nationalCode !== "") {
      setNationalCodeError(true);
    } else {
      setNationalCodeError(false);
    }
  }, [nationalCode]);

  useEffect(() => {
    if (
      firstName === "" ||
      firstName === null ||
      lastName === "" ||
      lastName === null ||
      nationalCode === null ||
      email === null ||
      tel === null ||
      county === null ||
      city === null ||
      zip === null ||
      address === null ||
      mobile === null ||
      nationalCode === "" ||
      email === "" ||
      tel === "" ||
      county === "" ||
      city === "" ||
      zip === "" ||
      address === "" ||
      mobile === ""
    ) {
      setFilled(false);
    } else if (
      firstName !== "" ||
      firstName !== null ||
      lastName !== "" ||
      lastName !== null ||
      nationalCode !== null ||
      email !== null ||
      tel !== null ||
      county !== null ||
      city !== null ||
      zip !== null ||
      address !== null ||
      mobile !== null ||
      nationalCode !== "" ||
      email !== "" ||
      tel !== "" ||
      county !== "" ||
      city !== "" ||
      zip !== "" ||
      address !== "" ||
      mobile !== ""
    ) {
      setFilled(true);
    }
  }, [
    firstName,
    lastName,
    email,
    nationalCode,
    email,
    tel,
    county,
    city,
    zip,
    address,
    mobile,
  ]);

  useEffect(() => {
    if (firstName?.length < 30 && firstName !== "" && firstName !== null) {
      setFirstNameErr(false);
    }
    if (lastName?.length <= 30 && lastName !== "" && lastName !== null) {
      setLastNameErr(false);
    }
    if (
      tel?.replace("-", "").replace(" ", "")?.length === 11 ||
      tel !== "" ||
      tel !== null
    ) {
      setPhoneErr(false);
    }
    if (city?.length <= 30 && city !== "" && city !== null) {
      setCityErr(false);
    }
    if (county?.length <= 30 && county !== "" && county !== null) {
      setCountyErr(false);
    }
    if (address?.length <= 255 && address !== "" && address !== null) {
      setAddressErr(false);
    }
  }, [
    firstName,
    lastName,
    email,
    nationalCode,
    tel,
    county,
    city,
    zip,
    address,
    mobile,
  ]);
  const file = new FormData();
  let result = {
    county: county,
    city: city,
    line: address,
    zip: zip,
  };
  async function editProfileUser() {
    file.append("files", files?.[0]);
    file.append("firstName", firstName);
    file.append("lastName", lastName);
    file.append("meliCode", nationalCode);
    file.append("mobile", mobile);
    file.append("phone", `${tel?.replace("-", "")}`);
    file.append("email", email);
    file.append("role", roleVal?.value);

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      meliCode: nationalCode,
      mobile: mobile,
      phone: `${tel?.replace("-", "")}`,
      address: {
        county: county,
        zip: zip,
        city: city,
        line: address,
      },

      role: roleVal?.value,
    };
    // var blob = new Blob([result], { type: "text/xml" });

    // file.append("address", blob, "{}");

    // Object.keys(result).forEach((key) => file.append(key, result[key]));

    setLoader(true);
    dispatch(usersAction.createUsers(data))
      .then((r) => {
        SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
    // }
  }

  const onChangeEmail = (evt) => {
    setEmail(evt.target.value);
  };
  const onChangeFirstName = (evt) => {
    setFirstName(evt.target.value);
  };
  const onChangeLastName = (evt) => {
    setLastName(evt.target.value);
  };
  const onChangeAddress = (evt) => {
    setAddress(evt.target.value);
  };
  const onChangeCounty = (evt) => {
    setCounty(evt.target.value);
  };
  const onChangeCity = (evt) => {
    setCity(evt.target.value);
  };
  const onChangeNationalCode = (evt) => {
    if (evt.value) {
      setNationalCode(evt.value);
    }
  };
  const onChangeTel = (evt) => {
    if (evt.value) {
      setTel(evt.value);
    }
  };
  const onChangeZip = (evt) => {
    if (evt.value) {
      setZip(evt.value);
    }
  };
  const onChangeMobile = (evt) => {
    if (evt.value) {
      setMobile(evt.value);
    }
  };
  return (
    <Fragment>
      <PageTitle activeMenu="ساخت کاربر جدید" motherMenu="کاربران" />
      <h3 className="mb-2 fs-14">{/* {firstName} {lastName} */}</h3>
      <div className="row">
        <div className="col-12">
          <div className="card h-auto">
            <div className="card-body">
              <div className="pt-3">
                <div className="settings-form">
                  {userDetails?.length !== 0 ? (
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <FormGroup className="form-group mb-0 row mx-1">
                            <Label for="status">نقش</Label>
                            <Select
                              styles={customStyles}
                              className={`react-select w-100 text-left`}
                              classNamePrefix="react-select"
                              placeholder={"انتخاب کنید"}
                              value={roleVal}
                              onChange={handleChangeRole}
                              options={rolesOptions}
                              instanceId="roleVal"
                              name="roleVal"
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <BaseInput
                            className="form-control text-right"
                            id="firstName"
                            value={firstName}
                            onChange={onChangeFirstName}
                            label={"نام"}
                            error={firstNameErr}
                            errorText="طول پارامتر نام نمی تواند بیشتر از 30 کاراکتر باشد"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <BaseInput
                            className="form-control text-right"
                            id="lastName"
                            value={lastName}
                            onChange={onChangeLastName}
                            label={"نام خانوادگی"}
                            error={lastNameErr}
                            errorText="طول پارامتر نام خانوادگی نمی تواند بیشتر از 50 کاراکتر باشد"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <BaseInput
                            className="form-control text-left ltr_direction"
                            id="email"
                            value={email}
                            onChange={onChangeEmail}
                            label={"ایمیل"}
                            error={emailError}
                            // errorText="فرمت ایمیل صحیح نیست"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <NumberInput
                            className="form-control text-left ltr_direction"
                            id="nationalCode"
                            value={nationalCode}
                            allowLeadingZeros={true}
                            format="##########"
                            onValueChange={onChangeNationalCode}
                            label={"کد ملی"}
                            error={nationalCodeError}
                            // errorText="فرمت کد ملی صحیح نیست"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <NumberInput
                            className="form-control text-left ltr_direction"
                            id="tel"
                            value={tel}
                            allowLeadingZeros={true}
                            format="###-########"
                            onValueChange={onChangeTel}
                            placeholder={"021"}
                            label={"تلفن"}
                            error={phoneErr}
                            errorText="طول شماره تلفن نباید کمتر از 11 رقم باشد.(همراه کد شهر)"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <NumberInput
                            className="form-control text-left ltr_direction"
                            id="mobile"
                            value={mobile}
                            format="###########"
                            allowLeadingZeros={true}
                            onValueChange={onChangeMobile}
                            label={"تلفن همراه"}
                            placeholder="09---------"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <NumberInput
                            className="form-control text-left ltr_direction"
                            id="zip"
                            value={zip}
                            allowLeadingZeros={true}
                            format="##########"
                            onValueChange={onChangeZip}
                            label={"کد پستی"}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <BaseInput
                            className="form-control text-right"
                            id="county"
                            value={county}
                            onChange={onChangeCounty}
                            label={"استان"}
                            error={countyErr}
                            errorText="طول پارامتر استان نمی تواند بیشتر از 30 کاراکتر باشد"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <BaseInput
                            className="form-control text-right"
                            id="city"
                            value={city}
                            onChange={onChangeCity}
                            label={"شهر"}
                            error={cityErr}
                            errorText="طول پارامتر شهر نمی تواند بیشتر از 30 کاراکتر باشد"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <BaseInput
                            className="form-control text-right"
                            id="address"
                            value={address}
                            onChange={onChangeAddress}
                            label={"آدرس"}
                            error={addressErr}
                            errorText="طول پارامتر آدرس نمی تواند بیشتر از 255 کاراکتر باشد"
                          />
                        </div>
                      </div>
                      <div className="cursor-pointer position-relative">
                        {/* <FileUploader
                          src={file}
                          onFileUpload={setFiles}
                          uploadMultiple={false}
                          showPreview={true}
                          title={"انتخاب تصویر پروفایل"}
                          description={"در صورت نیاز به تغییر تصویر"}
                        /> */}
                      </div>
                      <div className="text-center mt-4">
                        <DefaultButton
                          disabled={!filled}
                          onClick={() => editProfileUser()}
                          loading={loader}
                          variant="primary"
                        >
                          ویرایش اطلاعات
                        </DefaultButton>
                      </div>
                    </form>
                  ) : (
                    <div className="text-center mt-4">
                      <Spinner
                        size="lg"
                        className="mr-2"
                        animation="border"
                        variant="light"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(CreateUser);
