import React, { useEffect } from "react";
import axios from "axios";
import { ERROR } from "./_helper/alerts";

/// Components
import Markup from "./jsx";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import { withResizeDetector } from "react-resize-detector";

import ThemeContextProvider from "./context/ThemeContext";

const App = ({ width }) => {
  const body = document.querySelector("body");

  return (
    <ThemeContextProvider>
      <Markup />
    </ThemeContextProvider>
  );
};

export default withResizeDetector(App);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error === "Cannot read property 'status' of undefined") {
      ERROR("سایت در حال حاضر در دسترس نیست");
    } else if (401 === error.response.status) {
      localStorage.removeItem("token");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("balance");
      localStorage.removeItem("email");
      localStorage.removeItem("avatar");
      if (process.browser) {
        window.location = "/page-login";
      }
    } else if (403 === error.response.status) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("firstName");
      // localStorage.removeItem("lastName");
      // localStorage.removeItem("balance");
      // localStorage.removeItem("email");
      // localStorage.removeItem("avatar");
      // if (process.browser) {
      //   window.location = "/page-login";
      // }
    } else {
      return Promise.reject(error);
    }
  }
);
