import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import ConfirmationTable from "./ConfirmationTable";

const UserList = () => {
  return (
    <Fragment>
      <PageTitle
        activeMenu="تایید حساب"
        motherMenu="داشبورد"
        pageContent="تایید حساب"
      />
      <div className="row">
        <ConfirmationTable />
      </div>
    </Fragment>
  );
};

export default UserList;
