import React, { useState, useEffect } from "react";
import { httpRequest } from "../../../_helper/httpServices";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { NumberInput } from "../Defaults/NumberInput";
import { Button } from "react-bootstrap";
import { DefaultButton } from "../Defaults/DefaultButton";
import Switch from "react-switch";
import { Label } from "reactstrap";

const EtrForm = ({
  setModalCentered,
  tabs,
  setIsRef,
  title,
  name,
  isActive,
}) => {
  //state
  const [coinList, setCoinList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [orderMin, setOrderMin] = useState(0);
  const [orderMax, setOrderMax] = useState(0);
  const [takerOrderFeeFactor, setTakerOrderFeeFactor] = useState(0);
  const [makerOrderFeeFactor, setMakerOrderFeeFactor] = useState(0);
  const [takerOrderFeeMax, setTakerOrderFeeMax] = useState(0);
  const [makerOrderFeeMax, setMakerOrderFeeMax] = useState(0);
  //state otc
  const [otcMin, setOtcMin] = useState(0);
  const [otcMax, setOtcMax] = useState(0);
  const [takerOtcFeeFactor, setTakerOtcFeeFactor] = useState(0);
  const [makerOtcFeeFactor, setMakerOtcFeeFactor] = useState(0);
  const [takerOtcFeeMax, setTakerOtcFeeMax] = useState(0);
  const [makerOtcFeeMax, setMakerOtcFeeMax] = useState(0);
  //state diposit
  const [depositMin, setDepositMin] = useState(0);
  const [depositMax, setDepositMax] = useState(0);
  const [depositFeeFactor, setDepositFeeFactor] = useState(0);
  const [depositFeeMax, setDepositFeeMax] = useState(0);
  //state withdraw
  const [withdrawMin, setWithdrawMin] = useState(0);
  const [withdrawMax, setWithdrawMax] = useState(0);
  const [withdrawFeeFactor, setWithdrawFeeFactor] = useState(0);
  const [withdrawFeeMax, setWithdrawFeeMax] = useState(0);
  const [checked, setChecked] = useState(0);

  const statusHandleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    const getCoinList = () => {
      const { data } = httpRequest("get", "settings/coins")
        .then((res) => {
          setCoinList(res.data.data);
        })
        .catch((err) => console.log(err));
    };
    getCoinList();
  }, []);

  useEffect(() => {
    coinList?.filter((x) => {
      if (x.id === tabs) {
        setOrderMin(x?.order?.min);
        setOrderMax(x?.order?.max);
        setTakerOrderFeeFactor(x?.order?.takerFeeFactor);
        setMakerOrderFeeFactor(x?.order?.makerFeeFactor);
        setTakerOrderFeeMax(x?.order?.takerFeeMax);
        setMakerOrderFeeMax(x?.order?.makerFeeMax);
        setOtcMin(x?.otc?.min);
        setOtcMax(x?.otc?.max);
        setTakerOtcFeeFactor(x?.otc?.takerFeeFactor);
        setMakerOtcFeeFactor(x?.otc?.makerFeeFactor);
        setTakerOtcFeeMax(x?.otc?.takerFeeMax);
        setMakerOtcFeeMax(x?.otc?.makerFeeMax);
        setDepositMin(x?.coining?.deposit?.min);
        setDepositMax(x?.coining?.deposit?.max);
        setDepositFeeFactor(x?.coining?.deposit?.feeFactor);
        setDepositFeeMax(x?.coining?.deposit?.feeMax);
        setWithdrawMin(x?.coining?.withdraw?.min);
        setWithdrawMax(x?.coining?.withdraw?.max);
        setWithdrawFeeFactor(x?.coining?.withdraw?.feeFactor);
        setWithdrawFeeMax(x?.coining?.withdraw?.feeMax);
        setChecked(x?.isActive);
      }
    });
  }, [coinList, tabs]);

  async function editCoining() {
    const formData = coinList.map((coin, index) => {
      if (coin.id === tabs) {
        return (coin = {
          id: tabs,
          symbol: tabs,
          name: name,
          fa: title,
          otc: {
            min:
              typeof otcMin === "string"
                ? parseFloat(otcMin?.replaceAll(",", ""))
                : otcMin,
            max:
              typeof otcMax === "string"
                ? parseFloat(otcMax?.replaceAll(",", ""))
                : otcMax,
            takerFeeFactor:
              typeof takerOtcFeeFactor === "string"
                ? parseFloat(takerOtcFeeFactor?.replaceAll(",", ""))
                : takerOtcFeeFactor,
            makerFeeFactor:
              typeof makerOtcFeeFactor === "string"
                ? parseFloat(makerOtcFeeFactor?.replaceAll(",", ""))
                : makerOtcFeeFactor,
            takerFeeMax:
              typeof takerOtcFeeMax === "string"
                ? parseFloat(takerOtcFeeMax?.replaceAll(",", ""))
                : takerOtcFeeMax,
            makerFeeMax:
              typeof makerOtcFeeMax === "string"
                ? parseFloat(makerOtcFeeMax?.replaceAll(",", ""))
                : makerOtcFeeMax,
          },
          order: {
            min:
              typeof orderMin === "string"
                ? parseFloat(orderMin?.replaceAll(",", ""))
                : orderMin,
            max:
              typeof orderMax === "string"
                ? parseFloat(orderMax?.replaceAll(",", ""))
                : orderMax,
            takerFeeFactor:
              typeof takerOrderFeeFactor === "string"
                ? parseFloat(takerOrderFeeFactor?.replaceAll(",", ""))
                : takerOrderFeeFactor,
            makerFeeFactor:
              typeof makerOrderFeeFactor === "string"
                ? parseFloat(makerOrderFeeFactor?.replaceAll(",", ""))
                : makerOrderFeeFactor,
            takerFeeMax:
              typeof takerOrderFeeMax === "string"
                ? parseFloat(takerOrderFeeMax?.replaceAll(",", ""))
                : takerOrderFeeMax,
            makerFeeMax:
              typeof makerOrderFeeMax === "string"
                ? parseFloat(makerOrderFeeMax?.replaceAll(",", ""))
                : makerOrderFeeMax,
          },
          coining: {
            deposit: {
              min:
                typeof depositMin === "string"
                  ? parseFloat(depositMin?.replaceAll(",", ""))
                  : depositMin,
              max:
                typeof depositMax === "string"
                  ? parseFloat(depositMax?.replaceAll(",", ""))
                  : depositMax,
              feeFactor:
                typeof depositFeeFactor === "string"
                  ? parseFloat(depositFeeFactor?.replaceAll(",", ""))
                  : depositFeeFactor,
              feeMax:
                typeof depositFeeMax === "string"
                  ? parseFloat(depositFeeMax?.replaceAll(",", ""))
                  : depositFeeMax,
            },
            withdraw: {
              min:
                typeof withdrawMin === "string"
                  ? parseFloat(withdrawMin?.replaceAll(",", ""))
                  : withdrawMin,
              max:
                typeof withdrawMax === "string"
                  ? parseFloat(withdrawMax?.replaceAll(",", ""))
                  : withdrawMax,
              feeFactor:
                typeof withdrawFeeFactor === "string"
                  ? parseFloat(withdrawFeeFactor?.replaceAll(",", ""))
                  : withdrawFeeFactor,
              feeMax:
                typeof withdrawFeeMax === "string"
                  ? parseFloat(withdrawFeeMax?.replaceAll(",", ""))
                  : withdrawFeeMax,
            },
          },
          isActive: checked,
        });
      } else return coin;
    });

    setLoader(true);
    await httpRequest("put", "settings/coins", {
      data: { data: formData },
    })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r.data.message);
          setModalCentered(false);
          setLoader(false);
          setIsRef((value) => !value);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        ERROR(err.response?.data?.message || err.message, {
          error: err,
        });
      });
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-align-items-center">
        <h5 className="mb-4 mt-3 title__Modla"> سفارش {tabs}</h5>
        <h5 className="mb-4 mt-3">
          <div className="form-group col-md-12">
            <Label className="ml-3 mb-0 fs-12" for="status">
              وضعیت
            </Label>
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              height={15}
              width={28}
              onColor="#1fa2ff"
              onChange={statusHandleChange}
              checked={checked}
              classNmae="mt-2"
            />
          </div>
        </h5>
      </div>
      <h5 className="mb-3 mt-3 title__Modla">سفارش</h5>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <NumberInput
            label={"کمترین حد سفارش"}
            name="orderMin"
            allowLeadingZeros={true}
            className="form-control"
            onChange={(e) => setOrderMin(e.target.value)}
            value={orderMin}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <NumberInput
            id="orderMax"
            label={"بیشترین حد سفارش"}
            name="orderMax"
            className="form-control"
            allowLeadingZeros={true}
            value={orderMax}
            onChange={(e) => setOrderMax(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="takerOrderFeeFactor"
            label={"کارمزد Taker (%)"}
            placeholder="به درصد"
            name="takerOrderFeeFactor"
            className="form-control"
            allowLeadingZeros={true}
            value={takerOrderFeeFactor}
            onChange={(e) => setTakerOrderFeeFactor(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="makerOrderFeeFactor"
            label={"کارمزد Maker (%)"}
            placeholder="به درصد"
            name="makerOrderFeeFactor"
            className="form-control"
            allowLeadingZeros={true}
            value={makerOrderFeeFactor}
            onChange={(e) => setMakerOrderFeeFactor(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="takerOrderFeeMax"
            name="takerOrderFeeMax"
            label={"کارمزد Taker تا سقف (مقدار صفر = بدون سقف)"}
            className="form-control"
            allowLeadingZeros={true}
            value={takerOrderFeeMax}
            onChange={(e) => setTakerOrderFeeMax(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="makerOrderFeeMax"
            name="makerOrderFeeMax"
            label={"کارمزد Maker تا سقف (مقدار صفر = بدون سقف)"}
            className="form-control"
            allowLeadingZeros={true}
            value={makerOrderFeeMax}
            onChange={(e) => setMakerOrderFeeMax(e.target.value)}
          />
        </div>
      </div>
      <h5 className="mb-3 mt-3 title__Modla">OTC </h5>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <NumberInput
            label={"کمترین حد otc"}
            name="otcMin"
            allowLeadingZeros={true}
            placeholder="قیمت را به تومان وارد کنید..."
            className="form-control"
            onChange={(e) => setOtcMin(e.target.value)}
            value={otcMin}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <NumberInput
            id="otcMax"
            label={"بیشترین حد otc"}
            name="otcMax"
            placeholder="قیمت را به تومان وارد کنید..."
            className="form-control"
            allowLeadingZeros={true}
            value={otcMax}
            onChange={(e) => setOtcMax(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="takerOtcFeeFactor"
            label={"کارمزد Taker (%)"}
            placeholder="به درصد"
            name="takerOtcFeeFactor"
            className="form-control"
            allowLeadingZeros={true}
            value={takerOtcFeeFactor}
            onChange={(e) => setTakerOtcFeeFactor(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="makerOtcFeeFactor"
            label={"کارمزد Maker (%)"}
            placeholder="به درصد"
            name="makerOtcFeeFactor"
            className="form-control"
            allowLeadingZeros={true}
            value={makerOtcFeeFactor}
            onChange={(e) => setMakerOtcFeeFactor(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="takerOtcFeeMax"
            name="takerOtcFeeMax"
            label={"کارمزد Taker تا سقف (مقدار صفر = بدون سقف)"}
            className="form-control"
            allowLeadingZeros={true}
            value={takerOtcFeeMax}
            onChange={(e) => setTakerOtcFeeMax(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="makerOtcFeeMax"
            name="makerOtcFeeMax"
            label={"کارمزد Maker تا سقف (مقدار صفر = بدون سقف)"}
            className="form-control"
            allowLeadingZeros={true}
            value={makerOtcFeeMax}
            onChange={(e) => setMakerOtcFeeMax(e.target.value)}
          />
        </div>
      </div>
      <h5 className="mb-3 mt-3 title__Modla">واریز </h5>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="depositMin"
            name="depositMin"
            label={" کمترین حد واریز"}
            className="form-control"
            value={depositMin}
            onChange={(e) => setDepositMin(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="depositMax"
            label={" بیشترین حد واریز"}
            name="depositMax"
            className="form-control"
            value={depositMax}
            onChange={(e) => setDepositMax(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            htmlFor
            id="depositFeeFactor"
            placeholder="به درصد"
            label={"کارمزد (%)"}
            name="depositFeeFactor"
            value={depositFeeFactor}
            className="form-control"
            onChange={(e) => setDepositFeeFactor(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="depositFeeMax"
            label={"کارمزد تا سقف (مقدار صفر = بدون سقف)"}
            name="depositFeeMax"
            className="form-control"
            value={depositFeeMax}
            onChange={(e) => setDepositFeeMax(e.target.value)}
          />
        </div>
      </div>
      <h5 className="mb-3 mt-3 title__Modla">برداشت</h5>
      <div className="row">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="withdrawMin"
            label={"کمترین حد برداشت"}
            name="withdrawMin"
            className="form-control"
            value={withdrawMin}
            onChange={(e) => setWithdrawMin(e.target.value)}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <NumberInput
            id="withdrawMax"
            name="withdrawMax"
            label={" بیشترین حد برداشت "}
            className="form-control"
            value={withdrawMax}
            onChange={(e) => setWithdrawMax(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="withdrawFeeFactor"
            label={"کارمزد (%) "}
            name="withdrawFeeFactor"
            placeholder="به درصد"
            className="form-control"
            value={withdrawFeeFactor}
            onChange={(e) => setWithdrawFeeFactor(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <NumberInput
            id="withdrawFeeMax"
            label={"کارمزد تا سقف (مقدار صفر = بدون سقف)"}
            name="withdrawFeeMax"
            className="form-control"
            value={withdrawFeeMax}
            onChange={(e) => setWithdrawFeeMax(e.target.value)}
          />
        </div>
      </div>
      <DefaultButton
        type="submit"
        onClick={() => editCoining()}
        loading={loader}
        variant="primary"
        className="position-absolute "
        style={{ right: "20px", bottom: "-55px" }}
      >
        ذخیره تغییرات
      </DefaultButton>
    </div>
  );
};

export default EtrForm;
