

const TradeDetailCard = ({coin}) => {
    return (
        <div className="col-xl-3 col-sm-6 m-t35">
          <div className="card card-coin">
            <div className="card-body text-center">
              <img className="mb-3 currency-icon" onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "./images/coins/noImg.png";
                }}
                src={
                  process.env.REACT_APP_SOCKET_URL +
                  `assets/icon/${coin.name.toLowerCase()}.png`
                } alt=" " />
          
              <h2 className="text-black font-w600">
                {coin.detail.tradeOfTotal}
              </h2>

              <p className="text-right mb-0 mb-2 fs-14">
                <span
                  className="ml-1 number_trades"
                  style={{ background: "#95dad6" }}
                >
                  {coin.detail.tradeOfMonth}
                </span>
                معامله در ماه
              </p>
              <p className="text-right mb-0 mb-2 fs-14">
                <span
                  className="ml-1 number_trades"
                  style={{ background: "#95dad6" }}
                >
                  {coin.detail.tradeOfWeek}
                </span>
                معامله در هفته
              </p>
              <p className="text-right mb-0 fs-14">
                <span
                  className="ml-1 number_trades"
                  style={{ background: "#95dad6" }}
                >
                  {coin.detail.tradeOfDay}
                </span>
                معامله در روز
              </p>
            </div>
          </div>
        </div>
    )

}

export default TradeDetailCard;