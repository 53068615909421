import { panelConstants } from "./../_constants/panel.constants";
import { Orders } from "../_constants/panel.constants";
import { Coining } from "../_constants/panel.constants";
const initialState = [
  {
    userPanel: "",
    userTrades: "",
    userOrders: "",
  },
];

export function panel(state = initialState, action) {
  switch (action.type) {
    case panelConstants.USER_PANEL:
      return {
        ...state,
        requestOtp: action.data,
      };
    case panelConstants.USER_TRADES:
      return {
        ...state,
        userTrades: action.data,
      };
    case panelConstants.USER_ORDERS:
      return {
        ...state,
        userOrders: action.data,
      };
    default:
      return state;
  }
}

////detailsOrdersReducer
export const detailsOrdersReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case Orders.ORDER_DETAILS_REQUEST:
      return { loading: true };
    case Orders.ORDER_DETAILS_SUCCESS:
      return { loading: false, Orders: action.payload };
    case Orders.ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

////get All BanksDATA

export const getAllBankReducer = (
  state = { Banks: [], loading: true },
  action
) => {
  switch (action.type) {
    case Coining.GET_AVALIBLE_BANKS_REQUSET:
      return { loading: true };
    case Coining.GET_AVALIBLE_BANKS_SUCCESS:
      return { loading: false, Bank: action.payload };
    case Coining.GET_AVALIBLE_BANKS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

////get All Wages
export const getAllWagesReducer = (
  state = { Wages: [], loading: true },
  action
) => {
  switch (action.type) {
    case Coining.GET_AVALIBLE_WAGES_REQUSET:
      return { loading: true };
    case Coining.GET_AVALIBLE_WAGES_SUCCESS:
      return { loading: false, Wages: action.payload };
    case Coining.GET_AVALIBLE_WAGES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

////get All Coins
export const getAllCoinsReducer = (
  state = { Coins: [], loading: true },
  action
) => {
  switch (action.type) {
    case Coining.GET_AVALIBLE_COINS_REQUSET:
      return { loading: true };
    case Coining.GET_AVALIBLE_COINS_SUCCESS:
      return { loading: false, Coins: action.payload };
    case Coining.GET_AVALIBLE_COINS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
