import {httpRequest} from "./../_helper";

export const thirdPartyActions = {
    getThirdPartyDefault,
    getKUCoinBalance,
    getKUOrderHistory,
    getKUCoinDeposit,
    deleteKUCoinOrder,
};

function getThirdPartyDefault() {
    return () => {
        return httpRequest(
            "get",`configs/settings/item`
        );
    };
}

function getKUCoinBalance() {
    return () => {
        return httpRequest(
            "get",`thirdparty/kucoin/balance`
        );
    };
}

function getKUOrderHistory() {
    return () => {
        return httpRequest(
            "get",`thirdparty/kucoin/orders`
        );
    };
}

function getKUCoinDeposit({coin}) {
    return () => {
        return httpRequest(
            "get",`thirdparty/kucoin/balance/${coin}`
        );
    };
}

function deleteKUCoinOrder(id) {
    return () => {
        return httpRequest("delete", `thirdparty/kucoin/orders/${id}`);
    };
}
