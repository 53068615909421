import React, { useState, useRef, useEffect } from "react";
import { Modal, Dropdown, Row, Button, Col, Spinner } from "react-bootstrap";
import { affiliateActions } from "./../../../_actions";
import { ERROR, SUCCESS } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
// import data from "./tableData.js";

const AffiliateTable = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [affiliateList, setAffiliateList] = useState([]);
  const [usersMeta, setUsersMeta] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [activeToggle, setActiveToggle] = useState("all");

  const getAllAffiliateList = () => {
    dispatch(affiliateActions.getAllAffiliate({ page, limit }))
      .then((r) => {
        setAffiliateList(r.data?.data);
        console.log(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  useEffect(() => {
    getAllAffiliateList();
  }, []);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(affiliateActions.getAllAffiliate({ page, limit }))
      .then((r) => {
        setAffiliateList(r.data?.data);
        setUsersMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }

  const removeAffiliate = (item) => {
    const id = item._id;
    dispatch(affiliateActions.deleteAnAffiliate(id))
      .then((r) => {
        SUCCESS("کد دعوت با موفقیت حذف شد");
        getAllAffiliateList();
      })
      .catch((ex) => {
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  };

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">لیست کدهای دعوت</h4>
        </div>

        <div className="tab-content">
          <div
            id="all"
            className={`tab-pane fade ${
              activeToggle === "all" ? "active show" : ""
            }`}
          >
            {" "}
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {affiliateList && affiliateList?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                            // style={{ width: 73 }}
                          >
                            نام و نام خانوادگی
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                            // style={{ width: 73 }}
                          >
                            کد دعوت
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            سهم کاربر
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                            // style={{ width: 100 }}
                          >
                            سهم دیگری
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                            // style={{ width: 62 }}
                          >
                            حداکثر تعداد دفعات
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            تعداد استفاده
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            تاریخ ساخت
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          >
                            ساعت
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            // style={{ width: 106 }}
                          ></th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {affiliateList && affiliateList?.length !== 0 ? (
                        affiliateList?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center ">
                                  {item.user.avatar !== null ? (
                                    <img
                                      src={item.user.avatar}
                                      alt=""
                                      className="rounded-circle ml-2 width40 height40"
                                    />
                                  ) : (
                                    <div className="img_cont_table">
                                      <img
                                        src={item?.user?.avatar}
                                        className="rounded-circle user_img"
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  {item.user.firstName !== null ? (
                                    <span>
                                      {item.user.firstName} {item.user.lastName}
                                    </span>
                                  ) : (
                                    "------"
                                  )}
                                </div>
                              </td>
                              <td>
                                <span className="badge badge-outline-success">
                                  {item.code}
                                </span>
                              </td>
                              <td>{item.actorShare}%</td>
                              <td>{item.objectShare}%</td>
                              <td>{item.maxUse}</td>
                              <td>{item.used}</td>
                              <td>
                                {new Date(item.createdAt).toLocaleDateString(
                                  "fa-IR"
                                )}
                              </td>
                              <td>
                                {new Date(item.createdAt).toLocaleTimeString(
                                  "fa-IR"
                                )}
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                {!item.isDelete ? (
                                  <a
                                    onClick={() => removeAffiliate(item)}
                                    className="btn-link text-danger fs-18 float-right cursor-pointer"
                                  >
                                    <i className="fa fa-trash" />
                                  </a>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {usersMeta?.page * usersMeta?.limit -
                        usersMeta?.limit +
                        1}{" "}
                      تا {usersMeta?.page * usersMeta?.limit} از{" "}
                      {usersMeta?.total} کد دعوت
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={usersMeta?.limit}
                          totalItemsCount={usersMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AffiliateTable);
