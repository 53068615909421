import React, { useState, useEffect } from "react";
import { ticketsActions } from "../../../_actions";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { connect } from "react-redux";
import frenchStrings from "react-timeago/lib/language-strings/fa";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import TimeAgo from "react-timeago";

const MsgBox = ({
  avatar1,
  avatar2,
  openMsg,
  PerfectScrollbar,
  offMsg,
  userId,
  dispatch,
  userAvatar,
  userName,
}) => {
  const [message, setMessage] = useState("");
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [disable, setDisable] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;
  const formatter = buildFormatter(frenchStrings);
  const onChangeMessage = (evt) => {
    setMessage(evt.target.value);
  };

  const getAllTickets = () => {
    dispatch(ticketsActions.getAllTickets({ page, limit, userId }))
      .then((r) => {
        setTicketsData(r?.data?.data);
        setTicketTotal(r?.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };
  const readAllTickets = () => {
    dispatch(ticketsActions.readAllTicketFromUser(userId)).then((r) => {
      getAllTickets();
      // setTicketsData(r?.data?.data);
    });
    // .catch((ex) =>
    //   ERROR(ex.response?.data?.message || ex.message, {
    //     error: ex,
    //   }
    //   )
    // );
  };

  useEffect(() => {
    getAllTickets();
  }, [userId]);

  useEffect(() => {
    if (openMsg) readAllTickets();
  }, [openMsg]);

  async function sendTicket() {
    setDisable(true);
    const data = {
      message: message,
      receptorId: userId,
    };
    dispatch(ticketsActions.createTicket(data))
      .then((r) => {
        setDisable(false);
        setMessage("");
        SUCCESS(r?.data?.message || "پاسخ تیکت با موفقیت ارسال شد.");
        getAllTickets();
      })
      .catch((ex) => {
        setDisable(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {});
  }

  return (
    <div
      className={`card chat w-100 dz-chat-history-box ${
        openMsg ? "" : "d-none"
      }`}
    >
      <div className="card-header chat-list-header text-center">
        <div>
          <h6 className="mb-1 fs-13"> {userName}</h6>
          <p className="mb-0 text-success">Online</p>
        </div>
        <a href="#" className="dz-chat-history-back" onClick={() => offMsg()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <rect
                fill="#000000"
                opacity="0.3"
                transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
                x="14"
                y="7"
                width="2"
                height="10"
                rx="1"
              />
              <path
                d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                fill="#000000"
                fillRule="nonzero"
                transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
              />
            </g>
          </svg>
        </a>
      </div>
      <PerfectScrollbar
        className={`card-body msg_card_body dz-scroll ${
          openMsg ? "ps ps--active-y" : ""
        } `}
        id="DZ_W_Contacts_Body3"
      >
        {ticketsData && ticketsData?.length !== 0
          ? ticketsData
              ?.slice(0)
              ?.reverse()
              ?.map((item, index) => {
                return (
                  <>
                    {item.type === "ANSWER" ? (
                      <div
                        key={index}
                        className="d-flex justify-content-start  mb-4"
                      >
                        <div className="img_cont_msg">
                          <img
                            src={localStorage.getItem("avatar")}
                            className="rounded-circle user_img_msg"
                            alt=""
                          />
                        </div>
                        <div className="msg_cotainer fs-11">
                          {item.message}
                          <span className="msg_time text-left mt-3">
                            {" "}
                            <TimeAgo
                              date={item.createdAt}
                              formatter={formatter}
                            />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end mb-4">
                        <div className="msg_cotainer_send fs-11">
                          {item.message}
                          <span className="msg_time_send text-left mt-3">
                            <TimeAgo
                              date={item.createdAt}
                              formatter={formatter}
                            />
                          </span>
                        </div>
                        <div className="img_cont_msg">
                          <img
                            src={userAvatar}
                            className="rounded-circle user_img_msg"
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })
          : null}{" "}
      </PerfectScrollbar>
      <div className="card-footer type_msg">
        <div className="input-group">
          <textarea
            value={message}
            onChange={onChangeMessage}
            className="form-control"
            placeholder="Type your message..."
          ></textarea>
          <div className="input-group-append">
            <button
              disabled={disable}
              style={{ borderRadius: "100%" }}
              onClick={() => sendTicket()}
              type="button"
              className="btn btn-primary"
            >
              <i className="fa fa-location-arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(MsgBox);
