import { httpRequest } from "./../_helper";

export const bankingActions = {
  getBanking,
  verifyAccount,
  getBankingHistory,
};

function getBanking({ page, limit, amount, searchFlow, statusTitle }) {
  return () => {
    return httpRequest(
      "get",
      `banking/admin?page=${page}&limit=${limit}&search=${
        amount ? `amount` : ""
      }${amount && searchFlow ? `,` : ""}${searchFlow ? `flow` : ""}${
        (searchFlow && statusTitle) || (amount && statusTitle) ? `,` : ""
      }${statusTitle ? `status` : ""}&query=${amount ? `${amount}` : ""}${
        amount && searchFlow ? `,` : ""
      }${searchFlow ? `${searchFlow}` : ""}${
        (searchFlow && statusTitle) || (amount && statusTitle) ? `,` : ""
      }${statusTitle ? `${statusTitle}` : ""}`
    );
  };
}

function getBankingHistory({ page, limit, userId }) {
  return () => {
    return httpRequest(
      "get",
      `banking/admin?page=${page}&limit=${limit}&search=${
        userId ? `userId` : ""
      }&query=${userId ? `${userId}` : ""}`
    );
  };
}

function verifyAccount(id, accountId, isVerify) {
  return () => {
    return httpRequest("put", `users/${id}/account/${accountId}`, {
      data: { isVerify },
    });
  };
}
