import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ordersAction, usersAction } from "../../../_actions";
import { DateConvert } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { ERROR, Success } from "./../../../_helper/alerts";

import CircularProgress from "@material-ui/core/CircularProgress";
import "./orderStyle.css";

import "./orderStyle.css";

const OrderDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetails] = useState([]);

  const selectOrder = useSelector((state) => state.selectOrder);
  const { loading, error, Orders } = selectOrder;

  useEffect(() => {
    dispatch(ordersAction.detailsListOrders(id));
  }, [id, dispatch]);

  const FormatTime = (time) => {
    let date = new Date(time).toLocaleString("fa-IR");
    return date;
  };

  useEffect(() => {
    setUserId(Orders?.data?.userId);
  }, [Orders]);

  useEffect(() => {
    if (userId) {
      dispatch(usersAction.userInfo(userId))
        .then((r) => {
          setUserDetails(r?.data?.data);
          console.log(r?.data?.data);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [userId]);

  return (
    <div>
      <h3 className="fs-15 mb-2">اطلاعات سفارش</h3>

      {loading ? (
        <CircularProgress color="primary" />
      ) : error ? (
        <div style={{ color: "red ", fontSize: "45px" }}>{ERROR(error)}</div>
      ) : (
        <div className="Order__Area">
          <div className="Order__Box">
            <div className="row table-dashed-border">
              <div className="col-md-4">
                نام و نام خانوادگی:{" "}
                <span className="ml-3">
                  {userDetails?.firstName} {userDetails?.lastName}
                </span>
              </div>
              <div className="col-md-4">
                موبایل: <span className="ml-3">{userDetails?.mobile}</span>
              </div>
              <div className="col-md-4">
                ایمیل: <span className="ml-3">{userDetails?.email}</span>
              </div>
            </div>
            <div className="row table-dashed-border">
              <div className="col-md-4">
                <span className="">وضعیت : </span>
                <div
                  className={
                    Orders.data.status === "in_list"
                      ? "btn-xs fs-9 btn btn-success"
                      : "btn-xs fs-9 btn btn-danger"
                  }
                >
                  {Orders.data.status === "in_list" && "فعال"}
                </div>
              </div>
              <div className="col-md-4">
                نوع ارز : <span className="ml-3">{Orders.data.coin}</span>
                <img
                  className="cc_size"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "./images/coins/noImg.png";
                  }}
                  src={
                    Orders.data.coin === "btc"
                      ? `./images/coins/btc.svg`
                      : Orders.data.coin === "eth"
                      ? `./images/coins/eth.svg`
                      : Orders.data.coin === "trx"
                      ? `./images/coins/trx.svg`
                      : Orders.data.coin === "usdt"
                      ? `./images/coins/usdt.svg`
                      : ""
                  }
                />
              </div>
            </div>
            <div className="row table-dashed-border">
              <div className="col-md-3 col-sm-6">
                <p>کمترین مقدار: {Orders.data.minAmount}</p>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>میزان: {Orders.data.amount}</p>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>میزان معامله: {Orders.data.tradedAmount}</p>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>قیمت واحد: {Orders.data.priceUnit.toLocaleString()}</p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                به روز رسانی:{" "}
                <span className="ml-3">
                  {FormatTime(Orders.data.modifiedAt)}
                </span>
              </div>
              <div className="col-md-6">
                {/* <span className="text-secondary"> */}
                ساخته شده در :{" "}
                <span className="ml-3">
                  {FormatTime(Orders.data.createdAt)}
                </span>
                {/* </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
