import { httpRequest } from "./../_helper";
import { Orders } from "../_constants/panel.constants";

// function getOrdersList({ page, limit, dateFrom, types }) {
//   return () => {
//     return httpRequest(
//       "get",
//       `orders/admin?page=${page}&limit=${limit}&dateFrom=${
//         dateFrom ? dateFrom : ""
//       }&search=type&query=${types ? types : ""}`
//     );
//   };
// }

function getOrdersList({ page, limit, dateFrom, ip, types, coin, amount }) {
  return () => {
    return httpRequest(
      "get",
      `orders/admin?page=${page}&limit=${limit}&dateFrom=${
        dateFrom ? dateFrom : ""
      }&sort=createdAt&order=-1&search=${ip ? `ip` : ""}${
        ip && types ? `,` : ""
      }${types ? `type` : ""}${(types && coin) || (ip && coin) ? `,` : ""}${
        coin ? `coin` : ""
      }${(types && amount) || (amount && coin) || (ip && amount) ? `,` : ""}${
        amount ? `amount` : ""
      }&query=${ip ? `${ip}` : ""}${ip && types ? `,` : ""}${
        types ? `${types}` : ""
      }${(types && coin) || (ip && coin) ? `,` : ""}${coin ? `${coin}` : ""}${
        (types && amount) ||
        (ip && amount) ||
        (coin && amount) ||
        (coin && ip && amount) ||
        (coin && ip && types) ||
        (types && ip && amount)
          ? `,`
          : ""
      }${amount ? `${amount}` : ""}`
    );
  };
}
//get My order List

function getMyOrdersList({ page, limit, dateFrom, Mytype }) {
  return () => {
    return httpRequest(
      "get",
      `orders/my?page=${page}&limit=${limit}&dateFrom=${
        dateFrom ? dateFrom : ""
      }&search=type&query=${Mytype ? Mytype : ""}`
    );
  };
}

///order details

const detailsListOrders = (orderId) => async (dispatch) => {
  dispatch({ type: Orders.ORDER_DETAILS_REQUEST, orderId });
  try {
    const { data } = await httpRequest("get", `orders/${orderId}`);
    dispatch({ type: Orders.ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: Orders.ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

function getOtcOrdersList({ page, limit, dateFrom, ip, types, coin, amount }) {
  return () => {
    return httpRequest(
      "get",
      `otc/admin?page=${page}&limit=${limit}&dateFrom=${
        dateFrom ? dateFrom : ""
      }&sort=createdAt&order=-1&search=${ip ? `ip` : ""}${
        ip && types ? `,` : ""
      }${types ? `type` : ""}${(types && coin) || (ip && coin) ? `,` : ""}${
        coin ? `coin` : ""
      }${(types && amount) || (amount && coin) || (ip && amount) ? `,` : ""}${
        amount ? `amount` : ""
      }&query=${ip ? `${ip}` : ""}${ip && types ? `,` : ""}${
        types ? `${types}` : ""
      }${(types && coin) || (ip && coin) ? `,` : ""}${coin ? `${coin}` : ""}${
        (types && amount) ||
        (ip && amount) ||
        (coin && amount) ||
        (coin && ip && amount) ||
        (coin && ip && types) ||
        (types && ip && amount)
          ? `,`
          : ""
      }${amount ? `${amount}` : ""}`
    );
  };
}

function getAnOrder({ orderId }) {
  return () => {
    return httpRequest("get", `orders/${orderId}`);
  };
}

function deleteAnOrder({ orderId }) {
  return () => {
    return httpRequest("delete", `orders/${orderId}`);
  };
}

export const ordersAction = {
  getOrdersList,
  getAnOrder,
  getMyOrdersList,
  detailsListOrders,
  getOtcOrdersList,
  deleteAnOrder,
};
