import React, { Fragment, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import TokensTable from "./TokensTable";

const TokensList = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <PageTitle
        activeMenu="توکن ها"
        motherMenu="داشبورد"
        pageContent="توکن ها"
      />
      <div className="row">
        <TokensTable />
      </div>
    </Fragment>
  );
};

export default TokensList;
