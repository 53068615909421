import React, { useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import CoiningTabs from "../coining/CoiningTabs";

const CoinDetails = () => {
  
  return (
    <>
      <CoiningTabs />
    </>
  );
};
export default CoinDetails;
