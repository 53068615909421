import React, { useState, useEffect } from "react";
import { ticketsActions } from "../../../_actions";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { connect } from "react-redux";
import frenchStrings from "react-timeago/lib/language-strings/fa";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import TimeAgo from "react-timeago";

const MsgBoxPage = ({
  avatar1,
  avatar2,
  openMsg,
  PerfectScrollbar,
  offMsg,
  userId,
  dispatch,
  userAvatar,
  userName,
}) => {
  const [toggle, setToggle] = useState(false);
  const [message, setMessage] = useState("");
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [disable, setDisable] = useState(false);
  const limit = 10;
  const formatter = buildFormatter(frenchStrings);
  const onChangeMessage = (evt) => {
    setMessage(evt.target.value);
  };

  const getAllTickets = () => {
    dispatch(ticketsActions.getAllTickets({ page, limit, userId }))
      .then((r) => {
        setTicketsData(r?.data?.data);
        setTicketTotal(r?.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  useEffect(() => {
    getAllTickets();
  }, []);

  async function sendTicket() {
    setDisable(true);
    const data = {
      message: message,
      receptorId: userId,
    };
    dispatch(ticketsActions.createTicket(data))
      .then((r) => {
        setDisable(false);
        setMessage("");
        SUCCESS(r?.data?.message || "پاسخ تیکت با موفقیت ارسال شد.");
      })
      .catch((ex) => {
        setDisable(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      })
      .finally(() => {});
  }

  return (
    <div
      className={`card chat w-100 dz-chat-history-box ${
        openMsg ? "" : "d-none"
      }`}
    >
      <div className="card-header chat-list-header text-center">
        <div>
          <h6 className="mb-1 fs-13"> {userName}</h6>
        </div>
      </div>
      <PerfectScrollbar
        className={`card-body msg_card_body dz-scroll ${
          openMsg ? "ps ps--active-y" : ""
        } `}
        id="DZ_W_Contacts_Body3"
      >
        {ticketsData && ticketsData?.length !== 0 ? (
          ticketsData
            ?.slice(0)
            ?.reverse()
            ?.map((item) => {
              return (
                <>
                  {item.type === "ANSWER" ? (
                    <div className="d-flex justify-content-start  mb-4">
                      <div className="img_cont_msg">
                        <img
                          src={localStorage.getItem("avatar")}
                          className="rounded-circle user_img_msg"
                          alt=""
                        />
                      </div>
                      <div className="msg_cotainer fs-11">
                        {item.message}
                        <span className="msg_time text-left mt-3">
                          {" "}
                          <TimeAgo
                            date={item.createdAt}
                            formatter={formatter}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end mb-4">
                      <div className="msg_cotainer_send fs-11">
                        {item.message}
                        <span className="msg_time_send text-left mt-3">
                          <TimeAgo
                            date={item.createdAt}
                            formatter={formatter}
                          />
                        </span>
                      </div>
                      <div className="img_cont_msg">
                        <img
                          src={userAvatar}
                          className="rounded-circle user_img_msg"
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </>
              );
            })
        ) : (
          <div className="card-content bg-primary p-3 rounded text-center">
            <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
          </div>
        )}{" "}
      </PerfectScrollbar>
      <div className="card-footer type_msg">
        <div className="input-group">
          <textarea
            value={message}
            onChange={onChangeMessage}
            className="form-control"
            placeholder="Type your message..."
          ></textarea>
          <div className="input-group-append">
            <button
              disabled={disable}
              style={{ borderRadius: "100%" }}
              onClick={() => sendTicket()}
              type="button"
              className="btn btn-primary"
            >
              <i className="fa fa-location-arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(MsgBoxPage);
