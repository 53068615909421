import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ticketsActions } from "./../../../_actions";
import { ERROR } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import frenchStrings from "react-timeago/lib/language-strings/fa";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import TimeAgo from "react-timeago";

/// Images
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import AllMsgBox from "./AllMsgBox";

const AllChat = ({ PerfectScrollbar, toggleChatBox, toggleTab, dispatch }) => {
  const [openMsg, setOpenMsg] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [receptorId, setReceptorId] = useState("");
  const [page, setPage] = useState(1);
  const [userAvatar, setUserAvatar] = useState("");
  const [userName, setUserName] = useState("");
  const limit = 10;
  const formatter = buildFormatter(frenchStrings);

  const getAllTickets = () => {
    dispatch(ticketsActions.getAllTickets({ page, limit }))
      .then((r) => {
        setTicketsData(r?.data?.data);
        setTicketTotal(r?.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };
  useEffect(() => {
    getAllTickets();
  }, []);

  const goToTicketsDetail = (item) => {
    setOpenMsg(true);
    setReceptorId(item?.userId);
    setUserAvatar(item?.user?.avatar);
    setUserName(item?.user?.firstName + " " + item?.user?.lastName);
  };

  return (
    <div
      className={`tab-pane fade  ${
        toggleTab === "alerts" ? "active show" : ""
      }`}
      id="chat"
      role="tabpanel"
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box ${
          openMsg ? "d-none" : ""
        }`}
      >
        <div className="card-header chat-list-header text-center">
          <Link to="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                  fill="#000000"
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                />
              </g>
            </svg>
          </Link>
          <div>
            <h6 className="mb-1">لیست چت ها</h6>
            <p className="mb-0">Show All</p>
          </div>
          <Link to="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" cx="5" cy="12" r="2" />
                <circle fill="#000000" cx="12" cy="12" r="2" />
                <circle fill="#000000" cx="19" cy="12" r="2" />
              </g>
            </svg>
          </Link>
        </div>
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          id="DZ_W_Contacts_Body"
        >
          <ul className="contacts">
            {/* <li className="name-first-letter">A</li> */}
            {ticketsData && ticketsData?.length !== 0
              ? ticketsData?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="active dz-chat-user"
                      onClick={() => goToTicketsDetail(item)}
                    >
                      <div className="d-flex bd-highlight">
                        <div className="img_cont">
                          <img
                            src={item?.user?.avatar}
                            className="rounded-circle user_img"
                            alt=""
                          />
                          <span className="online_icon"></span>
                        </div>
                        <div className="user_info">
                          <span className="fs-12">
                            {item.user?.firstName} {item.user?.lastName}
                          </span>
                          <div>
                            <p className="fs-11">{item.message}</p>
                          </div>

                          <p className="fs-9 mt-2">
                            <TimeAgo
                              date={item.createdAt}
                              formatter={formatter}
                            />
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </PerfectScrollbar>
      </div>
      <AllMsgBox
        userAvatar={userAvatar}
        userName={userName}
        avatar1={avatar1}
        avatar2={avatar2}
        openMsg={openMsg}
        userId={receptorId}
        PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AllChat);
