import React, { useContext } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import CoinDetails from "./components/Dashboard/CoinDetails";
import RobotsList from "./components/robots/RobotsList";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import ConfirmationsList from "./components/Confirmations/ConfirmationsList";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Table
import UserList from "./components/users/UserList";
import OrderList from "./components/orders/OrderList";
import OrderDetails from "./components/orders/OrderDetails";
import PairCoins from "./components/coining/PairCoins";
import AffiliateList from "./components/affiliate/AffiliateList";
import ThirdPartyList from "./components/ThirdParty/ThirdPartyList";
import TokensList from "./components/Tokens/TokensList";

import TransactionList from "./components/transactions/TransactionList";
import RoleList from "./components/roles/RoleList";
import NewRole from "./components/roles/NewRole";
import CoiningList from "./components/coining/CoiningList";
import BankingList from "./components/banking/BankingList";
import UserDetail from "./components/users/UserDetail";
import KUCoinDetails from "./components/ThirdParty/KUCoinDetails";
import CreateUser from "./components/users/CreateUser";
import TicketsList from "./components/tickets/TicketsList";
import ChatsPage from "./components/tickets/ChatsPage";
import BlockItems from "./components/Settings/BlockItems";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import Notification from "../jsx/components/notification/Notification";
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "coin-details", component: CoinDetails },
    { url: "robots", component: RobotsList },
    // { url: "robotsList/:id", component: RobotsList },
    { url: "notification", component: Notification },
    //{ url: "dashboard-dark", component: Home2 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "confirmations-list", component: ConfirmationsList },
    { url: "post-details", component: PostDetails },

    /// table
    { url: "users-list", component: UserList },
    { url: "orders-list", component: OrderList },
    { url: "pair-coins", component: PairCoins },
    { url: "affiliate", component: AffiliateList },
    { url: "thirdParty", component: ThirdPartyList },
    { url: "tokensList", component: TokensList },
    { url: "orderDetails/:id", component: OrderDetails },
    { url: "transactions-list", component: TransactionList },
    { url: "coining-list", component: CoiningList },
    { url: "role-list", component: RoleList },
    { url: "create-role", component: NewRole },
    { url: "banking-list", component: BankingList },
    { url: "users-detail/:id", component: UserDetail },
    { url: "kucoin-detail", component: KUCoinDetails },
    { url: "create-user", component: CreateUser },
    { url: "ticket-list", component: TicketsList },
    { url: "chats/:id", component: ChatsPage },
    { url: "block-items", component: BlockItems },

    { url: "page-register", component: Registration },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </Router>
  );
};

export default Markup;
