import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import MsgBoxPage from "./../chatBox/MsgBoxPage";
import avatar1 from "../../../images/avatar/1.jpg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ticketsActions } from "./../../../_actions";
import { ERROR } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

const ChatsPage = ({dispatch}) => {
  const [openMsg, setOpenMsg] = useState(false);
  const [receptorId, setReceptorId] = useState("");
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10;
  const route = useParams();
  const { id } = route;
  const [userAvatar, setUserAvatar] = useState("");

  return (
    <Fragment>
      <PageTitle
        activeMenu="چت ها"
        motherMenu="لیست چت های کاربران"
        pageContent="چت ها"
      />
      <div className="row">
        <MsgBoxPage
          userAvatar={userAvatar}
          userName={""}
          avatar1={avatar1}
          avatar2={avatar1}
          openMsg={true}
          userId={id}
            PerfectScrollbar={PerfectScrollbar}
          offMsg={() => setOpenMsg(false)}
        />
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(ChatsPage);