import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { httpRequest } from "../../../_helper/httpServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NumberInput } from "../Defaults/NumberInput";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { useDispatch } from "react-redux";
import { getAvalibleBank } from "../../../_actions/CoiningActions";
import { DefaultButton } from "../Defaults/DefaultButton";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  coiningActions,
  settingsAction,
  thirdPartyActions,
  usersAction,
} from "../../../_actions";
import { BaseInput } from "../Defaults/BaseInput";

const ThirdPartyModal = ({ children, onSelect }) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allDefaults, setAllDefaults] = useState([]);
  const [defaultVal, setDefaultVal] = useState({});
  const [allTypes, setAllTypes] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [coinsList, setCoinsList] = useState([]);
  const [onVal, setOnVal] = useState({});
  const [coins, setCoins] = useState("");
  const [base, setBase] = useState("");
  const [baseErr, setBaseErr] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [order, setOrder] = useState(false);
  const [otc, setOtc] = useState(false);
  const [third, setThird] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [hasEditThird, setHasEditThird] = useState(false);
  const [hasEditSetting, setHasEditSetting] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.write === true;
      });

      roles?.filter((item) => {
        if (item.scope === "thirdParty") setHasEditThird(true);
        if (item.scope === "setting") setHasEditSetting(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const handleChangeDefault = (selected) => {
    setDefaultVal(selected);
  };
  const handleChangeType = (selected) => {
    setOnVal(selected);
  };
  const handleChangeCoins = (selected) => {
    setCoins(selected);
    // if (selected) {
    //     setCoinsList([
    //         ...selected.map((obj) => {
    //             return obj.value;
    //         }),
    //     ]);
    // } else {
    //     setCoinsList([]);
    // }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalCentered) {
      dispatch(thirdPartyActions.getThirdPartyDefault())
        .then((r) => {
          setAllDefaults(
            r?.data?.data?.third?.default?.map((item, index) => {
              return {
                value: index,
                label: item,
              };
            })
          );
          setAllTypes(
            r?.data?.data?.third?.on?.map((item, index) => {
              return {
                value: index,
                enLabel: item,
                label:
                  item === "buy"
                    ? "خرید"
                    : item === "sell"
                    ? "فروش"
                    : item === "both"
                    ? "خرید و فروش"
                    : "",
              };
            })
          );
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [modalCentered]);

  useEffect(() => {
    if (modalCentered) {
      // dispatch(coiningActions.getPreDefinedCoins())
      dispatch(coiningActions.getAllAvailableCoins())
        .then((r) => {
          setAllCoins(
            r?.data?.data?.map((item) => {
              return {
                value: item.id,
                label: item?.id?.toUpperCase(),
              };
            })
          );
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [modalCentered]);

  useEffect(() => {
    if (base !== "" && base !== null) {
      setBaseErr(false);
    }
  }, [base]);

  useEffect(() => {
    if (modalCentered) {
      dispatch(settingsAction.getConfigItems())
        .then((r) => {
          setDeposit(r?.data?.data?.block?.deposit);
          setWithdraw(r?.data?.data?.block?.withdraw);
          setOrder(r?.data?.data?.block?.order);
          setOtc(r?.data?.data?.block?.otc);
          setDefaultVal(r?.data?.data?.third?.default);
          setOnVal(r?.data?.data?.third?.on);
          setBase(r?.data?.data?.third?.base);
          const options = r?.data?.data?.third?.coins.map((item, index) => {
            return {
              value: item,
              label: item?.toUpperCase(),
            };
          });
          setCoins(options);
        })
        .catch((e) =>
          ERROR(e.response?.data?.message || e.message, {
            error: e,
          })
        );
    }
  }, [refresh, modalCentered]);

  async function editThirdParty() {
    setLoader(true);
    let coinsList = [];
    if (coins) {
      coinsList = [
        ...coins?.map((obj) => {
          return obj.value;
        }),
      ];
    }

    const dataObj = {
      default: defaultVal?.label ? defaultVal?.label : defaultVal,
      on: onVal?.enLabel ? onVal?.enLabel : onVal,
      base: base,
      coins: coinsList,
    };
    if (base === "" || base === null) setBaseErr(true);
    else if (base !== "" && base !== null) setBaseErr(false);

    const data = {
      data: {
        block: {
          deposit: deposit,
          withdraw: withdraw,
          order: order,
          otc: otc,
        },
        third: dataObj,
      },
    };
    if (
      (hasEditSetting && hasEditThird) ||
      localStorage.getItem("role") === "admin"
    ) {
      dispatch(settingsAction.setConfigItems(data))
        .then((r) => {
          r && SUCCESS(r?.data?.message || "اطلاعات با موفقیت ثبت شد");
          setLoader(false);
          setModalCentered(false);
          setRefresh(true);
          handleSelect(true);
        })
        .catch((ex) => {
          setLoader(false);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        });
    } else {
      ERROR("شما اجازه دسترسی به این بخش را ندارید");
      setModalCentered(false);
    }
  }

  const handleSelect = (item) => {
    setRefresh(true);
    onSelect(item);
  };

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <a className="d-flex m-auto" onClick={() => setModalCentered(true)}>
        {children}
      </a>
      {/* <!-- Modal --> */}
      <Modal
        className="fade "
        show={modalCentered}
        size="md"
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="number-black">ویرایش تنظیمات OTC</h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <FormGroup className="form-group mb-0 row mx-1">
                    <Label for="status">Default</Label>
                    <Select
                      styles={customStyles}
                      className={`react-select w-100 text-left`}
                      classNamePrefix="react-select"
                      onChange={handleChangeDefault}
                      options={allDefaults}
                      value={allDefaults.find(
                        (option) => option.label === defaultVal
                      )}
                      instanceId="defaultVal"
                      name="defaultVal"
                    />
                  </FormGroup>
                </div>
                <div className="form-group col-md-12">
                  <FormGroup className="form-group mb-0 row mx-1">
                    <Label for="status">On</Label>
                    <Select
                      styles={customStyles}
                      className={`react-select w-100 text-left`}
                      classNamePrefix="react-select"
                      onChange={handleChangeType}
                      options={allTypes}
                      // value={onVal}
                      value={allTypes.find(
                        (option) => option.enLabel === onVal
                      )}
                      instanceId="onVal"
                      name="onVal"
                    />
                  </FormGroup>
                </div>
                <div className="form-group col-md-12">
                  <BaseInput
                    className="form-control text-right"
                    id="base"
                    value={base}
                    onChange={(evt) => {
                      setBase(evt.target.value);
                    }}
                    label={"Base"}
                    error={baseErr}
                    errorText="base نمی تواند خالی باشد"
                  />
                </div>
                <div className="form-group col-md-12">
                  <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                    <Label for="status">ارزها</Label>
                    <Select
                      isMulti
                      styles={customStyles}
                      className={`react-select w-100 text-left`}
                      classNamePrefix="react-select"
                      onChange={handleChangeCoins}
                      options={allCoins}
                      value={coins}
                      placeholder="انتخاب کنید"
                      instanceId="coins"
                      name="coins"
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setModalCentered(false)}
              variant="danger light"
              className="ml-2"
            >
              بستن
            </Button>
            <DefaultButton
              onClick={() => editThirdParty()}
              loading={loader}
              type="submit"
              variant="primary"
            >
              ذخیره تغییرات
            </DefaultButton>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ThirdPartyModal;
