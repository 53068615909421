import React, { useState, useEffect } from "react";
import { DefaultButton } from "../Defaults/DefaultButton";
import { Button, Modal } from "react-bootstrap";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { httpRequest } from "../../../_helper/httpServices";
import Select from "react-select";
import { BaseInput } from "../Defaults/BaseInput";
import { FormGroup, Label } from "reactstrap";
import { coiningActions } from "../../../_actions";
import { useDispatch } from "react-redux";

const ModalPairCoins = ({ children, setUpdate, pairCoinData }) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [coin, setCoin] = useState("");
  const [pairCoin, setPairCoin] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [name, setName] = useState("");
  const [fa, setFa] = useState("");
  const [allCoins, setAllCoins] = useState([]);
  const dispatch = useDispatch();
  const handleChangeCoins = (selected) => {
    setSymbol(selected);
    setFa(selected?.faLabel);
    setName(selected?.enLabel);
  };

  useEffect(() => {
    setFa(pairCoinData[0]?.fa);
    setName(pairCoinData[0]?.name);
  }, [pairCoinData]);

  const getPairConis = () => {
    httpRequest("get", "settings/pairs")
      .then((res) => setPairCoin(res.data.data))
      .catch((err) => ERROR(err));
  };
  useEffect(() => {
    getPairConis();
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      // textAlign: "right",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        // textAlign: "right",
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  function getUniqueArray(arr = [], compareProps = []) {
    let modifiedArray = [];
    if (compareProps.length === 0 && arr.length > 0)
      compareProps.push(...Object.keys(arr[0]));
    arr.forEach((item) => {
      if (modifiedArray.length === 0) {
        modifiedArray.push(item);
      } else {
        if (
          !modifiedArray.some((item2) =>
            compareProps.every(
              (eachProps) => item2[eachProps] === item[eachProps]
            )
          )
        ) {
          modifiedArray.push(item);
        }
      }
    });
    return modifiedArray;
  }

  async function editPairCoins() {
    let items = {
      id: Array.isArray(symbol)
        ? symbol?.length !== 0 &&
          symbol?.map((x) => {
            return x?.value;
          })[0]
        : symbol?.value,
      symbol: Array.isArray(symbol)
        ? symbol?.length !== 0 &&
          symbol?.map((x) => {
            return x?.value;
          })[0]
        : symbol?.value,
      name: name,
      fa: fa,
    };
    // pairCoin.push(items);
    // const uniqueCoins = getUniqueArray(pairCoin);
    // const data = {
    //   data: [
    //     {
    //       id: "usdt",
    //       name: "Tether",
    //       symbol: "usdt",
    //       fa: "تتر ERC-20",
    //     },
    //   ],
    // };

    const data = {
      data: [items, ...pairCoin],
    };
    setLoader(true);
    await httpRequest("put", "settings/pairs", { data })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
          setModalCentered(false);
          // setUpdate(true);
        }
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(false);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  useEffect(() => {
    dispatch(coiningActions.getAllAvailableCoins())
      // dispatch(coiningActions.getPreDefinedCoins())
      .then((r) => {
        const arr = r?.data?.data?.map((item) => {
          return {
            value: item.id,
            faLabel: item.fa,
            enLabel: item.name,
            label: item?.id?.toUpperCase(),
          };
        });
        setAllCoins(arr);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  useEffect(() => {
    setSymbol(
      allCoins.filter((option) => option.value === pairCoinData[0]?.id)
    );
  }, [allCoins, pairCoinData]);

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <Button
        variant="primary"
        type="button"
        className="d-flex m-auto"
        onClick={() => setModalCentered(true)}
      >
        {children}
      </Button>
      {/* <!-- Modal --> */}
      <Modal
        className="fade customeModal"
        show={modalCentered}
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="number-black">ویرایش جفت ارز</h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                {/*<BaseInput*/}
                {/*  id="symbol"*/}
                {/*  label={"نماد"}*/}
                {/*  name="symbol"*/}
                {/*  placeholder="نماد وارد کنید..."*/}
                {/*  value={symbol}*/}
                {/*  onChange={(e) => setSymbol(e.target.value)}*/}
                {/*/>*/}
                <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                  <Label className="mb-1" for="status">
                    نماد
                  </Label>
                  <Select
                    styles={customStyles}
                    className={`react-select w-100 text-left`}
                    classNamePrefix="react-select"
                    onChange={handleChangeCoins}
                    options={allCoins}
                    value={symbol}
                    placeholder="ارز"
                    instanceId="coins"
                    name="coins"
                  />
                </FormGroup>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <BaseInput
                  id="fa"
                  label={" نام ارز به فارسی "}
                  name="fa"
                  placeholder="نام ارز به فارسی ..."
                  value={fa}
                  onChange={(e) => setFa(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <BaseInput
                  htmlFor
                  id="name"
                  label={" نام ارز به انگلیسی "}
                  placeholder="نام ارز به انگلیسی ..."
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="ml-3"
              onClick={() => setModalCentered(false)}
              variant="danger light"
            >
              بستن
            </Button>
            <DefaultButton
              type="submit"
              onClick={() => editPairCoins()}
              loading={loader}
              variant="primary"
            >
              ذخیره تغییرات
            </DefaultButton>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ModalPairCoins;
