import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { httpRequest } from "../../../_helper/httpServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NumberInput } from "../Defaults/NumberInput";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { useDispatch } from "react-redux";
import { getAvalibleBank } from "../../../_actions/CoiningActions";
import { DefaultButton } from "../Defaults/DefaultButton";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import {
  coiningActions,
  settingsAction,
  thirdPartyActions,
  tokenActions,
} from "../../../_actions";
import { BaseInput } from "../Defaults/BaseInput";
import UploadIcon from "./UploadIcon";

const TokensModal = ({
  children,
  onSelect,
  isEdit,
  tokenData,
  onSelectEdit,
}) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [loader, setLoader] = useState(false);
  const [networkCoins, setNetworkCoins] = useState([]);
  const [coinsList, setCoinsList] = useState([]);
  const [onVal, setOnVal] = useState({});
  const [coins, setCoins] = useState("");
  const [blockchain, setBlockchain] = useState("");
  const [contract, setContract] = useState("");
  const [network, setNetwork] = useState([]);
  const [blockchainErr, setBlockchainErr] = useState(false);
  const [symbolErr, setSymbolErr] = useState(false);
  const [contractErr, setContractErr] = useState(false);
  const [networkErr, setNetworkErr] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [netWorkOptions, setNetworkOptions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [hasEditThird, setHasEditThird] = useState(false);
  const [hasEditSetting, setHasEditSetting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [name, setName] = useState("");
  const [faName, setFaName] = useState("");
  const [decimal, setDecimal] = useState(null);
  const [id, setId] = useState("");
  const [iconRef, setIconRef] = useState(React.createRef());
  const [isExistImg, setIsExistImg] = useState(true);
  const [hasText, setHasText] = useState(false);
  const [isSaveToken, setIsSaveToken] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.write === true;
      });

      roles?.filter((item) => {
        if (item.scope === "thirdParty") setHasEditThird(true);
        if (item.scope === "setting") setHasEditSetting(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (modalCentered) {
      dispatch(settingsAction.getAvailableCoinNetwork())
        .then((r) => {
          setNetworkCoins(
            r?.data?.data?.map((item) => {
              return {
                value: item.id,
                label: item.fa,
                name: item.name,
                blockchain: item.blockchain,
                symbol: item.symbol,
                contract: item.contract,
                isActive: item.isActive,
                networks:
                  item.networks?.length !== 0 &&
                  item.networks?.map((x, index) => {
                    return x;
                  }),
                allNetworks: item?.networks?.map((x, index) => {
                  return { value: x, label: x };
                }),
              };
            })
          );
          // setNetworkOptions(
          //   r?.data?.data?.map((item) => {
          //     item?.networks?.map((x, index) => {
          //       return [{ value: index, label: x }];
          //     });
          //   })
          // );
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [modalCentered]);

  const handleChangeCoins = (selected) => {
    setCoins(selected);
    setName(selected?.name);
    setFaName(selected?.label);
    setBlockchain(selected?.blockchain);
    // setSymbol(selected?.symbol);
    setContract(selected?.contract);
    setChecked(selected?.isActive);
    setNetworkOptions(selected?.allNetworks);
    setNetwork(
      selected?.networks?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  };

  const handleChangeNetwork = (selected) => {
    setNetwork(selected);
  };

  useEffect(() => {
    if (blockchain !== "" && blockchain !== null) {
      setBlockchainErr(false);
    }
  }, [blockchain]);

  useEffect(() => {
    if (symbol !== "" && symbol !== null) {
      setSymbolErr(false);
    }
  }, [symbol]);

  useEffect(() => {
    if (contract !== "" && contract !== null) {
      setContractErr(false);
    }
  }, [contract]);

  useEffect(() => {
    if (isEdit) {
      setId(tokenData?._id);
      setFaName(tokenData?.fa);
      setSymbol(tokenData?.symbol);
      setCoins({ value: tokenData.id, label: tokenData.fa });
      setName(tokenData?.name);
      setBlockchain(tokenData?.blockchain);
      setNetwork({ value: tokenData?.network, label: tokenData?.network });
      setContract(tokenData?.contract);
      setChecked(tokenData?.isActive);
      setDecimal(tokenData?.decimal);
      setIsExistImg(true);
      setHasText(false);
      setIsSaveToken(true);
      setIconRef(React.createRef());
    } else if (!isEdit) {
      setFaName("");
      setSymbol("");
      setCoins({});
      setName("");
      setBlockchain("");
      setSymbol("");
      setNetwork({});
      setContract("");
      setDecimal(null);
      setChecked(false);
      setIsExistImg(true);
      setHasText(false);
      setIsSaveToken(false);
      setIconRef(React.createRef());
    }
  }, [isEdit, tokenData, modalCentered]);

  const saveToken = () => {
    if (decimal?.length <= 0 || decimal?.length >= 50) {
      ERROR("Decimal باید کمتر از ۵۰ رقم باشد.");
    } else {
      setLoader(true);
      const data = {
        symbol: symbol,
        name: name,
        fa: faName,
        blockchain: blockchain,
        network: Array.isArray(network)
          ? network?.length !== 0 &&
            network?.map((x) => {
              return x?.value;
            })[0]
          : network?.value,
        contract: contract,
        isActive: checked,
        decimal: parseFloat(decimal),
      };
      if (isEdit) {
        dispatch(tokenActions.updateToken(id, data))
          .then((r) => {
            r && SUCCESS(r?.data?.message || "اطلاعات با موفقیت ویرایش شد");
            setLoader(false);
            // setModalCentered(false);
            // setRefresh(true);
            // handleSelect(true);
            setIsSaveToken(true);
          })
          .catch((ex) => {
            setLoader(false);
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            });
          });
      } else {
        dispatch(tokenActions.createToken(data))
          .then((r) => {
            r && SUCCESS(r?.data?.message || "اطلاعات با موفقیت ثبت شد");
            setLoader(false);
            // setModalCentered(false);
            // setRefresh(true);
            // handleSelect(true);
            setIsSaveToken(true);
          })
          .catch((ex) => {
            setLoader(false);
            ERROR(ex.response?.data?.message || ex.message, {
              error: ex,
            });
          });
      }
    }
  };

  const statusHandleChange = () => {
    setChecked(!checked);
  };

  const checkImage = (e) => {
    // setIsExistImg(false);
    e.target.onerror = null;
    e.target.src = "./images/coins/noImg.png";
  };

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <a className="d-flex m-auto" onClick={() => setModalCentered(true)}>
        {children}
      </a>
      {/* <!-- Modal --> */}
      <Modal
        className="fade "
        show={modalCentered}
        size="md"
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="number-black">
            {isEdit ? "ویرایش توکن" : "افزودن توکن"}
          </h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              {!isSaveToken && !isEdit ? (
                <>
                  <div className="form-group col-md-12">
                    <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                      <Label for="status">بلاک چین</Label>
                      <Select
                        styles={customStyles}
                        className={`react-select w-100 text-left`}
                        classNamePrefix="react-select"
                        onChange={handleChangeCoins}
                        options={networkCoins}
                        value={coins}
                        placeholder="انتخاب کنید"
                        instanceId="coins"
                        name="coins"
                      />
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="blockChain"
                      value={blockchain}
                      onChange={(evt) => {
                        setBlockchain(evt.target.value);
                      }}
                      disabled
                      label={""}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                      <Label for="status">شبکه</Label>
                      <Select
                        styles={customStyles}
                        className={`react-select w-100 text-left`}
                        classNamePrefix="react-select"
                        onChange={handleChangeNetwork}
                        options={netWorkOptions}
                        value={network}
                        placeholder="انتخاب کنید"
                        instanceId="network"
                        name="network"
                      />
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="symbol"
                      value={symbol}
                      onChange={(evt) => {
                        setSymbol(evt.target.value);
                      }}
                      label={"سیمبل"}
                      error={symbolErr}
                      errorText="سیمبل نمی تواند خالی باشد"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="contract"
                      value={contract}
                      onChange={(evt) => {
                        setContract(evt.target.value);
                      }}
                      label={"Contract"}
                      error={contractErr}
                      errorText="contract نمی تواند خالی باشد"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <NumberInput
                      label={"Decimal"}
                      name="decimal"
                      allowLeadingZeros={true}
                      className="form-control"
                      onChange={(e) => setDecimal(e.target.value)}
                      value={decimal}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Label className="ml-2" for="status">
                      وضعیت
                    </Label>
                    <Switch
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={15}
                      width={28}
                      onColor="#1fa2ff"
                      onChange={statusHandleChange}
                      checked={checked}
                    />
                  </div>
                  <DefaultButton
                    className="w-100"
                    onClick={() => saveToken()}
                    loading={loader}
                    variant="primary"
                  >
                    {isEdit ? "ویرایش توکن" : "افزودن توکن"}
                  </DefaultButton>
                </>
              ) : isSaveToken && !isEdit ? (
                <UploadIcon
                  isExistImg={isExistImg}
                  symbol={symbol}
                  isEdit={isEdit}
                  isSaveToken={isSaveToken}
                />
              ) : !isSaveToken && isEdit ? (
                <>
                  <div className="form-group col-md-12">
                    <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                      <Label for="status">بلاک چین</Label>
                      <Select
                        styles={customStyles}
                        className={`react-select w-100 text-left`}
                        classNamePrefix="react-select"
                        onChange={handleChangeCoins}
                        options={networkCoins}
                        value={coins}
                        placeholder="انتخاب کنید"
                        instanceId="coins"
                        name="coins"
                      />
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="blockChain"
                      value={blockchain}
                      onChange={(evt) => {
                        setBlockchain(evt.target.value);
                      }}
                      disabled
                      label={""}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                      <Label for="status">شبکه</Label>
                      <Select
                        styles={customStyles}
                        className={`react-select w-100 text-left`}
                        classNamePrefix="react-select"
                        onChange={handleChangeNetwork}
                        options={netWorkOptions}
                        value={network}
                        placeholder="انتخاب کنید"
                        instanceId="network"
                        name="network"
                      />
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="symbol"
                      value={symbol}
                      onChange={(evt) => {
                        setSymbol(evt.target.value);
                      }}
                      label={"سیمبل"}
                      error={symbolErr}
                      errorText="سیمبل نمی تواند خالی باشد"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="contract"
                      value={contract}
                      onChange={(evt) => {
                        setContract(evt.target.value);
                      }}
                      label={"Contract"}
                      error={contractErr}
                      errorText="contract نمی تواند خالی باشد"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <NumberInput
                      label={"Decimal"}
                      name="decimal"
                      allowLeadingZeros={true}
                      className="form-control"
                      onChange={(e) => setDecimal(e.target.value)}
                      value={decimal}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Label className="ml-2" for="status">
                      وضعیت
                    </Label>
                    <Switch
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={15}
                      width={28}
                      onColor="#1fa2ff"
                      onChange={statusHandleChange}
                      checked={checked}
                    />
                  </div>
                  <DefaultButton
                    className="w-100"
                    onClick={() => saveToken()}
                    loading={loader}
                    variant="primary"
                  >
                    {isEdit ? "ویرایش توکن" : "افزودن توکن"}
                  </DefaultButton>
                </>
              ) : isSaveToken && isEdit ? (
                <>
                  <div className="form-group col-md-12">
                    <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                      <Label for="status">بلاک چین</Label>
                      <Select
                        styles={customStyles}
                        className={`react-select w-100 text-left`}
                        classNamePrefix="react-select"
                        onChange={handleChangeCoins}
                        options={networkCoins}
                        value={coins}
                        placeholder="انتخاب کنید"
                        instanceId="coins"
                        name="coins"
                      />
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="blockChain"
                      value={blockchain}
                      onChange={(evt) => {
                        setBlockchain(evt.target.value);
                      }}
                      disabled
                      label={""}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <FormGroup className="form-group mb-0 row mx-1 multi-select-style">
                      <Label for="status">شبکه</Label>
                      <Select
                        styles={customStyles}
                        className={`react-select w-100 text-left`}
                        classNamePrefix="react-select"
                        onChange={handleChangeNetwork}
                        options={netWorkOptions}
                        value={network}
                        placeholder="انتخاب کنید"
                        instanceId="network"
                        name="network"
                      />
                    </FormGroup>
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="symbol"
                      value={symbol}
                      onChange={(evt) => {
                        setSymbol(evt.target.value);
                      }}
                      label={"سیمبل"}
                      error={symbolErr}
                      errorText="سیمبل نمی تواند خالی باشد"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <BaseInput
                      className="form-control text-right"
                      id="contract"
                      value={contract}
                      onChange={(evt) => {
                        setContract(evt.target.value);
                      }}
                      label={"Contract"}
                      error={contractErr}
                      errorText="contract نمی تواند خالی باشد"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <NumberInput
                      label={"Decimal"}
                      name="decimal"
                      allowLeadingZeros={true}
                      className="form-control"
                      onChange={(e) => setDecimal(e.target.value)}
                      value={decimal}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <Label className="ml-2" for="status">
                      وضعیت
                    </Label>
                    <Switch
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={15}
                      width={28}
                      onColor="#1fa2ff"
                      onChange={statusHandleChange}
                      checked={checked}
                    />
                  </div>
                  <DefaultButton
                    className="w-100"
                    onClick={() => saveToken()}
                    loading={loader}
                    variant="primary"
                  >
                    {isEdit ? "ویرایش توکن" : "افزودن توکن"}
                  </DefaultButton>
                  <UploadIcon
                    isExistImg={isExistImg}
                    symbol={symbol}
                    isEdit={isEdit}
                    isSaveToken={isSaveToken}
                  />
                </>
              ) : null}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalCentered(false)}
            variant="danger light"
            className="ml-2"
          >
            بستن
          </Button>
          {isSaveToken ? (
            <DefaultButton
              onClick={() => setModalCentered(false)}
              loading={loader}
              variant="primary"
            >
              {isEdit ? "ویرایش" : "ذخیره"}
            </DefaultButton>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TokensModal;
