import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { ordersAction } from "./../../../_actions";
import { ERROR, SUCCESS } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";
import moment from "moment-jalaali";
import DatePicker from "react-datepicker2";
import { Link } from "react-router-dom";
import Select from "react-select";

const OrderTable = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );
  const [test, settest] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [description, setDescription] = useState("");
  const [ordersListData, setOrdersList] = useState([]);
  const [otcOrdersListData, setOtcOrdersList] = useState([]);
  const [myOrdersListData, setMyOrdersList] = useState([]);
  const [otcOrdersMeta, setOtcOrdersMeta] = useState([]);
  const [ordersMeta, setOrdersMeta] = useState([]);
  const [myOrdersMeta, setMyOrdersMeta] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [otcTotalItems, setOtcTotalItems] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeToggle, setActiveToggle] = useState("all");
  const [hasOrder, setHasOrder] = useState(false);
  const [coin, setCoin] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState({
    value: null,
    label: "همه",
    enLabel: "",
  });

  const [fromDate, setFromDate] = useState(null);
  const dateFrom = new Date(fromDate).toISOString();

  const toggleAll = () => {
    setCoin("");
    setAmount("");
    setType({
      value: null,
      label: "همه",
      enLabel: "",
    });
    setFromDate(moment(new Date()));
    setActiveToggle("all");
  };
  const toggleOtc = () => {
    setCoin("");
    setAmount("");
    setType({
      value: null,
      label: "همه",
      enLabel: "",
    });
    setFromDate(moment(new Date()));
    setActiveToggle("otc");
  };

  const onChangeDesc = (evt) => {
    setDescription(evt.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      let roles;
      roles = JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
        return x.activity?.read === true;
      });

      roles?.filter((item) => {
        if (item.scope === "order") setHasOrder(true);
      });
    }
  }, [localStorage.getItem("permissions")]);

  const getAllOrder = () => {
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getOrdersList({
          page,
          limit,
        })
      )
        .then((r) => {
          setOrdersList(r.data?.data);
          setOrdersMeta(r.data?.meta);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  useEffect(() => {
    getAllOrder();
  }, [hasOrder]);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    let types = type?.enLabel;
    let ip = "";
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getOrdersList({
          page,
          limit,
          dateFrom,
          ip,
          types,
          coin,
          amount,
        })
      )
        .then((r) => {
          setOrdersList(r.data?.data);
          setOrdersMeta(r.data?.meta);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }

  useEffect(() => {
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getOtcOrdersList({
          page,
          limit,
        })
      )
        .then((r) => {
          setOtcOrdersList(r.data?.data);
          setOtcOrdersMeta(r.data?.meta);
          setOtcTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }, [hasOrder]);

  async function handleOtcPageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    let types = type?.enLabel;
    let ip = "";
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getOtcOrdersList({
          page,
          limit,
          dateFrom,
          ip,
          types,
          coin,
          amount,
        })
      )
        .then((r) => {
          setOtcOrdersList(r.data?.data);
          setOtcOrdersMeta(r.data?.meta);
          setOtcTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  }

  const getAllMyOrder = () => {
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getMyOrdersList({
          page,
          limit,
        })
      )
        .then((r) => {
          setMyOrdersList(r.data?.data);
          setMyOrdersMeta(r.data?.meta);
          setTotalItems(r.data?.meta?.total);
        })
        .catch((ex) =>
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          })
        );
    }
  };

  useEffect(() => {
    getAllMyOrder();
  }, [hasOrder]);

  async function searchBy() {
    setLoader(true);
    let types = type?.enLabel;
    let page = 1;
    setPage(1);
    let ip = "";
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getOrdersList({
          page,
          limit,
          dateFrom,
          ip,
          types,
          coin,
          amount,
        })
      )
        .then((r) => {
          setOrdersList(r.data?.data);
          setOrdersMeta(r.data?.meta);
          setTotalItems(r.data?.meta?.total);
          setLoader(false);
        })
        .catch((ex) => {
          setLoader(true);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        });
    }
  }
  async function searchByOtc() {
    setLoader(true);
    let types = type?.enLabel;
    let page = 1;
    setPage(1);
    let ip = "";
    if (hasOrder || localStorage.getItem("role") === "admin") {
      dispatch(
        ordersAction.getOtcOrdersList({
          page,
          limit,
          dateFrom,
          ip,
          types,
          coin,
          amount,
        })
      )
        .then((r) => {
          setOtcOrdersList(r.data?.data);
          setOtcOrdersMeta(r.data?.meta);
          setOtcTotalItems(r.data?.meta?.total);
          setLoader(false);
        })
        .catch((ex) => {
          setLoader(true);
          ERROR(ex.response?.data?.message || ex.message, {
            error: ex,
          });
        });
    }
  }

  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    chackboxFun();
  }, [test]);

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  const FormatTime = (x) => {
    let time = new Date(x).toLocaleTimeString("fa-IR");
    return time;
  };
  const FormatDate = (x) => {
    let date = new Date(x).toLocaleDateString("fa-IR");
    return date;
  };
  const options = [
    { value: null, label: "همه", enLabel: "" },
    { value: 1, label: "خرید", enLabel: "buy" },
    { value: 2, label: "فروش", enLabel: "sell" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000" : "#000",
    }),
    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "#2c254a",
      borderColor: "#2c254a",
      borderRadius: 12,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.2 : 1;
      const transition = "opacity 300ms";
      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  const removeOrder = (orderId) => {
    dispatch(ordersAction.deleteAnOrder({ orderId }))
      .then((r) => {
        SUCCESS("سفارش با موفقیت حذف شد");
        getAllOrder();
      })
      .catch((ex) => {
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  };

  const handleTypeChange = (selected) => {
    setType(selected);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="profile-tab">
          <div className="custom-tab-1">
            <ul className="nav nav-tabs pr-2 my-3">
              <li className="nav-item">
                <Link
                  to="#all"
                  data-toggle="tab"
                  onClick={toggleAll}
                  className={`nav-link ${
                    activeToggle === "all" ? "active show" : ""
                  }`}
                >
                  همه سفارشات
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#otc"
                  data-toggle="tab"
                  onClick={toggleOtc}
                  className={`nav-link ${
                    activeToggle === "otc" ? "active show" : ""
                  }`}
                >
                  سفارشات OTC
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div
            id="all"
            className={`tab-pane fade ${
              activeToggle === "all" ? "active show" : ""
            }`}
          >
            <Row className="p-3">
              <Col lg={3} className="ml-0">
                <Select
                  styles={customStyles}
                  className={`react-select w-100 text-left`}
                  classNamePrefix="react-select"
                  placeholder={"نوع تراکنش"}
                  instanceId="types"
                  name="types"
                  value={type}
                  onChange={handleTypeChange}
                  options={options}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <div style={{ marginTop: "-15px" }}>
                  <label className="ml-2"></label>
                  <DatePicker
                    value={fromDate}
                    className="form-control"
                    placeholder={"از تاریخ"}
                    onChange={(fromDate) => setFromDate(fromDate)}
                    timePicker={false}
                    isGregorian={false}
                  />
                </div>
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ارز"}
                  id={"coin"}
                  name="coin"
                  value={coin}
                  onChange={(evt) => {
                    setCoin(evt.target.value);
                  }}
                />
              </Col>
              {/* <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"مقدار"}
                  id={"amount"}
                  name="amount"
                  value={amount}
                  onChange={(evt) => {
                    setAmount(evt.target.value);
                  }}
                />
              </Col> */}
              <Col lg={3}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchBy()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display  no-footer w-100  table-hover "
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {ordersListData && ordersListData?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            tabIndex={0}
                            className="sorting"
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            <span className="text-right d-block mr-4">
                              نام و نام خانوادگی
                            </span>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            نام ارز
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            نوع سفارش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                          >
                            مقدار
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                          >
                            قیمت واحد
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          >
                            قیمت (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                          >
                            نوع تراکنش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            تاریخ
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            ساعت
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {ordersListData && ordersListData?.length !== 0 ? (
                        ordersListData?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center   mt-3">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center mr-4">
                                  {item.user?.avatar ? (
                                    <>
                                      <img
                                        height="60px"
                                        src={item.user.avatar}
                                        className="rounded-circle ml-2 width40 height40"
                                        alt=""
                                      />
                                      <span className="fs-10 mr-2">
                                        {item.user?.firstName
                                          ? item.user?.firstName
                                          : "------"}{" "}
                                        {"   "}
                                        {item.user?.lastName}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <div class="img_cont_table">
                                        <img
                                          class="rounded-circle user_img"
                                          alt=""
                                        />
                                      </div>
                                      <span className="fs-10 mr-2">
                                        {item.user?.firstName
                                          ? item.user?.firstName
                                          : "------"}{" "}
                                        {"   "}
                                        {item.user?.lastName}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span className="ml-2 fs-11">{item?.coin}</span>
                                <img
                                  className="cc_size"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "./images/coins/noImg.png";
                                  }}
                                  src={
                                    process.env.REACT_APP_SOCKET_URL +
                                    `assets/icon/${item?.coin?.toLowerCase()}.png`
                                  }
                                />
                              </td>
                              <td>
                                {item?.status === "in_list"
                                  ? " سفارش باز - "
                                  : null}{" "}
                                {item?.submit}
                              </td>
                              <td>
                                <div className="fs-11">
                                  {item.priceUnit?.toLocaleString()
                                    ? item.priceUnit.toLocaleString()
                                    : "-----"}
                                </div>
                              </td>
                              <td>
                                <div className="fs-11">
                                  {item.price?.toLocaleString()
                                    ? item.price.toLocaleString()
                                    : "-----"}
                                </div>
                              </td>
                              <td>
                                <div
                                  className={
                                    item.type === "buy"
                                      ? "fs-9 btn-xs btn btn-success fs-8"
                                      : "fs-9 btn-xs btn btn-danger fs-8"
                                  }
                                >
                                  {item.type === "buy" ? " خرید " : "فروش"}
                                </div>
                              </td>
                              <td>
                                <div className="fs-11">
                                  {item.createdAt
                                    ? FormatDate(item.createdAt)
                                    : "-----"}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {item.createdAt
                                    ? FormatTime(item.createdAt)
                                    : "-----"}
                                </div>
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="table-dropdown i-false btn tp-btn-light sharp"
                                  >
                                    <span class="fs--1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <Link
                                        className="w-100 fs-11 text-secondary text-right"
                                        to={`orderDetails/${item._id}`}
                                      >
                                        نمایش جزئیات
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      {item.status === "in_list" ? (
                                        <a
                                          onClick={() => removeOrder(item._id)}
                                          className="w-100 fs-11 text-danger text-right"
                                        >
                                          حذف سفارش
                                        </a>
                                      ) : null}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {ordersMeta?.page * ordersMeta?.limit -
                        ordersMeta?.limit +
                        1}{" "}
                      تا {ordersMeta?.page * ordersMeta?.limit} از{" "}
                      {ordersMeta?.total} سفارش
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={ordersMeta?.limit}
                          totalItemsCount={ordersMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="otc"
            className={`tab-pane fade ${
              activeToggle === "otc" ? "active show" : ""
            }`}
          >
            <Row className="p-3">
              <Col lg={3} className="ml-0">
                <Select
                  styles={customStyles}
                  className={`react-select w-100 text-left`}
                  classNamePrefix="react-select"
                  placeholder={"نوع تراکنش"}
                  instanceId="types"
                  name="types"
                  value={type}
                  onChange={handleTypeChange}
                  options={options}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <div style={{ marginTop: "-15px" }}>
                  <label className="ml-2"></label>
                  <DatePicker
                    value={fromDate}
                    className="form-control"
                    placeholder={"از تاریخ"}
                    onChange={(fromDate) => setFromDate(fromDate)}
                    timePicker={false}
                    isGregorian={false}
                  />
                </div>
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ارز"}
                  id={"coin"}
                  name="coin"
                  value={coin}
                  onChange={(evt) => {
                    setCoin(evt.target.value);
                  }}
                />
              </Col>
              {/* <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"مقدار"}
                  id={"amount"}
                  name="amount"
                  value={amount}
                  onChange={(evt) => {
                    setAmount(evt.target.value);
                  }}
                />
              </Col> */}
              <Col lg={3}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchByOtc()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display  no-footer w-100  table-hover "
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {otcOrdersListData && otcOrdersListData?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            tabIndex={0}
                            className="sorting"
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            <span className="text-right d-block mr-4">
                              نام و نام خانوادگی
                            </span>
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            نام ارز
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                          >
                            مقدار
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                          >
                            قیمت واحد
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          >
                            قیمت (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                          >
                            نوع تراکنش
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            تاریخ
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            ساعت
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {otcOrdersListData && otcOrdersListData?.length !== 0 ? (
                        otcOrdersListData?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center   mt-3">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td className="py-4">
                                <div className="d-flex align-items-center mr-4">
                                  {item.user?.avatar ? (
                                    <>
                                      <span className="fs-10 mr-2">
                                        {item.user?.firstName
                                          ? item.user?.firstName
                                          : "------"}{" "}
                                        {"   "}
                                        {item.user?.lastName}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <div class="img_cont_table"></div>
                                      <span className="fs-10 mr-2">
                                        {item.user?.firstName
                                          ? item.user?.firstName
                                          : "------"}{" "}
                                        {"   "}
                                        {item.user?.lastName}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span className="ml-2 fs-11">{item?.coin}</span>
                                <img
                                  className="cc_size"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "./images/coins/noImg.png";
                                  }}
                                  src={
                                    process.env.REACT_APP_SOCKET_URL +
                                    `assets/icon/${item?.coin?.toLowerCase()}.png`
                                  }
                                />
                              </td>
                              <td>{item?.amount ? item?.amount : "-----"}</td>
                              <td>
                                <div className="fs-11">
                                  {item.priceUnit?.toLocaleString()
                                    ? item.priceUnit.toLocaleString()
                                    : "-----"}
                                </div>
                              </td>
                              <td>
                                <div className="fs-11">
                                  {item.price?.toLocaleString()
                                    ? item.price.toLocaleString()
                                    : "-----"}
                                </div>
                              </td>
                              <td>
                                <div
                                  className={
                                    item.type === "buy"
                                      ? "fs-9 btn-xs btn btn-success fs-8"
                                      : "fs-9 btn-xs btn btn-danger fs-8"
                                  }
                                >
                                  {item.type === "buy" ? " خرید " : "فروش"}
                                </div>
                              </td>
                              <td>
                                <div className="fs-11">
                                  {item.createdAt
                                    ? FormatDate(item.createdAt)
                                    : "-----"}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {item.createdAt
                                    ? FormatTime(item.createdAt)
                                    : "-----"}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {otcOrdersMeta?.page * otcOrdersMeta?.limit -
                        ordersMeta?.limit +
                        1}{" "}
                      تا {otcOrdersMeta?.page * otcOrdersMeta?.limit} از{" "}
                      {otcOrdersMeta?.total} سفارش OTC
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={otcOrdersMeta?.limit}
                          totalItemsCount={otcOrdersMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handleOtcPageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(OrderTable);
