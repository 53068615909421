import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BankAccount from "./BankAccount";
import Wages from "./Wages";
import Coins from "./Coins";
const CoiningTabs = () => {
  return (
    <div className="CoinDetail">
      <Tabs
        defaultActiveKey="BanckAccount"
        transition={false}
        id="noanim-tab-example"
      >
        <Tab eventKey="BanckAccount" title="تنظیمات فیات">
          <BankAccount />
        </Tab>
        <Tab eventKey="Wages" title="تنظیمات کوین ها">
          <Coins />
        </Tab>
        <Tab eventKey="Payment" title="تنظیمات سطوح کاربری">
          <Wages />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CoiningTabs;
