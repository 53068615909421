import { combineReducers } from "redux";

import { auth } from "./auth.reducer";
import { panel } from "./panel.reducer";
import {
  detailsOrdersReducer,
  getAllBankReducer,
  getAllWagesReducer,
  getAllCoinsReducer,
} from "./panel.reducer";

const rootReducer = combineReducers({
  auth,
  panel,
  selectOrder: detailsOrdersReducer,
  selectBank: getAllBankReducer,
  selectWages: getAllWagesReducer,
  selectCoins: getAllCoinsReducer,
});

export default rootReducer;
