import {httpRequest} from "../_helper";

export const profileAction = {
    getProfile,
    updateProfile,
    deleteAvatar,
    updateAvatar
};

function getProfile() {
    return () => {
        return httpRequest("get", "profile");
    };
}

function updateProfile(data) {
    return () => {
        return httpRequest("put", `profile`, {data});
    };
}

function deleteAvatar(data) {
    return () => {
        return httpRequest("delete", `profile/avatar`, {data});
    };
}

function updateAvatar(data) {
    return () => {
        return httpRequest("put", `profile/avatar`, {data});
    };
}
