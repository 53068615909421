import React, {Fragment, useEffect} from "react";
import PageTitle from "../../layouts/PageTitle";
import ThirdPartyTable from "./ThirdPartyTable";

const ThirdPartyList = () => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

    return (
        <Fragment>
            <PageTitle
                activeMenu="تنظیمات OTC"
                motherMenu="داشبورد"
                pageContent="تنظیمات OTC"
            />
            <div className="row">
                <ThirdPartyTable/>
            </div>
        </Fragment>
    );
};

export default ThirdPartyList;
