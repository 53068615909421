import { authConstants } from "../_constants/auth.constants";
import axios from "axios";
import { CONFIRM, ERROR, LOADING, SUCCESS } from "./../_helper/alerts";
import { history } from "./../_helper/history";
import { getResult } from "./../_helper";

const endpoint = process.env.REACT_APP_BASE_URL;

export const authActions = {
  requestOtp,
  verifyOtp,
  logout,
  verify2FA,
};

export const httpRequest = (
  method,
  url,
  { params, data, body, headers, ...rest } = {}
) => {
  if (!method) throw new Error("method not set");

  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios({
    baseURL,
    timeout: 120 * 1000,
    url,
    headers,
    params,
    data,
    body,
    method,
    ...rest,
  });
};

function requestOtp(mobile) {
  return (dispatch) => {
    dispatch(request());
    axios
      .post(endpoint + "auth/otp", {
        headers: {
          "Content-Type": "application/json",
        },
        mobile: mobile,
      })
      .then((res) => {
        getResult(res, authConstants.REQUEST_OTP, dispatch);
      })
      .catch((error) => {
        dispatch({
          type: authConstants.REQUEST_OTP,
          data: "error",
        });
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          localStorage.removeItem("balance");
          localStorage.removeItem("email");
          localStorage.removeItem("avatar");
          // window.location = "/signin";
        } else ERROR(error);
      });
    function request(res) {
      return { type: authConstants.REQUEST_OTP, res };
    }
  };
}

function verifyOtp(mobile, code, extra) {
  return (dispatch) => {
    dispatch(request());
    axios
      .post(endpoint + "auth/otp/verify", {
        headers: {
          "Content-Type": "application/json",
        },
        mobile: mobile,
        code: code,
        extra: extra,
      })
      .then((res) => {
        if (res.data.success === true) {
          const token = res.headers["x-auth-token"];

          localStorage.setItem("firstName", res.data.data.user.firstName);
          localStorage.setItem("balance", res.data.data.user.balance);
          localStorage.setItem("lastName", res.data.data.user.lastName);
          localStorage.setItem("email", res.data.data.user.email);
          localStorage.setItem("avatar", res.data.data.user.avatar);
          localStorage.setItem("userId", res.data.data.user.id);
          localStorage.setItem("role", res.data.data.user.role);
          localStorage.setItem("token", token);

          if (localStorage.getItem("role") !== "user") {
            history.push({
              pathname: `/`,
            });
          } else if (localStorage.getItem("role") === "user") {
            ERROR("کاربر گرامی شما اجازه ورود به پنل کاربری را ندارید");
          }
        } else if (res.data.success === false) {
          if (res?.data?.message === "تایید هویت دو مرحله‌ای نیاز است") {
            dispatch({
              type: authConstants.VERIFY_OTP,
              data: res.data.data,
            });
          } else {
            ERROR(res.data?.message);
            dispatch({
              type: authConstants.VERIFY_OTP,
              data: res.data.message,
            });
          }
        } else {
          ERROR("خطا");
          return;
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          ERROR("فرمت شماره موبایل صحیح نیست");
        } else if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          localStorage.removeItem("balance");
          localStorage.removeItem("email");
          localStorage.removeItem("avatar");
          // window.location = "/signin";
        } else ERROR(error);
      });

    function request(res) {
      return { type: authConstants.VERIFY_OTP, res };
    }
  };
}

function verify2FA(preToken, code, extra) {
  return (dispatch) => {
    dispatch(request());
    axios
      .post(endpoint + "auth/2fa/verify", {
        headers: {
          "Content-Type": "application/json",
        },
        preToken: preToken,
        code: code,
        extra: extra,
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch({
            type: authConstants.VERIFY_2FA,
            data: res.data.data,
          });

          localStorage.removeItem("token");
          localStorage.setItem("token", res.headers["x-auth-token"]);
          localStorage.setItem("avatar", res.data?.data?.user?.avatar);
          localStorage.setItem("firstName", res.data?.data?.user?.firstName);
          localStorage.setItem("lastName", res.data?.data?.user?.lastName);
          localStorage.setItem("email", res.data?.data?.user?.email);
          localStorage.setItem("role", res.data?.data?.user?.role);
          localStorage.setItem("balance", res.data?.data?.user?.balance);
          localStorage.setItem("role", res.data.data.user.role);
          localStorage.setItem("userId", res.data.data.user.id);

          history.push({
            pathname: `/`,
          });
        } else if (res.data.success === false) {
          ERROR(res.data?.message);
        } else {
          ERROR("خطا");
          return;
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          // window.location = "/signin";
          localStorage.removeItem("token");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          localStorage.removeItem("balance");
          localStorage.removeItem("email");
          localStorage.removeItem("avatar");
        } else ERROR(error);
      });

    function request(res) {
      return { type: authConstants.VERIFY_2FA, res };
    }
  };
}

function logout(data) {
  return (dispatch) => {
    dispatch(request());
    return httpRequest("delete", "auth/token", { data })
      .then((res) => {
        console.log(res);
        if (res.data?.data?.user) {
          dispatch({
            type: authConstants.LOGOUT,
            data: res.data,
          });
          localStorage.removeItem("token");
          localStorage.removeItem("avatar");
          localStorage.removeItem("firstName");
          localStorage.removeItem("lastName");
          localStorage.removeItem("email");
          localStorage.removeItem("role");
          localStorage.removeItem("balance");
          // window.location.href = "/";
        }
      })
      .catch((e) => {
        if (e?.response?.data) ERROR(e?.response?.data?.message);
        // else ERROR(e?.message);
      });
    function request(res) {
      return { type: authConstants.LOGOUT, res };
    }
  };
}
