import { Coining } from "../_constants/panel.constants";
import { httpRequest } from "../_helper/httpServices";

//// getAvalibleBANKS
export const getAvalibleBank = () => async (dispatch) => {
  dispatch({ type: Coining.GET_AVALIBLE_BANKS_REQUSET });

  try {
    const { data } = await httpRequest("get", "settings/banks");

    dispatch({ type: Coining.GET_AVALIBLE_BANKS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: Coining.GET_AVALIBLE_BANKS_FAIL, payload: error.message });
  }
};

////getAvaliblewages
export const getAvalibleWages = () => async (dispatch) => {
  dispatch({ type: Coining.GET_AVALIBLE_WAGES_REQUSET });

  try {
    const { data } = await httpRequest("get", "settings/wages");
    dispatch({ type: Coining.GET_AVALIBLE_WAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: Coining.GET_AVALIBLE_WAGES_FAIL, payload: error.message });
  }
};

////getAvailableCoins
export const getAvailableCoins = () => async (dispatch) => {
  dispatch({ type: Coining.GET_AVALIBLE_COINS_REQUSET });

  try {
    const { data } = await httpRequest("get", "settings/coins");
    dispatch({ type: Coining.GET_AVALIBLE_COINS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: Coining.GET_AVALIBLE_COINS_FAIL, payload: error.message });
  }
};
