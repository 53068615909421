import {httpRequest} from "./../_helper";

export const affiliateActions = {
    getAllAffiliate,
    deleteAnAffiliate,
};

function getAllAffiliate({page, limit}) {
    return () => {
        return httpRequest(
            "get",
            `affiliates/admin?page=${page}&limit=${limit}`
        );
    };
}

function deleteAnAffiliate(id) {
    return () => {
        return httpRequest("delete", `affiliates/admin/${id}`);
    };
}
