import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

const ReadCheckbox = ({ onSelectReadChecked, item }) => {
  const [readVal, setReadVal] = useState(false);
  const [readType, setReadType] = useState("");

  const handleReadChange = (e) => {
    setReadVal(e.target.checked);
    onSelectReadChecked(e.target.checked, item.scope);
  };
  const chackboxFun = (type) => {
    // onSelectReadType(type, item.scope);
  };

  return (
    <div className="custom-control custom-checkbox d-inline">
      <input
        type="checkbox"
        onClick={() => chackboxFun(`${item.scope}Read`)}
        className="custom-control-input"
        id={`customCheckBox${item.scope}Read`}
        onChange={handleReadChange}
        checked={readVal}
        required
      />
      <label
        className="custom-control-label"
        htmlFor={`customCheckBox${item.scope}Read`}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ReadCheckbox);
