import {httpRequest} from "./../_helper";

export const traceActions = {
    getAllTraces,
};

function getAllTraces({ page, limit, userId }) {
    return () => {
        return httpRequest(
            "get",
            `traces?page=${page}&limit=${limit}&search=${
                userId ? `userId` : ""
            }&query=${userId ? `${userId}` : ""}`
        );
    };
}
