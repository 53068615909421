import React, { useState, useEffect } from "react";
import { httpRequest } from "../../../_helper/httpServices";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import { NumberInput } from "../Defaults/NumberInput";
import { Button } from "react-bootstrap";
import { DefaultButton } from "../Defaults/DefaultButton";
import Switch from "react-switch";
import { Modal } from "react-bootstrap";
import { coiningActions, tokenActions, robotsActions } from "../../../_actions";
import { useDispatch } from "react-redux";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { BaseInput } from "../Defaults/BaseInput";

const RobotUpdate = ({ tabs, children, robotsData, userId }) => {
  //state
  const [loader, setLoader] = useState(false);
  const [modalCentered, setModalCentered] = useState(false);
  const [allCoins, setAllCoins] = useState([]);
  const [uId, setUid] = useState("");
  const [userIp, setUserIp] = useState("");
  //state disposite
  const [minAmountBuy, setMinAmountBuy] = useState(0);
  const [minAmountTradeBuy, setMinAmountTradeBuy] = useState(0);
  const [minAmountTradeSell, setMinAmountTradeSell] = useState(0);
  const [maxAmountBuy, setMaxAmountBuy] = useState(0);
  const [maxAmountSell, setMaxAmountSell] = useState(0);
  const [maxAmountTradeBuy, setMaxAmountTradeBuy] = useState(0);
  const [maxAmountTradeSell, setMaxAmountTradeSell] = useState(0);
  const [maxPriceFactorTradeBuy, setMaxPriceFactorTradeBuy] = useState(0);
  const [skipOnCountBuy, setSkipOnCountBuy] = useState(0);
  const [skipOnCountTradeBuy, setSkipOnCountTradeBuy] = useState(0);
  const [skipOnCountTradeSell, setSkipOnCountTradeSell] = useState(0);
  const [skipOnMinuteTradeBuy, setSkipOnMinuteTradeBuy] = useState(0);
  const [skipOnMinuteTradeSell, setSkipOnMinuteTradeSell] = useState(0);
  const [minPriceFactorBuy, setMinPriceFactorBuy] = useState(0);
  const [maxPriceFactorBuy, setMaxPriceFactorBuy] = useState(0);
  const [minPriceFactorTradeSell, setMinPriceFactorTradeSell] = useState(0);
  const [maxPriceFactorTradeSell, setMaxPriceFactorTradeSell] = useState(0);
  const [minAmountSell, setMinAmountSell] = useState(0);
  const [skipOnCountSell, setSkipOnCountSell] = useState(0);
  const [minPriceFactorSell, setMinPriceFactorSell] = useState(0);
  const [maxPriceFactorSell, setMaxPriceFactorSell] = useState(0);
  const [checked, setChecked] = useState(0);
  const [tokensList, setTokensList] = useState([]);
  const [robotsList, setRobotsList] = useState([]);
  const [coin, setCoin] = useState("");
  const [pair, setPair] = useState("");
  const [pairCoin, setPairCoin] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [allRobotsData, setAllRobotsData] = useState([]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "#303031",
      textAlign: "right",
      backgroundColor: "transparent",
    }),

    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "transparent",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };

  const getPairCoins = () => {
    httpRequest("get", "settings/pairs")
      .then((res) => {
        let options = res.data.data?.map((item) => {
          return {
            value: item.symbol,
            label: `${item.symbol.toUpperCase()}`,
          };
        });
        let irt = [{ value: "irt", label: "IRT" }];
        let result = [options, [...irt]];

        const concatArr = [].concat(...result);
        setPairCoin(concatArr);
      })
      .catch((err) => ERROR(err));
  };
  useEffect(() => {
    getPairCoins();
  }, []);

  useEffect(() => {
    dispatch(tokenActions.getAllTokens({ page, limit }))
      .then((r) => {
        const seen = new Set();
        const filteredArr = r.data?.data.filter((el) => {
          const duplicate = seen.has(el.symbol);
          seen.add(el.symbol);
          return !duplicate;
        });
        setTokensList(filteredArr);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, [modalCentered]);

  useEffect(() => {
    dispatch(coiningActions.getPreDefinedCoins())
      .then((r) => {
        const mergedArr = [...r?.data?.data, ...tokensList];
        const arr = mergedArr?.map((item) => {
          return {
            value: item.symbol,
            label: `${item.symbol.toUpperCase()}`,
            labelFa: item?.fa,
            name: item?.name,
            isActive: item?.isActive,
          };
        });

        var dataArr = arr.map((item) => {
          return [JSON.stringify(item), item];
        });
        var mapArr = new Map(dataArr);

        var result = [...mapArr.values()];

        setAllCoins(result);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, [modalCentered]);

  const handleChangeCoins = (selected) => {
    setCoin(selected);
    setChecked(selected.isActive);
  };

  const handleChangePairs = (selected) => {
    setPair(selected);
  };

  const statusHandleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setRobotsList(
      robotsData?.filter((x) => {
        return x?.coin === tabs;
      })[0]
    );
  }, [tabs, robotsData]);

  useEffect(() => {
    dispatch(robotsActions.getConfigRobots())
      .then((r) => {
        console.log(r?.data?.data);
        setAllRobotsData(r?.data?.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  function getUniqueArray(arr = [], compareProps = []) {
    let modifiedArray = [];
    if (compareProps.length === 0 && arr.length > 0)
      compareProps.push(...Object.keys(arr[0]));
    arr.forEach((item) => {
      if (modifiedArray.length === 0) {
        modifiedArray.push(item);
      } else {
        if (
          !modifiedArray.some((item2) =>
            compareProps.every(
              (eachProps) => item2[eachProps] === item[eachProps]
            )
          )
        ) {
          modifiedArray.push(item);
        }
      }
    });
    return modifiedArray;
  }

  async function saveToken() {
    let dataList = allRobotsData;

    dataList?.push({
      coin: coin?.value,
      pair: pair?.value,
      order: {
        buy: {
          minAmount:
            typeof minAmountBuy === "string"
              ? parseFloat(minAmountBuy?.replaceAll(",", ""))
              : minAmountBuy,
          maxAmount:
            typeof maxAmountBuy === "string"
              ? parseFloat(maxAmountBuy?.replaceAll(",", ""))
              : maxAmountBuy,
          minPriceFactor:
            typeof minPriceFactorBuy === "string"
              ? parseFloat(minPriceFactorBuy?.replaceAll(",", ""))
              : minPriceFactorBuy,
          maxPriceFactor:
            typeof maxPriceFactorBuy === "string"
              ? parseFloat(maxPriceFactorBuy?.replaceAll(",", ""))
              : maxPriceFactorBuy,
          skipOnCount:
            typeof skipOnCountBuy === "string"
              ? parseFloat(skipOnCountBuy?.replaceAll(",", ""))
              : skipOnCountBuy,
        },
        sell: {
          minAmount:
            typeof minAmountSell === "string"
              ? parseFloat(minAmountSell?.replaceAll(",", ""))
              : minAmountSell,
          maxAmount:
            typeof maxAmountSell === "string"
              ? parseFloat(maxAmountSell?.replaceAll(",", ""))
              : maxAmountSell,
          minPriceFactor:
            typeof minPriceFactorSell === "string"
              ? parseFloat(minPriceFactorSell?.replaceAll(",", ""))
              : minPriceFactorSell,
          maxPriceFactor:
            typeof maxPriceFactorSell === "string"
              ? parseFloat(maxPriceFactorSell?.replaceAll(",", ""))
              : maxPriceFactorSell,
          skipOnCount:
            typeof skipOnCountSell === "string"
              ? parseFloat(skipOnCountSell?.replaceAll(",", ""))
              : skipOnCountSell,
        },
      },
      trade: {
        buy: {
          minAmount:
            typeof minAmountTradeBuy === "string"
              ? parseFloat(minAmountTradeBuy?.replaceAll(",", ""))
              : minAmountTradeBuy,
          maxAmount:
            typeof maxAmountTradeBuy === "string"
              ? parseFloat(maxAmountTradeBuy?.replaceAll(",", ""))
              : maxAmountTradeBuy,
          maxPriceFactor:
            typeof maxPriceFactorTradeBuy === "string"
              ? parseFloat(maxPriceFactorTradeBuy?.replaceAll(",", ""))
              : maxPriceFactorTradeBuy,
          skipOnCount:
            typeof skipOnCountTradeBuy === "string"
              ? parseFloat(skipOnCountTradeBuy?.replaceAll(",", ""))
              : skipOnCountTradeBuy,
          skipOnMinutes:
            typeof skipOnMinuteTradeBuy === "string"
              ? parseFloat(skipOnMinuteTradeBuy?.replaceAll(",", ""))
              : skipOnMinuteTradeBuy,
        },
        sell: {
          minAmount:
            typeof minAmountTradeSell === "string"
              ? parseFloat(minAmountTradeSell?.replaceAll(",", ""))
              : minAmountTradeSell,
          maxAmount:
            typeof maxAmountTradeSell === "string"
              ? parseFloat(maxAmountTradeSell?.replaceAll(",", ""))
              : maxAmountTradeSell,
          minPriceFactor:
            typeof minPriceFactorTradeSell === "string"
              ? parseFloat(minPriceFactorTradeSell?.replaceAll(",", ""))
              : minPriceFactorTradeSell,
          skipOnCount:
            typeof skipOnCountTradeSell === "string"
              ? parseFloat(skipOnCountTradeSell?.replaceAll(",", ""))
              : skipOnCountTradeSell,
          skipOnMinutes:
            typeof skipOnMinuteTradeSell === "string"
              ? parseFloat(skipOnMinuteTradeSell?.replaceAll(",", ""))
              : skipOnMinuteTradeSell,
        },
      },
      isActive: checked,
    });

    const uniqueCoins = getUniqueArray(dataList);
    const data = { userId: uId, userIp: userIp, data: uniqueCoins };

    setLoader(true);
    await httpRequest("put", `settings/robots`, { data })
      .then((r) => {
        if (r.status === 200) {
          SUCCESS(r.data.message);
          setLoader(false);
          setModalCentered(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        ERROR(err.response?.data?.message || err.message, {
          error: err,
        });
      });
  }

  return (
    <>
      <a className="d-flex mr-auto" onClick={() => setModalCentered(true)}>
        {children}
      </a>
      <Modal
        className="fade customeModal"
        show={modalCentered}
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="number-black">افزودن ربات</h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-between align-align-items-center">
              <div className="form-group col-md-12">
                <Label className="ml-3 mb-0 fs-12" for="status">
                  وضعیت
                </Label>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={15}
                  width={28}
                  onColor="#1fa2ff"
                  onChange={statusHandleChange}
                  checked={checked}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="col-12">
              <BaseInput
                placeholder={"شناسه کاربر"}
                label={"شناسه کاربر"}
                id={"uId"}
                name="uId"
                value={uId}
                onChange={(evt) => {
                  setUid(evt.target.value);
                }}
              />
            </div>
            <div className="col-12">
              <BaseInput
                placeholder={"IP کاربر"}
                label={"IP کاربر"}
                id={"userIp"}
                name="userIp"
                value={userIp}
                onChange={(evt) => {
                  setUserIp(evt.target.value);
                }}
              />
            </div>
            <div className="col-12 mt-3">
              <FormGroup className="form-group multi-select-style">
                <Label for="status">ارز</Label>
                <Select
                  styles={customStyles}
                  className={`react-select w-100 text-left`}
                  classNamePrefix="react-select"
                  onChange={handleChangeCoins}
                  options={allCoins}
                  value={coin}
                  placeholder="ارز"
                  instanceId="coins"
                  name="coins"
                />
              </FormGroup>
            </div>
            <div className="col-12 mb-5">
              <FormGroup className="form-group multi-select-style">
                <Label for="status">جفت ارز</Label>
                <Select
                  styles={customStyles}
                  className={`react-select w-100 text-left`}
                  classNamePrefix="react-select"
                  onChange={handleChangePairs}
                  options={pairCoin}
                  value={pair}
                  placeholder="جفت ارز"
                  instanceId="pair"
                  name="pair"
                />
              </FormGroup>
            </div>
            <h5 className="mb-3 mt-3 title__Modla">
              سفارش <span className="text-success">(خرید)</span>
            </h5>
            <div className="row">
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="minAmountBuy"
                  name="minAmountBuy"
                  label={"کمترین مقدار"}
                  className="form-control"
                  value={minAmountBuy}
                  onChange={(e) => setMinAmountBuy(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="maxAmountBuy"
                  label={"بیشترین مقدار"}
                  name="maxAmountBuy"
                  className="form-control"
                  value={maxAmountBuy}
                  onChange={(e) => setMaxAmountBuy(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="minPriceFactorBuy"
                  placeholder="به درصد"
                  label={"حداقل درصد قیمت (%)"}
                  name="minPriceFactorBuy"
                  value={minPriceFactorBuy}
                  className="form-control"
                  onChange={(e) => setMinPriceFactorBuy(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  id="maxPriceFactorBuy"
                  label={"حداکثر درصد قیمت (%)"}
                  name="maxPriceFactorBuy"
                  className="form-control"
                  value={maxPriceFactorBuy}
                  onChange={(e) => setMaxPriceFactorBuy(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="skipOnCountBuy"
                  label={"Skip on count"}
                  name="skipOnCountBuy"
                  className="form-control"
                  value={skipOnCountBuy}
                  onChange={(e) => setSkipOnCountBuy(e.target.value)}
                />
              </div>
            </div>

            <h5 className="mb-3 mt-3 title__Modla">
              سفارش <span className="text-danger">(فروش)</span>
            </h5>
            <div className="row">
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="minAmountSell"
                  name="minAmountSell"
                  label={"کمترین مقدار"}
                  className="form-control"
                  value={minAmountSell}
                  onChange={(e) => setMinAmountSell(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="maxAmountSell"
                  label={"بیشترین مقدار"}
                  name="maxAmountSell"
                  className="form-control"
                  value={maxAmountSell}
                  onChange={(e) => setMaxAmountSell(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="minPriceFactorSell"
                  placeholder="به درصد"
                  label={"حداقل درصد قیمت (%)"}
                  name="minPriceFactorSell"
                  value={minPriceFactorSell}
                  className="form-control"
                  onChange={(e) => setMinPriceFactorSell(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  id="maxPriceFactorSell"
                  label={"حداکثر درصد قیمت (%)"}
                  name="maxPriceFactorSell"
                  className="form-control"
                  value={maxPriceFactorSell}
                  onChange={(e) => setMaxPriceFactorSell(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="skipOnCountSell"
                  label={"Skip on count"}
                  name="skipOnCountSell"
                  className="form-control"
                  value={skipOnCountSell}
                  onChange={(e) => setSkipOnCountSell(e.target.value)}
                />
              </div>
            </div>

            <h5 className="mb-3 mt-3 title__Modla">
              معامله <span className="text-success">(خرید)</span>
            </h5>
            <div className="row">
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="minAmountTradeBuy"
                  name="minAmountTradeBuy"
                  label={"کمترین مقدار"}
                  className="form-control"
                  value={minAmountTradeBuy}
                  onChange={(e) => setMinAmountTradeBuy(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  id="maxAmountTradeBuy"
                  label={"بیشترین مقدار"}
                  name="maxAmountTradeBuy"
                  className="form-control"
                  value={maxAmountTradeBuy}
                  onChange={(e) => setMaxAmountTradeBuy(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="maxPriceFactorTradeBuy"
                  placeholder="به درصد"
                  label={"حداکثر درصد قیمت (%)"}
                  name="maxPriceFactorTradeBuy"
                  value={maxPriceFactorTradeBuy}
                  className="form-control"
                  onChange={(e) => setMaxPriceFactorTradeBuy(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="skipOnCountTradeBuy"
                  label={"Skip on count"}
                  name="skipOnCountTradeBuy"
                  className="form-control"
                  value={skipOnCountTradeBuy}
                  onChange={(e) => setSkipOnCountTradeBuy(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="skipOnMinuteTradeBuy"
                  label={"Skip on minutes"}
                  name="skipOnMinuteTradeBuy"
                  className="form-control"
                  value={skipOnMinuteTradeBuy}
                  onChange={(e) => setSkipOnMinuteTradeBuy(e.target.value)}
                />
              </div>
            </div>

            <h5 className="mb-3 mt-3 title__Modla">
              معامله <span className="text-danger">(فروش)</span>
            </h5>
            <div className="row">
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="minAmountTradeSell"
                  name="minAmountTradeSell"
                  label={"کمترین مقدار"}
                  className="form-control"
                  value={minAmountTradeSell}
                  onChange={(e) => setMinAmountTradeSell(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  id="maxAmountTradeSell"
                  label={"بیشترین مقدار"}
                  name="maxAmountTradeSell"
                  className="form-control"
                  value={maxAmountTradeSell}
                  onChange={(e) => setMaxAmountTradeSell(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <NumberInput
                  htmlFor
                  id="minPriceFactorTradeSell"
                  placeholder="به درصد"
                  label={"حداقل درصد قیمت (%)"}
                  name="minPriceFactorTradeSell"
                  value={minPriceFactorTradeSell}
                  className="form-control"
                  onChange={(e) => setMinPriceFactorTradeSell(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="skipOnCountTradeSell"
                  label={"Skip on count"}
                  name="skipOnCountTradeSell"
                  className="form-control"
                  value={skipOnCountTradeSell}
                  onChange={(e) => setSkipOnCountTradeSell(e.target.value)}
                />
              </div>
              <div className="col-md-2 col-sm-6 col-xs-12">
                <NumberInput
                  id="skipOnMinuteTradeSell"
                  label={"Skip on minutes"}
                  name="skipOnMinuteTradeSell"
                  className="form-control"
                  value={skipOnMinuteTradeSell}
                  onChange={(e) => setSkipOnMinuteTradeSell(e.target.value)}
                />
              </div>
            </div>
            <div className="row pull-left mt-3">
              <DefaultButton
                type="submit"
                onClick={() => saveToken()}
                loading={loader}
                variant="primary"
              >
                ذخیره تغییرات
              </DefaultButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RobotUpdate;
