import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { thirdPartyActions, usersAction } from "../../../_actions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../../_helper/alerts";
import "react-image-lightbox/style.css";
import { Col, Row } from "react-bootstrap";
import { BaseInput } from "../Defaults/BaseInput";
import Select from "react-select";
import { DefaultButton } from "../Defaults/DefaultButton";
import Pagination from "react-js-pagination";

const KUCoinDetails = ({ dispatch }) => {
  const [balances, setBalances] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState([]);
  const [ordersHistoryMeta, setOrdersHistoryMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(thirdPartyActions.getKUCoinBalance())
      .then((r) => {
        setBalances(r.data?.data);
        console.log(r);
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  }, []);

  useEffect(() => {
    dispatch(thirdPartyActions.getKUOrderHistory())
      .then((r) => {
        setOrdersHistory(r.data?.data?.data?.itms);
        setOrdersHistoryMeta(r.data?.data?.data);
        console.log(r);
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  }, []);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(thirdPartyActions.getKUOrderHistory({ page, limit }))
      .then((r) => {
        setOrdersHistory(r.data?.data);
        setOrdersHistoryMeta(r.data?.data);
        console.log(r);
      })
      .catch((e) =>
        ERROR(e.response?.data?.message || e.message, {
          error: e,
        })
      );
  }

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title fs-14">لیست موجودی ها</h4>
        </div>
        <div className="card-body pt-0">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                role="grid"
                aria-describedby="example5_info"
              >
                {balances?.data?.length !== 0 && (
                  <thead>
                    <tr className="text-center" role="row">
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        نوع ارز
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        نوع
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Date Check in: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        Balance
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient Name: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        Available
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Holds
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {balances?.data?.length !== 0 ? (
                    balances?.data?.map((item, index) => {
                      return (
                        <tr role="row" className="odd text-center">
                          <td>
                            <span className="ml-2">
                              {item.currency?.toUpperCase()}
                            </span>
                            <img
                              className="cc_size fs-14"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "./images/coins/noImg.png";
                              }}
                              src={
                                process.env.REACT_APP_SOCKET_URL +
                                `assets/icon/${item?.currency?.toLowerCase()}.png`
                              }
                            />
                          </td>
                          <td>{item.type}</td>
                          <td>{item.balance}</td>
                          <td>{item.available}</td>

                          <td>{item.holds}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="card-content bg-primary p-3 rounded text-center">
                      <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-header">
          <h4 className="card-title fs-14">لیست تراکنش سفارشات</h4>
        </div>
        <div className="card-body pt-0">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                role="grid"
                aria-describedby="example5_info"
              >
                {ordersHistory?.length !== 0 && (
                  <thead>
                    <tr className="text-center" role="row">
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        Symbol
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient ID: activate to sort column ascending"
                        // style={{ width: 73 }}
                      >
                        OpType
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Date Check in: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        Type
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Patient Name: activate to sort column ascending"
                        // style={{ width: 100 }}
                      >
                        Side
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Price
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Size
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Funds
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Fee
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Fee Currency
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Doctor Assgined: activate to sort column ascending"
                        // style={{ width: 120 }}
                      >
                        Stop Price
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {ordersHistory?.length !== 0 ? (
                    ordersHistory?.map((item, index) => {
                      return (
                        <tr role="row" className="odd text-center">
                          <td>{item.symbol} </td>
                          <td>{item.opType}</td>
                          <td>{item.type}</td>
                          <td>{item.side}</td>

                          <td>{item.size}</td>
                          <td>{item.funds}</td>
                          <td>{item.fee}</td>
                          <td>{item.feeCurrency}</td>
                          <td>{item.stopPrice}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="card-content bg-primary p-3 rounded text-center">
                      <span className="">اطلاعاتی جهت نمایش وجود ندارد</span>
                    </div>
                  )}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  نمایش{" "}
                  {ordersHistoryMeta?.currentPage *
                    ordersHistoryMeta?.pageSize -
                    ordersHistoryMeta?.pageSize +
                    1}{" "}
                  تا{" "}
                  {ordersHistoryMeta?.currentPage * ordersHistoryMeta?.pageSize}{" "}
                  از {ordersHistoryMeta?.totalPage} سفارش
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <div className="d-inline-block pb-3 ltr_direction">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={ordersHistoryMeta?.pageSize}
                      totalItemsCount={ordersHistoryMeta?.total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(KUCoinDetails);
