/// Menu
import MetisMenu from "metismenujs";
import React, { useState, Component, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { rolesAction } from "./../../../_actions";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const [roles, setRoles] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [hasNoPermission, setHasNoPermission] = useState(false);
  const [hasOrder, setHasOrder] = useState(false);
  const [hasRole, setHasRole] = useState(false);
  const [hasBanking, setHasBanking] = useState(false);
  const [hasCoining, setHasCoining] = useState(false);
  const [hasSetting, setHasSetting] = useState(false);
  const [hasAffiliate, setHasAffiliate] = useState(false);
  const [hasUser, setHasUser] = useState(false);
  const [hasTrace, setHasTrace] = useState(false);
  const [hasThirdParty, setHasThirdParty] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshboard = [""],
    users = ["users-list", "create-user"],
    profile = ["app-profile"],
    account = ["confirmations-list"],
    role = ["role-list", "create-role"],
    order = ["orders-list"],
    fee = ["coin-details"],
    robot = ["robots"],
    blocks = ["block-items"],
    pairs = ["pair-coins"],
    affiliate = ["affiliate"],
    thirdParty = ["thirdParty"],
    tokens = ["tokens"],
    coining = ["coining-list"],
    banking = ["banking-list"];

  useEffect(() => {
    if (localStorage.getItem("role") === "user") {
      window.location = "/page-login";
    }
    if (
      localStorage.getItem("role") !== "admin" &&
      localStorage.getItem("role") !== "user"
    ) {
      dispatch(rolesAction.getRole(localStorage.getItem("role")))
        .then((res) => {
          console.log(res?.data?.data);
          localStorage.setItem(
            "permissions",
            JSON.stringify(res?.data?.data?.permissions)
          );
          // setRoles(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (localStorage.getItem("role") === "admin") {
      localStorage.setItem("isAdmin", JSON.stringify(true));
    } else if (localStorage.getItem("role") === "user") {
      localStorage.setItem("isUser", JSON.stringify(true));
    }
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    if (localStorage.getItem("permissions") !== "undefined") {
      // let roles;
      setRoles(
        JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
          return x.activity?.read === true;
        })
      );
      console.log(
        JSON.parse(localStorage.getItem("permissions"))?.filter((x) => {
          return x.activity?.read === true;
        })
      );
    }
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    roles?.map((item) => {
      item.scope === "order" ? setHasOrder(true) : setHasNoPermission(false);
      item.scope === "user" ? setHasUser(true) : setHasNoPermission(false);
      item.scope === "role" ? setHasRole(true) : setHasNoPermission(false);
      item.scope === "affiliate"
        ? setHasAffiliate(true)
        : setHasNoPermission(false);
      item.scope === "trace" ? setHasTrace(true) : setHasNoPermission(false);
      item.scope === "thirdParty"
        ? setHasThirdParty(true)
        : setHasNoPermission(false);
      item.scope === "banking"
        ? setHasBanking(true)
        : setHasNoPermission(false);
      item.scope === "coining"
        ? setHasCoining(true)
        : setHasNoPermission(false);
      item.scope === "setting"
        ? setHasSetting(true)
        : setHasNoPermission(false);
    });
  }, [localStorage.getItem("permissions"), roles]);

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <div className="main-profile">
          <img src={localStorage.getItem("avatar")} alt="" />
          <Link to={"#"}>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </Link>

          {localStorage.getItem("firstName") !== "null" ? (
            <h5 className="mb-0 fs-17 text-black ">
              <span className="font-w400">سلام، </span>{" "}
              {localStorage.getItem("firstName")}{" "}
            </h5>
          ) : null}
          <p className="mb-0 fs-14 font-w400">
            {/*{localStorage.getItem("email")}*/}

            {localStorage.getItem("email") !== "null"
              ? localStorage.getItem("email")
              : "ایمیل ناشناس"}
          </p>
        </div>
        <MM className="metismenu" id="menu">
          {/* {localStorage.getItem("role") === "admin" && ( */}
          <>
            <li className={`${deshboard.includes(path) ? "mm-active" : ""}`}>
              <Link to="/">
                <i className="flaticon-144-layout"></i>
                <span className="nav-text">داشبورد</span>
              </Link>
            </li>
            {hasUser || localStorage.getItem("role") === "admin" ? (
              <li className={`${users.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  <i className="flaticon-003-diamond"></i>
                  <span className="nav-text">کاربران</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/users-list">
                      <span className="nav-text">لیست کاربران</span>
                    </Link>
                  </li>
                  {(hasUser && hasRole) ||
                  localStorage.getItem("role") === "admin" ? (
                    <li>
                      <Link to="/create-user">
                        <span className="nav-text">افزودن کاربر جدید</span>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </li>
            ) : null}
            <li className={`${profile.includes(path) ? "mm-active" : ""}`}>
              <Link to="/app-profile">
                <i className="flaticon-077-menu-1"></i>
                <span className="nav-text">پروفایل</span>
              </Link>
            </li>
            {hasUser || localStorage.getItem("role") === "admin" ? (
              <li className={`${account.includes(path) ? "mm-active" : ""}`}>
                <Link to="/confirmations-list">
                  <i className="flaticon-053-heart"></i>
                  <span className="nav-text">حساب های در انتظار تایید</span>
                </Link>
              </li>
            ) : null}

            {hasRole || localStorage.getItem("role") === "admin" ? (
              <li className={`${role.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  <i className="flaticon-381-user"></i>
                  <span className="nav-text">نقش ها</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === "" ? "mm-active" : ""}`}
                      to="/role-list"
                    >
                      {" "}
                      لیست نقش ها{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === "role" ? "mm-active" : ""}`}
                      to="/create-role"
                    >
                      افزودن نقش
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {hasOrder || localStorage.getItem("role") === "admin" ? (
              <li className={`${order.includes(path) ? "mm-active" : ""}`}>
                <Link to="/orders-list">
                  <i className="flaticon-049-copy"></i>
                  <span className="nav-text">لیست سفارشات</span>
                </Link>
              </li>
            ) : null}
            {hasSetting || localStorage.getItem("role") === "admin" ? (
              <li className={`${tokens.includes(path) ? "mm-active" : ""}`}>
                <Link to="/TokensList">
                  <i className="flaticon-148-open-lock"></i>
                  <span className="nav-text">توکن ها</span>
                </Link>
              </li>
            ) : null}

            {/* ) : null} */}
            {hasSetting || localStorage.getItem("role") === "admin" ? (
              <li className={`${fee.includes(path) ? "mm-active" : ""}`}>
                <Link to="/coin-details">
                  <i className="flaticon-381-magic-wand"></i>
                  <span className="nav-text">کارمزد ها </span>
                </Link>
              </li>
            ) : null}
            {hasSetting || localStorage.getItem("role") === "admin" ? (
              <li className={`${robot.includes(path) ? "mm-active" : ""}`}>
                <Link to="/robots">
                  <i className="flaticon-381-focus"></i>
                  <span className="nav-text">ربات</span>
                </Link>
              </li>
            ) : null}
            {hasSetting || localStorage.getItem("role") === "admin" ? (
              <li className={`${blocks.includes(path) ? "mm-active" : ""}`}>
                <Link to="/block-items">
                  <i className="flaticon-381-settings"></i>
                  <span className="nav-text">بلاک کردن دسترسی های سیستم</span>
                </Link>
              </li>
            ) : null}
            {hasSetting || localStorage.getItem("role") === "admin" ? (
              <li className={`${pairs.includes(path) ? "mm-active" : ""}`}>
                <Link to="/pair-coins">
                  <i className="flaticon-381-settings-2"></i>
                  <span className="nav-text">جفت ارز </span>
                </Link>
              </li>
            ) : null}
            {hasAffiliate || localStorage.getItem("role") === "admin" ? (
              <li className={`${affiliate.includes(path) ? "mm-active" : ""}`}>
                <Link to="/affiliate">
                  <i className="flaticon-381-settings-8"></i>
                  <span className="nav-text">کدهای دعوت</span>
                </Link>
              </li>
            ) : null}
            {(hasThirdParty && hasSetting) ||
            localStorage.getItem("role") === "admin" ? (
              <li className={`${thirdParty.includes(path) ? "mm-active" : ""}`}>
                <Link to="/thirdParty">
                  <i className="flaticon-023-move"></i>
                  <span className="nav-text">تنظیمات OTC</span>
                </Link>
              </li>
            ) : null}
            {hasCoining || localStorage.getItem("role") === "admin" ? (
              <li className={`${coining.includes(path) ? "mm-active" : ""}`}>
                <Link to="/coining-list">
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text">کیف پول</span>
                </Link>
              </li>
            ) : null}
            {hasBanking || localStorage.getItem("role") === "admin" ? (
              <li className={`${banking.includes(path) ? "mm-active" : ""}`}>
                <Link to="/banking-list">
                  {/* <i className="flaticon-049-copy"></i> */}
                  <i className="flaticon-381-id-card-3"></i>
                  <span className="nav-text">حساب های بانکی</span>
                </Link>
              </li>
            ) : null}
          </>
          {/* )} */}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
