import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { notificationActions } from "./../../../_actions";
import { ERROR, SUCCESS } from "./../../../_helper/alerts";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import {
  DateConvertWithTime,
  DateConvert,
} from "../../components/Defaults/dateFunction";
const Notification = ({ dispatch }) => {
  const [notifyData, setNotifyData] = useState([]);
  const [notifyTotal, setNotifyTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);

  ///////
  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(notificationActions.getAllUnreadNotifications({ page, limit }))
      .then((r) => {
        setNotifyData(r.data?.data);
        setNotifyTotal(r.data?.meta.total);
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }
  const getAllUnreadNotifications = () => {
    dispatch(notificationActions.getAllUnreadNotifications({ page, limit }))
      .then((r) => {
        setNotifyData(r?.data?.data);
        setNotifyTotal(r?.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };
  useEffect(() => {
    getAllUnreadNotifications();
  }, []);
  return (
    <>
      <div class="row page-titles mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text"></div>
        </div>
        <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">داشبورد</a>
            </li>
            <li class="breadcrumb-item active c">
              <a href="/notification"> اطلاعیه ها</a>
            </li>
          </ol>
        </div>
      </div>
      <h3>
        <span class="mr-2 ml-2 badge light text-white bg-primary rounded-circle fs-10">
          {notifyTotal}
        </span>
        پیام ها
      </h3>
      <div className="card p-4 mt-4">
        <ul className=" notifycations">
          <div className="widget-timeline header_notify_scroll dz-scroll style-1 ps p-3 ">
            <ul className="timeline">
              {notifyData ? (
                notifyData?.map((item) => {
                  return (
                    <li>
                      <div
                        className={`timeline-badge 
                        ${
                          item.priority === "LOW"
                            ? "success"
                            : item.priority === "MEDIUM"
                            ? "primary"
                            : item.priority === "HIGH"
                            ? "danger"
                            : ""
                        } 
                        `}
                      />
                      <Link
                        className="mt-2 timeline-panel c-pointer text-muted d-inline-block"
                        to="#"
                      >
                        <div className="timeline-panel">
                          <div className="media-body">
                            <h6 className="mb-1 fs-13 text-right ">
                              {item.title}
                            </h6>
                            <p className="mb-1 fs-12 text-right mt-3">
                              {item.body}
                            </p>
                            <span
                              className={`badge light fs-10 mt-3 ${
                                item.type === "DEFAULT"
                                  ? "badge-primary"
                                  : item.type === "ORDER"
                                  ? "badge-warning"
                                  : item.type === "TRADE"
                                  ? "badge-success"
                                  : item.type === "USER"
                                  ? "badge-secondary"
                                  : item.type === "BALANCE_DEPOSIT"
                                  ? "badge-danger"
                                  : item.type === "BALANCE_WITHDRAW"
                                  ? "badge-dark"
                                  : item.type === "COIN_DEPOSIT"
                                  ? "badge-light"
                                  : item.type === "COIN_WITHDRAW"
                                  ? "badge-primary"
                                  : item.type === "TICKET"
                                  ? "badge-info"
                                  : ""
                              }`}
                            >
                              {item.type === "DEFAULT"
                                ? "پیش فرض"
                                : item.type === "ORDER"
                                ? "سفارش"
                                : item.type === "TRADE"
                                ? "معامله"
                                : item.type === "USER"
                                ? "کاربر"
                                : item.type === "BALANCE_DEPOSIT"
                                ? "واریز تومان"
                                : item.type === "BALANCE_WITHDRAW"
                                ? "برداشت تومان"
                                : item.type === "COIN_DEPOSIT"
                                ? "واریز ارز"
                                : item.type === "COIN_WITHDRAW"
                                ? "برداشت ارز"
                                : item.type === "TICKET"
                                ? "تیکت"
                                : ""}
                            </span>
                            <small className="d-block mt-4">
                              {new Date(item.createdAt).toLocaleDateString(
                                "fa-IR"
                              )}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })
              ) : (
                <p>|پیامی یافت نشد</p>
              )}
            </ul>
            <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: 0, width: 0 }}
              />
            </div>
            <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: 0, height: 0 }}
              />
            </div>
          </div>
        </ul>
        {console.log({ page, notifyTotal, limit })}

        <div className="d-inline-block pb-3 ltr_direction">
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={notifyTotal}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(Notification);
