import React, {useState, useRef, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import {rolesAction} from "./../../../_actions";
import {ERROR, SUCCESS} from "./../../../_helper/alerts";
import {connect} from "react-redux";
import {DefaultButton} from "../Defaults/DefaultButton";
import {BaseInput} from "../Defaults/BaseInput";
import WriteCheckbox from "./Checkboxes/WriteCheckbox";
import ReadCheckbox from "./Checkboxes/ReadCheckbox";

import {Link} from "react-router-dom";
// import data from "./tableData.js";

const NewRole = ({dispatch, history}) => {
    // const [data, setData] = useState(
    //   document.querySelectorAll("#patientTable_basic_table tbody tr")
    // );
    const [test, settest] = useState(0);
    const [rolesList, setRolesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [scopeVal, setScopeVal] = useState("");
    const [readVal, setReadVal] = useState(false);
    const [writeVal, setWriteVal] = useState(false);
    const [name, setName] = useState("");
    const [permissions, setPermissions] = useState([]);

    const handleNameChange = (evt) => {
        setName(evt.target.value);
    };

    useEffect(() => {
        dispatch(rolesAction.getRolesConfig())
            .then((r) => {
                setRolesList(r.data?.data);
                setPermissions(r.data?.data?.permissions);
                console.log(r.data?.data);
            })
            .catch((e) =>
                ERROR(e.response?.data?.message || e.message, {
                    error: e,
                })
            )
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    var data;

    const handleWriteChecked = (write, scope) => {
        setWriteVal(write);
        setScopeVal(scope);
        data = [
            permissions.map((item, index) => {
                delete item["description"];
                if (item.scope === scope) {
                    item.scope = scope;
                    item.activity.write = write;
                    return item[0];
                }
            }),
        ];
        permissions.map((obj) => data.find((o) => o.scope === obj.scope) || obj);

    };
    const handleReadChecked = (read, scope) => {
        setReadVal(read);
        setScopeVal(scope);
        data = [
            permissions.map((item, index) => {
                delete item["description"];
                if (item.scope === scope) {
                    item.scope = scope;
                    item.activity.read = read;
                    return item[0];
                }
            }),
        ];
        permissions.map((obj) => data.find((o) => o.scope === obj.scope) || obj);
    };

    const createRole = () => {
        setLoader(true);
        const data = {
            name: name,
            permissions: permissions,
        };
        dispatch(rolesAction.createRole(data))
            .then((r) => {
                SUCCESS(r?.data?.message || "نقش جدید با موفقیت اضافه شد");
                history.push("/role-list");
                setLoader(false);
            })
            .catch((ex) => {
                setLoader(false);
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                });
            });
    };
    return (
        <div className="col-xl-12 col-lg-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title fs-14">ساختن نقش جدید</h4>
                </div>

                <div className="card-body">
                    <div className="basic-form">
                        <div className="p-3">
                            <BaseInput
                                className="form-control text-right"
                                id="description"
                                value={name}
                                onChange={handleNameChange}
                                label={"نام نقش"}
                            />
                        </div>
                        {rolesList && rolesList?.length !== 0
                            ? rolesList?.permissions?.map((item, index) => {
                                return (
                                    <>
                                        <h6 className="fs-14 mt-5">
                                            {item.scope === "user"
                                                ? "مدیریت کاربران"
                                                : item.scope === "panel"
                                                    ? "مدیریت پنل"
                                                    : item.scope === "setting"
                                                        ? "مدیریت تنظیمات"
                                                        : item.scope === "config"
                                                            ? "مدیریت کانفیگ ها"
                                                            : item.scope === "role"
                                                                ? "مدیریت نقش ها"
                                                                : item.scope === "banking"
                                                                    ? "مدیریت حساب های بانکی"
                                                                    : item.scope === "coining"
                                                                        ? "مدیریت کیف پول ها"
                                                                        : item.scope === "order"
                                                                            ? "مدیریت سفارش ها"
                                                                            : item.scope === "otc"
                                                                                ? "مدیریت سفارشات سریع"
                                                                                : item.scope === "trade"
                                                                                    ? "مدیریت معاملات"
                                                                                    : item.scope === "notification"
                                                                                        ? "مدیریت پیام ها"
                                                                                        : item.scope === "ticket"
                                                                                            ? "مدیریت تیکت ها"
                                                                                            : item.scope === "trace"
                                                                                                ? "مدیریت Trace ها"
                                                                                                : item.scope === "affiliate"
                                                                                                    ? "مدیریت کدهای دعوت"
                                                                                                    : item.scope === "thirdParty"
                                                                                                        ? "مدیریت تنظیمات OTC"
                                                                                                        : item.scope === "balance"
                                                                                                            ? "مدیریت موجودی ها"
                                                                                                            : ""}
                                        </h6>
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4">
                                                <div className="custom-control custom-checkbox d-inline"></div>
                                                <p className="d-inline mr-2">{item.scope}</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4">
                                                <ReadCheckbox
                                                    item={item}
                                                    onSelectReadChecked={handleReadChecked}
                                                    // onSelectReadType={onSelectReadType}
                                                />
                                                <p className="d-inline mr-2">read access</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4">
                                                <WriteCheckbox
                                                    item={item}
                                                    onSelectWriteChecked={handleWriteChecked}
                                                    // onSelectWriteType={handleWriteType}
                                                />
                                                <p className="d-inline mr-2">write access</p>
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                            : null}
                    </div>
                </div>
                <div className="px-2 py-3">
                    <DefaultButton
                        onClick={() => createRole()}
                        loading={loader}
                        variant="primary"
                        className="w-100"
                    >
                        ساختن نقش جدید
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(NewRole);

// import React, { useState, useRef, useEffect } from "react";
// import { Spinner } from "react-bootstrap";
// import { rolesAction } from "./../../../_actions";
// import { ERROR, SUCCESS } from "./../../../_helper/alerts";
// import { connect } from "react-redux";
// import { DefaultButton } from "../Defaults/DefaultButton";
// import { BaseInput } from "../Defaults/BaseInput";

// import { Link } from "react-router-dom";
// // import data from "./tableData.js";

// const NewRole = ({ dispatch, history }) => {
//   const [data, setData] = useState(
//     document.querySelectorAll("#patientTable_basic_table tbody tr")
//   );
//   const [test, settest] = useState(0);
//   const [rolesList, setRolesList] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [loader, setLoader] = useState(false);
//   const [scopeVal, setScopeVal] = useState(false);
//   const [readVal, setReadVal] = useState(false);
//   const [writeVal, setWriteVal] = useState(false);
//   const [roles, setRoles] = useState([]);
//   const [typeRole, setTypeRole] = useState("");
//   const [name, setName] = useState("");
//   const [permissions, setPermission] = useState([]);

//   const handleNameChange = (evt) => {
//     setName(evt.target.value);
//   };

//   useEffect(() => {
//     dispatch(rolesAction.getRolesConfig())
//       .then((r) => {
//         setRolesList(r.data?.data);
//         console.log(r.data?.data);
//       })
//       .catch((e) =>
//         ERROR(e.response?.data?.message || e.message, {
//           error: e,
//         })
//       )
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, []);

//   const createRole = () => {
//     setLoader(true);
//     const data = {
//       name: name,
//       permissions: permissions,
//     };
//     dispatch(rolesAction.createRole(data))
//       .then((r) => {
//         SUCCESS(r?.data?.message || "نقش جدید با موفقیت اضافه شد");
//         history.push("/role-list");
//         setLoader(false);
//       })
//       .catch((ex) => {
//         setLoader(false);
//         ERROR(ex.response?.data?.message || ex.message, {
//           error: ex,
//         });
//       });
//   };

//   // use effect
//   useEffect(() => {
//     setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
//     // chackboxFun();
//   }, [test]);

//   var filtered = [];
//   let scope = "";
//   let read = false;
//   let write = false;
//   let newArr = {};

//   const chackboxFun = (type) => {
//     setTypeRole(type);
//     if (type?.includes("Write")) {
//       scope = type?.replace("Write", "");
//       write = true;
//     }
//     if (type?.includes("Read")) {
//       scope = type?.replace("Read", "");
//       read = true;
//     }
//     if (!type?.includes("Read") && !type?.includes("Write")) scope = type;

//     newArr = {
//       scope: scope,
//       activity: {
//         read: read,
//         write: write,
//       },
//     };

//     setRoles([...roles, newArr]);
//   };

//   useEffect(() => {
//     const ids = roles.map((o) => o.scope);
//     filtered = roles.filter(
//       ({ scope }, index) => !ids.includes(scope, index + 1)
//     );

//     if (scopeVal === false) {
//       for (var i = 0; i < filtered.length; i++) {
//         if (filtered[i].scope === typeRole) {
//           filtered.splice(i, 1);
//         }
//       }
//     }
//     if (readVal === false && scopeVal === false) {
//       for (var i = 0; i < filtered.length; i++) {
//         if (filtered[i].scope === typeRole) {
//           filtered.splice(i, 1);
//         }
//       }
//     }
//     if (writeVal === false && scopeVal === false) {
//       for (var i = 0; i < filtered.length; i++) {
//         if (filtered[i].scope === typeRole) {
//           filtered.splice(i, 1);
//         }
//       }
//     }

//     setPermission(filtered);
//     console.log(filtered);
//   }, [roles, typeRole]);

//   useEffect(() => {
//     if (scopeVal === false) {
//       setReadVal(false);
//       setWriteVal(false);
//     }
//     if (readVal === true && writeVal === true) {
//       setScopeVal(true);
//     }
//   }, [scopeVal, readVal, writeVal]);

//   const handleScopeChange = (e) => {
//     setScopeVal(e.target.checked);
//   };
//   const handleReadChange = (e) => {
//     setReadVal(e.target.checked);
//   };
//   const handleWriteChange = (e) => {
//     setWriteVal(e.target.checked);
//   };

//   return (
//     <div className="col-xl-12 col-lg-12">
//       <div className="card">
//         <div className="card-header">
//           <h4 className="card-title fs-14">ساختن نقش جدید</h4>
//         </div>

//         <div className="card-body">
//           <div className="basic-form">
//             <div className="p-3">
//               <BaseInput
//                 className="form-control text-right"
//                 id="description"
//                 value={name}
//                 onChange={handleNameChange}
//                 label={"نام نقش"}
//               />
//             </div>
//             {rolesList && rolesList?.length !== 0
//               ? rolesList?.permissions?.map((item, index) => {
//                   return (
//                     <>
//                       <h6 className="fs-14 mt-5">{item.description}</h6>
//                       <div className="row">
//                         <div className="col-xl-4 col-lg-4">
//                           <div className="custom-control custom-checkbox d-inline">
//                             <input
//                               type="checkbox"
//                               onClick={() => chackboxFun(item.scope)}
//                               className="custom-control-input"
//                               id={`customCheckBox${item.scope}`}
//                               onChange={handleScopeChange}
//                               // checked={scopeVal}
//                               required
//                             />
//                             <label
//                               className="custom-control-label"
//                               htmlFor={`customCheckBox${item.scope}`}
//                             />
//                           </div>
//                           <p className="d-inline mr-2">{item.scope}</p>
//                         </div>
//                         <div className="col-xl-4 col-lg-4">
//                           <div className="custom-control custom-checkbox d-inline">
//                             <input
//                               type="checkbox"
//                               onClick={() => chackboxFun(`${item.scope}Read`)}
//                               className="custom-control-input"
//                               id={`customCheckBox${item.scope}Read`}
//                               onChange={handleReadChange}
//                               // checked={readVal}
//                               required
//                             />
//                             <label
//                               className="custom-control-label"
//                               htmlFor={`customCheckBox${item.scope}Read`}
//                             />
//                           </div>
//                           <p className="d-inline mr-2">read access</p>
//                         </div>
//                         <div className="col-xl-4 col-lg-4">
//                           <div className="custom-control custom-checkbox d-inline">
//                             <input
//                               type="checkbox"
//                               onClick={() => chackboxFun(`${item.scope}Write`)}
//                               className="custom-control-input"
//                               id={`customCheckBox${item.scope}Write`}
//                               onChange={handleWriteChange}
//                               // checked={writeVal}
//                               required
//                             />
//                             <label
//                               className="custom-control-label"
//                               htmlFor={`customCheckBox${item.scope}Write`}
//                             />
//                           </div>
//                           <p className="d-inline mr-2">write access</p>
//                         </div>
//                       </div>
//                     </>
//                   );
//                 })
//               : null}
//           </div>
//         </div>
//         <div className="px-2 py-3">
//           <DefaultButton
//             onClick={() => createRole()}
//             loading={loader}
//             variant="primary"
//             className="w-100"
//           >
//             ساختن نقش جدید
//           </DefaultButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// function mapStateToProps(state) {
//   return {};
// }

// export default connect(mapStateToProps)(NewRole);
