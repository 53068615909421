import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import EtrForm from "./EtrForm";
import { coiningActions } from "../../../_actions";
import { ERROR } from "../../../_helper/alerts";
import { connect } from "react-redux";
import { getAvailableCoins } from "../../../_actions/CoiningActions";

const ModalCoining = ({ children, setIsRef, isRef, dispatch }) => {
  const [modalCentered, setModalCentered] = useState(false);
  const [tabs, setTabs] = useState("Bitcoin");
  const [allCoining, setAllCoining] = useState([]);
  const handelTbas = (e) => {
    setTabs(e);
  };

  useEffect(() => {
    // dispatch(coiningActions.getPreDefinedCoins())
    dispatch(coiningActions.getAllAvailableCoins())
      .then((r) => {
        setAllCoining(r?.data?.data);
        console.log(r?.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <Button
        variant="primary"
        type="button"
        className="d-flex m-auto"
        onClick={() => setModalCentered(true)}
      >
        {children}
      </Button>
      {/* <!-- Modal --> */}
      <Modal
        className="fade customeModal"
        show={modalCentered}
        onHide={setModalCentered}
      >
        <Modal.Header>
          <h4 className="text-black">تنظیمات کوین ها</h4>
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey={allCoining[0]?.id}
            transition={false}
            id="cp-coins"
          >
            {allCoining?.map((item, index) => {
              return (
                <Tab
                  key={index}
                  eventKey={item.id}
                  title={`${item.fa} - ${item.symbol?.toUpperCase()}`}
                >
                  <EtrForm
                    isRef={isRef}
                    setIsRef={setIsRef}
                    tabs={item.id}
                    title={item.fa}
                    name={item.name}
                    isActive={item.isActive}
                    setModalCentered={setModalCentered}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalCentered(false)}
            variant="danger light"
          >
            خروج
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(ModalCoining);
