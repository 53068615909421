import React, { useEffect, useState } from "react";
import { httpRequest } from "../../../_helper/httpServices";
import { Label, FormGroup, Button } from "reactstrap";
import ModalCoining from "./ModalCoining";
import NewCoinModal from "./NewCoinModal";
import Select from "react-select";
import { Link } from "react-router-dom";

const Coins = () => {
  const [CoinsList, setCoinsList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [roleVal, setRoleVal] = useState({});
  const [isRef, setIsRef] = useState(false);
  const [] = useState();

  useEffect(() => {
    const getCoinList = () => {
      const { data } = httpRequest("get", "settings/coins")
        .then((res) => setCoinsList(res.data.data))
        .catch((err) => console.log(err));
    };
    getCoinList();
  }, []);

  useEffect(() => {
    const getCoinList = () => {
      const { data } = httpRequest("get", "settings/coins")
        .then((res) => setCoinsList(res.data.data))
        .catch((err) => console.log(err));
    };
    getCoinList();
  }, [isRef]);
  useEffect(() => {
    setFilterList(CoinsList);
  }, [CoinsList]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000" : "#000",
    }),
    control: (provided, state) => ({
      ...provided,
      alignItems: "baseline",
      backgroundColor: "#2c254a",
      borderColor: "#2c254a",
      borderRadius: 12,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.2 : 1;
      const transition = "opacity 300ms";
      return {
        ...provided,
        opacity,
        transition,
        color: state.isFocused ? "yellow" : "#fff",
      };
    },
  };
  const handleChangeRole = (event, selected) => {
    setRoleVal(selected);
    let data = CoinsList.filter((val) => {
      return val.symbol === event?.value;
    });
    return setFilterList(data);
  };

  const rolesOptions = CoinsList?.map((item) => {
    return {
      value: item.id,
      label: (
        <>
          <div className="row rtl_direction">
            <div className="col-2 coin_id_input">
              <img
                className="cc_size"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "./images/coins/noImg.png";
                }}
                src={
                  process.env.REACT_APP_SOCKET_URL +
                  `assets/icon/${item?.id?.toLowerCase()}.png`
                }
              />
            </div>
            <span className="mr-3">{item.fa}</span>
          </div>
        </>
      ),
    };
  });

  return (
    <div className="">
      <div className="card">
        <div className="card-header d-sm-flex d-block pb-0 border-0">
          <div>
            <h4 className="fs-20 text-black">
              <NewCoinModal isEdit={false}>
                <a className="btn btn-secondary mb-2">
                  <i className="fa fa-plus ml-2" />
                  افزودن ارز جدید
                </a>
              </NewCoinModal>
            </h4>
          </div>
          <div className="">
            <Label for="status">انتخاب کنید</Label>
            <Select
              styles={customStyles}
              placeholder={CoinsList?.map(
                (val) => val.symbol === "btc" && "بیتکوین"
              )}
              // defaultValue={"roleVal"}
              className={`react-select w-100 text-left`}
              classNamePrefix="react-select"
              // value={roleVal}
              onChange={handleChangeRole}
              options={rolesOptions}
              instanceId="roleVal"
              name="roleVal"
            />
          </div>
        </div>
        <div className="card-body">
          {filterList?.slice(0, 1).map((item) => (
            <div key={item.id} className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <h3>سفارش</h3>
                <h3 className="mt-3">
                  <img
                    className="cc_size"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "./images/coins/noImg.png";
                    }}
                    src={
                      process.env.REACT_APP_SOCKET_URL +
                      `assets/icon/${item?.id?.toLowerCase()}.png`
                    }
                  />

                  <span className="ml-2"></span>
                  {item.fa}
                </h3>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>حداقل سفارش</span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.order.min}
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>حداکثر سفارش</span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.order.max.toLocaleString()}
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>تخفیف</span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.order.feeFactor}%
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span> کارمزد تا سقف</span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.order.feeMax}%
                </div>
              </div>
              {/* diposit */}
              <h3 className="my-3">واریز</h3>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>کمترین حد واریز </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.deposit.min}
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>بیشترین حد واریز </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.deposit.max}
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>کارمزد در واریز </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.deposit.feeFactor}%
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span> کارمزد واریز تا سقف </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.deposit.feeMax}%
                </div>
              </div>
              {/* widtdraw */}
              <h3 className="my-3">برداشت</h3>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>کمترین حد برداشت</span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.withdraw.min}
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span> بیشترین حد برداشت </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.withdraw.max}
                </div>
              </div>
              <div className="row mt-4 ltr_direction">
                <div className="col-md-3 title__left">
                  <span>کارمزد در برداشت </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.withdraw.feeFactor}%
                </div>
              </div>
              <div className="row mt-4  ltr_direction">
                <div className="col-md-3 title__left">
                  <span>کارمزد برداشت تا سقف </span>
                </div>
                <div className="col-md-9 body__right fs-18">
                  {item.coining.withdraw.feeMax}%
                </div>
              </div>
            </div>
          ))}
          <ModalCoining isRef={isRef} setIsRef={setIsRef} roleVal={roleVal}>
            <span className=" d-flex m-auto">ویرایش کوین ها</span>
          </ModalCoining>
        </div>
      </div>
    </div>
  );
};

export default Coins;
