import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import { tokenActions } from "../../../_actions";
import { ERROR } from "../../../_helper";
import { connect } from "react-redux";
import TokensModal from "./TokensModal";
import Pagination from "react-js-pagination";

const TokensTable = ({ dispatch }) => {
  const [tokensList, setTokensList] = useState([]);
  const [tokenData, setTokenData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [hasImg, setHasImg] = useState(true);
  const [tokensMeta, setTokensMeta] = useState([]);

  const getTokensList = () => {
    dispatch(tokenActions.getAllTokens({ page, limit }))
      .then((r) => {
        setTokensList(r.data?.data);
        setTokensMeta(r.data?.meta);
        console.log(r.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  async function handlePageChange(page) {
    setPage(page);
    dispatch(tokenActions.getAllTokens({ page, limit }))
      .then((r) => {
        setTokensList(r.data?.data);
        setTokensMeta(r.data?.meta);
        console.log(r.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    getTokensList();
  }, []);

  const handleSelect = () => {
    // setIsEdit(false);
    getTokensList();
  };

  const handleSelectEdit = () => {
    // setIsEdit(false);
    getTokensList();
  };

  const handleEditSelect = (item) => {
    setRefresh(item);
    setIsEdit(true);
    dispatch(tokenActions.getAnToken(item?._id))
      .then((r) => {
        setTokenData(r.data?.data);
        console.log(r.data?.data);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  const deleteToken = (item) => {
    dispatch(tokenActions.deleteToken(item?._id))
      .then((r) => {
        setTokensList(r.data?.data);
        getTokensList();
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  };

  const hideImage = () => {
    setHasImg(false);
  };

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="mb-0 fs-20 text-black">Tokens</h4>
          <TokensModal isEdit={false} onSelect={handleSelect}>
            <a className="btn btn-secondary mb-2">
              <i className="fa fa-plus ml-2" />
              افزودن Token جدید{" "}
            </a>
          </TokensModal>
        </div>
        <div className="card-body">
          {tokensList?.map((item) => {
            return (
              <div className="coin-holding third-part-wrapper flex-wrap mb-3">
                <div
                  className="row w-100"
                  style={{ justifyContent: "space-around" }}
                >
                  <div className="mb-2 coin-bx">
                    <div className="d-flex align-items-center">
                      <div className="ml-3">
                        <h4 className="coin-font font-w400 fs-16 mb-0 text-white">
                          <img
                            className="cc_size ml-2"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "./images/coins/noImg.png";
                            }}
                            // src={`./images/coins/${item?.sym bol?.toLowerCase()}.svg`}
                            src={
                              process.env.REACT_APP_SOCKET_URL +
                              `assets/icon/${item?.symbol?.toLowerCase()}.png`
                            }
                          />

                          {item?.symbol?.toUpperCase()}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-end">
                        <Link
                          to={"#"}
                          className="btn btn-primary text-white text-nowrap py-1 fs-12"
                        >
                          بلاک چین {item?.blockchain?.toUpperCase()}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 ml-2 fs-12 badge badge-outline-primary badge-rounded font-w400 py-1">
                        شبکه {item?.network}
                      </p>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={"#"}
                        className={`btn ${
                          item.isActive ? "btn-success" : "btn-danger"
                        } text-white text-nowrap py-1 fs-12`}
                      >
                        {item?.isActive ? "فعال" : "غیرفعال"}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-7">
                    <div className="d-flex mt-3 align-items-center third-party-coins">
                      <h4 className="coin-font font-w400 fs-16 mb-0 text-white ltr_direction">
                        <span style={{ color: "#fff" }}>Smart Contract: </span>{" "}
                        <p className="mb-0 ml-2 fs-16 badge text-light badge-secondary badge-rounded font-w400">
                          {item?.contract}
                        </p>
                      </h4>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex mt-3 align-items-center third-party-coins">
                      <h4 className="coin-font font-w400 fs-16 mb-0 text-white ltr_direction">
                        <span style={{ color: "#fff" }}>Decimal: </span>{" "}
                        <p className="mb-0 ml-2 fs-14 d-inline text-light font-w400">
                          {item?.decimal}
                        </p>
                      </h4>
                    </div>
                  </div>
                  <div className="col-1 text-left tokens-delete-icon">
                    <a onClick={() => deleteToken(item)}>
                      <i class="las la-trash-alt fs-20"></i>
                    </a>
                  </div>
                  <div className="col-1 text-right tokens-edit-icon">
                    <TokensModal
                      isEdit={true}
                      tokenData={tokenData}
                      onSelectEdit={handleSelectEdit}
                    >
                      <a
                        onClick={() => handleEditSelect(item)}
                        className="fs-11"
                        style={{ color: "#fff" }}
                      >
                        <i class="las la-edit fs-20"></i>
                      </a>
                    </TokensModal>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              نمایش{" "}
              {tokensMeta?.page * tokensMeta?.limit - tokensMeta?.limit + 1} تا{" "}
              {tokensMeta?.page * tokensMeta?.limit} از {tokensMeta?.total} توکن
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate"
            >
              <div className="d-inline-block pb-3 ltr_direction">
                <Pagination
                  activePage={page}
                  itemsCountPerPage={tokensMeta?.limit}
                  totalItemsCount={tokensMeta?.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(TokensTable);
