import { httpRequest } from "./../_helper";

export const coiningActions = {
  getCoining,
  getCoiningHistory,
  getPreDefinedCoins,
  getAllAvailableCoins
};

function getCoining({ page, limit, flowTitle, coinTitle, statusTitle }) {
  return () => {
    return httpRequest(
      "get",
      `coining/admin?page=${page}&limit=${limit}&search=${
          flowTitle ? `flow` : ""
      }${flowTitle && coinTitle ? `,` : ""}${coinTitle ? `coin` : ""}${
        (coinTitle && statusTitle) || (flowTitle && statusTitle) ? `,` : ""
      }${statusTitle ? `status` : ""}&query=${flowTitle ? `${flowTitle}` : ""}${
          flowTitle && coinTitle ? `,` : ""
      }${coinTitle ? `${coinTitle}` : ""}${
        (coinTitle && statusTitle) || (flowTitle && statusTitle) ? `,` : ""
      }${statusTitle ? `${statusTitle}` : ""}`
    );
  };
}

function getCoiningHistory({ page, limit, userId }) {
  return () => {
    return httpRequest(
      "get",
      `coining/admin?page=${page}&limit=${limit}&search=${
        userId ? `userId` : ""
      }&query=${userId ? `${userId}` : ""}`
    );
  };
}

function getAllAvailableCoins() {
  return () => {
    return httpRequest("get", "settings/coins");
  };
}

function getPreDefinedCoins() {
  return () => {
    return httpRequest("get", `settings/coins/defined`);
  };
}
