import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { transactionAction } from "./../../../_actions";
import { ERROR } from "./../../../_helper/alerts";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";

import { Link } from "react-router-dom";

const TransactionTable = ({ dispatch }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [test, settest] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [searchFamily, setSearchFamily] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [description, setDescription] = useState("");
  const [bankingListData, setBankingList] = useState([]);
  const [coiningListData, setCoiningList] = useState([]);
  const [bankingMeta, setBankingMeta] = useState([]);
  const [coiningMeta, setCoiningMeta] = useState([]);
  const [usersConfirmedMeta, setUsersConfirmedMeta] = useState([]);
  const [ordersListData, setOrdersList] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const [identitiesAccept, setIdentitiesAccept] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeToggle, setActiveToggle] = useState("all");

  const handleNameChange = (evt) => {
    setSearchName(evt.target.value);
  };

  const handleLastChange = (evt) => {
    setSearchFamily(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setSearchEmail(evt.target.value);
  };

  const handleMobileChange = (evt) => {
    setSearchMobile(evt.target.value);
  };

  const onChangeDesc = (evt) => {
    setDescription(evt.target.value);
  };

  useEffect(() => {
    dispatch(
      transactionAction.getBankingTransactionList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setBankingList(r.data?.data);
        setBankingMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  async function handlePageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(
      transactionAction.getBankingTransactionList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setBankingList(r.data?.data);
        setBankingMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }

  useEffect(() => {
    dispatch(
      transactionAction.getCoiningTransactionList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setCoiningList(r.data?.data);
        setCoiningMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }, []);

  async function handleCoiningPageChange(pageNumber) {
    setPage(pageNumber);
    const page = pageNumber;
    dispatch(
      transactionAction.getCoiningTransactionList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setCoiningList(r.data?.data);
        setCoiningMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
      })
      .catch((ex) =>
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        })
      );
  }

  async function searchBy() {
    setLoader(true);
    dispatch(
      transactionAction.getBankingTransactionList({
        page,
        limit,
        searchName,
        searchFamily,
        searchEmail,
        searchMobile,
      })
    )
      .then((r) => {
        setBankingList(r.data?.data);
        setBankingMeta(r.data?.meta);
        setTotalItems(r.data?.meta?.total);
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(true);
        ERROR(ex.response?.data?.message || ex.message, {
          error: ex,
        });
      });
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    chackboxFun();
  }, [test]);

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="profile-tab">
          <div className="custom-tab-1">
            <ul className="nav nav-tabs pr-2 my-3">
              <li className="nav-item">
                <Link
                  to="#all"
                  data-toggle="tab"
                  onClick={() => setActiveToggle("all")}
                  className={`nav-link ${
                    activeToggle === "all" ? "active show" : ""
                  }`}
                >
                  همه تراکنش های حساب بانکی
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveToggle("waiting")}
              >
                <Link
                  to="#waiting"
                  data-toggle="tab"
                  className={`nav-link ${
                    activeToggle === "waiting" ? "active show" : ""
                  }`}
                >
                  همه تراکنش های کیف پول
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div
            id="all"
            className={`tab-pane fade ${
              activeToggle === "all" ? "active show" : ""
            }`}
          >
            <Row className="p-3">
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام"}
                  id={"search"}
                  name="search"
                  value={searchName}
                  onChange={handleNameChange}
                />
              </Col>
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام خانوادگی"}
                  id={"searchF"}
                  name="searchF"
                  value={searchFamily}
                  onChange={handleLastChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ایمیل"}
                  id={"searchE"}
                  name="searchE"
                  value={searchEmail}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"موبایل"}
                  id={"searchM"}
                  name="searchM"
                  value={searchMobile}
                  onChange={handleMobileChange}
                />
              </Col>
              <Col lg={2}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchBy()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {bankingListData && bankingListData?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            کاربر
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                          >
                            مقدار
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                          >
                            قیمت واحد
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          >
                            قیمت (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                          >
                            وضعیت
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            تاریخ
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {bankingListData && bankingListData?.length !== 0 ? (
                        bankingListData?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>{item.userId}</td>
                              <td>{item.amount ? item.amount : "-----"}</td>
                              <td>
                                {item.priceUnit?.toLocaleString()
                                  ? item.priceUnit
                                  : "-----"}
                              </td>
                              <td>
                                {item.price?.toLocaleString()
                                  ? item.price
                                  : "-----"}
                              </td>
                              <td>
                                {item.status === "pending"
                                  ? "در حال انتظار"
                                  : item.status === "success"
                                  ? "موفق"
                                  : "رد شده"}
                              </td>
                              <td>
                                {item.createdAt ? item.createdAt : "-----"}
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="table-dropdown i-false btn tp-btn-light sharp"
                                  >
                                    <span className="fs--1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <Link
                                        className="w-100 fs-11 text-secondary text-right"
                                        to={`orderDetails/${item._id}`}
                                      >
                                        نمایش جزئیات
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {bankingMeta?.page * bankingMeta?.limit -
                        bankingMeta?.limit +
                        1}{" "}
                      تا {bankingMeta?.page * bankingMeta?.limit} از{" "}
                      {bankingMeta?.total} کاربر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={bankingMeta?.limit}
                          totalItemsCount={bankingMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="waiting"
            className={`tab-pane fade ${
              activeToggle === "waiting" ? "active show" : ""
            }`}
          >
            <Row className="p-3">
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام"}
                  id={"search"}
                  name="search"
                  value={searchName}
                  onChange={handleNameChange}
                />
              </Col>
              <Col lg={2} className="ml-0">
                <BaseInput
                  placeholder={"نام خانوادگی"}
                  id={"searchF"}
                  name="searchF"
                  value={searchFamily}
                  onChange={handleLastChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"ایمیل"}
                  id={"searchE"}
                  name="searchE"
                  value={searchEmail}
                  onChange={handleEmailChange}
                />
              </Col>
              <Col lg={3} className="ml-0">
                <BaseInput
                  placeholder={"موبایل"}
                  id={"searchM"}
                  name="searchM"
                  value={searchMobile}
                  onChange={handleMobileChange}
                />
              </Col>
              <Col lg={2}>
                <DefaultButton
                  loading={loader}
                  color={"primary"}
                  className={"d-block w-100"}
                  onClick={() => searchBy()}
                >
                  جستجو
                </DefaultButton>
              </Col>
            </Row>
            <div className="card-body pt-0">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    {coiningListData && coiningListData?.length !== 0 && (
                      <thead>
                        <tr className="text-center" role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="activate to sort column descending"
                            style={{ width: 32 }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun("all")}
                                className="custom-control-input"
                                id="checkAll"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkAll"
                              />
                            </div>
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient ID: activate to sort column ascending"
                          >
                            نام ارز
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                          >
                            مقدار
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date Check in: activate to sort column ascending"
                          >
                            مقدار کل
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Patient Name: activate to sort column ascending"
                          >
                            عملیات
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Doctor Assgined: activate to sort column ascending"
                          >
                            قیمت (تومان)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Disease: activate to sort column ascending"
                          >
                            وضعیت
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            تاریخ
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            جزئیات
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {coiningListData && coiningListData?.length !== 0 ? (
                        coiningListData?.map((item, index) => {
                          return (
                            <tr role="row" className="odd text-center">
                              <td className="sorting_1">
                                <div className="custom-control custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    onClick={() => chackboxFun()}
                                    className="custom-control-input"
                                    id={`customCheckBox${index}`}
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`customCheckBox${index}`}
                                  />
                                </div>
                              </td>
                              <td>{item.coin}</td>
                              <td>{item.amount ? item.amount : "-----"}</td>
                              <td>
                                {item.totalAmount ? item.totalAmount : "-----"}
                              </td>
                              <td>
                                {item.flow === "deposit" ? "واریز" : "برداشت"}
                              </td>
                              <td>{item.price ? item.price : "-----"}</td>
                              <td>
                                {item.status === "pending"
                                  ? "در حال انتظار"
                                  : item.status === "success"
                                  ? "موفق"
                                  : "رد شده"}
                              </td>
                              <td>
                                {item.createdAt ? item.createdAt : "-----"}
                              </td>
                              <td className="py-2 text-right">
                                {" "}
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="table-dropdown i-false btn tp-btn-light sharp"
                                  >
                                    <span className="fs--1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          stroke-width="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      className="text-light text-right"
                                    >
                                      <Link
                                        className="w-100 fs-11 text-secondary text-right"
                                        to={`orderDetails/${item._id}`}
                                      >
                                        نمایش جزئیات
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="card-content bg-primary p-3 rounded text-center">
                          <span className="">
                            اطلاعاتی جهت نمایش وجود ندارد
                          </span>
                        </div>
                      )}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      نمایش{" "}
                      {coiningMeta?.page * coiningMeta?.limit -
                        coiningMeta?.limit +
                        1}{" "}
                      تا {coiningMeta?.page * coiningMeta?.limit} از{" "}
                      {coiningMeta?.total} کاربر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <div className="d-inline-block pb-3 ltr_direction">
                        <Pagination
                          activePage={page}
                          itemsCountPerPage={coiningMeta?.limit}
                          totalItemsCount={coiningMeta?.total}
                          pageRangeDisplayed={5}
                          onChange={handleCoiningPageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(TransactionTable);
