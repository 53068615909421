import Card1 from "./../../../images/card/card1.jpg";
import Card2 from "./../../../images/card/card2.jpg";
import Card3 from "./../../../images/card/card3.jpg";
import Card4 from "./../../../images/card/card4.jpg";


export const getCardToShow = (index) => {
    switch (index % 4) {
        case 0:
            return Card1
        case 1:
            return Card2
        case 2:
            return Card3
        case 3:
            return Card4
        default:
            return Card1
    }
}


export const getCoinDetail = (userTradesData, coin) => {
    if (userTradesData) {
        return {
            tradeOfDay: userTradesData.tradeOfDay[coin],
            tradeOfWeek: userTradesData.tradeOfWeek[coin],
            tradeOfMonth: userTradesData.tradeOfMonth[coin],
            tradeOfTotal: userTradesData.tradeOfTotal[coin]
        }
    }
}

export const getOrderDetail = (userOrdersData, order) => {
    if (userOrdersData) {
        return {
            orderOfDay: userOrdersData.orderOfDay[order],
            orderOfWeek: userOrdersData.orderOfWeek[order],
            orderOfMonth: userOrdersData.orderOfMonth[order],
            orderOfTotal: userOrdersData.orderOfTotal[order]
        }
    }
}