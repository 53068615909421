import { authConstants } from "./../_constants/auth.constants";

const initialState = [
  {
    requestOtp: "",
    verifyOtp: "",
    verify2FA: "",
    logout: "",
  },
];

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.REQUEST_OTP:
      return {
        ...state,
        requestOtp: action.data,
      };
    case authConstants.VERIFY_OTP:
      return {
        ...state,
        verifyOtp: action.data,
      };
    case authConstants.VERIFY_2FA:
      return {
        ...state,
        verify2FA: action.data,
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        logout: action.data,
      };
    default:
      return state;
  }
}
