import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

const WriteCheckbox = ({ onSelectWriteChecked, item }) => {
  const [writeVal, setWriteVal] = useState(false);
  const [writeType, setWriteType] = useState(false);

  const handleWriteChange = (e) => {
    setWriteVal(e.target.checked);
    onSelectWriteChecked(e.target.checked, item.scope);
  };
  const chackboxFun = (type) => {
    // onSelectWriteType(type, item.scope);
  };

  return (
    <div className="custom-control custom-checkbox d-inline">
      <input
        type="checkbox"
        onClick={() => chackboxFun(`${item.scope}Write`)}
        className="custom-control-input"
        id={`customCheckBox${item.scope}Write`}
        onChange={handleWriteChange}
        checked={writeVal}
        required
      />
      <label
        className="custom-control-label"
        htmlFor={`customCheckBox${item.scope}Write`}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(WriteCheckbox);
